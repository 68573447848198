import { get, post, deleteMethod, put } from './core';

/**
 * @description Список статей
 * @param {number} page
 * @return {Promise<any>}
 */
const getArticlesList = (page: number): Promise<any> =>
  get(`/api/admin/article?page=${page}`);

/**
 * @description Создать статью
 * @param {object} params
 * @return {Promise<any>}
 */
const createArticle = (params: any): Promise<any> =>
  post('/api/admin/article', params, true);

/**
 * @description Обновить статью
 * @param {number} newsId
 * @param {object} params
 * @return {Promise<any>}
 */
const updateArticle = (newsId: number, params: any): Promise<any> =>
  post(`/api/admin/article/${newsId}`, params, true);

/**
 * @description Удалить статью
 * @param {number} newsId
 * @return {Promise<any>}
 */
const deleteArticle = (newsId: number): Promise<any> =>
  deleteMethod(`/api/admin/article/${newsId}`);

/**
 * @description Получить статью по id
 * @param {number} newsId
 * @return {Promise<any>}
 */
const getArticleById = (newsId: number): Promise<any> =>
  get(`/api/admin/article/${newsId}`);

/**
 * @description Поиск по статье
 * @param {object} params
 * @return {Promise<any>}
 */
const searchArticles = (params: any): Promise<any> =>
  get('/api/admin/article/search', params);



// Категории статей

/**
 * @description Получить категории статей
 * @return {Promise<any>}
 */
const getArticleCategory = (): Promise<any> =>
  get('/api/admin/article-category');

/**
 * @description Поиск по категориями статей
 * @param {object} params
 * @return {Promise<any>}
 */
const searchArticleCategory = (params: any): Promise<any> =>
  get(`/api/admin/article-category/search`, params);

/**
 * @description Получить категорию статьи по id
 * @param {number} id
 * @return {Promise<any>}
 */
const getArticleCategoryById = (id: number): Promise<any> =>
  get(`/api/admin/article-category/${id}`);

/**
 * @description Создать категорию статьи
 * @param params
 * @return {Promise<any>}
 */
const createCategoryArticle = (params: any): Promise<any> =>
  post(`/api/admin/article-category`, params);

/**
 * @description Обновить категорию статьи по id
 * @param {number} id
 * @param {object} params
 * @return {Promise<any>}
 */
const updateCategoryArticle = (id: number, params: any): Promise<any> =>
  put(`/api/admin/article-category/${id}`, params);

/**
 * @description Удалить категорию статьи
 * @param {number} id
 * @return {Promise<any>}
 */
const deleteArticleCategory = (id: number) =>
  deleteMethod(`/api/admin/article-category/${id}`);

export const restoreArticle = (id: number): Promise<any> => put(`/api/admin/article/${id}/restore`, {});

export const articles = {
  getArticlesList,
  createArticle,
  updateArticle,
  deleteArticle,
  getArticleById,
  searchArticles,
  getArticleCategory,
  searchArticleCategory,
  getArticleCategoryById,
  createCategoryArticle,
  updateCategoryArticle,
  deleteArticleCategory,
};

export default { articles };
