// @ts-ignore
import Cookie from '@/helpers/Cookie';
import queryString from 'qs';
// @ts-ignore
import {API_URL} from '@/global/constants';

const defaultParams = {
  // token: Cookie.get('admin_token'),
};

/**
 * @param {string} url - url для запроса
 * @param {object} params
 * @return {Promise}
 */
export const get = async (url: string, queryParams?: any, params: {} = {}): Promise<any> => {
  const query = queryString.stringify(Object.assign({}, defaultParams, queryParams)).replace(/[^=&]+=(?:&|$)/g, '');
  const requestUrl = query ? `${API_URL}${url}?${query}` : `${API_URL}${url}`;

  const requestParams = Object.assign(
    {
      headers: {
        Authorization: `Bearer ${Cookie.get('admin_token')}`,
        Accept: 'application/json',
      },
    },
    params,
  );

  return await fetch(requestUrl, requestParams);
};

/**
 * @param {string} url - url для запроса
 * @param {object} params - отправляемые параметры
 * @param {boolean} isForm
 * @return {Promise}
 */
export const post = async (
  url: string,
  params?: any,
  isForm?: boolean,
): Promise<any> => {
  const contentType = isForm ? 'multipart/form-data' : 'application/json';

  const options = {
    method: 'POST',
    body: isForm ? params : JSON.stringify(params),
    headers: {
      'Authorization': `Bearer ${Cookie.get('admin_token')}`,
      'Content-Type': contentType,
      'Accept': 'application/json',
    },
  };

  if (isForm) {
    delete options.headers['Content-Type'];
  }

  return await fetch(`${API_URL}${url}`, options).then((response) => {
    if (!response.ok) {
      throw response;
    }

    return response;
  });
};

/**
 * @param {string} url - url для запроса
 * @param {object} params - отправляемые параметры
 * @return {Promise}
 */
export const put = async (url: string, params: any): Promise<any> => {
  return await fetch(`${API_URL}${url}`, {
    method: 'PUT',
    body: JSON.stringify(params),
    headers: {
      'Authorization': `Bearer ${Cookie.get('admin_token')}`,
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
  });
};

/**
 * @param {string} url - url для запроса
 * @return {Promise<any>}
 */
export const deleteMethod = async (url: string): Promise<any> => {
  return await fetch(`${API_URL}${url}`, {
    method: 'DELETE',
    headers: {
      'Authorization': `Bearer ${Cookie.get('admin_token')}`,
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
  });
};

/**
 * @param {string} url - url для запроса
 * @param {object} params - отправляемые параметры
 * @return {Promise}
 */
export const postMethod = async (url: string, params: any): Promise<any> => {
  return await fetch(`${API_URL}${url}`, {
    method: 'POST',
    body: JSON.stringify(params),
    headers: {
      'Authorization': `Bearer ${Cookie.get('admin_token')}`,
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
  });
};

export default {get, post, put, deleteMethod};
