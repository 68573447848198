var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('PageTitle', {
    attrs: {
      "title": "Спонсорские приложения"
    }
  }, [_c('router-link', {
    attrs: {
      "to": {
        name: 'AddApplicationsSponsored'
      }
    }
  }, [_c('a-button', {
    attrs: {
      "size": "large",
      "icon": "plus",
      "type": "primary"
    }
  }, [_vm._v("Добавить спонсорское приложение ")])], 1)], 1), _c('a-layout-content', {
    staticClass: "page__content"
  }, [_c('a-table', {
    attrs: {
      "columns": _vm.columns,
      "pagination": false,
      "dataSource": _vm.apps,
      "loading": _vm.loading,
      "rowKey": "id"
    },
    scopedSlots: _vm._u([{
      key: "device_type",
      fn: function fn(device_type) {
        return [_vm._v(" " + _vm._s(_vm.getDeviceTypeName(device_type)) + " ")];
      }
    }, {
      key: "domains",
      fn: function fn(domains) {
        return [_vm._v(" " + _vm._s(domains.map(function (domain) {
          return domain.short_name;
        }).join(',')) + " ")];
      }
    }, {
      key: "action",
      fn: function fn(action) {
        return [_vm._v(" " + _vm._s(_vm.getActionName(action)) + " ")];
      }
    }, {
      key: "actions",
      fn: function fn(row) {
        return [_c('router-link', {
          attrs: {
            "to": {
              name: 'EditApplicationsSponsored',
              params: {
                id: row.id
              }
            }
          }
        }, [_c('a-button', {
          staticClass: "gray-btn",
          staticStyle: {
            "margin-right": "10px"
          },
          attrs: {
            "type": "primary",
            "icon": "edit"
          }
        })], 1), _c('a-button', {
          staticClass: "gray-btn",
          attrs: {
            "type": "danger",
            "icon": "delete"
          },
          on: {
            "click": function click($event) {
              return _vm.deleteSponsoredApplication(row.id);
            }
          }
        })];
      }
    }])
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }