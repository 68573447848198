var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('PageTitle', {
    attrs: {
      "title": "Тег Листинги"
    }
  }), _c('a-layout-content', {
    staticClass: "page__content"
  }, [_vm.loading ? _c('a-spin', {
    attrs: {
      "size": "large"
    }
  }) : _c('a-row', [_c('a-card', {
    attrs: {
      "title": ""
    }
  }, [_c('a-tabs', _vm._l(_vm.LOCALE_LIST, function (_ref) {
    var title = _ref.title,
      locale = _ref.locale;
    return _c('a-tab-pane', {
      key: locale,
      attrs: {
        "tab": title
      }
    }, [_c('a-tabs', [_c('a-tab-pane', {
      key: "default",
      attrs: {
        "tab": "Общая"
      }
    }, [_c('a-col', {
      attrs: {
        "span": "24"
      }
    }, [_c('a-form-item', {
      attrs: {
        "label": "H1"
      }
    }, [_c('a-input', {
      attrs: {
        "size": "large",
        "placeholder": "H1"
      },
      model: {
        value: _vm.tagListing.translations[locale].h1,
        callback: function callback($$v) {
          _vm.$set(_vm.tagListing.translations[locale], "h1", $$v);
        },
        expression: "tagListing.translations[locale].h1"
      }
    })], 1)], 1), _c('a-col', {
      attrs: {
        "span": "24"
      }
    }, [_c('a-form-item', {
      attrs: {
        "label": "Заголовок"
      }
    }, [_c('a-input', {
      attrs: {
        "size": "large",
        "placeholder": "Заголовок"
      },
      model: {
        value: _vm.tagListing.translations[locale].title,
        callback: function callback($$v) {
          _vm.$set(_vm.tagListing.translations[locale], "title", $$v);
        },
        expression: "tagListing.translations[locale].title"
      }
    })], 1)], 1), _c('a-col', {
      attrs: {
        "span": "24"
      }
    }, [_c('a-form-item', {
      attrs: {
        "label": "Description"
      }
    }, [_c('a-textarea', {
      attrs: {
        "size": "large",
        "placeholder": "Description",
        "auto-size": ""
      },
      model: {
        value: _vm.tagListing.translations[locale].description,
        callback: function callback($$v) {
          _vm.$set(_vm.tagListing.translations[locale], "description", $$v);
        },
        expression: "tagListing.translations[locale].description"
      }
    })], 1)], 1), _c('a-col', {
      attrs: {
        "span": "24"
      }
    }, [_c('a-form-item', {
      attrs: {
        "label": "Breadcrumbs"
      }
    }, [_c('a-input', {
      attrs: {
        "size": "large",
        "placeholder": "Breadcrumbs"
      },
      model: {
        value: _vm.tagListing.translations[locale].breadcrumbs,
        callback: function callback($$v) {
          _vm.$set(_vm.tagListing.translations[locale], "breadcrumbs", $$v);
        },
        expression: "tagListing.translations[locale].breadcrumbs"
      }
    })], 1)], 1), _c('a-col', {
      attrs: {
        "span": "24"
      }
    }, [_c('a-form-item', {
      attrs: {
        "label": "Content"
      }
    }, [_c('a-textarea', {
      attrs: {
        "size": "large",
        "placeholder": "Content",
        "auto-size": ""
      },
      model: {
        value: _vm.tagListing.translations[locale].content,
        callback: function callback($$v) {
          _vm.$set(_vm.tagListing.translations[locale], "content", $$v);
        },
        expression: "tagListing.translations[locale].content"
      }
    })], 1)], 1)], 1), _c('a-tab-pane', {
      key: "popularity",
      attrs: {
        "tab": "По популярности"
      }
    }, [_c('a-col', {
      attrs: {
        "span": "24"
      }
    }, [_c('a-form-item', {
      attrs: {
        "label": "H1"
      }
    }, [_c('a-input', {
      attrs: {
        "size": "large",
        "placeholder": "H1"
      },
      model: {
        value: _vm.tagListing.translations[locale].popularity_h1,
        callback: function callback($$v) {
          _vm.$set(_vm.tagListing.translations[locale], "popularity_h1", $$v);
        },
        expression: "tagListing.translations[locale].popularity_h1"
      }
    })], 1)], 1), _c('a-col', {
      attrs: {
        "span": "24"
      }
    }, [_c('a-form-item', {
      attrs: {
        "label": "Заголовок"
      }
    }, [_c('a-input', {
      attrs: {
        "size": "large",
        "placeholder": "Заголовок"
      },
      model: {
        value: _vm.tagListing.translations[locale].popularity_title,
        callback: function callback($$v) {
          _vm.$set(_vm.tagListing.translations[locale], "popularity_title", $$v);
        },
        expression: "tagListing.translations[locale].popularity_title"
      }
    })], 1)], 1), _c('a-col', {
      attrs: {
        "span": "24"
      }
    }, [_c('a-form-item', {
      attrs: {
        "label": "Description"
      }
    }, [_c('a-textarea', {
      attrs: {
        "size": "large",
        "placeholder": "Description",
        "auto-size": ""
      },
      model: {
        value: _vm.tagListing.translations[locale].popularity_description,
        callback: function callback($$v) {
          _vm.$set(_vm.tagListing.translations[locale], "popularity_description", $$v);
        },
        expression: "tagListing.translations[locale].popularity_description"
      }
    })], 1)], 1), _c('a-col', {
      attrs: {
        "span": "24"
      }
    }, [_c('a-form-item', {
      attrs: {
        "label": "Breadcrumbs"
      }
    }, [_c('a-input', {
      attrs: {
        "size": "large",
        "placeholder": "Breadcrumbs"
      },
      model: {
        value: _vm.tagListing.translations[locale].popularity_breadcrumbs,
        callback: function callback($$v) {
          _vm.$set(_vm.tagListing.translations[locale], "popularity_breadcrumbs", $$v);
        },
        expression: "tagListing.translations[locale].popularity_breadcrumbs"
      }
    })], 1)], 1), _c('a-col', {
      attrs: {
        "span": "24"
      }
    }, [_c('a-form-item', {
      attrs: {
        "label": "Content"
      }
    }, [_c('a-textarea', {
      attrs: {
        "size": "large",
        "placeholder": "Content",
        "auto-size": ""
      },
      model: {
        value: _vm.tagListing.translations[locale].popularity_content,
        callback: function callback($$v) {
          _vm.$set(_vm.tagListing.translations[locale], "popularity_content", $$v);
        },
        expression: "tagListing.translations[locale].popularity_content"
      }
    })], 1)], 1)], 1), _c('a-tab-pane', {
      key: "last_updated",
      attrs: {
        "tab": "По обновлениям"
      }
    }, [_c('a-col', {
      attrs: {
        "span": "24"
      }
    }, [_c('a-form-item', {
      attrs: {
        "label": "H1"
      }
    }, [_c('a-input', {
      attrs: {
        "size": "large",
        "placeholder": "H1"
      },
      model: {
        value: _vm.tagListing.translations[locale].last_updated_h1,
        callback: function callback($$v) {
          _vm.$set(_vm.tagListing.translations[locale], "last_updated_h1", $$v);
        },
        expression: "tagListing.translations[locale].last_updated_h1"
      }
    })], 1)], 1), _c('a-col', {
      attrs: {
        "span": "24"
      }
    }, [_c('a-form-item', {
      attrs: {
        "label": "Заголовок"
      }
    }, [_c('a-input', {
      attrs: {
        "size": "large",
        "placeholder": "Заголовок"
      },
      model: {
        value: _vm.tagListing.translations[locale].last_updated_title,
        callback: function callback($$v) {
          _vm.$set(_vm.tagListing.translations[locale], "last_updated_title", $$v);
        },
        expression: "tagListing.translations[locale].last_updated_title"
      }
    })], 1)], 1), _c('a-col', {
      attrs: {
        "span": "24"
      }
    }, [_c('a-form-item', {
      attrs: {
        "label": "Description"
      }
    }, [_c('a-textarea', {
      attrs: {
        "size": "large",
        "placeholder": "Description",
        "auto-size": ""
      },
      model: {
        value: _vm.tagListing.translations[locale].last_updated_description,
        callback: function callback($$v) {
          _vm.$set(_vm.tagListing.translations[locale], "last_updated_description", $$v);
        },
        expression: "tagListing.translations[locale].last_updated_description"
      }
    })], 1)], 1), _c('a-col', {
      attrs: {
        "span": "24"
      }
    }, [_c('a-form-item', {
      attrs: {
        "label": "Breadcrumbs"
      }
    }, [_c('a-input', {
      attrs: {
        "size": "large",
        "placeholder": "Breadcrumbs"
      },
      model: {
        value: _vm.tagListing.translations[locale].last_updated_breadcrumbs,
        callback: function callback($$v) {
          _vm.$set(_vm.tagListing.translations[locale], "last_updated_breadcrumbs", $$v);
        },
        expression: "tagListing.translations[locale].last_updated_breadcrumbs"
      }
    })], 1)], 1), _c('a-col', {
      attrs: {
        "span": "24"
      }
    }, [_c('a-form-item', {
      attrs: {
        "label": "Content"
      }
    }, [_c('a-textarea', {
      attrs: {
        "size": "large",
        "placeholder": "Content",
        "auto-size": ""
      },
      model: {
        value: _vm.tagListing.translations[locale].last_updated_content,
        callback: function callback($$v) {
          _vm.$set(_vm.tagListing.translations[locale], "last_updated_content", $$v);
        },
        expression: "tagListing.translations[locale].last_updated_content"
      }
    })], 1)], 1)], 1)], 1)], 1);
  }), 1)], 1)], 1), _c('a-button', {
    attrs: {
      "type": "primary",
      "size": "large"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v(" Сохранить ")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }