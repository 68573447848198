<template>
  <div>
    <PageTitle title="Ссылка на сайт ожидающие проверки"/>

    <a-layout-content class="page__content">
      <a-table
          :columns="columns"
          :pagination="false"
          :dataSource="users.data"
          :loading="loading"
          rowKey="id"
      >
        <template #url="url">
          <a :href="url" target="_blank">{{ url }}</a>
        </template>

        <template #actions="user">
          <a-button
              type="primary"
              @click="approve(user)"
          >
            Принять
          </a-button>

          <a-button
              type="danger"
              @click="decline(user)"
          >
            Отклонить
          </a-button>
        </template>
      </a-table>

      <a-row style="text-align:  right;">
        <a-pagination
          v-if="!loading && users.meta"
          @change="paginationChange"
          v-model="users.meta.current_page"
          :total="users.meta.total"
          :pageSize="users.meta.per_page"
          style="margin-top: 20px"
        />
      </a-row>
    </a-layout-content>
  </div>
</template>

<script>
import PageTitle from "@/components/Global/PageTitle.vue";
import {searchUsers, updateUser} from "@/api/users";
import {applications} from "@/api";

export default {
  name: "LinkToSiteList",

  components: {PageTitle},

  data() {
    return {
      loading: false,
      columns: [
        {
          title: 'Email',
          dataIndex: 'email',
          key: 'email',
          sorter: false,
        },
        {
          title: 'Сайт',
          dataIndex: 'site',
          key: 'site',
          scopedSlots: {customRender: 'url'},
          sorter: false,
        },
        {
          title: 'Обратная ссылка на наш ресурс',
          dataIndex: 'back_site_url',
          key: 'back_site_url',
          scopedSlots: {customRender: 'url'},
          sorter: false,
        },
        {
          title: 'Действия',
          key: 'actions',
          scopedSlots: {customRender: 'actions'},
          width: 250,
        },
      ],
      users: {},
    };
  },

  created() {
    this.getUsers();
  },

  methods: {
    async getUsers() {
      this.loading = true;
      const response = await searchUsers({back_site_url_status: 'awaiting_verification'});
      const json = await response.json();
      this.users = json;
      this.loading = false;
    },

    async paginationChange(page) {
      window.scrollTo(0, 0);
      await this.getUsers();
    },

    async approve(user) {
      this.$confirm({
        title: 'Вы действительно хотите подтвердить ссылку?',
        onOk: async () => {
          await updateUser(user.id, {back_site_url_status: 'found'});
          this.getUsers();
        },
      });
    },

    async decline(user) {
      this.$confirm({
        title: 'Вы действительно хотите отклонить ссылку?',
        onOk: async () => {
          await updateUser(user.id, {back_site_url_status: 'not_found'});
          this.getUsers();
        },
      });
    },
  },
}
</script>

<style scoped lang="scss">

</style>