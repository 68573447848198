
// @ts-nocheck
import { Component, Vue } from 'vue-property-decorator';
import PageTitle from '@/components/Global/PageTitle.vue';
import { Position as IPosition } from '@/types/advert';
import { advert } from '@/api/advert';

@Component({ name: 'Position', components: { PageTitle } })
export default class Position extends Vue {
  label: string = '';
  type: string = '';
  positions = [];
  hasLoadPositions: boolean = false;
  columns = [
    {
      title: 'Страница',
      dataIndex: 'route',
      key: 'route',
    },
    {
      title: 'Позиция',
      dataIndex: 'position',
      key: 'position',
    },

    {
      title: 'Описание',
      dataIndex: 'label',
      key: 'label',
    },
    {
      title: 'Тип',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Действия',
      dataIndex: '',
      key: 'actions',
      scopedSlots: { customRender: 'actions' },
    },
  ];

  clearFilter() {
    this.label = '';
    this.type = '';
  }

  mounted() {
    this.getAdvertPositions();
  }

  private async searchPositions(): Promise<any> {
    this.hasLoadPositions = true;
    await advert
      .searchAdvertsPositions({
        label: this.label,
        type: this.type,
      })
      .then((res) => res.json())
      .then(({ data }) => {
        this.positions = data;
        this.hasLoadPositions = false;
      });
  }

  private async getAdvertPositions(): Promise<any> {
    this.hasLoadPositions = true;
    await advert
      .getAdvertPositions()
      .then((res) => res.json())
      .then(({ data }) => {
        this.positions = data;
        this.hasLoadPositions = false;
      });
  }
}
