<template>
  <div>
    <PageTitle title="Список категорий">
      <router-link :to="CategorizationCategoryCreateRoute">
        <a-button size="large" icon="plus" type="primary"
        >Добавить категорию
        </a-button>
      </router-link>
    </PageTitle>
    <a-layout-content class="page__content">
      <h3>Фильтр</h3>
      <a-row :gutter="24">
        <a-col span="6">
          <a-select
            size="large"
            placeholder="Выбрать OS"
            v-model="searchParams.os"
          >
            <a-select-option
              v-for="os in operationSystems"
              :value="os.id"
              :key="os.id"
            >
              {{ os.name }}
            </a-select-option>
          </a-select>
        </a-col>
      </a-row>

      <a-row style="padding-top: 50px">
        <a-col span="12">
          <a-button size="large" @click="resetFields">Сбросить</a-button>
        </a-col>
        <a-col span="12" style="text-align: right">
          <a-button
            size="large"
            type="primary"
            style="margin-left: 20px"
            icon="search"
            @click="handleSearch"
          >Фильтровать
          </a-button>
        </a-col>
      </a-row>
    </a-layout-content>

    <a-layout-content class="page__content">
      <a-table
        @change="onChange"
        :columns="columns"
        :pagination="false"
        :dataSource="categories.data"
        :loading="loading"
        rowKey="id"
      >
        <template slot="parent" slot-scope="parent">
          {{ parent ? parent.name : '' }}
        </template>

        <template slot="os" slot-scope="os">
          {{ os.name }}
        </template>

        <template slot="listing_action" slot-scope="id, record">
          <a-input-search
            placeholder="listing ids"
            style="max-width: 100px"
            v-model="record.listing_ids"
            @search="saveListing(record.id, {listing_ids: record.listing_ids})"
          >
            <a-button
              slot="enterButton"
              type="primary"
              class="gray-btn"
              icon="save"
            />
          </a-input-search>
        </template>

        <template slot="actions" slot-scope="id, record">
          <a-row type="flex">
            <a-col>
              <a-button
                type="primary"
                class="gray-btn"
                @click="changePosition(record, -1)"
                icon="up"
                style="margin-right: 10px"
                title="Поднять на позицию вверх"
              />

              <a-button
                type="primary"
                class="gray-btn"
                @click="changePosition(record, 1)"
                icon="down"
                style="margin-right: 10px"
                title="Опустить на позицию вниз"
              />

              <router-link :to="{ path: `/categorization/categories/${record.id}` }">
                <a-button
                  type="primary"
                  class="gray-btn"
                  icon="edit"
                  style="margin-right: 10px"
                ></a-button>
              </router-link>

              <a-button
                type="danger"
                class="gray-btn"
                @click="deleteCategory(record.id)"
                icon="delete"
              />
            </a-col>

          </a-row>
        </template>
      </a-table>

      <a-row style="text-align: right;">
        <a-pagination
          v-if="categories.meta"
          @change="paginationChange"
          v-model="categories.meta.current_page"
          :total="categories.meta.total"
          :pageSize="categories.meta.per_page"
          style="margin-top: 20px"
        />
      </a-row>
    </a-layout-content>

    <a-modal v-model="showDescription" title="Описание" @ok="showDescription=false">
      <div v-html="showDescription"></div>
    </a-modal>
  </div>
</template>

<script>
import Vue from 'vue';
import PageTitle from '@/components/Global/PageTitle.vue';
import { changeCategoryPosition, deleteCategory, getCategories, moveCategory, updateCategory } from '@/api/category';
import { mapState } from 'vuex';
import { CategorizationCategoryCreateRoute } from './routes';

export default Vue.extend({
  components: { PageTitle },
  data() {
    return {
      CategorizationCategoryCreateRoute,
      loading     : false,
      categories  : {
        data: [],
        meta: null,
      },
      sortParams  : {},
      searchParams: {
        page     : 1,
        os       : undefined,
        parent_id: undefined,
      },
      columns     : [
        {
          title         : 'Название',
          dataIndex     : 'name',
          key           : 'name',
          sorter        : true,
          sortDirections: ['asc', 'desc'],
        },
        {
          title         : 'OS',
          dataIndex     : 'os',
          key           : 'os',
          scopedSlots   : { customRender: 'os' },
          sorter        : true,
          sortDirections: ['asc', 'desc'],
        },
        {
          title    : 'кол-во аппов',
          dataIndex: 'applications_count',
          key      : 'applications_count',
          sorter   : false,
        },
        {
          title    : 'Позиция',
          dataIndex: 'position',
          key      : 'position',
          sorter   : false,
        },
        {
          title      : 'Листинг ID',
          dataIndex  : '',
          key        : 'listing_action',
          scopedSlots: { customRender: 'listing_action' },
        },
        {
          title      : 'Действия',
          dataIndex  : '',
          key        : 'actions',
          scopedSlots: { customRender: 'actions' },
        },
      ],
    };
  },

  computed: {
    ...mapState(['operationSystems']),
  },

  mounted() {
    this.getCategories({ page: 1 });
  },

  methods: {

    resetFields() {
      this.searchParams = {
        page: 1,
        os  : undefined,
      };

      this.getCategories({ page: 1 });
    },

    onChange(pagination, filters, sorter) {
      const { order, field } = sorter;
      // this.sortAppsByField(field, order);
    },

    async handleSearch() {
      this.loading = true;

      Object.keys(this.searchParams).forEach((key) => {
        const value = this.searchParams[key];
        if (typeof value === 'boolean') {
          this.searchParams[key] = Number(value);
        }
      });

      const res = await getCategories({
        ...this.searchParams,
        page: 1,
      });

      const data = await res.json();

      this.categories = data;
      this.loading    = false;
    },

    async getCategories(params) {
      this.loading    = true;
      const res       = await getCategories(params);
      const data      = await res.json();
      this.categories = data;
      this.loading    = false;
    },

    paginationChange(page) {
      this.searchParams = { ...this.searchParams, page };
      this.getCategories({ ...this.searchParams, ...this.sortParams });
      window.scrollTo(0, 0);
    },

    async deleteCategory(id) {
      this.$confirm({
        title  : 'Вы действительно хотите удалить категорию?',
        content: 'Категория будет удалена',
        onOk   : async () => {
          const response = await deleteCategory(id);

          if (response.ok) {
            this.$message.success('Категория успешно удалена.', 3);
            this.getCategories({ ...this.searchParams, ...this.sortParams });
          }
        },
      });
    },

    async saveListing(id, params) {
      const response = await updateCategory(id, params);
      const json     = await response.json();
    },

    async changePosition(category, offset) {
      this.loading = true;
      await changeCategoryPosition(category.id, { offset });
      this.getCategories({ ...this.searchParams, ...this.sortParams });
    },
  },
});
</script>
