
// @ts-nocheck
import Vue from 'vue';
// @ts-ignore
import Cookie from '@/helpers/Cookie';
import { API_URL } from '@/global/constants';
import PageTitle from '@/components/Global/PageTitle.vue';
import { SeoFrequency } from '@/api/seoFrequency';


interface SeoFrequencyItem {
  id: number;
  name: string;
  seoName: string;
  frequency: number;
  editable?: boolean;
}

export default Vue.extend({
  components: {
    PageTitle,
  },
  data() {
    return {
      headers: {
        Authorization: `Bearer ${Cookie.get('admin_token')}`,
      },
      uploadUrl : `${API_URL}/api/admin/apps/seo-frequency/import`,
      slug: '',
      name: '',
      seoFrequency: {
        cacheData: [],
        data: [],
      },
      cacheData: null,
      hasLoadSeoFrequency: false,
      columns: [
        {
          title: 'Название ',
          dataIndex: 'name',
          key: 'name',
          sorter: true,
          sortDirections: ['desc', 'asc'],
        },
        {
          title: 'URL',
          dataIndex: 'seoName',
          key: 'seoName',
          sorter: true,
          sortDirections: ['desc', 'asc'],
        },
        {
          title: 'SEO частотность',
          dataIndex: 'frequency',
          key: 'frequency',
          scopedSlots: { customRender: 'actions' },
        },
      ],
    };
  },

  methods: {
    handleChange(info: any) {
      if (info.file.status === 'done') {
        this.$message.success(
          `${
            info.file.name
          } успешно загружен :) Вы можете найти нужную информацию в поиске`,
        );
      } else if (info.file.status === 'error') {
        this.$message.error(`${info.file.name} не удалось загрузить.`);
      }
    },

    async getSeoFrequency(page = 1): Promise<any> {
      this.hasLoadSeoFrequency = true;
      await SeoFrequency.getSeoFrequency(page)
        .then((res) => res.json())
        .then((data) => {
          this.hasLoadSeoFrequency = false;
          this.seoFrequency = data;
          this.seoFrequency.cacheData = data.data.map((item: any) => ({
            ...item,
          }));
        });
    },

    handleChangeFrequency(value: string, data: any) {
      const newData = [...this.seoFrequency.data];
      const target = newData.filter(
        (item: SeoFrequencyItem) => data.id === item.id,
      )[0];

      if (target) {
        // @ts-ignore
        target.frequency = value;
        this.seoFrequency.data = newData;
      }
    },

    async saveEditFrequency(data: any): Promise<any> {
      const params = {
        id: data.id,
        frequency: Number(data.frequency),
      };

      await SeoFrequency.updateSeoFrequency(data.id, params)
        .then((res) => res.json())
        .then(() => {
          this.$message.success('Частотность успешно обновлена :)', 2);
          this.getSeoFrequency();
        })
        .catch(() => {
          this.$message.error('Ошибка обновления частотности :(', 2);
        });
    },

    cancelEditFrequency(data: any) {
      const newData = [...this.seoFrequency.data];
      const target: SeoFrequencyItem = newData.filter(
        (item: SeoFrequencyItem) => item.id === data.id,
      )[0];
      if (target) {
        Object.assign(
          target,
          this.seoFrequency.cacheData.filter(
            (item: SeoFrequencyItem) => data.id === item.id,
          )[0],
        );
        delete target.editable;
        this.seoFrequency.data = newData;
      }
    },

    editFrequency(data: any) {
      const newData = [...this.seoFrequency.data];
      const target: SeoFrequencyItem = newData.filter(
        (item: SeoFrequencyItem) => item.id === data.id,
      )[0];
      if (target) {
        target.editable = true;
        this.seoFrequency.data = newData;
      }
    },

    async searchSeoFrequencyByFields() {
      this.hasLoadSeoFrequency = true;
      await SeoFrequency.searchSeoFrequency({
        name: this.name,
        seoName: this.slug,
      })
        .then((res) => res.json())
        .then((data) => {
          this.hasLoadSeoFrequency = false;
          this.seoFrequency = data;
          this.seoFrequency.cacheData = data.data.map((item: any) => ({
            ...item,
          }));
        });
    },

    async searchSeoFrequency(params: any): Promise<any> {
      this.hasLoadSeoFrequency = true;
      await SeoFrequency.searchSeoFrequency(params)
        .then((res) => res.json())
        .then((data) => {
          this.seoFrequency = data;
          this.hasLoadSeoFrequency = false;
          this.seoFrequency.cacheData = data.data.map((item: any) => ({
            ...item,
          }));
        });
    },

    async exportSeoFrequency(): Promise<any> {
      this.$message.loading('Формирование CSV, пожалуйста подождите', 18);
      await SeoFrequency.exportSeoFrequency()
        .then((res) => res.text())
        .then((data) => {
          const fileURL = window.URL.createObjectURL(new Blob([data]));
          const fileLink = document.createElement('a');

          fileLink.href = fileURL;
          fileLink.setAttribute('download', 'frequency.csv');
          document.body.appendChild(fileLink);
          fileLink.click();
        });
    },

    onChange(pagination: any, filters: any, sorter: any) {
      const { order, field } = sorter;
      if (order === 'frequency') { return; }
      if (field === 'seoName') {
        this.searchSeoFrequency({
          [`slug`]: order,
        });
      } else {
        this.searchSeoFrequency({
          [`${field}Sort`]: order,
        });
      }
    },
    paginationChange(page: number): void {
      this.getSeoFrequency(page);
      window.scrollTo(0, 0);
    },
  },
  mounted() {
    this.getSeoFrequency();
  },
});
