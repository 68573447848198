var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('PageTitle', {
    attrs: {
      "title": "Комментарии новостей"
    }
  }), _c('a-layout-content', {
    staticClass: "page__content"
  }, [_c('a-form', [_c('a-row', [_c('h3', [_vm._v("Поиск")])]), _c('a-row', {
    attrs: {
      "gutter": 24
    }
  }, [_c('a-col', {
    attrs: {
      "span": 6
    }
  }, [_c('a-form-item', [_c('a-input', {
    attrs: {
      "placeholder": "Ввведите текст комментария",
      "size": "large"
    },
    model: {
      value: _vm.filter.textComment,
      callback: function callback($$v) {
        _vm.$set(_vm.filter, "textComment", $$v);
      },
      expression: "filter.textComment"
    }
  })], 1)], 1), _c('a-col', {
    attrs: {
      "span": 6
    }
  }, [_c('a-form-item', [_c('a-input', {
    attrs: {
      "placeholder": "Имя автора",
      "size": "large"
    },
    model: {
      value: _vm.filter.author,
      callback: function callback($$v) {
        _vm.$set(_vm.filter, "author", $$v);
      },
      expression: "filter.author"
    }
  })], 1)], 1), _c('a-col', {
    attrs: {
      "span": 5
    }
  }, [_c('a-form-item', [_c('a-select', {
    attrs: {
      "placeholder": "Выберите статус",
      "size": "large"
    },
    model: {
      value: _vm.filter.status,
      callback: function callback($$v) {
        _vm.$set(_vm.filter, "status", $$v);
      },
      expression: "filter.status"
    }
  }, [_c('a-select-option', {
    key: "0",
    attrs: {
      "value": ""
    }
  }, [_vm._v("Выберите статус ")]), _c('a-select-option', {
    key: "1",
    attrs: {
      "value": "0"
    }
  }, [_vm._v("Удаленно")]), _c('a-select-option', {
    key: "2",
    attrs: {
      "value": "1"
    }
  }, [_vm._v("Активно")]), _c('a-select-option', {
    key: "3",
    attrs: {
      "value": "2"
    }
  }, [_vm._v("Спам")]), _c('a-select-option', {
    key: "4",
    attrs: {
      "value": "3"
    }
  }, [_vm._v("Не спам")])], 1)], 1)], 1), _c('a-col', {
    attrs: {
      "span": 7
    }
  }, [_c('a-form-item', [_c('a-range-picker', {
    attrs: {
      "placeholder": ['Начало', 'Конец'],
      "size": "large"
    },
    model: {
      value: _vm.dates,
      callback: function callback($$v) {
        _vm.dates = $$v;
      },
      expression: "dates"
    }
  })], 1)], 1), _c('a-col', {
    staticStyle: {
      "margin-top": "25px"
    },
    attrs: {
      "span": "24"
    }
  }, [_c('a-button', {
    staticStyle: {
      "margin-right": "25px"
    },
    attrs: {
      "icon": "search",
      "size": "large",
      "type": "primary",
      "htmlType": "submit"
    },
    on: {
      "click": _vm.handleSearch
    }
  }, [_vm._v("Поиск ")]), _c('a-button', {
    attrs: {
      "size": "large"
    },
    on: {
      "click": _vm.resetFilter
    }
  }, [_vm._v("Сбросить")])], 1)], 1)], 1)], 1), _c('a-layout-content', {
    staticClass: "page__content"
  }, [_c('a-table', {
    attrs: {
      "loading": _vm.hasLoadComments,
      "columns": _vm.columns,
      "pagination": false,
      "dataSource": _vm.comments.data ? _vm.comments.data : [],
      "rowKey": "id"
    },
    on: {
      "change": _vm.onChange
    },
    scopedSlots: _vm._u([{
      key: "operation",
      fn: function fn(id, comment) {
        return [_c('a', {
          attrs: {
            "href": comment.url
          }
        }, [_c('a-button', {
          staticClass: "gray-btn",
          staticStyle: {
            "margin-right": "10px"
          },
          attrs: {
            "type": "primary",
            "icon": "eye"
          }
        })], 1), _c('a-button', {
          staticClass: "gray-btn",
          staticStyle: {
            "margin-right": "10px"
          },
          attrs: {
            "type": "primary",
            "icon": "edit"
          },
          on: {
            "click": function click($event) {
              return _vm.editComment(id);
            }
          }
        }), comment.mark_as_spam === 0 ? _c('a-button', {
          staticClass: "gray-btn",
          staticStyle: {
            "margin-right": "10px"
          },
          attrs: {
            "type": "primary",
            "icon": "info-circle"
          },
          on: {
            "click": function click($event) {
              return _vm.handleSetCommentToSpam(id);
            }
          }
        }) : _vm._e(), comment.mark_as_spam === 1 ? _c('a-tooltip', [_c('template', {
          slot: "title"
        }, [_vm._v(" Комментарий помещен в спам ")]), _c('a-button', {
          staticStyle: {
            "background": "#ffd666",
            "margin-right": "10px",
            "border": "none"
          },
          attrs: {
            "icon": "info-circle",
            "disabled": ""
          }
        })], 2) : _vm._e(), _c('a-button', {
          staticClass: "gray-btn",
          attrs: {
            "type": "danger",
            "icon": "delete"
          },
          on: {
            "click": function click($event) {
              return _vm.handleRemoveComment(id);
            }
          }
        })];
      }
    }, {
      key: "rating",
      fn: function fn(rating) {
        return [_c('p', {
          staticStyle: {
            "color": "#1BA305"
          }
        }, [_vm._v(_vm._s(rating))])];
      }
    }, {
      key: "user",
      fn: function fn(user) {
        return [_c('p', [_vm._v(_vm._s(user.name))])];
      }
    }, {
      key: "text",
      fn: function fn(text) {
        return [text.length > 150 ? _c('p', [_vm._v(_vm._s(text.slice(0, 150)) + " ...")]) : _vm._e(), text.length < 150 ? _c('p', [_vm._v(_vm._s(text))]) : _vm._e()];
      }
    }])
  }), _vm.comments.meta ? _c('a-row', {
    staticStyle: {
      "text-align": "left"
    }
  }, [_c('a-pagination', {
    staticStyle: {
      "margin-top": "20px"
    },
    attrs: {
      "total": _vm.comments.meta.total,
      "pageSize": _vm.comments.meta.per_page
    },
    on: {
      "change": _vm.paginationChange
    },
    model: {
      value: _vm.comments.meta.current_page,
      callback: function callback($$v) {
        _vm.$set(_vm.comments.meta, "current_page", $$v);
      },
      expression: "comments.meta.current_page"
    }
  })], 1) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }