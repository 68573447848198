<template>
  <div>
    <PageTitle title="Список приложений">
      <router-link to="/add-description">
        <a-button size="large" icon="plus" type="primary">
          Добавить приложение
        </a-button>
      </router-link>
    </PageTitle>
    <a-layout-content class="page__content">
      <h3>Поиск</h3>
      <a-row :gutter="24">
        <a-col span="8">
          <a-input
            size="large"
            placeholder="Название программы"
            v-model="searchParams.name"
            v-on:keyup.enter="handleSearch"
          />
        </a-col>
        <a-col span="8">
          <a-input
            size="large"
            placeholder="SEO name (URL)"
            v-model="searchParams.seoName"
            v-on:keyup.enter="handleSearch"
          />
        </a-col>
        <a-col span="8">
          <a-select
            size="large"
            placeholder="Выбрать OS"
            v-model="searchParams.os"
          >
            <a-select-option
              v-for="os in operationSystems"
              :value="os.id"
              :key="os.id"
            >
              {{ os.name }}
            </a-select-option>
          </a-select>
        </a-col>
      </a-row>
      <a-row style="padding-top: 20px" :gutter="24" v-if="hasShowMore">
        <a-col span="8">
          <a-select
            size="large"
            placeholder="Платформа"
            v-model="searchParams.platform"
          >
            <a-select-option
              v-for="platform in platformTypes"
              :value="platform.id"
              :key="platform.id"
            >
              {{ platform.name }}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col span="8">
          <a-select
            size="large"
            placeholder="Лицензия"
            v-model="searchParams.license"
          >
            <a-select-option
              v-for="license in licenseTypes"
              :value="license.id"
              :key="license.id"
            >
              {{ license.name }}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col span="8">
          <a-input
            size="large"
            v-model="searchParams.developer"
            placeholder="Разработчик"
            v-on:keyup.enter="handleSearch"
          />
        </a-col>
      </a-row>
      <a-row style="padding-top: 20px" :gutter="24" v-if="hasShowMore">
        <a-col span="8">
          <a-select
            size="large"
            showSearch
            placeholder="Тег 1"
            :defaultActiveFirstOption="false"
            :showArrow="false"
            :filterOption="false"
            @search="searchTags"
            notFoundContent="Введите  первый тег..."
            v-model="searchParams.tag1"
          >
            <a-select-option
              v-for="tag in tagsList"
              :key="tag.id"
              :value="tag.id"
            >
              {{ tag.name }}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col span="8">
          <a-select
            size="large"
            showSearch
            placeholder="Тег 1"
            :defaultActiveFirstOption="false"
            :showArrow="false"
            :filterOption="false"
            @search="searchTags"
            notFoundContent="Введите  второй тег..."
            v-model="searchParams.tag2"
          >
            <a-select-option
              v-for="tag in tagsList"
              :key="tag.id"
              :value="tag.id"
            >
              {{ tag.name }}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col span="8">
          <a-select
            size="large"
            showSearch
            placeholder="Тег 3"
            :defaultActiveFirstOption="false"
            :showArrow="false"
            :filterOption="false"
            @search="searchTags"
            notFoundContent="Введите третий тег..."
            v-model="searchParams.tag3"
          >
            <a-select-option
              v-for="tag in tagsList"
              :key="tag.id"
              :value="tag.id"
            >
              {{ tag.name }}
            </a-select-option>
          </a-select>
        </a-col>
      </a-row>
      <a-row style="padding-top: 20px" :gutter="24" v-if="hasShowMore">
        <a-col span="6">
          <a-switch v-model="searchParams.isApiOffer"></a-switch>
          <span style="padding: 15px 0 0 20px">API Offers</span>
        </a-col>

        <a-col span="13">
          <a-switch v-model="searchParams.isApi"></a-switch>
          <span style="padding: 15px 0 0 20px">API Programs</span>
        </a-col>

        <a-col span="3">
          <a-checkbox v-model="searchParams.enabled">Опубликованы</a-checkbox>
        </a-col>
        <a-col span="2">
          <a-checkbox v-model="searchParams.trashed">Удалены</a-checkbox>
        </a-col>
      </a-row>
      <a-row style="padding-top: 50px">
        <a-col span="12">
          <a-button size="large" @click="resetFields">Сбросить</a-button>
        </a-col>
        <a-col span="12" style="text-align: right">
          <a-button size="large" type="default" @click="showMoreParams">
            {{ showMoreStr }}
          </a-button>
          <a-button
            size="large"
            type="primary"
            style="margin-left: 20px"
            icon="search"
            @click="handleSearch"
          >
            Поиск
          </a-button>
        </a-col>
      </a-row>
    </a-layout-content>
    <a-layout-content class="page__content">
      <a-table
        @change="onChange"
        :columns="columns"
        :pagination="false"
        :dataSource="apps.data"
        :loading="hasLoadApps"
        rowKey="id"
      >
        <template slot="actions" slot-scope="id, record">
          <a-dropdown>
            <a-menu slot="overlay">
              <a-menu-item key="1">
                <router-link :to="{ path: `/edit-description/${record.id}/edit` }">
                  <a-button
                    type="link"
                    icon="edit"
                    size="small"
                  >
                    Редактировать
                  </a-button>
                </router-link>
              </a-menu-item>
              <a-menu-item key="2">
                <a :href="record.url" target="_blank">
                  <a-button
                    type="link"
                    icon="eye"
                    size="small"
                  >
                    Просмотр
                  </a-button>
                </a>
              </a-menu-item>
              <a-menu-item key="3">
                <a :href="record.site" target="_blank" v-if="record.site">
                  <a-button
                    type="link"
                    icon="link"
                    size="small"
                  >
                    Открыть сайт приложения
                  </a-button>
                </a>
              </a-menu-item>
              <a-menu-item key="4">
                <a-button
                  type="link"
                  icon="delete"
                  size="small"
                  @click="deleteApplication(record.id, record.name)"
                >
                  Удалить приложение
                </a-button>
              </a-menu-item>
              <a-menu-item key="5">
                <a-button
                  type="link"
                  icon="edit"
                  size="small"
                  @click="editApplicationPublishedAt= record"
                >
                  Настройки публикации
                </a-button>
              </a-menu-item>
            </a-menu>
            <a-button> Действия
              <a-icon type="down"/>
            </a-button>
          </a-dropdown>
        </template>

        <template slot="os" slot-scope="os">
          {{ os.name }}
        </template>

        <template slot="version" slot-scope="files">
          {{ getLatestFileVersion(files) }}
        </template>

        <template slot="developer" slot-scope="developer">
          <p :key="developer.id" style="margin: 0">{{ developer.name }}</p>
        </template>

        <template slot="published" slot-scope="id, record">
          <p v-for="(data, locale) in record.translations" :key="locale">
            {{ locale }} -
            <template v-if="data.published_at">{{ data.published_at | dateFormat }}</template>
            <template v-else>нет</template>
          </p>

        </template>
      </a-table>
      <a-row style="text-align: right;">
        <a-pagination
          v-if="apps.meta"
          @change="paginationChange"
          v-model="apps.meta.current_page"
          :total="apps.meta.total"
          :pageSize="apps.meta.per_page"
          style="margin-top: 20px"
        />
      </a-row>
    </a-layout-content>
    <application-published-at-modal
      :application="editApplicationPublishedAt"
      @close="editApplicationPublishedAt=undefined"
      @save="onPublishedAtSave"
    />
  </div>
</template>

<script>
import PageTitle from '@/components/Global/PageTitle.vue';
import { applications } from '@/api/Applications';
import { mapState } from 'vuex';
import { users } from '@/api/users';
import { debounce } from 'lodash';
import ApplicationPublishedAtModal from '@/components/Applications/ApplicationPublishedAtModal';

export default {
  components: { ApplicationPublishedAtModal, PageTitle },
  computed  : mapState(['operationSystems', 'platformTypes', 'licenseTypes']),
  mounted() {
    this.getApplications({ page: 1, trashed: 1, enabled: 1 });
  },
  data() {
    return {
      editApplicationPublishedAt: undefined,
      developersList            : [],
      tagsList                  : [],
      relatedProgramsList       : [],
      hasShowMore               : false,
      hasLoadApps               : false,
      showMoreStr               : 'Больше параметров',
      searchDevelopers          : debounce(this.handleDevelopersSearch, 300),
      searchTags                : debounce(this.handleTagsSearch, 300),
      apps                      : [],
      sortParams                : {},
      searchParams              : {
        page      : 1,
        name      : undefined,
        seoName   : undefined,
        os        : undefined,
        developer : undefined,
        platform  : undefined,
        license   : undefined,
        enabled   : 1,
        tag1      : undefined,
        tag2      : undefined,
        tag3      : undefined,
        isApiOffer: undefined,
        isApi     : undefined,
        trashed   : 1,
      },
      columns                   : [
        {
          title    : 'Название программы',
          dataIndex: 'name',
          key      : 'name',
          sorter   : true,
        },
        {
          title    : 'SEO Name (URL)',
          dataIndex: 'seoName',
          key      : 'seoName',
          sorter   : true,
        },
        {
          title      : 'OS',
          dataIndex  : 'operationSystem',
          key        : 'os',
          scopedSlots: { customRender: 'os' },
          sorter     : true,
        },
        {
          title      : 'Версия',
          dataIndex  : 'files',
          key        : 'version',
          scopedSlots: { customRender: 'version' },
        },
        {
          title      : 'Разработчик',
          dataIndex  : 'developer',
          key        : 'developer',
          scopedSlots: { customRender: 'developer' },
          sorter     : true,
        },
        {
          title    : 'Рейтинг',
          dataIndex: 'sort_rating',
          key      : 'sort_rating',
          sorter   : true,
        },
        {
          title      : 'Опубликовано',
          dataIndex  : '',
          key        : 'published',
          scopedSlots: { customRender: 'published' },
          width      : 200,
        },
        {
          title      : 'Действия',
          dataIndex  : '',
          key        : 'actions',
          scopedSlots: { customRender: 'actions' },
        },
      ],
    };
  },

  methods: {
    getLatestFileVersion(files) {
      const isLastFile = files.find((file) => file.isLast);
      if (isLastFile) {
        return isLastFile.version;
      }

      return files.length ? files[files.length - 1].version : '-';
    },

    resetFields() {
      this.searchParams = {
        page      : 1,
        name      : undefined,
        seoName   : undefined,
        os        : undefined,
        developer : undefined,
        platform  : undefined,
        license   : undefined,
        enabled   : 1,
        tag1      : undefined,
        tag2      : undefined,
        tag3      : undefined,
        isApiOffer: undefined,
        isApi     : undefined,
        trashed   : 0,
      };

      this.getApplications({ page: 1, trashed: 1, enabled: 1 });
    },

    /**
     * @description Удаление приложения
     * @param {number} id
     * @param {string} name
     * @return {Promise<any>}
     */
    deleteApplication(id, name) {
      this.$confirm({
        title  : `Вы действительно хотите удалить приложение ${name}?`,
        content:
          'Вы не сможете восстановить его после удаления, только добавить еще раз.',
        onOk   : async () => {
          await applications.deleteAppById(id).then(() => {
            this.$message.success('Приложение успешно удаленно :)', 2);
            this.getApplications({ page: 1 });
          });
        },
      });
    },
    /**
     * @description Сортировка приложений
     * @param {string} field - текущее поле
     * @param {string} direction - направление
     * @return {Promise<any>}
     */
    async sortAppsByField(field, direction) {
      this.hasLoadApps = true;
      const params     = {...this.searchParams};

      if (direction) {
        if (field === 'operationSystem') {
          params['osOrder'] = direction;

          this.sortParams = { [`osOrder`]: direction };
        } else {
          params[`${field}Order`] = direction;

          this.sortParams = { [`${field}Order`]: direction };
        }
      } else {
        this.sortParams = {};
      }

      await applications
        .searchApplications({ ...params })
        .then((res) => res.json())
        .then((data) => {
          this.apps        = data;
          this.hasLoadApps = false;
        });
    },

    /**
     * @description Обработчик на change таблички
     * @param {object} pagination
     * @param {object} filters
     * @param {object} sorter
     * @return {Promise<any>}
     */
    onChange(pagination, filters, sorter) {
      const { order, field } = sorter;
      this.sortAppsByField(field, order ? order.replace('end', '') : undefined);
    },

    /**
     * @description Поиск по приложениям
     * @return {Promise<any>}
     */
    async handleSearch() {
      this.hasLoadApps = true;

      Object.keys(this.searchParams).forEach((key) => {
        const value = this.searchParams[key];
        if (typeof value === 'boolean') {
          this.searchParams[key] = Number(value);
        }
      });

      await applications
        .searchApplications({
          ...this.searchParams,
          page   : 1,
          trashed: this.searchParams.enabled === 1 ? 1 : 0,

        })
        .then((res) => res.json())
        .then((data) => {
          this.apps        = data;
          this.hasLoadApps = false;
        });
    },

    /**
     * @description Поиск по тегам
     * @param {string} value
     * @return {Promise<any>}
     */
    async handleTagsSearch(value) {
      await applications
        .searchTags({ tagList: value })
        .then((res) => res.json())
        .then(({ data }) => {
          this.tagsList = data;
        });
    },

    /**
     * @description Поиск по разработчикам
     * @param {string} value
     * @return {Promise<any>}
     */
    async handleDevelopersSearch(value) {
      await users
        .searchUsers({
          role: 'developer',
          name: value,
        })
        .then((res) => res.json())
        .then(({ data }) => {
          this.developersList = data;
        });
    },

    /**
     * @description Поиск приложений
     * @param {object} params
     * @return {Promise<any>}
     */
    async getApplications(params) {
      this.hasLoadApps = true;
      await applications
        .searchApplications(params)
        .then((res) => res.json())
        .then((data) => {
          this.apps        = data;
          this.hasLoadApps = false;
        });
    },

    /**
     * @description Показ фильтров
     * @return {void}
     */
    showMoreParams() {
      this.hasShowMore = !this.hasShowMore;
      this.showMoreStr = !this.hasShowMore
        ? 'Больше параметров'
        : 'Меньше параметров';
    },

    /**
     * @description Обработчик на change компонента с пагинацией
     * @param {number} page
     * @return {void}
     */
    paginationChange(page) {
      this.searchParams = { ...this.searchParams, page };
      this.getApplications({ ...this.searchParams, ...this.sortParams });
      window.scrollTo(0, 0);
    },

    onPublishedAtSave(application) {
      const index = this.apps.data.findIndex((app) => app.id === application.id);
      this.apps.data.splice(index, 1, application);
    },
  },
};
</script>
