var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('PageTitle', {
    attrs: {
      "title": _vm.title
    }
  }), _c('a-layout-content', {
    staticClass: "page__content"
  }, [_c('a-form', [_c('a-row', {
    attrs: {
      "gutter": "24"
    }
  }, [_c('a-col', {
    attrs: {
      "span": "12"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "Название (ru)* "
    }
  }, [_c('a-input', {
    attrs: {
      "size": "large"
    },
    model: {
      value: _vm.baseVersionOs.label,
      callback: function callback($$v) {
        _vm.$set(_vm.baseVersionOs, "label", $$v);
      },
      expression: "baseVersionOs.label"
    }
  })], 1)], 1), _c('a-col', {
    attrs: {
      "span": "12"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "Название (en)* "
    }
  }, [_c('a-input', {
    attrs: {
      "size": "large"
    },
    model: {
      value: _vm.baseVersionOs.enLabel,
      callback: function callback($$v) {
        _vm.$set(_vm.baseVersionOs, "enLabel", $$v);
      },
      expression: "baseVersionOs.enLabel"
    }
  })], 1)], 1)], 1), _c('a-row', {
    attrs: {
      "gutter": "24"
    }
  }, [_c('a-col', {
    attrs: {
      "span": "12"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "Уникальное название"
    }
  }, [_c('a-input', {
    attrs: {
      "size": "large"
    },
    model: {
      value: _vm.baseVersionOs.name,
      callback: function callback($$v) {
        _vm.$set(_vm.baseVersionOs, "name", $$v);
      },
      expression: "baseVersionOs.name"
    }
  })], 1)], 1), _c('a-col', {
    attrs: {
      "span": "12"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "ОС"
    }
  }, [_c('a-select', {
    attrs: {
      "size": "large",
      "placeholder": "OS"
    },
    model: {
      value: _vm.baseVersionOs.operationSystem.id,
      callback: function callback($$v) {
        _vm.$set(_vm.baseVersionOs.operationSystem, "id", $$v);
      },
      expression: "baseVersionOs.operationSystem.id"
    }
  }, _vm._l(_vm.operationSystems, function (os) {
    return _c('a-select-option', {
      key: os.id,
      attrs: {
        "value": os.id
      }
    }, [_vm._v(_vm._s(os.name))]);
  }), 1)], 1)], 1), _c('a-col', {
    attrs: {
      "span": "12"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "Seo название"
    }
  }, [_c('a-input', {
    attrs: {
      "size": "large"
    },
    model: {
      value: _vm.baseVersionOs.seoName,
      callback: function callback($$v) {
        _vm.$set(_vm.baseVersionOs, "seoName", $$v);
      },
      expression: "baseVersionOs.seoName"
    }
  })], 1)], 1)], 1), _c('a-row', [_c('a-button', {
    attrs: {
      "size": "large",
      "type": "primary"
    },
    on: {
      "click": _vm.handleSubmit
    }
  }, [_vm._v(_vm._s(_vm.action))])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }