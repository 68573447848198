var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.menuList.length ? _c('a-menu', {
    attrs: {
      "mode": "inline",
      "theme": "dark",
      "selected-keys": _vm.activeMenuItems.selected,
      "default-open-keys": _vm.activeMenuItems.opened
    }
  }, [_vm._l(_vm.menuList, function (menu) {
    return [!menu.hasOwnProperty('subMenu') ? _c('a-menu-item', {
      key: menu.key
    }, [_c('a-icon', {
      attrs: {
        "type": menu.icon
      }
    }), _c('span', [_vm._v(_vm._s(menu.title))]), _c('router-link', {
      attrs: {
        "to": menu.link
      }
    }, [_vm._v(_vm._s(menu.title))])], 1) : _c('a-sub-menu', {
      key: menu.key
    }, [_c('span', {
      attrs: {
        "slot": "title"
      },
      slot: "title"
    }, [_c('a-icon', {
      attrs: {
        "type": menu.icon
      }
    }), _c('span', [_vm._v(_vm._s(menu.title))])], 1), _vm._l(menu.subMenu, function (menuItem) {
      return _c('a-menu-item', {
        key: menuItem.key
      }, [_c('router-link', {
        attrs: {
          "to": menuItem.link
        }
      }, [_vm._v(" " + _vm._s(menuItem.title))])], 1);
    })], 2)];
  }), _vm.user.hasAdmin || _vm.user.hasEditor ? _c('a-menu-item', {
    on: {
      "click": _vm.flushCache
    }
  }, [_c('a-icon', {
    attrs: {
      "type": "delete"
    }
  }), _vm._v(" Сбросить кеш ")], 1) : _vm._e()], 2) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }