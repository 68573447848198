var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('PageTitle', {
    attrs: {
      "title": "Сторонний код"
    }
  }, [_c('router-link', {
    attrs: {
      "to": "/settings/external-code/add"
    }
  }, [_c('a-button', {
    attrs: {
      "type": "primary",
      "icon": "plus"
    }
  }, [_vm._v(" Добавить код ")])], 1)], 1), _c('a-layout-content', {
    style: {
      margin: '24px 16px',
      padding: '24px',
      background: '#fff',
      minHeight: '280px'
    }
  }, [_c('Table', {
    attrs: {
      "data": _vm.codes
    },
    on: {
      "update-data": function updateData($event) {
        return _vm.getExternalCode();
      }
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }