var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('PageTitle', {
    attrs: {
      "title": "Редактирование dct] файлов"
    }
  }), _c('a-layout-content', {
    staticClass: "page__content"
  }, [_c('a-tabs', {
    attrs: {
      "type": "card"
    }
  }, [_c('a-tab-pane', {
    key: "1",
    attrs: {
      "tab": "Robots.txt RU"
    }
  }, [_c('a-form', [_c('a-form-item', {
    attrs: {
      "label": "Код"
    }
  }, [_c('a-textarea', {
    attrs: {
      "rows": "20",
      "placeholder": "I'm the content"
    },
    model: {
      value: _vm.robotsRu.file_content,
      callback: function callback($$v) {
        _vm.$set(_vm.robotsRu, "file_content", $$v);
      },
      expression: "robotsRu.file_content"
    }
  })], 1), _c('a-button', {
    attrs: {
      "type": "success",
      "disabled": _vm.buttonDisable
    },
    on: {
      "click": function click($event) {
        return _vm.saveCode(_vm.robotsRu, 'robotsRu');
      }
    }
  }, [_vm._v(_vm._s(_vm.button))])], 1)], 1), _c('a-tab-pane', {
    key: "2",
    attrs: {
      "tab": "Robots.txt EN"
    }
  }, [_c('a-form', [_c('a-form-item', {
    attrs: {
      "label": "Код"
    }
  }, [_c('a-textarea', {
    attrs: {
      "rows": "20",
      "placeholder": "I'm the content"
    },
    model: {
      value: _vm.robotsEn.file_content,
      callback: function callback($$v) {
        _vm.$set(_vm.robotsEn, "file_content", $$v);
      },
      expression: "robotsEn.file_content"
    }
  })], 1), _c('a-button', {
    attrs: {
      "type": "success",
      "disabled": _vm.buttonDisable
    },
    on: {
      "click": function click($event) {
        return _vm.saveCode(_vm.robotsEn, 'robotsEn');
      }
    }
  }, [_vm._v(_vm._s(_vm.button))])], 1)], 1), _c('a-tab-pane', {
    key: "3",
    attrs: {
      "tab": "Ads.txt RU"
    }
  }, [_c('a-form', [_c('a-form-item', {
    attrs: {
      "label": "Код"
    }
  }, [_c('a-textarea', {
    attrs: {
      "rows": "20",
      "placeholder": "I'm the content"
    },
    model: {
      value: _vm.adsRu.file_content,
      callback: function callback($$v) {
        _vm.$set(_vm.adsRu, "file_content", $$v);
      },
      expression: "adsRu.file_content"
    }
  })], 1), _c('a-button', {
    attrs: {
      "type": "success",
      "disabled": _vm.buttonDisable
    },
    on: {
      "click": function click($event) {
        return _vm.saveCode(_vm.adsRu, 'adsRu');
      }
    }
  }, [_vm._v(_vm._s(_vm.button))])], 1)], 1), _c('a-tab-pane', {
    key: "4",
    attrs: {
      "tab": "Ads.txt EN"
    }
  }, [_c('a-form', [_c('a-form-item', {
    attrs: {
      "label": "Код"
    }
  }, [_c('a-textarea', {
    attrs: {
      "rows": "20",
      "placeholder": "I'm the content"
    },
    model: {
      value: _vm.adsEn.file_content,
      callback: function callback($$v) {
        _vm.$set(_vm.adsEn, "file_content", $$v);
      },
      expression: "adsEn.file_content"
    }
  })], 1), _c('a-button', {
    attrs: {
      "type": "success",
      "disabled": _vm.buttonDisable
    },
    on: {
      "click": function click($event) {
        return _vm.saveCode(_vm.adsEn, 'adsEn');
      }
    }
  }, [_vm._v("Сохранить")])], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }