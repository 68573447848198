
// @ts-nocheck
import { Component, Vue, Prop } from 'vue-property-decorator';
import PageTitle from '@/components/Global/PageTitle.vue';
import { applications } from '@/api';
import { platformsRoute } from '@/components/Applications/routes';

@Component({ name: 'AddPlatform', components: { PageTitle } })
export default class AddPlatform extends Vue {
  @Prop() public id!: number;

  title: string = '';
  action: string = '';

  basePlatform: any = {
    name: '',
    seoName: '',
    label: '',
    enLabel: '',
  };

  /**
   * @description Обработка ошибок
   * @param {string} message
   * @return {function}
   */
  handleErrors(message: string) {
    return ({ errors, code }: any) => {
      if (errors && code === 422) {
        Object.values(errors).forEach((values: any) => {
          values.forEach((value: string) => {
            this.$message.error(value);
          });
        });
      } else {
        this.$message.success(message, 2);
        this.$store.dispatch('GET_PLATFORM_TYPES');

      }
    };
  }

  mounted() {
    if (this.id) {
      this.action = 'Сохранить';
      this.title = 'Редактировать платформу';
      this.getPlatformById();
    } else {
      this.action = 'Добавить';
      this.title = 'Добавить платформу';
    }
  }

  /**
   * @description Получение языка по id
   * @return {Promise<any>}
   */
  private async getPlatformById(): Promise<any> {
    await applications
      .getPlatformTypeById(this.id)
      .then((res) => res.json())
      .then(({ data }) => {
        this.basePlatform = data;
      });
  }

  /**
   * @description Метод по клику на кнопку (текущего действия)
   * @return {Promise<any>}
   */
  private async handleSubmit(): Promise<any> {
    if (this.id) {
      await applications
        .updatePlatformType(this.id, this.basePlatform)
        .then((res) => res.json())
        .then(this.handleErrors('Платформа успешно обновлена :)'));
    } else {
      await applications
        .createPlatformType(this.basePlatform)
        .then((res) => res.json())
        .then(this.handleErrors('Платформа успешно создана :)'));
    }
  }
}
