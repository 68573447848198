<template>
  <a-table
    :columns="columns"
    :dataSource="tagListings"
    :loading="loading || removing"
    rowKey="id"
  >
    <div
      slot="filterDropdown"
      slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
      style="padding: 8px"
    >
      <a-input
        v-ant-ref="c => (searchInput = c)"
        :placeholder="`Адрес`"
        :value="selectedKeys[0]"
        style="width: 188px; margin-bottom: 8px; display: block;"
        @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
        @pressEnter="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
      />
      <a-button
        type="primary"
        icon="search"
        size="small"
        style="width: 90px; margin-right: 8px"
        @click="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
      >
        Искать
      </a-button>
      <a-button size="small" style="width: 90px" @click="() => handleReset(clearFilters)">
        Сбросить
      </a-button>
    </div>
    <a-icon
      slot="filterIcon"
      slot-scope="filtered"
      type="search"
      :style="{ color: filtered ? '#108ee9' : undefined }"
    />

    <template slot="customRender" slot-scope="text, record, index, column">
        <span v-if="searchText && searchedColumn === column.dataIndex">
          <template
            v-for="(fragment, i) in text
              .toString()
              .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
          >
            <mark
              v-if="fragment.toLowerCase() === searchText.toLowerCase()"
              :key="i"
              class="highlight"
            >{{ fragment }}</mark
            >
            <template v-else>{{ fragment }}</template>
          </template>
        </span>
      <template v-else>
        {{ text }}
      </template>
    </template>

    <template slot="actions" slot-scope="tagListing">
      <a-tooltip>
        <template slot="title">
          Отвязать категорию
        </template>
        <a-button
          type="danger"
          class="gray-btn"
          icon="minus"
          @click="remove(tagListing.id)"
        />
      </a-tooltip>
    </template>
  </a-table>
</template>

<script>
  import PageTitle from '../../Global/PageTitle';
  import TopMenu from '../Add/TopMenu';
  import ModerationTop from '../../Moderation/ModerationTop';
  import DeveloperModeration from '../../Moderation/DeveloperModeration';
  import { applications as appApi } from '@/api';
  import TagListingAdd from '@/components/Applications/TagListings/TagListingAdd';

  export default {
    name      : 'TagListingsList',
    components: {
      TagListingAdd,
      PageTitle,
      TopMenu,
      ModerationTop,
      DeveloperModeration,
    },
    props     : {
      id         : {
        required: true,
      },
      loading    : {
        required: true,
        type    : Boolean,
      },
      tagListings: {
        required: true,
        type    : Array,
      },
    },
    data() {
      return {
        searchText    : '',
        searchInput   : null,
        searchedColumn: '',

        columns : [
          {
            title           : 'Id',
            dataIndex       : 'id',
            key             : 'id',
            defaultSortOrder: 'ascend',
            sorter          : (a, b) => a.id - b.id,
          },
          {
            title    : 'Адрес',
            dataIndex: 'url',
            key      : 'url',

            scopedSlots: {
              filterDropdown: 'filterDropdown',
              filterIcon    : 'filterIcon',
              customRender  : 'customRender',
            },

            onFilter: (value, record) =>
              record.url
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),

            onFilterDropdownVisibleChange: (visible) => {
              if (visible) {
                setTimeout(() => {
                  this.searchInput.focus();
                });
              }
            },

            sorter: (a, b) => (a.url > b.url) ? 1 : ((b.url > a.url) ? -1 : 0),
          },
          {
            title      : 'Действия',
            key        : 'actions',
            scopedSlots: { customRender: 'actions' },
          },
        ],
        removing: false,
      };
    },
    methods   : {
      async remove(tagId) {
        this.removing = true;

        try {
          await appApi.detachTagListing(this.id, tagId);
          this.$message.success('Категория удалена для приложения', 2);
          this.$emit('removed', tagId);
        } catch (e) {
          this.$message.error('Не удалось удалить', 2);
        } finally {
          this.removing = false;
        }
      },

      handleSearch(selectedKeys, confirm, dataIndex) {
        confirm();
        this.searchText     = selectedKeys[0];
        this.searchedColumn = dataIndex;
      },

      handleReset(clearFilters) {
        clearFilters();
        this.searchText = '';
      },
    },
  };
</script>

<style scoped>

</style>
