var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('PageTitle', {
    attrs: {
      "title": "Тег Листинги"
    }
  }), _c('a-layout-content', {
    staticClass: "page__content"
  }, [_c('h3', [_vm._v("Фильтр")]), _c('a-row', {
    attrs: {
      "gutter": 24
    }
  }, [_c('a-col', {
    attrs: {
      "span": "6"
    }
  }, [_c('a-select', {
    attrs: {
      "size": "large",
      "placeholder": "Выбрать OS"
    },
    model: {
      value: _vm.searchParams.os_id,
      callback: function callback($$v) {
        _vm.$set(_vm.searchParams, "os_id", $$v);
      },
      expression: "searchParams.os_id"
    }
  }, _vm._l(_vm.operationSystems, function (os) {
    return _c('a-select-option', {
      key: os.id,
      attrs: {
        "value": os.id
      }
    }, [_vm._v(" " + _vm._s(os.seo_name) + " ")]);
  }), 1)], 1), _c('a-col', {
    attrs: {
      "span": "6"
    }
  }, [_c('a-input', {
    attrs: {
      "size": "large",
      "placeholder": "URL"
    },
    model: {
      value: _vm.searchParams.url,
      callback: function callback($$v) {
        _vm.$set(_vm.searchParams, "url", $$v);
      },
      expression: "searchParams.url"
    }
  })], 1)], 1), _c('a-row', {
    staticStyle: {
      "padding-top": "50px"
    }
  }, [_c('a-col', {
    attrs: {
      "span": "12"
    }
  }, [_c('a-button', {
    attrs: {
      "size": "large"
    },
    on: {
      "click": _vm.resetFields
    }
  }, [_vm._v("Сбросить")])], 1), _c('a-col', {
    staticStyle: {
      "text-align": "right"
    },
    attrs: {
      "span": "12"
    }
  }, [_c('a-button', {
    staticStyle: {
      "margin-left": "20px"
    },
    attrs: {
      "size": "large",
      "type": "primary",
      "icon": "search"
    },
    on: {
      "click": _vm.handleSearch
    }
  }, [_vm._v("Фильтровать ")])], 1)], 1)], 1), _c('a-layout-content', {
    staticClass: "page__content"
  }, [_c('a-table', {
    attrs: {
      "columns": _vm.columns,
      "pagination": false,
      "dataSource": _vm.listings.data,
      "loading": _vm.loading,
      "rowKey": "id"
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn(id, record) {
        return [_c('a-row', {
          attrs: {
            "type": "flex"
          }
        }, [_c('a-col', [_c('router-link', {
          attrs: {
            "to": {
              path: "/tag-listings/".concat(record.id)
            }
          }
        }, [_c('a-button', {
          staticClass: "gray-btn",
          staticStyle: {
            "margin-right": "10px"
          },
          attrs: {
            "type": "primary",
            "icon": "edit"
          }
        })], 1)], 1)], 1)];
      }
    }])
  }), _c('a-row', {
    staticStyle: {
      "text-align": "right"
    }
  }, [_vm.listings.meta ? _c('a-pagination', {
    staticStyle: {
      "margin-top": "20px"
    },
    attrs: {
      "total": _vm.listings.meta.total,
      "pageSize": _vm.listings.meta.per_page
    },
    on: {
      "change": _vm.paginationChange
    },
    model: {
      value: _vm.listings.meta.current_page,
      callback: function callback($$v) {
        _vm.$set(_vm.listings.meta, "current_page", $$v);
      },
      expression: "listings.meta.current_page"
    }
  }) : _vm._e()], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }