<template>
  <div>
    <PageTitle title="Редактирование комментария"/>

    <a-layout-content class="page__content">
      <a-spin tip="Loading..." :spinning="loading"/>

      <template v-if="!loading">
        <div class="change__comment__info">
          <b>Пользователь: {{ comment.user && comment.user.name }}</b>
        </div>
        <div class="change__comment__info">
          <b>Дата создания: </b>{{ comment.created_at }}
        </div>
        <div class="change__comment__info">
          <b>Дата публикации: </b>{{ comment.published_at }}
        </div>
        <div class="change__comment__info" v-if="commentId">
          <b>Оценка: </b>{{ comment.rating }}
        </div>

        <a-row>
          <a-card title="Параметры для локали">
            <a-tabs
                v-model="activeTab"
            >
              <a-tab-pane
                  v-for="{title, locale} in LOCALE_LIST_EXTENDED"
                  :key="locale"
                  :tab="title"
              >
                <a-form-item :label="`Текст комментария ${locale}`">
                  <a-textarea v-model="comment.translations[locale].text" style="height: 120px"></a-textarea>
                </a-form-item>
              </a-tab-pane>
            </a-tabs>
          </a-card>
        </a-row>

        <a-button
            type="primary"
            size="large"
            @click="handleSubmit"
        >
          Сохранить
        </a-button>

        <a-button
            v-if="comment.mark_as_spam"
            size="large"
            class="orange-btn"
            type="primary"
            @click="removeCommentFromSpam"
        >
          Убрать из спама
        </a-button>
        <a-button
            v-else
            size="large"
            class="orange-btn"
            @click="seCommentToSpam"
        >
          Отметить как спам
        </a-button>
      </template>
    </a-layout-content>
  </div>
</template>

<script>
import PageTitle from '@/components/Global/PageTitle.vue';
import {comments} from '@/api/comments';
import {commentsApps} from '@/components/Comments/routes';
import {applications} from '@/api';
import {
  LOCALE_EN, LOCALE_EN_NG, LOCALE_EN_SO,
  LOCALE_FR,
  LOCALE_FR_CI,
  LOCALE_FR_CM,
  LOCALE_FR_FR,
  LOCALE_FR_SN,
  LOCALE_LIST_EXTENDED,
  LOCALE_RU,
} from '@/global/constants';
import {merge} from 'lodash';
import {buildTranslationsDataForModel} from '@/helpers';

export default {
  components: {
    PageTitle,
  },

  props: ['id', 'commentId'],

  data() {
    return {
      comment: {
        mark_as_spam: 0,
        translations: {
          [LOCALE_EN]: {text: ''},
          [LOCALE_RU]: {text: ''},
          [LOCALE_FR]: {text: ''},
          [LOCALE_FR_CI]: {text: ''},
          [LOCALE_FR_CM]: {text: ''},
          [LOCALE_FR_SN]: {text: ''},
          [LOCALE_FR_FR]: {text: ''},
          [LOCALE_EN_NG]: {text: ''},
          [LOCALE_EN_SO]: {text: ''},
        },
      },
      LOCALE_LIST_EXTENDED,
      activeTab: LOCALE_RU,
      loading: true,
    };
  },

  mounted() {
    if (this.id) {
      this.getCommentById();
    } else {
      this.getAppReviewById();
    }
  },

  methods: {
    async getAppReviewById() {
      this.loading = true;

      const response = await applications.getCommentById(this.commentId);
      const {data} = await response.json();

      merge(this.comment, data);

      for (const {locale} of LOCALE_LIST_EXTENDED) {
        if (this.comment.translations.hasOwnProperty(locale) && this.comment.translations[locale].text) {
          this.activeTab = locale;
          break;
        }
      }

      this.loading = false;
    },

    /**
     * @description Получение комментария по id
     * @return {Promise<any>}
     */
    async getCommentById() {
      await comments
          .getNewsCommentById(this.id)
          .then((res) => res.json())
          .then(({data}) => {
            merge(this.comment, data);
            this.loading = false;
            console.log('Получение комментария по id')
          });
    },

    /**
     * @description Отправка комментария в спам
     * @return {Promise<any>}
     */
    async seCommentToSpam() {
      if (this.id) {
        await comments.setCommentToSpam(this.id).then(async (res) => {
          if (res.status === 200) {
            this.$message.success('Сообщение помещено в спам', 2);
            await this.getCommentById();
          }
        });
      }

      if (this.commentId) {
        await applications
            .updateCommentSpam(this.commentId, {
              markAsSpam: 1,
            })
            .then(async (res) => {
              if (res.status === 200) {
                this.$message.success('Отзыв помещен в спам', 2);
                await this.getAppReviewById();
              }
            });
      }
    },

    /**
     * @description Удаление комментария из спама
     * @return {Promise<any>}
     */
    async removeCommentFromSpam() {
      if (this.id) {
        await comments
            .removeCommentFromSpam(this.id || this.commentId)
            .then(async (res) => {
              if (res.status === 200) {
                this.$message.success('Сообщение убрано из спама :)', 2);
                await this.getCommentById();
              }
            });
      }

      if (this.commentId) {
        await applications
            .updateCommentSpam(this.commentId, {
              markAsSpam: 0,
            })
            .then(async (res) => {
              if (res.status === 200) {
                this.$message.success('Отзыв убран из спама :)', 2);
                await this.getAppReviewById();
              }
            });
      }
    },

    /**
     * @description Обновление текста комментария
     * @return {Promise<any>}
     */
    async editCommentById() {
      const params = {...this.comment};
      const translations = buildTranslationsDataForModel(this.comment.translations);
      params.translations = translations;

      if (this.id) {
        await comments.editCommentById(this.id, params).then((res) => {
          if (res.status === 200) {
            this.$message.success('Успешно обновлено :)');

          }
        });
      }

      if (this.commentId) {
        delete params.markAsSpam;
        await applications
            .updateCommentById(this.commentId, params)
            .then((res) => {
              if (res.status === 200) {
                this.$message.success('Успешно обновлено :)');
                this.$router.push(commentsApps);
              }
            });
      }
    },

    /**
     * @description Сабмит формы
     * @return {Promise<any>}
     */
    async handleSubmit() {
      await this.editCommentById();
    },
  },

};
</script>

<style lang="scss" scoped>
.change__comment__info {
  margin: 15px 0;
  font-size: 14px;
}

.page__content {
  position: relative;
}

.orange-btn,
.orange-btn:active,
.orange-btn:focus,
.orange-btn:hover {
  background: #ffd666;
  border-radius: 4px;
  color: #fff;
  position: absolute;
  top: 20px;
  right: 20px;
  border: none;
  font-size: 14px;
}
</style>
