<template>
  <div>
    <a-layout-content
      v-if="profile"
      class="page__content"
    >
      <a-form layout="horizontal">
        <a-form-item
          label="Avatar"
          :label-col="formLayout.labelCol"
          :wrapper-col="formLayout.wrapperCol"
        >
          <a-upload
            name="file"
            list-type="picture-card"
            class="avatar-uploader"
            :show-upload-list="false"
            :action="action"
            :headers="headers"
            @change="handleChange"
          >
            <a-avatar v-if="profile.avatar_url" :src="profile.avatar_url" shape="square" size="large"/>
            <div v-else>
              <a-icon :type="loadingAvatar ? 'loading' : 'plus'"/>
              <div class="ant-upload-text">
                Upload
              </div>
            </div>
          </a-upload>
        </a-form-item>

        <a-form-item
          label="Email"
          :label-col="formLayout.labelCol"
          :wrapper-col="formLayout.wrapperCol"
          :class="{'has-error': errors.hasOwnProperty('email')}"
          :required="true"
        >
          <a-input
            size="large"
            v-model="profile.email"
          />
        </a-form-item>

        <a-form-item
          :label="user.hasDeveloper ? 'Разработчик' : 'Имя'"
          size="large"
          :label-col="formLayout.labelCol"
          :wrapper-col="formLayout.wrapperCol"
          :class="{'has-error': errors.hasOwnProperty('name')}"
          :required="true"
        >
          <a-input
            v-model="profile.name"
            size="large"
          />
        </a-form-item>

        <a-form-item
          label="Фамилия"
          size="large"
          :label-col="formLayout.labelCol"
          :wrapper-col="formLayout.wrapperCol"
          :class="{'has-error': errors.hasOwnProperty('surname')}"
        >
          <a-input
            v-model="profile.surname"
            size="large"
          />
        </a-form-item>

        <a-form-item
          label="Дата рождения"
          :label-col="formLayout.labelCol"
          :wrapper-col="formLayout.wrapperCol"
          :class="{'has-error': errors.hasOwnProperty('birthday')}"
        >
          <a-date-picker
            size="large"
            v-model="profile.birthday"
            placeholder="Выберите дату"
          />
        </a-form-item>

        <a-form-item
          label="Официальный представитель"
          size="large"
          :label-col="formLayout.labelCol"
          :wrapper-col="formLayout.wrapperCol"
          :class="{'has-error': errors.hasOwnProperty('official_representative')}"
        >
          <a-checkbox
            v-model="profile.official_representative"
            size="large"
          />
        </a-form-item>

        <a-form-item
          label="Сайт"
          :label-col="formLayout.labelCol"
          :wrapper-col="formLayout.wrapperCol"
          :class="{'has-error': errors.hasOwnProperty('site')}"
        >
          <a-input
            v-model="profile.site"
            size="large"
          />
        </a-form-item>

        <a-form-item
          label="Город"
          :label-col="formLayout.labelCol"
          :wrapper-col="formLayout.wrapperCol"
          :class="{'has-error': errors.hasOwnProperty('city')}"
        >
          <a-input
            v-model="profile.city"
            size="large"
          />
        </a-form-item>

        <a-form-item
          label="Род деятельности"
          :label-col="formLayout.labelCol"
          :wrapper-col="formLayout.wrapperCol"
          :class="{'has-error': errors.hasOwnProperty('role_of_activity')}"
        >
          <a-input v-model="profile.role_of_activity" size="large"/>
        </a-form-item>

        <a-form-item
          label="Должность"
          :label-col="formLayout.labelCol"
          :wrapper-col="formLayout.wrapperCol"
          :class="{'has-error': errors.hasOwnProperty('position')}"
        >
          <a-input v-model="profile.position" size="large"></a-input>
        </a-form-item>

        <a-form-item
          label="Обо мне"
          :label-col="formLayout.labelCol"
          :wrapper-col="formLayout.wrapperCol"
          :class="{'has-error': errors.hasOwnProperty('description')}"
        >
          <a-textarea
            v-model="profile.description"
            size="large"
            style="height: 120px"
          ></a-textarea>
        </a-form-item>
      </a-form>

      <a-row :gutter="24">
        <a-col span="12">
          <a-button type="primary" size="large" @click="save">Сохранить</a-button>
        </a-col>
      </a-row>
    </a-layout-content>
  </div>
</template>

<script>
import { getProfile, updateProfile } from '@/api/profile';
import moment from 'moment';
import { API_URL, DATE_FORMAT } from '@/global/constants';
import Cookie from '@/helpers/Cookie';
import { mapState } from 'vuex';

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

export default {
  name: 'Profile',
  data() {
    return {
      profile: null,

      loadingAvatar: false,
      action       : `${API_URL}/api/admin/profile/avatar`,
      headers      : {
        Authorization: `Bearer ${Cookie.get('admin_token')}`,
        Accept       : 'application/json',
      },

      formLayout: {
        labelCol  : { span: 6 },
        wrapperCol: { span: 14 },
      },

      errors: {},
    };
  },
  computed: {
    ...mapState(['user']),
  },
  async created() {
    const profileResponse = await getProfile();
    const json            = await profileResponse.json();
    this.profile          = json.data;
  },
  methods: {
    async save() {
      if (this.profile.birthday) {
        this.profile.birthday = moment(this.profile.birthday).format(DATE_FORMAT);
      }

      this.errors    = {};
      const response = await updateProfile({ ...this.profile });
      const json     = await response.json();

      if (response.ok) {
        this.profile = json.data;
        this.$message.success('Личные данные успешно обновлены');
      } else {
        if (json.hasOwnProperty('errors')) {
          for (const name of Object.keys(json.errors)) {
            for (const error of json.errors[name]) {
              this.$set(this.errors, name, error);
              this.$message.error(error);
            }
          }
        }
      }
    },

    handleChange(info) {
      if (info.file.status === 'uploading') {
        this.loadingAvatar = true;
        return;
      }

      if (info.file.status === 'done') {
        // Get this url from response in real world.
        getBase64(info.file.originFileObj, (imageUrl) => {
          this.profile.avatar_url = imageUrl;
          this.loadingAvatar      = false;
        });
      }
    },
  },
};
</script>

<style scoped>
.ant-avatar-lg {
  width: 80px;
  height: 80px;
  line-height: 80px;
}
</style>
