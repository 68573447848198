var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('PageTitle', {
    attrs: {
      "title": "Платформы"
    }
  }, [_c('router-link', {
    attrs: {
      "to": {
        path: "/applications/platforms/add"
      }
    }
  }, [_c('a-button', {
    staticStyle: {
      "margin-right": "10px"
    },
    attrs: {
      "type": "primary",
      "size": "large"
    }
  }, [_vm._v("Добавить платформу")])], 1)], 1), _c('a-layout-content', {
    staticClass: "page__content"
  }, [_c('a-table', {
    attrs: {
      "columns": _vm.columns,
      "dataSource": _vm.platformTypes,
      "pagination": false,
      "rowKey": "id"
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn(operation, record) {
        return [_c('router-link', {
          attrs: {
            "to": {
              path: "/applications/platforms/".concat(record.id, "/change")
            }
          }
        }, [_c('a-button', {
          staticClass: "gray-btn",
          staticStyle: {
            "margin-right": "10px"
          },
          attrs: {
            "type": "primary",
            "icon": "edit"
          }
        })], 1), _c('a-button', {
          staticClass: "gray-btn",
          attrs: {
            "type": "danger",
            "icon": "delete"
          },
          on: {
            "click": function click($event) {
              return _vm.deletePLatformType(record.id);
            }
          }
        })];
      }
    }])
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }