var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('AddPageTitle', {
    attrs: {
      "title": _vm.title,
      "id": _vm.id || _vm.moderationId
    }
  }), _vm.moderationId ? _c('ModerationTop', {
    attrs: {
      "appId": _vm.moderationId
    }
  }) : _vm._e(), _vm.id ? _c('DeveloperModeration', {
    attrs: {
      "appId": _vm.id
    }
  }) : _vm._e(), _c('a-layout-content', {
    staticClass: "page__content"
  }, [_c('TopMenu', {
    attrs: {
      "id": _vm.id,
      "moderationId": _vm.moderationId,
      "active": "images"
    }
  }), _c('a-form', {
    attrs: {
      "form": _vm.form
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "Загрузка логотипа"
    }
  }, [_c('a-upload', {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ['logotype', {
        rules: [{
          required: true,
          message: 'Загрузите логотип приложения'
        }]
      }],
      expression: "[\n            'logotype',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: 'Загрузите логотип приложения'\n                }\n              ]\n            }\n          ]"
    }],
    attrs: {
      "listType": "picture-card",
      "fileList": _vm.logoList,
      "multiple": false,
      "before-upload": _vm.beforeUploadLogo,
      "remove": _vm.handleRemove
    },
    on: {
      "preview": _vm.handlePreview
    }
  }, [_vm.logoList.length < 1 ? _c('div', [_c('a-icon', {
    attrs: {
      "type": "plus"
    }
  }), _c('div', {
    staticClass: "ant-upload-text"
  }, [_vm._v("Загрузить логотип")])], 1) : _vm._e()])], 1), _c('a-row', {
    staticClass: "row-st",
    attrs: {
      "gutter": 24
    }
  }, [_c('a-card', {
    attrs: {
      "title": "Локализация"
    },
    scopedSlots: _vm._u([_vm.availableLocales.length ? {
      key: "extra",
      fn: function fn() {
        return [_c('a-dropdown', {
          scopedSlots: _vm._u([{
            key: "overlay",
            fn: function fn() {
              return [_c('a-menu', {
                on: {
                  "click": _vm.addTranslation
                }
              }, _vm._l(_vm.availableLocales, function (locale) {
                return _c('a-menu-item', {
                  key: locale
                }, [_vm._v(" " + _vm._s(_vm.getLocaleTitle(locale)) + " ")]);
              }), 1)];
            },
            proxy: true
          }], null, false, 2503522434)
        }, [_c('a-button', [_vm._v(" Добавить локаль "), _c('a-icon', {
          attrs: {
            "type": "down"
          }
        })], 1)], 1)];
      },
      proxy: true
    } : null], null, true)
  }, [_c('a-tabs', {
    attrs: {
      "type": "editable-card",
      "hide-add": ""
    },
    on: {
      "edit": _vm.onTabTranslationsEdit
    },
    model: {
      value: _vm.activeTab,
      callback: function callback($$v) {
        _vm.activeTab = $$v;
      },
      expression: "activeTab"
    }
  }, [_vm._l(_vm.LOCALE_LIST_EXTENDED, function (_ref) {
    var locale = _ref.locale,
      title = _ref.title;
    return [_vm.groupedScreenshotsList.hasOwnProperty(locale) ? _c('a-tab-pane', {
      key: locale,
      attrs: {
        "tab": title,
        "closabel": true
      }
    }, [_c('a-form-item', {
      attrs: {
        "label": "Загрузка скриншотов"
      }
    }, [_c('a-upload', {
      directives: [{
        name: "decorator",
        rawName: "v-decorator",
        value: ["screenshots.".concat(locale), {
          rules: [{
            required: true,
            message: 'Загрузите скриншоты приложения'
          }]
        }],
        expression: "[\n                      `screenshots.${locale}`,\n                      {\n                        rules: [\n                          {\n                            required: true,\n                            message: 'Загрузите скриншоты приложения'\n                          }\n                        ]\n                      }\n                    ]"
      }],
      attrs: {
        "listType": "picture-card",
        "fileList": _vm.groupedScreenshotsList[locale],
        "remove": _vm.handleRemove,
        "multiple": true,
        "before-upload": _vm.beforeUploadScreenshot
      },
      on: {
        "preview": _vm.handlePreview
      }
    }, [_vm.groupedScreenshotsList[locale].length < 5 ? _c('div', [_c('a-icon', {
      attrs: {
        "type": "plus"
      }
    }), _c('div', {
      staticClass: "ant-upload-text"
    }, [_vm._v("Загрузить скриншоты")])], 1) : _vm._e()])], 1)], 1) : _vm._e()];
  })], 2)], 1)], 1)], 1), _c('a-button', {
    staticStyle: {
      "margin-top": "20px"
    },
    attrs: {
      "size": "large",
      "type": "primary"
    },
    on: {
      "click": _vm.handleSubmit
    }
  }, [_vm._v(" Сохранить ")])], 1), _c('a-modal', {
    attrs: {
      "visible": _vm.previewVisible,
      "footer": null
    },
    on: {
      "cancel": _vm.handleCancel
    }
  }, [_c('img', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "alt": "example",
      "src": _vm.previewImage
    }
  })])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }