import { addResponseInterceptor } from '../helpers/fetchInterceptor';
import AuthService from '@/helpers/AuthService';

const authService = new AuthService();

addResponseInterceptor(null, function (response) {
  if (response instanceof Response) {
    if (!response.ok && response.status === 401) {
      authService.logout();
      const redirectTo = window.location.pathname;
      window.location = `/login?redirectTo=${redirectTo}`;
      console.log('no auth');
    }
  }
});
