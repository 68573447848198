var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('PageTitle', {
    attrs: {
      "title": _vm.title
    }
  }), _c('a-layout-content', {
    staticClass: "page__content"
  }, [_c('a-spin', {
    attrs: {
      "spinning": _vm.submitting
    }
  }, [_c('a-form', {
    attrs: {
      "form": _vm.form,
      "layout": "horizontal"
    },
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.handleSubmit.apply(null, arguments);
      }
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "Приложение",
      "label-col": _vm.formItemLayout.labelCol,
      "wrapper-col": _vm.formItemLayout.wrapperCol
    }
  }, [_c('a-select', {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ['application_id', {
        rules: [{
          required: true,
          message: 'Выберите приложение'
        }]
      }],
      expression: "['application_id', { rules: [{ required: true, message: 'Выберите приложение' }] }]"
    }],
    attrs: {
      "show-search": "",
      "allow-clear": "",
      "placeholder": "Название приложения",
      "default-active-first-option": false,
      "show-arrow": false,
      "filter-option": false,
      "not-found-content": _vm.appsSearchDataSearching ? undefined : 'Не найдено',
      "size": "large"
    },
    on: {
      "search": _vm.handleAppNameSearch
    }
  }, [_vm.appsSearchDataSearching ? _c('a-spin', {
    attrs: {
      "slot": "notFoundContent",
      "size": "small"
    },
    slot: "notFoundContent"
  }) : _vm._e(), _vm._l(_vm.appsSearchData, function (app) {
    return _c('a-select-option', {
      key: app.id
    }, [_vm._v(" " + _vm._s(app.name) + " (" + _vm._s(app.seoName) + ") ")]);
  })], 2)], 1), _c('a-form-item', {
    attrs: {
      "label": "Тип устройства",
      "label-col": _vm.formItemLayout.labelCol,
      "wrapper-col": _vm.formItemLayout.wrapperCol
    }
  }, [_c('a-select', {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ['device_type', {
        rules: [{
          required: true,
          message: 'Тип устройства - обязательное поле'
        }]
      }],
      expression: "[\n            'device_type',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: 'Тип устройства - обязательное поле'\n                }\n              ]\n            }\n          ]"
    }],
    attrs: {
      "placeholder": "Выберите тип устройства",
      "size": "large"
    }
  }, _vm._l(_vm.deviceTypes, function (type) {
    return _c('a-select-option', {
      key: type.value,
      attrs: {
        "value": type.value
      }
    }, [_vm._v(_vm._s(type.name) + " ")]);
  }), 1)], 1), _c('a-form-item', {
    attrs: {
      "label": "Показывать для категории",
      "label-col": _vm.formItemLayout.labelCol,
      "wrapper-col": _vm.formItemLayout.wrapperCol
    }
  }, [_c('a-select', {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ['categories', {
        rules: []
      }],
      expression: "[\n            'categories',\n            {\n              rules: []\n            }\n          ]"
    }],
    attrs: {
      "size": "large",
      "placeholder": "категории",
      "mode": "multiple",
      "filter-option": _vm.filterOption
    }
  }, [_vm._l(_vm.categories, function (category) {
    return [_c('a-select-option', {
      key: category.id,
      attrs: {
        "value": category.id,
        "title": category.name
      }
    }, [_vm._v(" " + _vm._s(category.parent ? category.parent.name + ' / ' : '') + _vm._s(category.name) + " (" + _vm._s(category.os.name) + ") ")]), _vm._l(category.children, function (subCategory) {
      return category.children ? [_c('a-select-option', {
        key: subCategory.id,
        attrs: {
          "value": subCategory.id,
          "title": subCategory.name
        }
      }, [_vm._v(" " + _vm._s(category.name) + " / " + _vm._s(subCategory.name) + " (" + _vm._s(category.os.name) + ") ")])] : _vm._e();
    })];
  })], 2)], 1), _c('a-form-item', {
    attrs: {
      "label": "Показывать для приложения",
      "label-col": _vm.formItemLayout.labelCol,
      "wrapper-col": _vm.formItemLayout.wrapperCol
    }
  }, [_c('a-select', {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ['applications'],
      expression: "['applications']"
    }],
    attrs: {
      "show-search": "",
      "allow-clear": "",
      "placeholder": "Название приложения",
      "default-active-first-option": false,
      "show-arrow": false,
      "filter-option": false,
      "not-found-content": _vm.relatedAppsSearchDataSearching ? undefined : 'Не найдено',
      "size": "large",
      "mode": "multiple"
    },
    on: {
      "search": _vm.handleRelatedAppNameSearch
    }
  }, [_vm.relatedAppsSearchDataSearching ? _c('a-spin', {
    attrs: {
      "slot": "notFoundContent",
      "size": "small"
    },
    slot: "notFoundContent"
  }) : _vm._e(), _vm._l(_vm.relatedAppsSearchData, function (app) {
    return _c('a-select-option', {
      key: app.id
    }, [_vm._v(" " + _vm._s(app.name) + " (" + _vm._s(app.seoName) + ") ")]);
  })], 2)], 1), _c('a-form-item', {
    attrs: {
      "label": "Действие",
      "label-col": _vm.formItemLayout.labelCol,
      "wrapper-col": _vm.formItemLayout.wrapperCol
    }
  }, [_c('a-select', {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ['action', {
        rules: [{
          required: true,
          message: 'действие - обязательное поле'
        }]
      }],
      expression: "[\n            'action',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: 'действие - обязательное поле'\n                }\n              ]\n            }\n          ]"
    }],
    attrs: {
      "placeholder": "Выберите действие",
      "size": "large"
    }
  }, _vm._l(_vm.actions, function (action) {
    return _c('a-select-option', {
      key: action.value,
      attrs: {
        "value": action.value
      }
    }, [_vm._v(_vm._s(action.name) + " ")]);
  }), 1)], 1), _c('a-form-item', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.form.getFieldValue('action') === 'url',
      expression: "form.getFieldValue('action') === 'url'"
    }],
    attrs: {
      "label": "Ссылка",
      "label-col": _vm.formItemLayout.labelCol,
      "wrapper-col": _vm.formItemLayout.wrapperCol
    }
  }, [_c('a-input', {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ['url', {
        rules: [{
          required: false,
          message: 'Ссылка'
        }]
      }],
      expression: "['url', { rules: [{ required: false, message: 'Ссылка' }] }]"
    }],
    attrs: {
      "placeholder": "Ссылка"
    }
  })], 1), _c('a-form-item', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.form.getFieldValue('action') === 'download_file',
      expression: "form.getFieldValue('action') === 'download_file'"
    }],
    attrs: {
      "label": "Файл",
      "label-col": _vm.formItemLayout.labelCol,
      "wrapper-col": _vm.formItemLayout.wrapperCol
    }
  }, [_c('a-upload', {
    attrs: {
      "name": "file",
      "action": _vm.uploadAction,
      "headers": _vm.uploadHeaders,
      "file-list": _vm.fileList,
      "multiple": false,
      "showUploadList": {
        showPreviewIcon: false,
        showRemoveIcon: false
      }
    },
    on: {
      "change": _vm.uploadFileChange
    }
  }, [_c('a-button', [_c('a-icon', {
    attrs: {
      "type": "upload"
    }
  }), _vm._v(" Click to upload ")], 1)], 1)], 1), _c('a-form-item', {
    attrs: {
      "label": "Доступно для",
      "label-col": _vm.formItemLayout.labelCol,
      "wrapper-col": _vm.formItemLayout.wrapperCol
    }
  }, [_c('a-checkbox-group', {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["domains", {
        rules: [{
          required: true,
          message: 'Выберите хотя бы один домен'
        }]
      }],
      expression: "[\n                `domains`,\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Выберите хотя бы один домен'\n                    }\n                  ]\n                }\n              ]"
    }],
    attrs: {
      "options": _vm.domainOptions
    }
  })], 1), _c('a-form-item', {
    attrs: {
      "wrapper-col": {
        span: 14,
        offset: 4
      }
    }
  }, [_c('a-button', {
    attrs: {
      "type": "primary",
      "html-type": "submit"
    }
  }, [_vm._v(" " + _vm._s(_vm.btnTitle) + " ")])], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }