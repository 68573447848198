export const deviceTypes = [
  // {
  //   name: 'Все',
  //   value: 'any',
  // },
  {
    name: 'Десктоп',
    value: 'desktop',
  },
  {
    name: 'Мобайл',
    value: 'mobile',
  },
];

export function getDeviceTypeName(value) {
  return deviceTypes.find(device => device.value === value).name;
}

export const actions = [
  {
    name: 'Переход по ссылке',
    value: 'url',
  },
  {
    name: 'Качать загруженный файл',
    value: 'download_file',
  },
  {
    name: 'Качать последний файл аппа',
    value: 'download_last',
  },
];

export function getActionName(value) {
  return actions.find(action => action.value === value).name;
}
