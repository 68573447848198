
// @ts-nocheck
import { Component, Vue } from 'vue-property-decorator';
import { Redirect } from '@/types/Redirect';
import { settings } from '@/api';
import PageTitle from '@/components/Global/PageTitle.vue';
import Table from './Table.vue';

@Component({ name: 'List', components: { PageTitle, Table } })
export default class List extends Vue {
  redirects: Redirect[] = [];
  source: string = '';
  destination: string = '';
  loading: boolean = false;

  clearFilter() {
    this.source = '';
    this.destination = '';
  }

  mounted() {
    this.getRedirects();
  }

  paginationChange(page: number) {
    this.getRedirects(page);
    window.scrollTo(0, 0);
  }

  getRedirects(page = 1) {
    this.loading = true;
    settings
      .getAllRedirects(page)
      .then((res) => res.json())
      .then((data) => {
        this.redirects = data;
        this.loading = false;
      });
  }

  searchRedirect(page = 1) {
    const params = {
      page,
      source: this.source,
      destination: this.destination,
    };

    settings
      .searchRedirect(params)
      .then((res) => res.json())
      .then((data) => {
        this.redirects = data;
      });
  }

  handleReset() {
    this.getRedirects();
    this.source = '';
    this.destination = '';
  }
}
