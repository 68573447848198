<template>
  <div>
    <AddPageTitle
      title="Добавить SEO"
      :id="id"
    />
    <a-layout-content class="page__content">
      <TopMenu :id="id" active="seo"/>
      <a-spin :spinning="hasLoadApplicationSeo">
        <a-form :form="form">
          <a-tabs
            v-if="app"
            type="card"
          >
            <a-tab-pane
              v-for="{title, locale} in locales"
              :key="locale"
              :tab="title"
            >
              <div class="spin-content">
                <a-row gutter="24">
                  <a-col >
                    <a-form-item :label="`SeoTitle (${locale})`">
                      <a-input
                        size="large"
                        placeholder="Введите title"
                        v-decorator="[`translations.${locale}.seo_title`,{initialValue: app.translations[locale] ? app.translations[locale].seo_title : undefined}]"
                      />
                    </a-form-item>
                  </a-col>

                  <a-col >
                    <a-form-item :label="`SeoDescription (${locale})`">
                      <a-textarea
                        auto-size
                        style="height: 145px;"
                        size="large"
                        placeholder="Введите description"
                        v-decorator="[`translations.${locale}.seo_description`,{initialValue: app.translations[locale] ? app.translations[locale].seo_description : undefined,}]"
                      />
                    </a-form-item>
                  </a-col>

                  <a-col >
                    <a-form-item :label="`SeoKeywords (${locale})`">
                      <a-input
                        size="large"
                        placeholder="Введите keywords"
                        v-decorator="[`translations.${locale}.seo_keywords`,{initialValue: app.translations[locale]? app.translations[locale].seo_keywords : undefined,}]"
                      />
                    </a-form-item>
                  </a-col>
                </a-row>
              </div>
            </a-tab-pane>
          </a-tabs>
        </a-form>

        <a-row :gutter="24">
          <a-col span="12">
            <a-button size="large" type="primary" @click="handleSubmit">Сохранить</a-button>
          </a-col>
        </a-row>
      </a-spin>

    </a-layout-content>
  </div>
</template>

<script>
import PageTitle from '@/components/Global/PageTitle.vue';
import TopMenu from './TopMenu.vue';
import { isEmpty } from 'lodash';

import { applications } from '@/api';
import AddPageTitle from '@/components/Applications/Add/AddPageTitle.vue';
import { LOCALE_LIST, LOCALE_LIST_EXTENDED } from '@/global/constants';

export default {
  name: 'Seo',
  components: {
    AddPageTitle,
    PageTitle,
    TopMenu,
  },
  props     : ['id'],

  mounted() {
    if (this.id) {
      this.getApplicationSeo();
    }
  },
  data() {
    return {
      form                 : this.$form.createForm(this),
      app: null,
      hasLoadApplicationSeo: false,
    };
  },

  computed: {
    locales() {
      const locales = this.app ? Object.keys(this.app.translations) : [];
      return LOCALE_LIST_EXTENDED.filter(locale => locales.includes(locale.locale));
    },
  },

  methods: {
    handleSubmit() {
      this.form.validateFieldsAndScroll((errors, values) => {
        if (isEmpty(errors)) {
          applications
            .updateAppDescription(this.id, values)
            .then((res) => {
              if (res.status === 200) {
                this.$message.success(
                  'SEO описание успешно отредактировано :)',
                  2,
                );
              }
            })
            .catch(() => {
              this.$message.error(
                'Ошибка редактирования SEO описания,  :( Попробуйте позже',
                4,
              );
            });
        }
      });
    },

    /**
     * @description Получение SEO по приложению
     * @return {Promise<any>}
     */
    async getApplicationSeo() {
      this.hasLoadApplicationSeo = true;
      applications
        .getAppById(this.id)
        .then((res) => res.json())
        .then(({ data }) => {
          this.app = data;

          this.hasLoadApplicationSeo = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.ant-menu-root {
  height: 80px !important;
}

.ant-menu-item {
  padding: 0 100px;
  font-size: 16px;
}

.ant-upload-select-picture-card i {
  margin-top: 15px;
  font-size: 24px;
  color: #1890ff;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>
