<template>
  <div>
    <PageTitle title="Спонсорские приложения">
      <router-link :to="{name: 'AddApplicationsSponsored'}">
        <a-button size="large" icon="plus" type="primary"
        >Добавить спонсорское приложение
        </a-button>
      </router-link>
    </PageTitle>

    <a-layout-content class="page__content">
      <a-table
        :columns="columns"
        :pagination="false"
        :dataSource="apps"
        :loading="loading"
        rowKey="id"
      >
        <template v-slot:device_type="device_type">
          {{ getDeviceTypeName(device_type) }}
        </template>

        <template v-slot:domains="domains">
          {{ domains.map(domain => domain.short_name).join(',') }}
        </template>

        <template v-slot:action="action">
          {{ getActionName(action) }}
        </template>

        <template v-slot:actions="row">
          <router-link :to="{name: 'EditApplicationsSponsored', params: {id : row.id} }">
            <a-button
              type="primary"
              class="gray-btn"
              icon="edit"
              style="margin-right: 10px"
            />
          </router-link>

          <a-button
            type="danger"
            class="gray-btn"
            @click="deleteSponsoredApplication(row.id)"
            icon="delete"
          />
        </template>
      </a-table>
    </a-layout-content>
  </div>
</template>

<script>
import PageTitle from "@/components/Global/PageTitle.vue";
import {deleteSponsoredApplication, getSponsoredApplications} from "@/api/sponsoredApplication";
import {getActionName, getDeviceTypeName} from './SponsoredApplication';

export default {
  name: "ApplicationsSponsoredList",

  components: {PageTitle},

  data() {
    return {
      apps: [],
      loading: false,
      columns: [
        {
          title: 'Приложение',
          dataIndex: 'application.name',
          key: 'application.name',
        },
        {
          title: 'Тип устройства',
          dataIndex: 'device_type',
          key: 'device_type',
          scopedSlots: {customRender: 'device_type'},
        },
        {
          title: 'Доступно для',
          dataIndex: 'domains',
          key: 'domains',
          scopedSlots: {customRender: 'domains'},
        },
        {
          title: 'Действие',
          dataIndex: 'action',
          key: 'action',
          scopedSlots: {customRender: 'action'},
        },
        {
          title: 'Действия',
          dataIndex: '',
          key: 'actions',
          scopedSlots: {customRender: 'actions'},
        },
      ],
    };
  },

  created() {
    this.fetchApps();
  },

  methods: {
    async fetchApps() {
      this.loading = true;
      const response = await getSponsoredApplications();
      const json = await response.json();
      this.apps = json.data;
      this.loading = false;
    },

    getDeviceTypeName,
    getActionName,

    deleteSponsoredApplication(id) {
      this.$confirm({
        title: 'Вы действительно хотите удалить спонсорское приложение?',
        onOk: async () => {
          const response = await deleteSponsoredApplication(id);
          if (response.ok) {
            this.$message.success('Спонсорское приложение успешно удалено.', 3);
            this.fetchApps();
          }
        },
      });
    },
  },
}
</script>

<style scoped>

</style>
