
// @ts-nocheck
import { Component, Vue } from 'vue-property-decorator';
import PageTitle from '@/components/Global/PageTitle.vue';
import Table from './Table.vue';
import { settings } from '@/api/';

@Component({
  name: 'List',
  components: { PageTitle, Table },
})
export default class List extends Vue {
  // TODO: Описать тип

  codes: any[] = [];

  mounted() {
    this.getExternalCode();
  }

  getExternalCode() {
    settings
      .getExternalCode()
      .then((res) => res.json())
      .then(({ data }) => {
        this.codes = data;
      });
  }
}
