var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('PageTitle', {
    attrs: {
      "title": _vm.title
    }
  }), _c('a-layout-content', {
    staticClass: "page__content"
  }, [_c('a-form', [_c('a-row', {
    attrs: {
      "gutter": 24
    }
  }, [_c('a-col', {
    attrs: {
      "span": "12"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "Название (ru)*"
    }
  }, [_c('a-input', {
    attrs: {
      "size": "large"
    },
    model: {
      value: _vm.basePlatform.label,
      callback: function callback($$v) {
        _vm.$set(_vm.basePlatform, "label", $$v);
      },
      expression: "basePlatform.label"
    }
  })], 1)], 1), _c('a-col', {
    attrs: {
      "span": "12"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "Название (en)"
    }
  }, [_c('a-input', {
    attrs: {
      "size": "large"
    },
    model: {
      value: _vm.basePlatform.enLabel,
      callback: function callback($$v) {
        _vm.$set(_vm.basePlatform, "enLabel", $$v);
      },
      expression: "basePlatform.enLabel"
    }
  })], 1)], 1), _c('a-col', {
    attrs: {
      "span": "12"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "Уникальное название"
    }
  }, [_c('a-input', {
    attrs: {
      "size": "large"
    },
    model: {
      value: _vm.basePlatform.name,
      callback: function callback($$v) {
        _vm.$set(_vm.basePlatform, "name", $$v);
      },
      expression: "basePlatform.name"
    }
  })], 1)], 1), _c('a-col', {
    attrs: {
      "span": "12"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "Seo name"
    }
  }, [_c('a-input', {
    attrs: {
      "size": "large"
    },
    model: {
      value: _vm.basePlatform.seoName,
      callback: function callback($$v) {
        _vm.$set(_vm.basePlatform, "seoName", $$v);
      },
      expression: "basePlatform.seoName"
    }
  })], 1)], 1)], 1), _c('a-button', {
    attrs: {
      "size": "large",
      "type": "primary"
    },
    on: {
      "click": _vm.handleSubmit
    }
  }, [_vm._v(_vm._s(_vm.action))])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }