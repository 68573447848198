<template>
  <div>
    <PageTitle title="Статичные страницы">
      <router-link to="/static-pages/add">
        <a-button size="large" type="primary" style="margin-right: 24px">Добавить статью</a-button>
      </router-link>
    </PageTitle>
    <a-layout-content class="page__content">
      <a-table
        :columns="columns"
        :loading="hasLoadPages"
        :dataSource="pages.data"
        :pagination="false"
        rowKey="id"
      >
        <template slot="operation" slot-scope="operation, record">
          <a :href="record.url" target="_blank">
            <a-button
              type="primary"
              class="gray-btn"
              icon="eye"
              style="margin-right: 10px"
            />
          </a>

          <router-link :to="{ path: `/static-pages/${record.id}/change` }">
            <a-button
              type="primary"
              class="gray-btn"
              icon="edit"
              style="margin-right: 10px"
            />
          </router-link>

          <a-button
            type="primary"
            class="gray-btn"
            @click="deleteLanguage(record.id)"
            icon="delete"
          />
        </template>
        <template slot="titleRu" slot-scope="ru">
          <p>{{ ru.title }}</p>
        </template>
      </a-table>
    </a-layout-content>
  </div>
</template>

<script>
import PageTitle from '@/components/Global/PageTitle.vue';
import { staticPages } from '@/api/pages';

export default {
  name      : 'List',
  components: { PageTitle },
  data() {
    return {
      hasLoadPages: false,
      pages       : [],
      columns     : [
        {
          title      : 'Заголовок (ru)',
          dataIndex  : 'ru.title',
          key        : 'TitleRu',
        },
        {
          title    : 'URL',
          dataIndex: 'slug',
          key      : 'url',
        },
        {
          title    : 'Дата создания',
          dataIndex: 'created_at',
          key      : 'created_at',
        },
        {
          title      : 'Действия',
          dataIndex  : 'actions',
          key        : 'actions',
          scopedSlots: { customRender: 'operation' },
        },
      ],
    };
  },

  mounted() {
    this.getStaticPages();
  },

  methods: {
    /**
     * @description Получение списка статичных страниц
     * @return {Promise<any>}
     */
    async getStaticPages(page) {
      this.hasLoadPages = true;
      await staticPages
        .getStaticPages(page)
        .then((res) => res.json())
        .then((data) => {
          this.pages        = data;
          this.hasLoadPages = false;
        });
    },

    /**
     * @description Пагинация по страницам
     * @param {number} page
     * @return {void}
     */
    paginationChange(page) {
      this.getStaticPages(page);
      window.scrollTo(0, 0);
    },

    /**
     * @description Удаление языка
     * @param {number} id
     * @return {Promise<any>}
     */
    deleteLanguage(id) {
      this.$confirm({
        title  : 'Вы действительно хотите удалить страницу?',
        content:
          'Вы не сможете восстановить ee после удаления, только добавить еще раз.',
        onOk   : async () => {
          await staticPages.deleteStaticPage(id).then(() => {
            this.$message.success('Страница успешно удалена :)', 2);
            this.getStaticPages();
          });
        },
      });
    },
  }
};
</script>
