var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a-modal', {
    attrs: {
      "visible": Boolean(_vm.info),
      "title": "Информация",
      "footer": null
    },
    on: {
      "ok": function ok($event) {
        return _vm.$emit('close');
      },
      "cancel": function cancel($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm.info ? [_c('p', [_c('b', [_vm._v("VirusTotal")])]), _c('p', [_c('b', [_vm._v("Status:")]), _vm._v(" " + _vm._s(_vm.info.response.data.attributes.status))]), _c('p', [_c('b', [_vm._v("Stats: ")])]), _c('p', [_vm._v("malicious: " + _vm._s(_vm.info.response.data.attributes.stats.malicious))]), _c('p', [_vm._v("harmless: " + _vm._s(_vm.info.response.data.attributes.stats.harmless))]), _c('p', [_vm._v("suspicious: " + _vm._s(_vm.info.response.data.attributes.stats.suspicious))]), Object.keys(_vm.info.response.data.attributes.results).length ? [_c('p', [_c('b', [_vm._v("Results: ")])]), _vm._l(_vm.info.response.data.attributes.results, function (scanner) {
    return [scanner.result ? [_c('p', [_vm._v("engine_name: " + _vm._s(scanner.engine_name))]), _c('p', [_vm._v("engine_version: " + _vm._s(scanner.engine_version))]), _c('p', [_vm._v("result: " + _vm._s(scanner.result))]), _c('hr')] : _vm._e()];
  })] : _vm._e()] : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }