<template>
  <div>
    <PageTitle title="Создать комментарий для приложения"/>

    <a-layout-content class="page__content">
      <a-form
        :form="form"
        layout="horizontal"
        @submit.prevent="handleSubmit"
      >

        <a-form-item
          label="Приложение"
          :label-col="formItemLayout.labelCol"
          :wrapper-col="formItemLayout.wrapperCol"
        >
          <a-select
            show-search
            allow-clear
            placeholder="Название приложения"
            :default-active-first-option="false"
            :show-arrow="false"
            :filter-option="false"
            :not-found-content="appsSearchDataSearching? undefined : 'Не найдено'"
            size="large"
            @search="handleAppNameSearch"
            v-decorator="['application_id', { rules: [{ required: true, message: 'Выберите приложение' }] }]"
          >
            <a-spin v-if="appsSearchDataSearching" slot="notFoundContent" size="small"/>

            <a-select-option
              v-for="app in appsSearchData"
              :key="app.id"
            >
              {{ app.name }} ({{ app.seoName }})
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item
          label="Имя пользователя"
          :label-col="formItemLayout.labelCol"
          :wrapper-col="formItemLayout.wrapperCol"
        >
          <a-input
            placeholder="Имя пользователя"
            v-decorator="['name', { rules: [{ required: true, message: 'Введите имя пользователя' }] }]"
          />
        </a-form-item>

        <a-form-item
          label="Выберите дату"
          :label-col="formItemLayout.labelCol"
          :wrapper-col="formItemLayout.wrapperCol"
        >
          <a-date-picker
            placeholder="Select Time"
            v-decorator="['published_at', { rules: [{ required: true, message: 'Введите дату' }] }]"
          />
        </a-form-item>

        <a-card title="Параметры зависимые от локали">
          <a-tabs>
            <a-tab-pane
              v-for="{title, locale} in LOCALE_LIST"
              :key="locale"
              :tab="title"
            >
              <a-form-item
                :label="`Комментарий ${locale}`"
                :label-col="formItemLayout.labelCol"
                :wrapper-col="formItemLayout.wrapperCol"
              >
                <a-textarea
                  placeholder="Комментарий"
                  rows="4"
                  v-decorator="[`translations.${locale}.text`, { rules: [] }]"
                />
              </a-form-item>
            </a-tab-pane>
          </a-tabs>
        </a-card>

        <a-form-item
          label="Ответ"
          :label-col="formItemLayout.labelCol"
          :wrapper-col="formItemLayout.wrapperCol"
        >
          <a-checkbox :checked="answer" @change="answer=$event.target.checked"/>
        </a-form-item>

        <template v-if="answer">
          <a-form-item
            label="Имя пользователя"
            :label-col="formItemLayout.labelCol"
            :wrapper-col="formItemLayout.wrapperCol"
          >
            <a-input
              placeholder="Имя пользователя"
              v-decorator="['answer_name', { rules: [{ required: true, message: 'Введите имя пользователя' }] }]"
            />
          </a-form-item>

          <a-form-item
            label="Выберите дату"
            :label-col="formItemLayout.labelCol"
            :wrapper-col="formItemLayout.wrapperCol"
          >
            <a-date-picker
              placeholder="Select Time"
              v-decorator="['answer_published_at', { rules: [{ required: true, message: 'Введите дату' }] }]"
            />
          </a-form-item>

          <a-card title="Параметры зависимые от локали">
            <a-tabs :default-active-key="LOCALE_LIST[0]">
              <a-tab-pane
                v-for="locale in LOCALE_LIST"
                :key="locale"
                :tab="locale"
              >
                <a-form-item
                  label="Комментарий"
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                >
                  <a-textarea
                    placeholder="Комментарий"
                    rows="4"
                    v-decorator="[`answer_translations.${locale}.text`, { rules: [] }]"
                  />
                </a-form-item>
              </a-tab-pane>
            </a-tabs>
          </a-card>
        </template>

        <a-form-item :wrapper-col="{ span: 14, offset: 4 }">
          <a-button type="primary" html-type="submit">
            Создать
          </a-button>
        </a-form-item>
      </a-form>
    </a-layout-content>
  </div>
</template>

<script>
import PageTitle from '@/components/Global/PageTitle.vue';
import { searchApps } from '@/api/Applications/app';
import debounce from 'lodash/debounce';
import { createComment, createFakeComment } from '@/api/Applications/comments';
import { LOCALE_LIST } from '@/global/constants';

export default {
  name      : 'CommentsAppsCreate',
  components: { PageTitle },
  data() {
    return {
      form                   : this.$form.createForm(this),
      appsSearchController   : null,
      appsSearchDataSearching: false,
      appsSearchData         : [],
      formItemLayout         : {
        labelCol  : { span: 4 },
        wrapperCol: { span: 14 },
      },

      answer  : false,
      creating: false,
      LOCALE_LIST,
    };
  },
  created() {
    this.handleAppNameSearch = debounce(this.handleAppNameSearch, 800);
  },
  methods: {
    async handleSubmit() {
      this.creating = true;
      try {
        const values = await this.form.validateFields();
        await createFakeComment(values.application_id, values);
        this.$message.success('Комментарий создан', 3);
        this.$router.push({ name: 'CommentsApps' });
      } catch (err) {
        console.log(err);
      } finally {
        this.creating = false;
      }
    },

    async handleAppNameSearch(value) {
      if (!value.trim().length) {
        return;
      }

      if (true === this.appsSearchDataSearching) {
        this.appsSearchController.abort();
      }

      this.appsSearchController    = new AbortController();
      this.appsSearchDataSearching = true;
      this.appsSearchData          = [];
      try {
        const res = await searchApps({
          name : value,
          limit: 50,
        }, { signal: this.appsSearchController.signal });

        const json          = await res.json();
        this.appsSearchData = json.data;
      } catch (err) {
        if (err.name !== 'AbortError') { // обработать ошибку от вызова abort()
          throw err;
        }
      } finally {
        this.appsSearchDataSearching = false;
      }
    },
  },
};
</script>

<style scoped>

</style>
