
// @ts-nocheck
import Vue from 'vue';
import PageTitle from '@/components/Global/PageTitle.vue';
import { news } from '@/api/news';

export default Vue.extend({
  components: { PageTitle },
  data() {
    return {
      newsCategories: [],
      hasLoadNewsCategories: false,
      search: {
        title: '',
      },
      columns: [
        {
          title: 'Название',
          dataIndex: 'title',
          key: 'name',
        },
        {
          title: 'Родительская категория',
          dataIndex: 'parentCategory',
          key: 'parentCategory',
          scopedSlots: { customRender: 'parent-category' },
        },
        {
          title: 'Действия',
          dataIndex: 'parentCategory',
          key: 'parentCategory',
          scopedSlots: { customRender: 'actions' },
        },
      ],
    };
  },
  mounted() {
    this.getNewsCategories();
  },
  methods: {
    /**
     * @description Получение категорий новостей
     * @return {Promise<any>}
     */
    async getNewsCategories(): Promise<any> {
      this.hasLoadNewsCategories = true;
      await news
        .getNewsCategory()
        .then((res: Response) => res.json())
        .then(({ data }) => {
          this.newsCategories = data;
          this.hasLoadNewsCategories = false;
        });
    },

    /**
     * @description Поиск по категориям новостей
     * @return {Promise<any>}
     */
    async searchCategories(): Promise<any> {
      this.hasLoadNewsCategories = true;
      await news
        .searchNewsCategory({
          title: this.search.title,
        })
        .then((res) => res.json())
        .then(({ data }) => {
          this.hasLoadNewsCategories = false;
          this.newsCategories = data;
        });
    },

    deleteCategory(id: number) {
      this.$confirm({
        title: `Вы действительно хотите удалить данную категорию ?`,
        content:
          'Вы не сможете восстановить ее после удаления, только добавить еще раз.',
        onOk: async () => {
          await news.deleteNewsCategory(id).then(() => {
            this.$message.success('Категория успешна удалена :)', 2);
            this.getNewsCategories();
          });
        },
      });
    },
  },
});
