import TagListingList from './TagListingList.vue';
import TagListingModify from './TagListingModify.vue';

export default [
  {
    name: 'tag-listing-list',
    path: '/tag-listings',
    component: TagListingList,
    breadcrumbName: 'Тег Листинги',
    meta: {title: 'Тег Листинги',},
  },
  {
    name: 'tag-listing',
    path: '/tag-listings/:id',
    component: TagListingModify,
    breadcrumbName: 'Редактировать Тег Листинг',
    meta: {title: 'Редактировать Тег Листинг',},
    props: true,
  },
];
