var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('PageTitle', {
    attrs: {
      "title": _vm.title
    }
  }), _c('a-layout-content', {
    staticClass: "page__content"
  }, [_c('a-row', {
    attrs: {
      "gutter": 24
    }
  }, [_c('a-col', {
    attrs: {
      "span": "2"
    }
  }, [_c('a-avatar', {
    attrs: {
      "size": 64,
      "icon": "user"
    }
  })], 1), _c('a-col', {
    attrs: {
      "span": "22"
    }
  }, [_c('a-textarea', {
    staticStyle: {
      "height": "150px",
      "margin-bottom": "20px"
    },
    attrs: {
      "placeholder": "Оставьте ваш комментарий"
    },
    model: {
      value: _vm.message,
      callback: function callback($$v) {
        _vm.message = $$v;
      },
      expression: "message"
    }
  }), _vm.user.hasAdmin || _vm.user.hasEditor ? _c('a-button', {
    attrs: {
      "size": "large",
      "type": "primary"
    },
    on: {
      "click": _vm.handleSubmit
    }
  }, [_vm._v("Добавить комментарий и отклонить")]) : _vm.user.hasDeveloper ? _c('div', {
    staticClass: "developer-actions"
  }, [_c('a-button', {
    attrs: {
      "size": "large",
      "type": "primary"
    },
    on: {
      "click": _vm.sendToModeration
    }
  }, [_vm._v("Отправить на модерацию")])], 1) : _vm._e()], 1)], 1), _vm._l(_vm.messageList, function (message) {
    return _c('div', {
      key: message.id,
      staticClass: "moderation-comment"
    }, [_c('a-row', {
      attrs: {
        "gutter": 24
      }
    }, [_c('a-col', {
      attrs: {
        "span": "2"
      }
    }, [_c('a-avatar', {
      attrs: {
        "size": 64,
        "icon": "user"
      }
    })], 1), _c('a-col', {
      attrs: {
        "span": "22"
      }
    }, [_c('div', {
      staticClass: "moderation-comment-top"
    }, [_c('h3', [_vm._v(" " + _vm._s(message.developer.name)), _c('span', [_vm._v(_vm._s(message.createdAt))])])]), _c('div', {
      staticClass: "moderation-comment-description"
    }, [_c('p', [_vm._v(" " + _vm._s(message.message) + " ")])])])], 1)], 1);
  })], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }