var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('PageTitle', {
    attrs: {
      "title": _vm.title
    }
  }), _c('a-layout-content', {
    style: {
      margin: '24px 16px',
      padding: '24px',
      background: '#fff',
      minHeight: '280px'
    }
  }, [_vm.id ? _c('a-form', {
    staticStyle: {
      "margin": "20px 0"
    }
  }, [_c('a-row', {
    attrs: {
      "gutter": 24
    }
  }, [_c('a-col', {
    attrs: {
      "span": 8
    }
  }, [_c('a-form-item', [_c('a-input', {
    attrs: {
      "size": "large",
      "placeholder": "Откуда"
    },
    model: {
      value: _vm.baseRedirect.source,
      callback: function callback($$v) {
        _vm.$set(_vm.baseRedirect, "source", $$v);
      },
      expression: "baseRedirect.source"
    }
  })], 1)], 1), _c('a-col', {
    attrs: {
      "span": 8
    }
  }, [_c('a-input', {
    attrs: {
      "size": "large",
      "placeholder": "Куда"
    },
    model: {
      value: _vm.baseRedirect.destination,
      callback: function callback($$v) {
        _vm.$set(_vm.baseRedirect, "destination", $$v);
      },
      expression: "baseRedirect.destination"
    }
  })], 1)], 1)], 1) : _vm._e(), _vm._l(_vm.redirectItems, function (redirect) {
    return _vm.id === undefined ? _c('a-form', {
      key: redirect.id,
      staticStyle: {
        "margin": "20px 0"
      }
    }, [_c('a-row', {
      attrs: {
        "gutter": 24
      }
    }, [_c('a-col', {
      attrs: {
        "span": 8
      }
    }, [_c('a-input', {
      attrs: {
        "size": "large",
        "placeholder": "Откуда"
      },
      model: {
        value: redirect.source,
        callback: function callback($$v) {
          _vm.$set(redirect, "source", $$v);
        },
        expression: "redirect.source"
      }
    })], 1), _c('a-col', {
      attrs: {
        "span": 8
      }
    }, [_c('a-input', {
      attrs: {
        "size": "large",
        "placeholder": "Куда"
      },
      model: {
        value: redirect.destination,
        callback: function callback($$v) {
          _vm.$set(redirect, "destination", $$v);
        },
        expression: "redirect.destination"
      }
    })], 1), redirect.id === 0 ? _c('a-col', {
      attrs: {
        "span": 1
      }
    }, [_c('a-button', {
      attrs: {
        "type": "primary",
        "size": "large",
        "icon": "plus"
      },
      on: {
        "click": _vm.addRedirect
      }
    })], 1) : _vm._e(), redirect.id !== 0 ? _c('a-col', {
      attrs: {
        "span": 1
      }
    }, [_c('a-button', {
      staticClass: "gray-btn",
      attrs: {
        "size": "large",
        "icon": "delete"
      },
      on: {
        "click": function click($event) {
          return _vm.removeRedirect(redirect.id);
        }
      }
    })], 1) : _vm._e()], 1)], 1) : _vm._e();
  }), _c('a-button', {
    attrs: {
      "type": "primary",
      "size": "large",
      "disabled": _vm.buttonDisable
    },
    on: {
      "click": _vm.saveRedirect
    }
  }, [_vm._v("Сохранить")])], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }