var render = function render(){
  var _vm$baseNews$publishe;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('PageTitle', {
    attrs: {
      "title": _vm.title
    }
  }), _c('a-form', {
    attrs: {
      "form": _vm.form
    }
  }, [_c('a-layout-content', {
    staticClass: "page__content"
  }, [_c('a-row', {
    attrs: {
      "gutter": 24
    }
  }, [_c('a-col', {
    attrs: {
      "span": "12"
    }
  }, [_c('a-button', {
    attrs: {
      "size": "large",
      "type": "primary"
    },
    on: {
      "click": _vm.handleSubmit
    }
  }, [_vm._v(" " + _vm._s(_vm.action) + " ")])], 1), _c('a-col', {
    staticStyle: {
      "text-align": "right",
      "margin-top": "10px"
    },
    attrs: {
      "span": "12"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "Опубликовать"
    }
  }, [_c('a-switch', {
    attrs: {
      "size": "large",
      "checked": _vm.form.getFieldValue('published_at')
    },
    on: {
      "change": _vm.onPublishChanged
    }
  })], 1)], 1)], 1)], 1), _c('a-layout-content', {
    staticClass: "page__content"
  }, [_c('a-row', {
    staticClass: "row-st",
    attrs: {
      "gutter": 24
    }
  }, [_c('a-col', {
    attrs: {
      "span": "24"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "Слаг (Пример: введенная строка 'slug', создаст ссылку 'freesoft.ru/blog/slug')"
    }
  }, [_c('a-input', {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ['slug', {
        initialValue: _vm.baseNews.slug,
        rules: [{
          required: true,
          message: 'Slug обязательное поле'
        }]
      }],
      expression: "[\n                  'slug',\n                  {\n                    initialValue: baseNews.slug,\n                    rules: [\n                      {\n                        required: true,\n                        message: 'Slug обязательное поле'\n                      }\n                    ]\n                  }\n                ]"
    }],
    attrs: {
      "size": "large",
      "placeholder": "Slug"
    }
  })], 1)], 1), _c('a-col', {
    attrs: {
      "span": "12"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "Добавьте категории"
    }
  }, [_c('a-select', {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ['categories', {
        initialValue: _vm.baseNews.categories,
        rules: [{
          required: true,
          message: 'Категории обязательное поле'
        }]
      }],
      expression: "[\n                'categories',\n                {\n                  initialValue: baseNews.categories,\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Категории обязательное поле'\n                    }\n                  ]\n                }\n              ]"
    }],
    attrs: {
      "size": "large",
      "placeholder": "Категория",
      "mode": "multiple",
      "maxTagCount": "2"
    }
  }, _vm._l(_vm.articleCategories, function (category) {
    return _c('a-select-option', {
      key: category.id,
      attrs: {
        "value": category.id
      }
    }, [_vm._v(" " + _vm._s(category.title) + " ")]);
  }), 1)], 1)], 1)], 1), _c('a-row', {
    staticClass: "row-st",
    attrs: {
      "gutter": 24
    }
  }, [_c('a-col', {
    attrs: {
      "span": "12"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "Загрузка превью"
    }
  }, [_c('a-upload', {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ['image', {
        initialValue: _vm.imageList,
        rules: [{
          required: true,
          message: 'Загрузите превью'
        }]
      }],
      expression: "[\n                'image',\n                {\n                  initialValue: imageList,\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Загрузите превью'\n                    }\n                  ]\n                }\n              ]"
    }],
    attrs: {
      "listType": "picture-card",
      "fileList": _vm.imageList,
      "remove": _vm.handlePreviewRemove,
      "before-upload": _vm.beforeUploadPreview
    },
    on: {
      "preview": _vm.handlePreview
    }
  }, [_vm.imageList && _vm.imageList.length < 1 ? _c('div', {
    staticStyle: {
      "padding-top": "15px"
    }
  }, [_c('a-icon', {
    attrs: {
      "type": "plus"
    }
  }), _c('div', {
    staticClass: "ant-upload-text"
  }, [_vm._v("Загрузить изображение")])], 1) : _vm._e()]), _c('a-modal', {
    attrs: {
      "visible": _vm.previewVisible,
      "footer": null
    },
    on: {
      "cancel": _vm.handleCancel
    }
  }, [_c('img', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "alt": "example",
      "src": _vm.previewImage
    }
  })])], 1)], 1), _c('a-col', {
    attrs: {
      "span": "24"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "Дата публикации"
    }
  }, [_c('a-date-picker', {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["published_at", {
        initialValue: (_vm$baseNews$publishe = _vm.baseNews.published_at) !== null && _vm$baseNews$publishe !== void 0 ? _vm$baseNews$publishe : undefined
      }],
      expression: "[`published_at`, { initialValue: baseNews.published_at??undefined }]"
    }],
    attrs: {
      "format": "YYYY-MM-DD HH:mm",
      "valueFormat": "YYYY-MM-DD HH:mm",
      "show-time": true,
      "allowClear": "",
      "placeholder": "Не опубликовано"
    }
  })], 1)], 1), _c('a-col', {
    attrs: {
      "span": "24"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "Настройки"
    }
  }, [_c('a-checkbox', {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ['disable_ads', {
        valuePropName: 'checked',
        initialValue: _vm.baseNews.disable_ads
      }],
      expression: "[\n                    'disable_ads',\n                    {\n                      valuePropName: 'checked',\n                      initialValue: baseNews.disable_ads\n                    }\n                  ]"
    }]
  }, [_vm._v(" Отключить рекламу ")])], 1)], 1)], 1), _c('a-row', {
    staticClass: "row-st",
    attrs: {
      "gutter": 24
    }
  }, [_c('a-card', {
    attrs: {
      "title": "Локализация"
    },
    scopedSlots: _vm._u([_vm.availableLocales.length ? {
      key: "extra",
      fn: function fn() {
        return [_c('a-dropdown', {
          scopedSlots: _vm._u([{
            key: "overlay",
            fn: function fn() {
              return [_c('a-menu', {
                on: {
                  "click": _vm.addTranslation
                }
              }, _vm._l(_vm.availableLocales, function (locale) {
                return _c('a-menu-item', {
                  key: locale
                }, [_vm._v(" " + _vm._s(_vm.getLocaleTitle(locale)) + " ")]);
              }), 1)];
            },
            proxy: true
          }], null, false, 2503522434)
        }, [_c('a-button', [_vm._v(" Добавить локаль "), _c('a-icon', {
          attrs: {
            "type": "down"
          }
        })], 1)], 1)];
      },
      proxy: true
    } : null], null, true)
  }, [_c('a-tabs', {
    attrs: {
      "type": "editable-card",
      "hide-add": ""
    },
    on: {
      "edit": _vm.onTabTranslationsEdit
    },
    model: {
      value: _vm.activeTab,
      callback: function callback($$v) {
        _vm.activeTab = $$v;
      },
      expression: "activeTab"
    }
  }, [_vm._l(_vm.LOCALE_LIST_EXTENDED, function (_ref) {
    var locale = _ref.locale,
      title = _ref.title;
    return [_vm.baseNews.translations.hasOwnProperty(locale) ? _c('a-tab-pane', {
      key: locale,
      attrs: {
        "tab": title,
        "closabel": true
      }
    }, [_c('a-form-item', {
      attrs: {
        "label": "\u0417\u0430\u0433\u043E\u043B\u043E\u0432\u043E\u043A (".concat(locale, ")")
      }
    }, [_c('a-input', {
      directives: [{
        name: "decorator",
        rawName: "v-decorator",
        value: ["translations.".concat(locale, ".title"), {
          initialValue: _vm.baseNews.translations[locale] ? _vm.baseNews.translations[locale].title : undefined,
          rules: [{
            required: true,
            message: 'Заголовок обязательное поле'
          }]
        }],
        expression: "[\n                      `translations.${locale}.title`,\n                      {\n                        initialValue: baseNews.translations[locale] ? baseNews.translations[locale].title : undefined,\n                        rules: [\n                          {\n                            required: true,\n                            message: 'Заголовок обязательное поле'\n                          }\n                        ]\n                      }\n                    ]"
      }],
      attrs: {
        "size": "large",
        "placeholder": "Заголовок"
      }
    })], 1), _c('a-col', {
      attrs: {
        "span": "24"
      }
    }, [_c('a-form-item', {
      attrs: {
        "label": "\u0410\u043D\u043E\u043D\u0441 (".concat(locale, ")")
      }
    }, [_c('a-textarea', {
      directives: [{
        name: "decorator",
        rawName: "v-decorator",
        value: ["translations.".concat(locale, ".anons"), {
          initialValue: _vm.baseNews.translations[locale] ? _vm.baseNews.translations[locale].anons : undefined,
          rules: [{
            required: true,
            message: 'Анонс обязательное поле'
          }]
        }],
        expression: "[\n                        `translations.${locale}.anons`,\n                        {\n                          initialValue: baseNews.translations[locale] ? baseNews.translations[locale].anons : undefined,\n                          rules: [\n                            {\n                              required: true,\n                              message: 'Анонс обязательное поле'\n                            }\n                          ]\n                        }\n                      ]"
      }],
      staticStyle: {
        "height": "200px"
      },
      attrs: {
        "placeholder": "Анонс"
      }
    })], 1)], 1), _c('a-col', {
      attrs: {
        "span": "24"
      }
    }, [_c('a-form-item', {
      staticClass: "ant-form-item-required",
      attrs: {
        "label": "\u0422\u0435\u043A\u0441\u0442 (".concat(locale, ")")
      }
    }, [_c('ckeditor', {
      directives: [{
        name: "decorator",
        rawName: "v-decorator",
        value: ["translations.".concat(locale, ".text"), {
          initialValue: _vm.baseNews.translations[locale] ? _vm.baseNews.translations[locale].text : undefined
        }],
        expression: "[`translations.${locale}.text`, {initialValue: baseNews.translations[locale] ? baseNews.translations[locale].text : undefined}]"
      }],
      attrs: {
        "config": _vm.editorConfig()
      },
      on: {
        "input": function input($event) {
          var _vm$form$setFieldsVal;
          return _vm.form.setFieldsValue((_vm$form$setFieldsVal = {}, _vm$form$setFieldsVal["translations.".concat(locale, ".text")] = $event, _vm$form$setFieldsVal));
        }
      }
    })], 1)], 1), _c('a-col', {
      attrs: {
        "span": "24"
      }
    }, [_c('a-form-item', {
      attrs: {
        "label": "SeoTitle (".concat(locale, ")")
      }
    }, [_c('a-input', {
      directives: [{
        name: "decorator",
        rawName: "v-decorator",
        value: ["translations.".concat(locale, ".seo_title"), {
          initialValue: _vm.baseNews.translations[locale] ? _vm.baseNews.translations[locale].seo_title : undefined,
          rules: [{
            required: false
          }]
        }],
        expression: "[\n                        `translations.${locale}.seo_title`,\n                        {\n                          initialValue: baseNews.translations[locale] ? baseNews.translations[locale].seo_title : undefined,\n                          rules: [\n                            {\n                              required: false\n                            }\n                          ]\n                        }\n                      ]"
      }],
      attrs: {
        "size": "large",
        "placeholder": "Введите title"
      }
    })], 1)], 1), _c('a-col', {
      attrs: {
        "span": "24"
      }
    }, [_c('a-form-item', {
      attrs: {
        "label": "SeoDescription (".concat(locale, ")")
      }
    }, [_c('a-textarea', {
      directives: [{
        name: "decorator",
        rawName: "v-decorator",
        value: ["translations.".concat(locale, ".seo_description"), {
          initialValue: _vm.baseNews.translations[locale] ? _vm.baseNews.translations[locale].seo_description : undefined,
          rules: [{
            required: false
          }]
        }],
        expression: "[\n                        `translations.${locale}.seo_description`,\n                        {\n                          initialValue: baseNews.translations[locale] ? baseNews.translations[locale].seo_description : undefined,\n                          rules: [\n                            {\n                              required: false\n                            }\n                          ]\n                        }\n                      ]"
      }],
      staticStyle: {
        "height": "145px"
      },
      attrs: {
        "size": "large",
        "placeholder": "Введите description"
      }
    })], 1)], 1), _c('a-col', {
      attrs: {
        "span": "24"
      }
    }, [_c('a-form-item', {
      attrs: {
        "label": "SeoKeywords (".concat(locale, ")")
      }
    }, [_c('a-input', {
      directives: [{
        name: "decorator",
        rawName: "v-decorator",
        value: ["translations.".concat(locale, ".seo_keywords"), {
          initialValue: _vm.baseNews.translations[locale] ? _vm.baseNews.translations[locale].seo_keywords : undefined,
          rules: [{
            required: false
          }]
        }],
        expression: "[\n                        `translations.${locale}.seo_keywords`,\n                        {\n                          initialValue: baseNews.translations[locale] ? baseNews.translations[locale].seo_keywords : undefined,\n                          rules: [\n                            {\n                              required: false\n                            }\n                          ]\n                        }\n                      ]"
      }],
      attrs: {
        "size": "large",
        "placeholder": "Введите keywords"
      }
    })], 1)], 1)], 1) : _vm._e()];
  })], 2)], 1)], 1), _c('a-row', {
    staticClass: "row-st",
    attrs: {
      "gutter": 24
    }
  }, [_c('a-col', {
    attrs: {
      "span": "12"
    }
  }, [_c('a-button', {
    attrs: {
      "size": "large",
      "type": "primary"
    },
    on: {
      "click": _vm.handleSubmit
    }
  }, [_vm._v(" " + _vm._s(_vm.action) + " ")])], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }