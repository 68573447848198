<template>
  <div>
    <PageTitle title="Тег Листинги"/>

    <a-layout-content class="page__content">
      <a-spin v-if="loading" size="large"/>
      <a-row v-else>
        <a-card title="">
          <a-tabs>
            <a-tab-pane
              v-for="{title, locale} in LOCALE_LIST"
              :key="locale"
              :tab="title"
            >
              <a-tabs>
                <a-tab-pane
                  key="default"
                  tab="Общая"
                >
                  <a-col span="24">
                    <a-form-item label="H1">
                      <a-input
                        size="large"
                        placeholder="H1"
                        v-model="tagListing.translations[locale].h1"
                      >
                      </a-input>
                    </a-form-item>
                  </a-col>

                  <a-col span="24">
                    <a-form-item label="Заголовок">
                      <a-input
                        size="large"
                        placeholder="Заголовок"
                        v-model="tagListing.translations[locale].title"
                      >
                      </a-input>
                    </a-form-item>
                  </a-col>

                  <a-col span="24">
                    <a-form-item label="Description">
                      <a-textarea
                        size="large"
                        placeholder="Description"
                        auto-size
                        v-model="tagListing.translations[locale].description"
                      >
                      </a-textarea>
                    </a-form-item>
                  </a-col>

                  <a-col span="24">
                    <a-form-item label="Breadcrumbs">
                      <a-input
                        size="large"
                        placeholder="Breadcrumbs"
                        v-model="tagListing.translations[locale].breadcrumbs"
                      >
                      </a-input>
                    </a-form-item>
                  </a-col>

                  <a-col span="24">
                    <a-form-item label="Content">
                      <a-textarea
                        size="large"
                        placeholder="Content"
                        auto-size
                        v-model="tagListing.translations[locale].content"
                      >
                      </a-textarea>
                    </a-form-item>
                  </a-col>
                </a-tab-pane>

                <a-tab-pane
                  key="popularity"
                  tab="По популярности"
                >
                  <a-col span="24">
                    <a-form-item label="H1">
                      <a-input
                        size="large"
                        placeholder="H1"
                        v-model="tagListing.translations[locale].popularity_h1"
                      >
                      </a-input>
                    </a-form-item>
                  </a-col>

                  <a-col span="24">
                    <a-form-item label="Заголовок">
                      <a-input
                        size="large"
                        placeholder="Заголовок"
                        v-model="tagListing.translations[locale].popularity_title"
                      >
                      </a-input>
                    </a-form-item>
                  </a-col>

                  <a-col span="24">
                    <a-form-item label="Description">
                      <a-textarea
                        size="large"
                        placeholder="Description"
                        auto-size
                        v-model="tagListing.translations[locale].popularity_description"
                      >
                      </a-textarea>
                    </a-form-item>
                  </a-col>

                  <a-col span="24">
                    <a-form-item label="Breadcrumbs">
                      <a-input
                        size="large"
                        placeholder="Breadcrumbs"
                        v-model="tagListing.translations[locale].popularity_breadcrumbs"
                      >
                      </a-input>
                    </a-form-item>
                  </a-col>

                  <a-col span="24">
                    <a-form-item label="Content">
                      <a-textarea
                        size="large"
                        placeholder="Content"
                        auto-size
                        v-model="tagListing.translations[locale].popularity_content"
                      >
                      </a-textarea>
                    </a-form-item>
                  </a-col>
                </a-tab-pane>

                <a-tab-pane
                  key="last_updated"
                  tab="По обновлениям"
                >
                  <a-col span="24">
                    <a-form-item label="H1">
                      <a-input
                        size="large"
                        placeholder="H1"
                        v-model="tagListing.translations[locale].last_updated_h1"
                      >
                      </a-input>
                    </a-form-item>
                  </a-col>

                  <a-col span="24">
                    <a-form-item label="Заголовок">
                      <a-input
                        size="large"
                        placeholder="Заголовок"
                        v-model="tagListing.translations[locale].last_updated_title"
                      >
                      </a-input>
                    </a-form-item>
                  </a-col>

                  <a-col span="24">
                    <a-form-item label="Description">
                      <a-textarea
                        size="large"
                        placeholder="Description"
                        auto-size
                        v-model="tagListing.translations[locale].last_updated_description"
                      >
                      </a-textarea>
                    </a-form-item>
                  </a-col>

                  <a-col span="24">
                    <a-form-item label="Breadcrumbs">
                      <a-input
                        size="large"
                        placeholder="Breadcrumbs"
                        v-model="tagListing.translations[locale].last_updated_breadcrumbs"
                      >
                      </a-input>
                    </a-form-item>
                  </a-col>

                  <a-col span="24">
                    <a-form-item label="Content">
                      <a-textarea
                        size="large"
                        placeholder="Content"
                        auto-size
                        v-model="tagListing.translations[locale].last_updated_content"
                      >
                      </a-textarea>
                    </a-form-item>
                  </a-col>
                </a-tab-pane>
              </a-tabs>
            </a-tab-pane>
          </a-tabs>
        </a-card>
      </a-row>

      <a-button
        type="primary"
        @click="save"
        size="large"
      >
        Сохранить
      </a-button>

    </a-layout-content>
  </div>
</template>

<script>
import PageTitle from "@/components/Global/PageTitle.vue";
import {LOCALE_LIST} from "@/global/constants";
import {getTagListing, updateTagListing} from "@/api/tagListing";

export default {
  name: "TagListingModify",

  components: {PageTitle},

  props: {
    id: {
      required: false,
    },
  },

  data() {
    return {
      tagListing: null,
      LOCALE_LIST,
      loading: true,
    };
  },

  async created() {
    const response = await getTagListing(this.id);
    const json = await response.json();
    this.tagListing = json.data;
    this.loading = false;
  },

  methods: {
    async save() {
      const response = await updateTagListing(this.id, {translations: this.tagListing.translations});
      const json = await response.json();
      this.$message.success('Сохранено', 3);
    },
  },
}
</script>

<style scoped>

</style>
