<template>
  <a-modal
    :visible="Boolean(info)"
    title="Информация"
    @ok="$emit('close')"
    @cancel="$emit('close')"
    :footer="null"
  >
    <template v-if="info">
      <p><b>VirusTotal</b></p>
      <p><b>Status:</b> {{ info.response.data.attributes.status }}</p>
      <p><b>Stats: </b></p>
      <p>malicious: {{ info.response.data.attributes.stats.malicious }}</p>
      <p>harmless: {{ info.response.data.attributes.stats.harmless }}</p>
      <p>suspicious: {{ info.response.data.attributes.stats.suspicious }}</p>

      <template v-if="Object.keys(info.response.data.attributes.results).length">
        <p><b>Results: </b></p>
        <template v-for="scanner in info.response.data.attributes.results">
          <template v-if="scanner.result">
            <p>engine_name: {{ scanner.engine_name }}</p>
            <p>engine_version: {{ scanner.engine_version }}</p>
            <p>result: {{ scanner.result }}</p>
            <hr/>
          </template>
        </template>
      </template>
    </template>
  </a-modal>
</template>

<script>
export default {
  name : 'FileInfoModal',
  props: {
    info: {
      required: true,
      default : null,
    },
  },
  data() {
    return {
      visible: false,
    };
  },
};
</script>

<style scoped>

</style>
