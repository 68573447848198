import Profile from './Profile.vue';
import ProfileChangePassword from './ProfileChangePassword.vue';

import { Route } from '@/types/route';

export const profileRoute = '/profile';

export const profile: Route[] = [
  {
    name: 'Profile',
    path: profileRoute,
    component: Profile,
    breadcrumbName: 'Личные данные',
    meta: {
      title: 'Личные данные',
    },
  },
  {
    name: 'ProfileChangePassword',
    path: '/profile/change-password',
    component: ProfileChangePassword,
    breadcrumbName: 'Сменить пароль',
    meta: {
      title: 'Сменить пароль',
    },
  },
];

export default { profile };
