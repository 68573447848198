<template>
  <a-row
    gutter="24"
    style="margin-top: 15px"
  >
    <a-col>
      <h3 style="margin-top: 20px">Блок "Вопросы и ответы"</h3>

      <a-row
        v-for="(question, index) in questions"
        :key="index"
      >
        <a-col span="6">
          <a-textarea
            auto-size
            placeholder="Вопрос"
            v-model="question.question"
          />
        </a-col>
        <a-col span="6">
          <a-textarea
            auto-size
            placeholder="Ответ"
            v-model="question.answer"
          />
        </a-col>
        <a-col span="6">
          <a-button type="danger" icon="delete" @click="remove(index)"/>
        </a-col>
      </a-row>

      <a-row>
        <a-button type="primary" @click="add">Добавить</a-button>
      </a-row>
    </a-col>
  </a-row>
</template>

<script>

function getDefaultQuestion() {
  return {
    question: '',
    answer  : '',
  };
}

export default {
  name   : 'AppQuestions',
  props  : {
    questions: {
      required: true,
      type    : Array,
    },
  },
  methods: {
    add() {
      this.questions.push(getDefaultQuestion());
    },
    remove(index) {
      this.questions.splice(index, 1);
    },
  },
};
</script>

<style scoped>
.ant-row + .ant-row {
  margin-top: 10px;
}

.ant-col + .ant-col {
  margin-left: 15px;
}
</style>
