<template>
  <a-table
    :columns="columns"
    :dataSource="tagListingsFiltered"
    :loading="loading || adding"
    rowKey="id"
  >
    <div
      slot="filterDropdown"
      slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
      style="padding: 8px"
    >
      <a-input
        v-ant-ref="c => (searchInput = c)"
        :placeholder="`Адрес`"
        :value="selectedKeys[0]"
        style="width: 188px; margin-bottom: 8px; display: block;"
        @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
        @pressEnter="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
      />
      <a-button
        type="primary"
        icon="search"
        size="small"
        style="width: 90px; margin-right: 8px"
        @click="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
      >
        Искать
      </a-button>
      <a-button size="small" style="width: 90px" @click="() => handleReset(clearFilters)">
        Сбросить
      </a-button>
    </div>
    <a-icon
      slot="filterIcon"
      slot-scope="filtered"
      type="search"
      :style="{ color: filtered ? '#108ee9' : undefined }"
    />

    <template slot="customRender" slot-scope="text, record, index, column">
        <span v-if="searchText && searchedColumn === column.dataIndex">
          <template
            v-for="(fragment, i) in text
              .toString()
              .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
          >
            <mark
              v-if="fragment.toLowerCase() === searchText.toLowerCase()"
              :key="i"
              class="highlight"
            >{{ fragment }}</mark
            >
            <template v-else>{{ fragment }}</template>
          </template>
        </span>
      <template v-else>
        {{ text }}
      </template>
    </template>

    <template slot="actions" slot-scope="tagListing">
      <a-tooltip>
        <template slot="title">
          Присоединить категорию
        </template>
        <a-button
          type="primary"
          class="gray-btn"
          icon="plus"
          @click="add(tagListing.id)"
        />
      </a-tooltip>
    </template>
  </a-table>
</template>

<script>
  import { applications as appApi } from '@/api';

  export default {
    name    : 'TagListingAdd',
    props   : {
      id         : {
        required: true,
      },
      loading    : {
        required: true,
        type    : Boolean,
      },
      tagListings: {
        required: true,
        type    : Array,
      },
      exclude    : {
        required: true,
        type    : Array,
      },
    },
    data() {
      return {
        searchText    : '',
        searchInput   : null,
        searchedColumn: '',

        adding: false,

        columns: [
          {
            title           : 'Id',
            dataIndex       : 'id',
            key             : 'id',
            defaultSortOrder: 'ascend',
            sorter          : (a, b) => a.id - b.id,
          },
          {
            title    : 'Адрес',
            dataIndex: 'url',
            key      : 'url',

            scopedSlots: {
              filterDropdown: 'filterDropdown',
              filterIcon    : 'filterIcon',
              customRender  : 'customRender',
            },

            onFilter: (value, record) =>
              record.url
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),

            onFilterDropdownVisibleChange: (visible) => {
              if (visible) {
                setTimeout(() => {
                  this.searchInput.focus();
                });
              }
            },

            sorter: (a, b) => (a.url > b.url) ? 1 : ((b.url > a.url) ? -1 : 0),
          },
          {
            title      : 'Действия',
            key        : 'actions',
            scopedSlots: { customRender: 'actions' },
          },
        ],
      };
    },
    computed: {
      tagListingsFiltered() {
        return this.tagListings.filter((tagListing) => {
          return -1 === this.exclude.findIndex((item) => item.id === tagListing.id);
        });
      },
    },
    methods : {
      filterOption(input, option) {
        return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
        );
      },

      handleSearch(selectedKeys, confirm, dataIndex) {
        confirm();
        this.searchText     = selectedKeys[0];
        this.searchedColumn = dataIndex;
      },

      handleReset(clearFilters) {
        clearFilters();
        this.searchText = '';
      },

      async add(tagId) {
        this.adding = true;
        try {
          const response = await appApi.attachTagListing(this.id, tagId);
          const { data } = await response.json();
          this.$emit('added', data);
          this.$message.success('Категория добавлена для приложения', 2);
        } catch (exception) {
          const json = await exception.json();
          if (json.hasOwnProperty('errors')) {
            for (const name of Object.keys(json.errors)) {
              for (const error of json.errors[name]) {
                this.$message.error(error, 2);
              }
            }
          }
        } finally {
          this.adding = false;
        }
      },
    },
  };
</script>

<style scoped>

</style>
