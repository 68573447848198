import { post, put, get } from '../core';
import { CreateAppDescription } from '@/types/applications';

const getMiniSiteByApplicationId = (appId: number) =>
  get(`/api/admin/minisite/${appId}`);

const createMiniSiteForApplication = (data: any, appId: number) =>
  post(`/api/admin/minisite/${appId}`, data);

const updateMiniSiteForApplication = (data: any, appId: number) =>
  put(`/api/admin/minisite/${appId}`, data);

export const MiniSite = {
  getMiniSiteByApplicationId,
  createMiniSiteForApplication,
  updateMiniSiteForApplication,
};

export default { MiniSite };
