var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('AddPageTitle', {
    attrs: {
      "title": _vm.title,
      "id": _vm.id || _vm.moderationId
    }
  }), _vm.moderationId ? _c('ModerationTop', {
    attrs: {
      "appId": _vm.moderationId
    }
  }) : _vm._e(), _vm.id && !_vm.hasLoadUser ? _c('DeveloperModeration', {
    attrs: {
      "appId": _vm.id
    }
  }) : _vm._e(), _c('a-layout-content', {
    staticClass: "page__content"
  }, [_c('TopMenu', {
    attrs: {
      "id": _vm.id,
      "moderationId": _vm.moderationId,
      "active": "description"
    }
  }), _c('a-spin', {
    attrs: {
      "spinning": _vm.hasLoadApplication
    }
  }, [_c('div', {
    staticClass: "spin-content"
  }, [_c('a-form', {
    staticStyle: {
      "margin-top": "30px"
    },
    attrs: {
      "form": _vm.form
    }
  }, [_c('a-row', {
    attrs: {
      "gutter": 24
    }
  }, [_c('a-col', {
    attrs: {
      "span": "12"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "Платформа"
    }
  }, [_c('a-select', {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ['platformTypeId', {
        initialValue: _vm.app.platform && _vm.app.platform.id,
        rules: [{
          required: true,
          message: 'Платформа - обязательное поле'
        }]
      }],
      expression: "[\n                    'platformTypeId',\n                    {\n                      initialValue: app.platform && app.platform.id,\n                      rules: [\n                        {\n                          required: true,\n                          message: 'Платформа - обязательное поле'\n                        }\n                      ]\n                    }\n                  ]"
    }],
    attrs: {
      "placeholder": "Выберите платформу",
      "size": "large"
    }
  }, _vm._l(_vm.platformTypes, function (platform) {
    return _c('a-select-option', {
      key: platform.id,
      attrs: {
        "value": platform.id
      }
    }, [_vm._v(_vm._s(platform.name) + " ")]);
  }), 1)], 1)], 1), _c('a-col', {
    attrs: {
      "span": "12"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "Операционная система"
    }
  }, [_c('a-select', {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ['operationSystemId', {
        initialValue: _vm.app.operationSystem && _vm.app.operationSystem.id,
        rules: [{
          required: true,
          message: 'Операционная система - обязательное поле'
        }]
      }],
      expression: "[\n                    'operationSystemId',\n                    {\n                      initialValue:\n                        app.operationSystem && app.operationSystem.id,\n                      rules: [\n                        {\n                          required: true,\n                          message: 'Операционная система - обязательное поле'\n                        }\n                      ]\n                    }\n                  ]"
    }],
    attrs: {
      "placeholder": "Выберите операционную систему",
      "size": "large"
    },
    on: {
      "change": _vm.handleOsChange
    }
  }, _vm._l(_vm.operationSystems, function (os) {
    return _c('a-select-option', {
      key: os.id,
      attrs: {
        "value": os.id
      }
    }, [_vm._v(_vm._s(os.name) + " ")]);
  }), 1)], 1)], 1), _c('a-col', {
    attrs: {
      "span": "24"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "Версия операционной системы"
    }
  }, [_c('a-select', {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ['osVersions', {
        initialValue: _vm.app.osVersions && _vm.app.osVersions.map(function (version) {
          return String(version.id);
        }),
        rules: [{
          required: true,
          message: 'Версия операционной системы - обязательное поле'
        }]
      }],
      expression: "[\n                    'osVersions',\n                    {\n                      initialValue:\n                        app.osVersions &&\n                        app.osVersions.map(version => String(version.id)),\n                      rules: [\n                        {\n                          required: true,\n                          message:\n                            'Версия операционной системы - обязательное поле'\n                        }\n                      ]\n                    }\n                  ]"
    }],
    attrs: {
      "mode": "tags",
      "placeholder": "Выберите версию операционной системы",
      "size": "large"
    }
  }, _vm._l(_vm.operationSystemsVersionsByOs, function (version) {
    return _c('a-select-option', {
      key: version.id,
      attrs: {
        "value": String(version.id)
      }
    }, [_vm._v(_vm._s(version.name) + " ")]);
  }), 1)], 1)], 1)], 1), _c('a-row', {
    attrs: {
      "gutter": 24
    }
  }, [_c('a-col', {
    attrs: {
      "span": "8"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "Лицензия"
    }
  }, [_c('a-select', {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ['licenseTypeId', {
        initialValue: _vm.app.license && _vm.app.license.id,
        rules: [{
          required: true,
          message: 'Лицензия - обязательное поле'
        }]
      }],
      expression: "[\n                    'licenseTypeId',\n                    {\n                      initialValue: app.license && app.license.id,\n                      rules: [\n                        {\n                          required: true,\n                          message: 'Лицензия - обязательное поле'\n                        }\n                      ]\n                    }\n                  ]"
    }],
    attrs: {
      "placeholder": "Выберите лицензию",
      "size": "large"
    }
  }, _vm._l(_vm.licenseTypes, function (license) {
    return _c('a-select-option', {
      key: license.id,
      attrs: {
        "value": license.id
      }
    }, [_vm._v(_vm._s(license.name) + " ")]);
  }), 1)], 1)], 1), _c('a-col', {
    attrs: {
      "span": "8"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "Сайт"
    }
  }, [_c('a-input', {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ['site', {
        initialValue: _vm.app.site,
        rules: [{
          required: false
        }]
      }],
      expression: "[\n                    'site',\n                    {\n                      initialValue: app.site,\n                      rules: [\n                        {\n                          required: false\n                        }\n                      ]\n                    }\n                  ]"
    }],
    attrs: {
      "placeholder": "Введите сайт",
      "size": "large"
    }
  })], 1)], 1)], 1), _c('a-row', {
    staticClass: "row-st",
    attrs: {
      "gutter": 24
    }
  }, [_c('a-col', {
    attrs: {
      "lg": "24"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "Языки интерфейса"
    }
  }, [_c('a-checkbox-group', {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ['interfaceLangs', {
        initialValue: _vm.app.interfaceLangs && _vm.app.interfaceLangs.data && _vm.app.interfaceLangs.data.map(function (app) {
          return app.id;
        }),
        rules: [{
          required: true,
          message: 'Выберите хотя бы один язык'
        }]
      }],
      expression: "[\n                    'interfaceLangs',\n                    {\n                      initialValue:\n                        app.interfaceLangs &&\n                        app.interfaceLangs.data &&\n                        app.interfaceLangs.data.map(app => app.id),\n                      rules: [\n                        {\n                          required: true,\n                          message: 'Выберите хотя бы один язык'\n                        }\n                      ]\n                    }\n                  ]"
    }],
    attrs: {
      "options": _vm.options
    }
  })], 1)], 1)], 1), _c('a-row', {
    staticClass: "row-st",
    attrs: {
      "gutter": 24
    }
  }, [_c('a-card', [_c('a-row', {
    attrs: {
      "gutter": 24
    }
  }, [_c('a-col', {
    attrs: {
      "span": "12"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "\u041D\u0430\u0437\u0432\u0430\u043D\u0438\u0435 (ru)"
    }
  }, [_c('a-input', {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["translations.ru.name", {
        initialValue: _vm.app.translations.ru.name,
        rules: [{
          required: true,
          max: 150,
          message: 'Название обязательное поле. Должно быть не более 150 символов'
        }]
      }],
      expression: "[\n                          `translations.ru.name`,\n                          {\n                      initialValue: app.translations.ru.name,\n                      rules: [\n                        {\n                          required: true,\n                          max: 150,\n                          message: 'Название обязательное поле. Должно быть не более 150 символов'\n                        }\n                      ]\n                    }\n                  ]"
    }],
    attrs: {
      "size": "large",
      "placeholder": "Введите название (не более 150 символов)"
    }
  })], 1)], 1), _c('a-col', {
    attrs: {
      "span": "8"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "Цена"
    }
  }, [_c('a-input', {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["translations.ru.price", {
        initialValue: _vm.app.translations.ru.price,
        rules: [{
          required: false
        }]
      }],
      expression: "[\n                    `translations.ru.price`,\n                    {\n                      initialValue: app.translations.ru.price,\n                      rules: [\n                        {\n                          required: false\n                        }\n                      ]\n                    }\n                  ]"
    }],
    attrs: {
      "placeholder": "Цена",
      "size": "large"
    }
  })], 1)], 1)], 1), _c('a-form-item', {
    class: {
      'has-error': _vm.errorsBag.shortDescriptionRu
    },
    attrs: {
      "label": "\u041A\u0440\u0430\u0442\u043A\u043E\u0435 \u043E\u043F\u0438\u0441\u0430\u043D\u0438\u0435 (ru)"
    }
  }, [_c('a-textarea', {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["translations.ru.short_description", {
        initialValue: _vm.app.translations.ru.short_description,
        rules: [{
          required: true,
          min: 100,
          max: 500,
          message: 'Краткое описание обязательное поле. Должно быть не менее 100 и не более 500 символов'
        }]
      }],
      expression: "[\n                      `translations.ru.short_description`,\n                      {\n                        initialValue: app.translations.ru.short_description,\n                        rules: [\n                          {\n                            required: true,\n                            min: 100,\n                            max: 500,\n                            message: 'Краткое описание обязательное поле. Должно быть не менее 100 и не более 500 символов',\n                          }\n                        ],\n                      }\n                      ]"
    }],
    attrs: {
      "auto-size": ""
    }
  }), _c('p', [_vm._v(" Количество слов: " + _vm._s(_vm.getWordCount(_vm.form.getFieldValue("translations.ru.short_description"))) + " Количество символов: " + _vm._s(_vm.form.getFieldValue("translations.ru.short_description") ? String(_vm.form.getFieldValue("translations.ru.short_description")).length : 0) + " ")])], 1), _c('a-form-item', {
    class: {
      'has-error': _vm.errorsBag.descriptionRu
    },
    attrs: {
      "label": "\u041F\u043E\u043B\u043D\u043E\u0435 \u043E\u043F\u0438\u0441\u0430\u043D\u0438\u0435 (ru)"
    }
  }, [_c('ckeditor', {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["translations.ru.description", {
        initialValue: _vm.app.translations.ru.description,
        rules: [{
          required: true,
          min: 250,
          max: 50000,
          message: 'Полное описание обязательное поле. Должно быть не менее 250 и не более 50000 символов'
        }]
      }],
      expression: "[\n                      `translations.ru.description`, {\n                      initialValue: app.translations.ru.description,\n                      rules: [\n                          {\n                            required: true,\n                            min: 250,\n                            max: 50000,\n                            message: 'Полное описание обязательное поле. Должно быть не менее 250 и не более 50000 символов',\n                          }\n                        ],\n                    }\n                    ]"
    }],
    attrs: {
      "config": _vm.editorConfig()
    },
    on: {
      "input": function input($event) {
        var _vm$form$setFieldsVal;
        return _vm.form.setFieldsValue((_vm$form$setFieldsVal = {}, _vm$form$setFieldsVal["translations.ru.description"] = $event, _vm$form$setFieldsVal));
      }
    }
  }), _c('p', [_vm._v(" Количество слов: " + _vm._s(_vm.getWordCount(_vm.app.translations.ru.description)) + " Количество символов: " + _vm._s(_vm.form.getFieldValue("translations.ru.description") ? String(_vm.form.getFieldValue("translations.ru.description")).length : 0) + " ")])], 1)], 1)], 1), _c('a-row', {
    attrs: {
      "gutter": "24"
    }
  }, [_c('a-col', {
    attrs: {
      "span": "8"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "Опубликовано"
    }
  }, [_c('a-switch', {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ['enabled', {
        valuePropName: 'checked',
        initialValue: _vm.app.enabled,
        rules: [{
          required: false
        }]
      }],
      expression: "[\n                    'enabled',\n                    {\n                      valuePropName: 'checked',\n                      initialValue: app.enabled,\n                      rules: [\n                        {\n                          required: false\n                        }\n                      ]\n                    }\n                  ]"
    }]
  })], 1)], 1)], 1), _c('a-row', {
    attrs: {
      "gutter": 24
    }
  }, [_c('a-col', {
    staticStyle: {
      "text-align": "right"
    },
    attrs: {
      "span": "24"
    }
  }, [_c('a-button', {
    attrs: {
      "size": "large",
      "type": "primary"
    },
    on: {
      "click": _vm.handleSaveDescription
    }
  }, [_vm._v(_vm._s(_vm.action))])], 1)], 1)], 1)], 1)])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }