<template>
  <div>
    <PageTitle title="Список категорий"/>

    <a-layout-content class="page__content">
      <a-row>
        <a-card title="Параметры для локали">
          <a-tabs>
            <a-tab-pane
              v-for="{title, locale} in LOCALE_LIST"
              :key="locale"
              :tab="title"
            >
              <a-col span="6">
                <a-form-item label="Название">
                  <a-input
                    size="large"
                    placeholder="Название"
                    v-model="category.translations[locale].name"
                  >
                  </a-input>
                </a-form-item>
              </a-col>
            </a-tab-pane>
          </a-tabs>
        </a-card>
      </a-row>

      <a-row :gutter="24">
        <a-col span="6">
          <a-form-item label="OS">
            <a-select
              size="large"
              placeholder="OS"
              v-model="category.os_id"
              @change="onOsChange"
            >
              <a-select-option
                v-for="os in operationSystems"
                :value="os.id"
                :key="os.id"
              >{{ os.name }}
              </a-select-option
              >
            </a-select>
          </a-form-item>
        </a-col>

        <a-col span="6">
          <a-form-item label="Родительская категория">
            <a-select
              size="large"
              placeholder="Родительская категория"
              v-model="category.parent_id"
            >
              <a-select-option
                value=""
              >
                Не выбрано
              </a-select-option>
              <a-select-option
                v-for="category in filteredCategories"
                :value="category.id"
                :key="category.id"
              >
                {{ category.parent ? category.parent.name + ' / ' : '' }}{{ category.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col span="6">
          <a-form-item label="Переместить апы в">
            <a-select
              size="large"
              placeholder="Переместить в"
              v-model="move_to"
            >
              <a-select-option
                value=""
              >
                Не выбрано
              </a-select-option>
              <template v-for="category in filteredCategoriesForMove">
                <a-select-option
                  :value="category.id"
                  :key="category.id"
                >
                  {{ category.parent ? category.parent.name + ' / ' : '' }}{{ category.name }}
                </a-select-option>
                <template
                  v-if="category.children"
                  v-for="subCategory in category.children"
                >
                  <a-select-option
                    :value="subCategory.id"
                    :key="subCategory.id"
                  >
                    {{ category.name }} / {{ subCategory.name }}
                  </a-select-option>
                </template>
              </template>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>

      <a-button
        type="primary"
        @click="save"
        size="large"
      >
        Сохранить
      </a-button>

    </a-layout-content>
  </div>
</template>

<script>
import PageTitle from '@/components/Global/PageTitle';
import { getCategory, getCategories, updateCategory, createCategory, moveCategory } from '../../api/category';
import { mapState } from 'vuex';
import { LOCALE_LIST } from '@/global/constants';
import { merge } from 'lodash';
import { buildTranslationsDataForModel } from '@/helpers';

export default {
  name      : 'ModifyCategory',
  components: { PageTitle },
  props     : {
    id: {
      required: false,
    },
  },
  data() {
    return {
      category  : {
        id          : null,
        name        : '',
        slug        : '',
        os_id       : null,
        parent_id   : '',
        translations: {
          en: { name: '' },
          ru: { name: '' },
          fr: { name: '' },
        },
      },
      categories: [],
      move_to   : undefined,
      LOCALE_LIST,
    };
  },
  computed: {
    ...mapState(['operationSystems']),
    filteredCategories() {
      return this.filteredCategoriesByOs.filter(
        (cat) => cat.id !== this.category.id && null === cat.parent_id,
      );
    },
    filteredCategoriesByOs() {
      return this.categories.filter(
        (cat) => cat.os_id === this.category.os_id,
      );
    },
    filteredCategoriesForMove() {
      return this.filteredCategoriesByOs.filter(
        (cat) => cat.id !== this.category.id,
      );
    },
  },
  created() {
    if (this.id) {
      getCategory(this.id).then((response) => response.json()).then((json) => {
        merge(this.category, json.data);
      });
    }

    getCategories({ all: 1 }).then((response) => response.json()).then((json) => {
      this.categories = json.data;
    });
  },

  methods: {
    onOsChange() {
      this.category.parent_id = null;
    },

    async save() {
      if (this.move_to) {
        return this.move();
      }

      const translations = buildTranslationsDataForModel(this.category.translations);

      const category = {
        id          : this.category.id,
        slug        : this.category.slug,
        os_id       : this.category.os_id,
        parent_id   : this.category.parent_id,
        translations,
      };

      const response = category.id ? await updateCategory(category.id, category) : await createCategory(category);

      const json = await response.json();

      if (json.errors) {
        Object.values(json.errors).forEach((value) => {
          this.$message.error(value[0]);
        });
      } else {
        this.$message.success('Категория успешно отредактирована.', 3);
        this.$router.push({ name: 'CategorizationCategories' });
      }
    },

    async move() {
      this.$confirm({
        title  : 'Вы действительно хотите переместить категорию?',
        content:
          'При все приложение будут перемещены в указанную категорию',
        onOk   : async () => {
          const response = await moveCategory(this.category.id, this.move_to);
          this.$router.push({ name: 'CategorizationCategories' });
        },
      });
    },
  },
};
</script>

<style scoped>

</style>
