
// @ts-nocheck
import Vue from 'vue';
import PageTitle from '@/components/Global/PageTitle.vue';
import { news } from '@/api/news';
import { pickBy } from 'lodash';

export default Vue.extend({
  components: {
    PageTitle,
  },
  props: ['id'],

  mounted() {
    if (this.id) {
      this.title = 'Редактировать категорию';
      this.action = 'Сохранить';
      this.hasLoadCategory = true;
      news
        .getNewsCategoryNewsById(this.id)
        .then((res) => res.json())
        .then(({ data }) => {
          this.baseCategory = data;
          this.hasLoadCategory = false;
          const { active } = data;
          this.active = Boolean(active);
        });
    } else {
      this.title = 'Создать категорию';
      this.action = 'Создать';
    }

    this.getNewsCategories();
    window.scrollTo(0, 0);
  },

  data() {
    return {
      form: this.$form.createForm(this),
      newsCategories: [],
      baseCategory: {},
      hasLoadCategory: false,
      active: false,
      action: '',
      title: '',
    };
  },

  methods: {
    async handleSubmit(): Promise<any> {
      const fields = pickBy({
        ...this.form.getFieldsValue(),
      });

      if (this.id) {
        await news
          .updateCategoryNews(this.id, {
            ...fields,
            active: Number(this.active),
          })
          .then(() => {
            this.$message.success('Успешно отредактировано!');
          });
      } else {
        await news
          .createCategoryNews({
            ...fields,
            active: Number(this.active),
          })
          .then(() => {
            this.$message.success('Успешно создано!');
          });
      }
    },

    /**
     * @description Получение категорий новостей
     * @return {Promise<any>}
     */
    async getNewsCategories(): Promise<any> {
      await news
        .getNewsCategory()
        .then((res: Response) => res.json())
        .then(({ data }) => {
          this.newsCategories = data;
        });
    },
  },
});
