import NewsList from './NewsList.vue';
import ChangeNews from './NewsChange.vue';
import Categories from './Categories.vue';
import EditCategory from './EditCategory.vue';

export const newsRoute = '/news';
export const newsChangeRoute = '/news/:id/edit';
export const newsAddRoute = '/news/add/';
export const newsCategoryRoute = '/news/category';
export const newsCategoryEditRoute = '/news/category/:id/edit';
export const newsCategoryAddRoute = '/news/add-category';

export const news = [
  {
    name: 'Edit news category',
    path: newsCategoryEditRoute,
    component: EditCategory,
    props: true,
    breadcrumbName: 'Редактировать категорию',
    meta: {
      title: 'Редактировать категорию',
    },
  },
  {
    name: 'add category',
    path: newsCategoryAddRoute,
    component: EditCategory,
    breadcrumbName: 'Добавить категорию',
    meta: {
      title: 'Добавить категорию',
    },
  },
  {
    name: 'News Category',
    path: newsCategoryRoute,
    component: Categories,
    breadcrumbName: 'Категории новостей',
    meta: {
      title: 'Категории новостей',
    },
  },
  {
    name: 'News',
    path: newsRoute,
    component: NewsList,
    breadcrumbName: 'Новости',
    meta: {
      title: 'Новости',
    },
  },
  {
    name: 'EditNews',
    path: newsChangeRoute,
    component: ChangeNews,
    breadcrumbName: 'Редактировать новость',
    props: true,
    meta: {
      title: 'Редактировать новость',
    },
  },
  {
    name: 'AddNews',
    path: newsAddRoute,
    component: ChangeNews,
    breadcrumbName: 'Добавить новость',
    meta: {
      title: 'Добавить новость',
    },
  },
];

export default { news };
