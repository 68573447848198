import { get, post, put, deleteMethod } from '@/api/core';
import * as positions from './advertPosition';

/**
 * @description Получить список банеров рекламы
 * @return {Promise<any>}
 */
const getAdvertBanners = (): Promise<any> => get('/api/admin/ads/ad');

/**
 * @description Создать баннер
 * @param {object} params
 * @return {Promise<any>}
 */
const createAdvertBanner = (params: any): Promise<any> =>
  post('/api/admin/ads/ad', params);

/**
 * @description Поиск банеров по языку
 * @param {object} params
 * @return {Promise<any>}
 */
const searchAdvertByLanguage = (params: any): Promise<any> =>
  post('/api/admin/ads/ad/search', params);

/**
 * @description Получить банер по id
 * @param {number} id
 * @return {Promise<any>}
 */
const getAdvertBannerById = (id: number): Promise<any> =>
  get(`/api/admin/ads/ad/${id}`);

/**
 * @description Обновить банер
 * @param {number} id
 * @param {object} params
 * @return {Promise<any>}
 */
const updateAdvertBannerById = (id: number, params: any) =>
  put(`/api/admin/ads/ad/${id}`, params);

/**
 * @description Удалить банер
 * @param {number} id
 * @return {Promise<any>}
 */
const deleteAdvertBannerById = (id: number): Promise<any> =>
  deleteMethod(`/api/admin/ads/ad/${id}`);

export const advert = {
  ...positions.default,
  getAdvertBanners,
  createAdvertBanner,
  searchAdvertByLanguage,
  getAdvertBannerById,
  updateAdvertBannerById,
  deleteAdvertBannerById,
};

export default { advert };
