import jwt from 'jsonwebtoken';
// @ts-ignore
import Cookie from '@/helpers/Cookie';

export default class AuthService {
  decode = jwt.decode;

  /**
   * Установка токена
   * @param {string} token
   * @return {null} state
   */
  setToken(token) {
    const userJwtInfo = this.decode(token);
    // @ts-ignore
    const {exp} = userJwtInfo;
    Cookie.set('admin_token', token, exp);
  }

  /**
   * @description Получение токена
   * @return {string} token
   */
  getToken() {
    return Cookie.get('admin_token');
  }

  /**
   * @description Выход
   * @return {void}
   */
  logout() {
    Cookie.delete('admin_token');
  }

  /**
   * @description Проверка на залогиненность
   * @return {boolean}
   */
  loggedIn() {
    const token = this.getToken();
    return !!token && !this.isTokenExpired(token);
  }

  /**
   * @description Объект с пользователем
   * @return {object} user
   */
  getProfile() {
    return this.decode(this.getToken());
  }

  /**
   * @description Проверка токена на действительность токена
   * @return {boolean}
   */
  isTokenExpired(token) {
    try {
      const decoded = this.decode(token);
      // @ts-ignore
      if (decoded.exp < Date.now() / 1000) {
        return true;
      }
      return false;
    } catch (err) {
      return false;
    }
  }
}

