export function getCkeditorConfig(config = {}) {
  const defaultConfig = {
    language: 'ru',
    scayt_customerId: process.env.VUE_APP_CKEDITOR_SCAYT_CUSTOMERID,
    scayt_autoStartup: true,
    allowedContent: true,
    format_tags: 'p;h1;h2;h3;h4;h5;h6;pre;div',
    filebrowserUploadUrl: process.env.VUE_APP_CKEDITOR_FILEBROWSER_UPLOAD_URL,
    versionCheck: false,
  };

  return Object.assign(defaultConfig, config);
}
