import { Route } from '@/types/route';
import CommentsApps from './Apps.vue';
import CommentsArticles from './Articles.vue';
import CommentNews from './News.vue';
import ChangeComment from './Change.vue';
import CommentsAppsCreate from './CommentsAppsCreate.vue';

export const commentsApps = '/comments/apps';
export const commentArticles = '/comments/articles';
export const commentsNews = '/comments/news';
export const changeComment = '/comments/change';
export const commentsAppsCreate = '/comments/apps/create';

export const commentRoutes: Route[] = [
  {
    path: commentsApps,
    component: CommentsApps,
    name: 'CommentsApps',
    breadcrumbName: 'Комментарии программ',
    meta: {
      title: 'Комментарии программ',
    },
  },
  {
    path: commentsAppsCreate,
    component: CommentsAppsCreate,
    name: 'commentsAppsCreate',
    breadcrumbName: 'Создать комментарий для программы',
    meta: {
      title: 'Создать комментарий для программы',
    },
  },
  {
    path: commentArticles,
    component: CommentsArticles,
    name: 'CommentsArticles',
    breadcrumbName: 'Комментарии статей',
    meta: {
      title: 'Комментарии статей',
    },
  },
  {
    path: commentsNews,
    component: CommentNews,
    name: 'CommentNews',
    breadcrumbName: 'Комментарии новостей',
    meta: {
      title: 'Комментарии новостей',
    },
  },
  {
    path: '/comments/change/:id',
    component: ChangeComment,
    name: 'ChangeComment',
    breadcrumbName: 'Редактирование комментария',
    props: true,
    meta: {
      title: 'Редактирование комментария',
    },
  },
  {
    path: '/reviews/change/:commentId',
    component: ChangeComment,
    name: 'ChangeReview',
    breadcrumbName: 'Редактирование отзыва',
    props: true,
    meta: {
      title: 'Редактирование отзыва',
    },
  },
];

export default { commentRoutes };
