
// @ts-nocheck
  import {Component, Prop, Vue} from 'vue-property-decorator';
  import PageTitle from '@/components/Global/PageTitle.vue';
  import {advert} from '@/api/advert';
  import {Position} from '@/types/advert';

  @Component({name: 'AddPosition', components: {PageTitle}})
  export default class AddPosition extends Vue {
    @Prop() public id!: number;
    title: string = '';

    basePosition = {
      id: 0,
      label: '',
      position: '',
      route: '',
      type: '',
    };

    /**
     * @description Обработка ошибок
     * @param {string} message
     * @return {function}
     */
    handleErrors(message: string) {
      return ({errors, code}: any) => {
        if (errors && code === 422) {
          Object.values(errors).forEach((value: any) => {
            this.$message.error(value[0]);
          });
        } else {
          this.$message.success(message, 2);

        }
      };
    }

    mounted() {
      if (this.id) {
        this.title = 'Редактировать позицию';
        this.getAdvertPositionById();
      } else {
        this.title = 'Добавить позицию';
      }
    }

    private async handleSubmit(): Promise<any> {
      if (this.id) {
        advert
          .updatePositionAdvertById(this.id, this.basePosition)
          .then((res) => res.json())
          .then(this.handleErrors('Позиция успешно отредактирована ! :)'));
      } else {
        advert
          .createAdvertPosition({...this.basePosition})
          .then((res) => res.json())
          .then(this.handleErrors('Позиция успешно создана ! :)'));
      }
    }

    /**
     * @description Получение позиции рекламы по id
     * @return {Promise<any>}
     */
    private async getAdvertPositionById(id?: number): Promise<any> {
      return await advert
        .getAdvertPositionById(id || this.id)
        .then((res) => res.json())
        .then(({data}) => {
          if (!id) {
            this.basePosition = data;
          }
          return data;
        });
    }
  }
