
// @ts-nocheck
import Vue from 'vue';
import PageTitle from '@/components/Global/PageTitle.vue';
import { Developer } from '@/api/developer';
import {applications} from '@/api';

export default Vue.extend({
  components: { PageTitle },
  data() {
    return {
      hasLoadApps: false,
      searchParams: {},
      apps: {
        data: [],
        meta: {
          per_page: 1,
          current_page: 1,
          total: 0,
        },
        links: {},
      },
      columns: [
        {
          title: 'Название программы',
          dataIndex: 'name',
          key: 'name',
          sorter: true,
          sortDirections: ['asc', 'desc'],
        },
        {
          title: 'Статус',
          dataIndex: 'moderateStatus',
          key: 'status',
          scopedSlots: { customRender: 'status' },
          sorter: true,
          sortDirections: ['asc', 'desc'],
        },
        {
          title: 'Последняя версия',
          dataIndex: 'lastVersion',
          key: 'categories',
        },
        {
          title: 'Дата обновления',
          dataIndex: 'lastUpdate',
          key: 'lastUpload',
          sorter: true,
          sortDirections: ['asc', 'desc'],
        },
        {
          title: 'Оценка ПО',
          dataIndex: 'rate',
          key: 'applicationRate',
          scopedSlots: { customRender: 'rate' },
          sorter: true,
          sortDirections: ['asc', 'desc'],
        },
        {
          title: 'Действия',
          dataIndex: '',
          key: 'actions',
          scopedSlots: { customRender: 'actions' },
        },
      ],
    };
  },
  mounted() {
    this.getApplications();
  },
  methods: {
    /**
     * @description Удаление приложения
     * @param {number} id
     * @param {string} name
     * @return {Promise<any>}
     */
    deleteApplication(id: number, name: string): void {
      this.$confirm({
        title: `Вы действительно хотите удалить приложение ${name}?`,
        content:
          'Вы не сможете восстановить его после удаления, только добавить еще раз.',
        onOk: async () => {
          await applications.deleteAppById(id).then(() => {
            this.$message.success('Приложение успешно удаленно :)', 2);
            this.getApplications();
          });
        },
      });
    },

    /**
     * @description Получение списка приложений разработчика
     * @return {Promise<any>}
     */
    async getApplications(): Promise<any> {
      this.hasLoadApps = true;
      Developer.getDeveloperApplications()
        .then((res) => res.json())
        .then((data) => {
          this.apps = data;
          this.hasLoadApps = false;
        });
    },

    async searchApplications(params: any): Promise<any> {
      await Developer.searchDeveloperApplications(params)
        .then((res) => res.json())
        .then((data) => {
          this.apps = data;
          this.hasLoadApps = false;
        });
    },

    /**
     * @description Сортировка приложений
     * @return {Promise<any>}
     */
    async sortAppsByField(field: string, direction: string): Promise<any> {
      this.hasLoadApps = true;
      this.searchParams = { [`${field}Order`]: direction  };
      await this.searchApplications(this.searchParams);
    },

    /**
     * @description Обработчик на change таблички
     * @param {object} pagination
     * @param {object} filters
     * @param {object} sorter
     * @return {Promise<any>}
     */
    onChange(pagination: any, filters: any, sorter: any) {
      const { order, columnKey } = sorter;
      this.sortAppsByField(columnKey, order);
    },

    /**
     * @description Обработчик на change компонента с пагинацией
     * @param {number} page
     * @return {void}
     */
    paginationChange(page: number): void {
      this.searchParams = { ...this.searchParams, page };
      this.searchApplications({ ...this.searchParams });
      window.scrollTo(0, 0);
    },
  },
});
