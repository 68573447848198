var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('PageTitle', {
    attrs: {
      "title": _vm.title
    }
  }, [_vm.url ? _c('a', {
    attrs: {
      "href": _vm.url,
      "target": "_blank"
    }
  }, [_c('a-button', {
    attrs: {
      "type": "primary",
      "icon": "eye"
    }
  }, [_vm._v("Просмотреть ")])], 1) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }