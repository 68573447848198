import Vue from 'vue';
import Vuex from 'vuex';
import { applications } from '@/api/Applications';
import { users } from './api/users';
import { hasAdmin, hasDeveloper, hasEditor, hasSeo } from './helpers';
import { getMenu } from '@/api/global';
import {getCategories} from "@/api/category";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    operationSystems: [],
    developers: [],
    operationSystemsVersions: [],
    licenseTypes: [],
    platformTypes: [],
    languages: [],
    user: {
      hasAdmin: false,
      hasEditor: false,
      hasDeveloper: false,
    },
    hasLoadUser: false,
    menuList: [],
    categories: [],
  },
  getters: {
    getOperationSystems: (state) => {
      return state.operationSystems;
    },
    getOperationSystemsVersions: (state) => {
      return state.operationSystemsVersions;
    },
    getlicenseTypes: (state) => {
      return state.licenseTypes;
    },
    getPlatformTypes: (state) => {
      return state.platformTypes;
    },
    getDevelopers: (state) => {
      return state.developers;
    },
    getLanguages: (state) => {
      return state.languages;
    },
    getCategories: (state) => {
      return state.categories;
    },
  },
  mutations: {
    SET_USER: (state, payload) => {
      state.user = payload;
    },
    SET_LANGUAGES_LIST: (state, payload) => {
      state.languages = payload;
    },
    SET_OPERATION_SYSTEMS: (state, payload) => {
      state.operationSystems = payload;
    },
    SET_DEVELOPERS: (state, payload) => {
      state.developers = payload;
    },
    SET_OPERATION_SYSTEMS_VERSIONS: (state, payload) => {
      state.operationSystemsVersions = payload;
    },
    SET_LICENSE_TYPES: (state, payload) => {
      state.licenseTypes = payload;
    },
    SET_PLATFORM_TYPES: (state, payload) => {
      state.platformTypes = payload;
    },
    SET_LOAD_USER: (state, payload) => {
      state.hasLoadUser = payload;
    },
    SET_MENU_LIST: (state, payload) => {
      state.menuList = payload;
    },
    setCategories: (state, payload) => {
      state.categories = payload;
    },
  },
  actions: {
    GET_USER: async (context) => {
      context.commit('SET_LOAD_USER', true);
      await users
        .getUserInfo()
        .then((res) => res.json())
        .then(({ data: user }) => {
          context.commit('SET_USER', {
            ...user,
            hasAdmin: hasAdmin(user),
            hasEditor: hasEditor(user),
            hasDeveloper: hasDeveloper(user),
            hasSeo: hasSeo(user),
          });
          context.commit('SET_LOAD_USER', false);
        });
    },
    GET_LANGUAGES: async (context) => {
      await applications
        .getListLanguages()
        .then((res) => res.json())
        .then(({ data }) => {
          context.commit('SET_LANGUAGES_LIST', data);
        });
    },
    GET_OPERATION_SYSTEMS: async (context) => {
      await applications
        .getOsList()
        .then((res) => res.json())
        .then(({ data }) => {
          context.commit('SET_OPERATION_SYSTEMS', data);
        });
    },
    GET_OPERATION_SYSTEMS_VERSIONS: async (context) => {
      await applications
        .getOsVersions()
        .then((res) => res.json())
        .then(({ data }) => {
          context.commit('SET_OPERATION_SYSTEMS_VERSIONS', data);
        });
    },
    GET_LICENSE_TYPES: async (context) => {
      await applications
        .getLicenseTypes()
        .then((res) => res.json())
        .then(({ data }) => {
          context.commit('SET_LICENSE_TYPES', data);
        });
    },
    GET_PLATFORM_TYPES: async (context) => {
      await applications
        .getPlatformTypes()
        .then((res) => res.json())
        .then(({ data }) => {
          context.commit('SET_PLATFORM_TYPES', data);
        });
    },
    GET_MENU_LIST: async (context) => {
      getMenu()
        .then((res) => res.json())
        .then(({ data }) => {
          context.commit('SET_MENU_LIST', data);
        })
        .catch(() => {
          // this.menuList = menuList;
        });
    },
    getCategories: async (context) => {
      await getCategories({all: 1})
        .then((res) => res.json())
        .then(({ data }) => {
          context.commit('setCategories', data);
        });
    },
  },
});
