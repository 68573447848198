
// @ts-nocheck
// @ts-nocheck
import Vue from 'vue';
import {afterLoginCallback} from '@/global/callbacks/afterLoginCallback';
import AuthService from '@/helpers/AuthService';
import {mapState} from 'vuex';

const authService = new AuthService();

export default Vue.extend({
  props: {
    msg: String,
  },

  computed: {
    ...mapState(['hasLoadUser']),
  },

  created() {
    if (authService.loggedIn()) {
      afterLoginCallback(this.$store);
    }
  },
});
