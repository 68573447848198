<template>
  <div>
    <PageTitle :title="title"/>
    <a-layout-content class="page__content">
      <a-form>
        <a-form-item label="Позиция баннера">
          <a-select
            size="large"
            mode="multiple"
            :maxTagCount="4"
            placeholder="Выберите позиции банеров"
            :filterOption="filterOption"
            v-model="positionIds"
          >
            <a-select-option
              v-for="position in positions"
              :value="position.id"
              :key="position.id"
            >
              {{ position.route }}-{{ position.position }}
            </a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item label="Десктоп" requred>
          <a-textarea v-model="banner.desktop" style="height: 114px"/>
        </a-form-item>
        <a-form-item label="Мобайл" requred>
          <a-textarea v-model="banner.mobile" style="height: 114px"/>
        </a-form-item>
        <a-row :gutter="24">
          <a-col span="12">
            <a-form-item label="Локаль сайта">
              <a-select size="large" v-model="banner.locale">
                <a-select-option
                  v-for="{title, locale} in LOCALE_LIST"
                  :key="locale"
                  :value="title"
                >
                  {{ title }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col span="12">
            <a-form-item label="Отображение" requred>
              <a-select
                size="large"
                v-model="banner.isVisible"
                defaultValue="banner.isVisible"
              >
                <a-select-option :value="1">Вкл</a-select-option>
                <a-select-option :value="0">Выкл</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-button type="primary" @click="handleSubmit" size="large">Сохранить</a-button>
      </a-form>
    </a-layout-content>
  </div>
</template>

<script>
import PageTitle from '@/components/Global/PageTitle.vue';
import { advert } from '@/api/advert';
import { advertRoute } from '@/components/Advert/routes';
import { LOCALE_LIST } from '@/global/constants';

export default {
  name      : 'AddAdvert',
  components: { PageTitle },
  props     : {
    id: {
      required: false,
    },
  },
  data() {
    return {
      title      : '',
      positions  : [
        {
          id      : 0,
          label   : '',
          position: '',
          route   : '',
          type    : '',
        },
      ],
      banner     : {
        adPositions: [{ id: 0, label: '', position: '', route: '', type: '' }],
        content    : '',
        createdAt  : '',
        desktop    : '',
        id         : 0,
        isVisible  : 0,
        locale     : '',
        mobile     : '',
        updatedAt  : '',
        positions  : [1],
      },
      positionIds: [],
      LOCALE_LIST,
    };
  },

  mounted() {
    this.getAdvertPositions();
    if (this.id) {
      this.title = 'Редактировать баннер';
      this.getAdvertById();
    } else {
      this.title = 'Создать баннер';
    }
  },

  methods: {
    /**
     * @description Поиск в селекте по позициям
     */
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },

    /**
     * @description Получим позиции
     * @return {Promise<any>}
     */
    async getAdvertPositions() {
      await advert
        .getAdvertPositions()
        .then((res) => res.json())
        .then(({ data }) => {
          this.positions = data;
        });
    },

    /**
     * @description
     * @return {Promise<any>}
     */
    async handleSubmit() {
      const params = { ...this.banner, adPositions: this.positionIds };

      if (this.id) {
        await advert
          .updateAdvertBannerById(this.id, params)
          .then((res) => res.json())
          .then(({ code, errors }) => {
            if (errors && code === 422) {
              Object.values(errors).forEach((value) => {
                this.$message.error(value[0]);
              });
            } else {
              this.$message.success('Баннер успешно отредактирован ! :)', 2);

            }
          })
          .catch(() => {

          });
      } else {
        await advert
          .createAdvertBanner(params)
          .then((res) => res.json())
          .then(({ code, errors }) => {
            if (errors && code === 422) {
              Object.values(errors).forEach((value) => {
                this.$message.error(value[0]);
              });
            } else {
              this.$message.success('Баннер успешно создан ! :)', 2);
              this.$router.push(advertRoute);
            }
          });
      }
    },

    /**
     * @description Получим текущий баннер если это редактирование
     * @return {Promise<any>}
     */
    async getAdvertById() {
      await advert
        .getAdvertBannerById(this.id)
        .then((res) => res.json())
        .then(({ data }) => {
          const { adPositions } = data;
          this.banner           = data;
          // @ts-ignore
          this.positionIds      = adPositions.map((position) => position.id);
        });
    },
  },
};
</script>
