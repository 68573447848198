var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a-row', {
    staticStyle: {
      "margin-top": "15px"
    },
    attrs: {
      "gutter": "24"
    }
  }, [_c('a-col', [_c('h3', {
    staticStyle: {
      "margin-top": "20px"
    }
  }, [_vm._v("Блок преимущества")]), _vm._l(_vm.benefits, function (benefit, index) {
    return _c('a-row', {
      key: index
    }, [_c('a-col', {
      attrs: {
        "span": "6"
      }
    }, [_c('a-input', {
      attrs: {
        "placeholder": "Название"
      },
      model: {
        value: benefit.name,
        callback: function callback($$v) {
          _vm.$set(benefit, "name", $$v);
        },
        expression: "benefit.name"
      }
    })], 1), _c('a-col', {
      attrs: {
        "span": "6"
      }
    }, [_c('a-input', {
      attrs: {
        "placeholder": "Значение"
      },
      model: {
        value: benefit.value,
        callback: function callback($$v) {
          _vm.$set(benefit, "value", $$v);
        },
        expression: "benefit.value"
      }
    })], 1), _c('a-col', {
      attrs: {
        "span": "6"
      }
    }, [_c('a-button', {
      attrs: {
        "type": "danger",
        "icon": "delete"
      },
      on: {
        "click": function click($event) {
          return _vm.remove(index);
        }
      }
    })], 1)], 1);
  }), _c('a-row', [_c('a-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.add
    }
  }, [_vm._v("Добавить")])], 1)], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }