
// @ts-nocheck
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ExternalCode } from '@/types/externalCode';
import { settings } from '@/api';

@Component({
  name: 'List',
})
export default class List extends Vue {
  @Prop() public data!: ExternalCode[];

  columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Позиция',
      dataIndex: 'position',
      width: '15%',
      scopedSlots: { customRender: 'position' },
    },
    {
      title: 'Название',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Локаль',
      dataIndex: 'locale',
      key: 'locale',
    },
    {
      title: 'Действия',
      dataIndex: 'operation',
      scopedSlots: { customRender: 'operation' },
    },
  ];

  setPosition(pos: number) {
    if (pos === 1) {
      return 'Перед </body>';
    }
    return 'В <head></head>';
  }

  editCode(id: number) {
    this.$router.push(`/settings/external-code/${id}`);
  }

  removeCode(id: number) {
    const self = this;
    this.$confirm({
      title: 'Вы действительно хотите удалить данный код?',
      content: 'Вы не сможете восстановить данный код после удаления.',
      onOk() {
        self.removeCodeFetch(id);
      },
    });
  }

  removeCodeFetch(id: number) {
    settings.deleteExternalCode(id).then((res) => res.json()).then(({ success }) => {
      this.$emit('update-data');
      this.$message.success(success, 3);
    });
  }
}
