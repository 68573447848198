var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('PageTitle', {
    attrs: {
      "title": "Список категорий"
    }
  }), _c('a-layout-content', {
    staticClass: "page__content"
  }, [_c('a-row', [_c('a-card', {
    attrs: {
      "title": "Параметры для локали"
    }
  }, [_c('a-tabs', _vm._l(_vm.LOCALE_LIST, function (_ref) {
    var title = _ref.title,
      locale = _ref.locale;
    return _c('a-tab-pane', {
      key: locale,
      attrs: {
        "tab": title
      }
    }, [_c('a-col', {
      attrs: {
        "span": "6"
      }
    }, [_c('a-form-item', {
      attrs: {
        "label": "Название"
      }
    }, [_c('a-input', {
      attrs: {
        "size": "large",
        "placeholder": "Название"
      },
      model: {
        value: _vm.category.translations[locale].name,
        callback: function callback($$v) {
          _vm.$set(_vm.category.translations[locale], "name", $$v);
        },
        expression: "category.translations[locale].name"
      }
    })], 1)], 1)], 1);
  }), 1)], 1)], 1), _c('a-row', {
    attrs: {
      "gutter": 24
    }
  }, [_c('a-col', {
    attrs: {
      "span": "6"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "OS"
    }
  }, [_c('a-select', {
    attrs: {
      "size": "large",
      "placeholder": "OS"
    },
    on: {
      "change": _vm.onOsChange
    },
    model: {
      value: _vm.category.os_id,
      callback: function callback($$v) {
        _vm.$set(_vm.category, "os_id", $$v);
      },
      expression: "category.os_id"
    }
  }, _vm._l(_vm.operationSystems, function (os) {
    return _c('a-select-option', {
      key: os.id,
      attrs: {
        "value": os.id
      }
    }, [_vm._v(_vm._s(os.name) + " ")]);
  }), 1)], 1)], 1), _c('a-col', {
    attrs: {
      "span": "6"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "Родительская категория"
    }
  }, [_c('a-select', {
    attrs: {
      "size": "large",
      "placeholder": "Родительская категория"
    },
    model: {
      value: _vm.category.parent_id,
      callback: function callback($$v) {
        _vm.$set(_vm.category, "parent_id", $$v);
      },
      expression: "category.parent_id"
    }
  }, [_c('a-select-option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v(" Не выбрано ")]), _vm._l(_vm.filteredCategories, function (category) {
    return _c('a-select-option', {
      key: category.id,
      attrs: {
        "value": category.id
      }
    }, [_vm._v(" " + _vm._s(category.parent ? category.parent.name + ' / ' : '') + _vm._s(category.name) + " ")]);
  })], 2)], 1)], 1), _c('a-col', {
    attrs: {
      "span": "6"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "Переместить апы в"
    }
  }, [_c('a-select', {
    attrs: {
      "size": "large",
      "placeholder": "Переместить в"
    },
    model: {
      value: _vm.move_to,
      callback: function callback($$v) {
        _vm.move_to = $$v;
      },
      expression: "move_to"
    }
  }, [_c('a-select-option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v(" Не выбрано ")]), _vm._l(_vm.filteredCategoriesForMove, function (category) {
    return [_c('a-select-option', {
      key: category.id,
      attrs: {
        "value": category.id
      }
    }, [_vm._v(" " + _vm._s(category.parent ? category.parent.name + ' / ' : '') + _vm._s(category.name) + " ")]), _vm._l(category.children, function (subCategory) {
      return category.children ? [_c('a-select-option', {
        key: subCategory.id,
        attrs: {
          "value": subCategory.id
        }
      }, [_vm._v(" " + _vm._s(category.name) + " / " + _vm._s(subCategory.name) + " ")])] : _vm._e();
    })];
  })], 2)], 1)], 1)], 1), _c('a-button', {
    attrs: {
      "type": "primary",
      "size": "large"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v(" Сохранить ")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }