
// @ts-nocheck
import {Component, Vue} from 'vue-property-decorator';
import PageTitle from '@/components/Global/PageTitle.vue';
import {mapState, mapGetters} from 'vuex';
import {addVersionOsRoute} from '../routes';
import {deleteOsVersion} from '../../../api/Applications/osVersions';

@Component({
  name: 'VersionOs',
  components: {PageTitle},
  computed: {
    ...mapState(['operationSystemsVersions']),
    filteredList() {
      // @ts-ignore
      const query = this.query.toLowerCase();
      // @ts-ignore
      return this.operationSystemsVersions.filter((os) => os.name.toLowerCase().includes(query)
        || os.label.toLowerCase().includes(query)
        || os.seoName.toLowerCase().includes(query),
      );
    },
  },
  data() {
    return {
      query: '',
      addVersionOsRoute,
    };
  },
  methods: {
    deleteOsVersion(id: number) {
      this.$confirm({
        title: 'Вы действительно хотите удалить версию операционной системы?',
        content: 'Вы не сможете восстановить ее после удаления, только добавить еще раз.',
        onOk: async () => {
          await deleteOsVersion(id).then(() => {
            this.$message.success('Операционная система успешно удалена :)', 2);
            this.$store.dispatch('GET_OPERATION_SYSTEMS_VERSIONS');
          });
        },
      });
    },
  },
})
export default class VersionOs extends Vue {
  columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      sorter: true,
    },
    {
      title: 'ОС',
      dataIndex: 'operationSystem',
      key: 'os',
      sorter: true,
      scopedSlots: {customRender: 'os'},
    },
    {
      title: 'Версия(уникальная для ОС)\t',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
    },
    {
      title: 'Название (ru)',
      dataIndex: 'label',
      key: 'label',
      sorter: true,
    },
    {
      title: 'Название (en)',
      dataIndex: 'enLabel',
      key: 'enLabel',
      sorter: true,
    },
    {
      title: 'Действия',
      dataIndex: 'actions',
      key: 'actions',
      sorter: true,
      scopedSlots: {customRender: 'actions'},
    },
  ];
  private operationSystemsVersions: any[];
}
