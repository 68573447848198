<template>
  <div>
    <PageTitle :title="title"/>
    <a-layout-content
      :style="{
        margin: '24px 16px',
        padding: '24px',
        background: '#fff',
        minHeight: '280px'
      }"
    >
      <a-form>
        <a-row :gutter="24">
          <a-col span="8">
            <a-form-item label="Расположение">
              <a-select size="large" defaultValue="0" v-model="code.position">
                <a-select-option :value="1">Перед /body</a-select-option>
                <a-select-option :value="0">В тэг head</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col span="8">
            <a-form-item label="Название">
              <a-input
                size="large"
                v-model="code.name"
                placeholder="I'm the content"
              />
            </a-form-item>
          </a-col>
          <a-col span="8">
            <a-form-item label="Язык">
              <a-select size="large" v-model="code.locale">
                <a-select-option
                  v-for="{title, locale} in LOCALE_LIST"
                  :key="locale"
                  :value="locale"
                >
                  {{ title }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :span="24">
            <a-form-item size="large" label="Код">
              <a-textarea
                rows="20"
                v-model="code.content"
                placeholder="I'm the content"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-button
          type="primary"
          size="large"
          @click="saveCode"
          :disabled="buttonDisable"
        >{{ button }}</a-button>
      </a-form>
    </a-layout-content>
  </div>
</template>

<script>
import PageTitle from '@/components/Global/PageTitle.vue';
import { settings } from '@/api/';
import { LOCALE_LIST } from '@/global/constants';

export default {
  name: 'Add',

  components: { PageTitle },

  props: {
    id: {
      required: false,
    },
  },

  data() {
    return {
      title        : 'Добавление стороннего кода',
      button       : 'Сохранить',
      buttonDisable: false,
      LOCALE_LIST,
      code         : {
        id       : 0,
        position : 0,
        name     : '',
        content  : '',
        createdAt: '',
        updatedAt: '',
        locale   : '',
      },
    };
  },
  mounted() {
    if (this.id) {
      this.title = 'Редактирование кода';
      settings
        .getExternalCodeById(this.id)
        .then((res) => res.json())
        .then(({ data }) => {
          this.code = data;
        });
    }
  },
  methods: {
    saveCode() {
      this.buttonDisable = true;
      settings
        .setExternalCode(this.id, this.code)
        .then((res) => {
          const { status } = res;
          if (status === 201 || status === 200) {
            this.$message.success('Успешно сохранен');
            this.buttonDisable = false;
          }
          return res.json();
        })
        .then(({ code, data }) => {
          if (!code) { this.code = data; }
        })
        .catch(() => {
          this.$message.error('Сохранить не удалось');
          this.buttonDisable = false;
        });
    },
  },
};
</script>
