var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('PageTitle', {
    attrs: {
      "title": "Список категорий"
    }
  }, [_c('router-link', {
    attrs: {
      "to": _vm.CategorizationCategoryCreateRoute
    }
  }, [_c('a-button', {
    attrs: {
      "size": "large",
      "icon": "plus",
      "type": "primary"
    }
  }, [_vm._v("Добавить категорию ")])], 1)], 1), _c('a-layout-content', {
    staticClass: "page__content"
  }, [_c('h3', [_vm._v("Фильтр")]), _c('a-row', {
    attrs: {
      "gutter": 24
    }
  }, [_c('a-col', {
    attrs: {
      "span": "6"
    }
  }, [_c('a-select', {
    attrs: {
      "size": "large",
      "placeholder": "Выбрать OS"
    },
    model: {
      value: _vm.searchParams.os,
      callback: function callback($$v) {
        _vm.$set(_vm.searchParams, "os", $$v);
      },
      expression: "searchParams.os"
    }
  }, _vm._l(_vm.operationSystems, function (os) {
    return _c('a-select-option', {
      key: os.id,
      attrs: {
        "value": os.id
      }
    }, [_vm._v(" " + _vm._s(os.name) + " ")]);
  }), 1)], 1)], 1), _c('a-row', {
    staticStyle: {
      "padding-top": "50px"
    }
  }, [_c('a-col', {
    attrs: {
      "span": "12"
    }
  }, [_c('a-button', {
    attrs: {
      "size": "large"
    },
    on: {
      "click": _vm.resetFields
    }
  }, [_vm._v("Сбросить")])], 1), _c('a-col', {
    staticStyle: {
      "text-align": "right"
    },
    attrs: {
      "span": "12"
    }
  }, [_c('a-button', {
    staticStyle: {
      "margin-left": "20px"
    },
    attrs: {
      "size": "large",
      "type": "primary",
      "icon": "search"
    },
    on: {
      "click": _vm.handleSearch
    }
  }, [_vm._v("Фильтровать ")])], 1)], 1)], 1), _c('a-layout-content', {
    staticClass: "page__content"
  }, [_c('a-table', {
    attrs: {
      "columns": _vm.columns,
      "pagination": false,
      "dataSource": _vm.categories.data,
      "loading": _vm.loading,
      "rowKey": "id"
    },
    on: {
      "change": _vm.onChange
    },
    scopedSlots: _vm._u([{
      key: "parent",
      fn: function fn(parent) {
        return [_vm._v(" " + _vm._s(parent ? parent.name : '') + " ")];
      }
    }, {
      key: "os",
      fn: function fn(os) {
        return [_vm._v(" " + _vm._s(os.name) + " ")];
      }
    }, {
      key: "listing_action",
      fn: function fn(id, record) {
        return [_c('a-input-search', {
          staticStyle: {
            "max-width": "100px"
          },
          attrs: {
            "placeholder": "listing ids"
          },
          on: {
            "search": function search($event) {
              return _vm.saveListing(record.id, {
                listing_ids: record.listing_ids
              });
            }
          },
          model: {
            value: record.listing_ids,
            callback: function callback($$v) {
              _vm.$set(record, "listing_ids", $$v);
            },
            expression: "record.listing_ids"
          }
        }, [_c('a-button', {
          staticClass: "gray-btn",
          attrs: {
            "slot": "enterButton",
            "type": "primary",
            "icon": "save"
          },
          slot: "enterButton"
        })], 1)];
      }
    }, {
      key: "actions",
      fn: function fn(id, record) {
        return [_c('a-row', {
          attrs: {
            "type": "flex"
          }
        }, [_c('a-col', [_c('a-button', {
          staticClass: "gray-btn",
          staticStyle: {
            "margin-right": "10px"
          },
          attrs: {
            "type": "primary",
            "icon": "up",
            "title": "Поднять на позицию вверх"
          },
          on: {
            "click": function click($event) {
              return _vm.changePosition(record, -1);
            }
          }
        }), _c('a-button', {
          staticClass: "gray-btn",
          staticStyle: {
            "margin-right": "10px"
          },
          attrs: {
            "type": "primary",
            "icon": "down",
            "title": "Опустить на позицию вниз"
          },
          on: {
            "click": function click($event) {
              return _vm.changePosition(record, 1);
            }
          }
        }), _c('router-link', {
          attrs: {
            "to": {
              path: "/categorization/categories/".concat(record.id)
            }
          }
        }, [_c('a-button', {
          staticClass: "gray-btn",
          staticStyle: {
            "margin-right": "10px"
          },
          attrs: {
            "type": "primary",
            "icon": "edit"
          }
        })], 1), _c('a-button', {
          staticClass: "gray-btn",
          attrs: {
            "type": "danger",
            "icon": "delete"
          },
          on: {
            "click": function click($event) {
              return _vm.deleteCategory(record.id);
            }
          }
        })], 1)], 1)];
      }
    }])
  }), _c('a-row', {
    staticStyle: {
      "text-align": "right"
    }
  }, [_vm.categories.meta ? _c('a-pagination', {
    staticStyle: {
      "margin-top": "20px"
    },
    attrs: {
      "total": _vm.categories.meta.total,
      "pageSize": _vm.categories.meta.per_page
    },
    on: {
      "change": _vm.paginationChange
    },
    model: {
      value: _vm.categories.meta.current_page,
      callback: function callback($$v) {
        _vm.$set(_vm.categories.meta, "current_page", $$v);
      },
      expression: "categories.meta.current_page"
    }
  }) : _vm._e()], 1)], 1), _c('a-modal', {
    attrs: {
      "title": "Описание"
    },
    on: {
      "ok": function ok($event) {
        _vm.showDescription = false;
      }
    },
    model: {
      value: _vm.showDescription,
      callback: function callback($$v) {
        _vm.showDescription = $$v;
      },
      expression: "showDescription"
    }
  }, [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.showDescription)
    }
  })])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }