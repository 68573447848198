import { get, put } from './core';

/**
 * @description Получить список программ для модерации
 * @return {Promise<any>}
 */
const getModerationAppsList = (params: {} = {}): Promise<any> =>
  get(`/api/admin/moderation`, params);

/**
 * @description /api/admin/moderation/search
 * @param {object} params
 * @return {Promise<any>}
 */
const sortModerationApps = (params: any): Promise<any> =>
  get('/api/admin/moderation/search', params);

/**
 * @description  Получение истории модерации для приложения
 * @param {number} appId
 * @return {Promise<any>}
 */
const getModerationHistoryByAppId = (appId: number): Promise<any> =>
  get(`/api/admin/moderation/${appId}`);

/**
 * @description Обновление модерации
 * @param {number} appId
 * @param {object} params
 * @return {Promise<any>}
 */
const updateModerationStatus = (
  appId: number,
  params: any,
): Promise<any> => put(`/api/admin/moderation/${appId}`, params);


export const Moderation = {
  getModerationAppsList,
  sortModerationApps,
  getModerationHistoryByAppId,
  updateModerationStatus,
};
