<template>
  <div>
    <a-menu
      v-if="menuList.length"
      mode="inline"
      theme="dark"
      :selected-keys="activeMenuItems.selected"
      :default-open-keys="activeMenuItems.opened"
    >
      <template v-for="menu in menuList">
        <a-menu-item
          v-if="!menu.hasOwnProperty('subMenu')"
          :key="menu.key"
        >
          <a-icon :type="menu.icon"/>
          <span>{{ menu.title }}</span>
          <router-link :to="menu.link">{{ menu.title }}</router-link>
        </a-menu-item>

        <a-sub-menu
          v-else
          :key="menu.key"
        >
        <span slot="title">
          <a-icon :type="menu.icon"/>
          <span>{{ menu.title }}</span>
        </span>
          <a-menu-item v-for="menuItem in menu.subMenu" :key="menuItem.key">
            <router-link :to="menuItem.link"> {{ menuItem.title }}</router-link>
          </a-menu-item>
        </a-sub-menu>
      </template>

      <a-menu-item
        v-if="user.hasAdmin || user.hasEditor"
        @click="flushCache"
      >
        <a-icon type="delete"/>
        Сбросить кеш
      </a-menu-item>
    </a-menu>
  </div>
</template>

<script>
import cacheApi from '../../api/cache';
import { mapState } from 'vuex';

export default {
  name : 'Menu',
  props: {
    msg: String,
  },
  data() {
    return {
      collapsed: true,
    };
  },
  computed: {
    ...mapState(['user', 'menuList']),
    activeMenuItems() {
      const active = {
        selected: [],
        opened: [],
      };

      if (!this.menuList.length) {
        return active;
      }

      for (const menu of this.menuList) {
        if (menu.link === this.$route.path) {
          active.selected.push(menu.key)
        }

        if (menu.hasOwnProperty('subMenu')) {
          for (const subMenu of menu.subMenu) {
            if (subMenu.link === this.$route.path) {
              active.selected.length = 0;
              active.selected.push(subMenu.key)
              active.opened.push(menu.key)
            } else if (this.$route.path.startsWith(subMenu.link)) {
              active.selected.length = 0;
              active.selected.push(subMenu.key)
              active.opened.push(menu.key)
            }
          }
        }
      }

      return active;
    },
  },
  methods: {
    flushCache() {
      if (false === confirm('Вы уверены что хотите сбросить кеш?')) {
        return;
      }

      cacheApi.flush();
    },
  },
};
</script>

<style scoped lang="scss">
.ant-menu {
  height: 100% !important;
  min-height: 100vh;
}
</style>
