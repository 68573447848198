import { Route } from '@/types/route';
import StaticPages from './List.vue';
import AddStaticPage from './Add.vue';

export const staticPagesRoute = '/static-pages';
export const addStaticPageRoute = '/static-pages/add';
export const editStaticPageRoute = '/static-pages/:id/change';

export const staticPagesRoutes: Route[] = [
  {
    path: staticPagesRoute,
    component: StaticPages,
    name: 'StaticPages',
    breadcrumbName: 'Статичные страницы',
    meta: {
      title: 'Статичные страницы',
    },
  },
  {
    path: addStaticPageRoute,
    component: AddStaticPage,
    name: 'AddStaticPage',
    breadcrumbName: 'Добавить статичную страницу',
    meta: {
      title: 'Добавить статичную страницу',
    },
  },
  {
    path: editStaticPageRoute,
    component: AddStaticPage,
    name: 'EditStaticPage',
    props: true,
    breadcrumbName: 'Редактировать статичную страницу',
    meta: {
      title: 'Редактировать статичную страницу',
    },
  },
];

export default { staticPagesRoutes };
