<template>
  <a-row
    gutter="24"
    style="margin-top: 15px"
  >
    <a-col>
      <h3 style="margin-top: 20px">Блок преимущества</h3>

      <a-row
        v-for="(benefit, index) in benefits"
        :key="index"
      >
        <a-col span="6">
          <a-input placeholder="Название" v-model="benefit.name"/>
        </a-col>
        <a-col span="6">
          <a-input placeholder="Значение" v-model="benefit.value"/>
        </a-col>
        <a-col span="6">
          <a-button type="danger" icon="delete" @click="remove(index)"/>
        </a-col>
      </a-row>

      <a-row>
        <a-button type="primary" @click="add">Добавить</a-button>
      </a-row>
    </a-col>
  </a-row>
</template>

<script>

function getDefaultBenefit() {
  return {
    name : '',
    value: '',
  };
}

export default {
  name : 'AppBenefits',
  props: {
    benefits: {
      required: true,
      type    : Array,
    },
  },
  methods: {
    add() {
      this.$emit('add', getDefaultBenefit());
      // this.benefits.push(getDefaultBenefit());
    },
    remove(index) {
      this.benefits.splice(index, 1);
    },
  },
};
</script>

<style scoped>
.ant-row + .ant-row {
  margin-top: 10px;
}

.ant-col + .ant-col {
  margin-left: 15px;
}
</style>
