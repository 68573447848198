import {get, post, put, deleteMethod} from '../core';

/**
 * @description Список версий операционных систем
 * @return {Promise<any>}
 */
export const getTagListings = (id: number): Promise<any> =>
  get(`/api/admin/apps/${id}/tag_listings`);

export const attachTagListing = (id: number, tagId: number): Promise<any> =>
  post(`/api/admin/apps/${id}/tag_listings/${tagId}`);

export const detachTagListing = (id: number, tagId: number): Promise<any> =>
  deleteMethod(`/api/admin/apps/${id}/tag_listings/${tagId}`);

export default {
  getTagListings,
  attachTagListing,
  detachTagListing,
};
