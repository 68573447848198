
// @ts-nocheck
import Vue from 'vue';
import PageTitle from '@/components/Global/PageTitle.vue';
import { articles } from '@/api/articles';
import { pickBy } from 'lodash';

export default Vue.extend({
  components: {
    PageTitle,
  },
  props: ['id'],

  mounted() {
    if (this.id) {
      this.title = 'Редактировать категорию';
      this.action = 'Сохранить';
      this.hasLoadArticlesCategories = true;
      articles
        .getArticleCategoryById(this.id)
        .then((res) => res.json())
        .then(({ data }) => {
          this.baseCategory = data;
          this.hasLoadArticlesCategories = false;
          const { active } = data;
          this.active = Boolean(active);
        });
    } else {
      this.title = 'Создать категорию';
      this.action = 'Создать';
    }

    this.getArticleCategories();
    window.scrollTo(0, 0);
  },

  data() {
    return {
      form: this.$form.createForm(this),
      articlesCategories: [],
      baseCategory: {},
      hasLoadArticlesCategories: false,
      active: false,
      action: '',
      title: '',
    };
  },

  methods: {
    async handleSubmit(): Promise<any> {
      const fields = pickBy({
        ...this.form.getFieldsValue(),
      });

      if (this.id) {
        await articles
          .updateCategoryArticle(this.id, {
            ...fields,
            active: Number(this.active),
          })
          .then(() => {
            this.$message.success('Успешно отредактировано!');
          });
      } else {
        await articles
          .createCategoryArticle({
            ...fields,
            active: Number(this.active),
          })
          .then(() => {
            this.$message.success('Успешно создано!');
          });
      }
    },

    /**
     * @description Получение категорий статей
     * @return {Promise<any>}
     */
    async getArticleCategories(): Promise<any> {
      this.hasLoadArticlesCategories = true;
      await articles
        .getArticleCategory()
        .then((res: Response) => res.json())
        .then(({ data }) => {
          this.articlesCategories = data;
          this.hasLoadArticlesCategories = false;
        });
    },
  },
});
