import { Route } from '@/types/route';
import Edit from './Edit.vue';

export const editRoute = '/settings/file-edit';

export const editFileRoutes: Route[] = [
    {
        path: editRoute,
        name: 'editFileEditor',
        component: Edit,
        breadcrumbName: 'Редактирование файлов',
        meta: {
            title: 'Редактирование файлов',
        },
    },
];

export default { editFileRoutes };
