import Landing from './Landing.vue';
export const landingRoute = '/landing';

export const landingRoutes = [
  {
    path: landingRoute,
    component: Landing,
    name: 'Landing',
    breadcrumbName: 'Лэндинги',
    meta: {
      title: 'Лэндинги',
    },
  },
];

export default { landingRoutes };
