
// @ts-nocheck
/* tslint:disable */
import Vue from 'vue';
import PageTitle from '@/components/Global/PageTitle.vue';
import TopMenu from './TopMenu.vue';
import {isEmpty} from 'lodash';
import {
  addDescriptionRoute,
  addFilesRoute,
  addImagesRoute,
  addSeoRoute,
} from '@/components/Applications/routes';
import {applications} from '@/api';
// @ts-ignore
import {getFormData} from '../../../helpers';
import {ApplicationFile} from '@/types/applications';
import ModerationTop from '@/components/Moderation/ModerationTop.vue';
import {pickBy, first} from 'lodash';
import {mapState} from 'vuex';
import DeveloperModeration from '@/components/Moderation/DeveloperModeration.vue';
import AddPageTitle from "@/components/Applications/Add/AddPageTitle.vue";
import {
  LOCALE_DOMAIN_LIST,
  LOCALE_TO_DOMAIN,
  OS_SEO_NAME_LINUX,
  OS_SEO_NAME_MAC_OS,
  OS_SEO_NAME_WINDOWS
} from "@/global/constants";
import FileInfoModal from "@/components/Applications/Add/FileInfoModal.vue";

export default Vue.extend({
  components: {
    FileInfoModal,
    AddPageTitle,
    PageTitle,
    TopMenu,
    ModerationTop,
    DeveloperModeration,
  },
  props: ['id', 'moderationId'],
  data() {
    return {
      addFileForms: [0],
      title: 'Добавить файлы',
      activeTab: '1',
      action: 'Добавить',
      // @ts-ignore
      form: this.$form.createForm(this, {name: 'files_pc'}),
      // @ts-ignore
      partnerForm: this.$form.createForm(this, {name: 'files_partner'}),
      hasEditMode: false,
      selectedRowKeys: [],
      fileList: [],
      fileControlledList: [],
      loading: false,
      operationSystemSeoName: '',
      currentEditFileId: 0,
      architectureOptions: [
        {
          label: 'X32',
          value: 'x32',
        },
        {
          label: 'X64',
          value: 'x64',
        },
      ],
      routes: {
        addDescriptionRoute,
        addFilesRoute,
        addImagesRoute,
        addSeoRoute,
      },
      CLEAR_FIELDS: [
        'file',
        'version',
        'hasCapacity64',
        'hasCapacity86',
        'assembly',
        'changesInVersion',
        'descriptionVersion',
      ],
      fileColumns: [
        {
          title: 'Файл (отметьте последнюю версию)',
          dataIndex: 'name',
          key: 'file',
        },
        {
          title: 'Версия',
          dataIndex: 'version',
          key: 'version',
        },
        {
          title: 'Размер',
          dataIndex: 'size',
          key: 'size',
          scopedSlots: {customRender: 'size'},
        },
        {
          title: 'Доступно для',
          dataIndex: 'availabilities',
          key: 'availabilities',
          scopedSlots: {customRender: 'availabilities'},
        },
        {
          title: 'Разрядность',
          dataIndex: 'architecture',
          key: 'architecture',
        },
        {
          title: 'Сборка',
          dataIndex: 'assembly',
          key: 'assembly',
        },
        {
          title: 'Описание',
          dataIndex: 'description',
          key: 'description',
          width: 300,
        },
        {
          title: '',
          key: 'actions',
          scopedSlots: {customRender: 'actions'},
        },
      ],
      fileInfo: null,
      availabilityOptions: LOCALE_DOMAIN_LIST,
    };
  },

  computed: {
    LOCALE_TO_DOMAIN() {
      return LOCALE_TO_DOMAIN;
    },
    ...mapState(['user']),
    hasArchitecture(): boolean {
      return [OS_SEO_NAME_WINDOWS, OS_SEO_NAME_MAC_OS, OS_SEO_NAME_LINUX].includes(this.operationSystemSeoName);
    }
  },

  mounted() {
    if (this.moderationId) {
      this.title = 'Модерация приложения';
    }
    this.getApplicationById();
  },

  methods: {
    handleAddFile() {
      this.addFileForms = [...this.addFileForms, this.addFileForms.length + 1];
    },

    onDeleteFile(id: number) {
      this.addFileForms = [...this.addFileForms].filter((num) => num !== id);
    },

    async setLastFile(file: any): Promise<any> {
      const {id: fileId} = file;
      applications
        .updateAppFile(
          fileId,
          getFormData({isLast: 1, _method: 'PUT'}, ''),
        )
        .then(
          async (res: Response): Promise<any> => {
            if (res.status === 200) {
              this.$message.info(
                'Версия успешно установленна как последняя :)',
                2,
              );
              await this.getApplicationById();
            }
            if (res.status === 500) {
              this.$message.error('Ошибка установления версии :(', 2);
            }
          },
        );
    },

    onCancelEdit() {
      this.partnerForm.resetFields();
      this.form.resetFields();
      this.hasEditMode = false;
      window.scrollTo(0, 0);
    },

    addFile() {
      this.form.validateFieldsAndScroll(async (errors: any, values: any) => {
        if (isEmpty(errors)) {
          let data;

          const getFilesFromForm = () => {
            const files: any = [];
            this.addFileForms.forEach((formNum: number) => {
              for (const i in values) {
                const value = values[i];

                if (value instanceof Array) {
                  value.forEach((val, index) => {
                    if (index === formNum) {
                      files[formNum] = {...files[index], [`${i}`]: val};
                    }
                  });
                }
              }
            });
            if (this.hasEditMode) {
              return first(files);
            }
            return files.filter(Boolean);
          };

          /**
           * @description Форматирование массива разрядности (only windows)
           * @param file
           * @return {string | null}
           */
          const formatArchitecture = (file: any): string | null => {
            if (file.architecture && file.architecture.length) {
              return file.architecture.join('/');
            } else {
              return null;
            }
          };

          // Если мы находимся формате редактирования то можем редактировать за раз 1 файл
          if (this.hasEditMode) {
            const editFormValues = getFilesFromForm();

            data = getFormData(
              {
                ...editFormValues,
                file: editFormValues.file && editFormValues.file.file,
                architecture: formatArchitecture(editFormValues),
                _method: 'PUT',
              },
              '',
            );

            this.loading = true;

            applications
              .updateAppFile(this.currentEditFileId, data)
              .then(async (res: Response) => {
                if (res.status === 200 || res.status === 201) {
                  this.$message.info('Файл успешно обновлен :)', 2);
                  await this.getApplicationById();
                  this.hasEditMode = false;
                }
                if (res.status === 500) {
                  this.$message.error('Ошибка обновления файла :(', 2);
                }
              }).finally(() => this.loading = false);
          } else {
            // Иначе, мы находимся в формате добавления файлов и можем добавить сразу несколько
            data = getFormData(
              {
                files: [...getFilesFromForm()].map((file) => {
                  let attachFile = {};
                  if (file.file) {
                    attachFile = file.file.file;
                  }
                  return pickBy({
                    ...file,
                    architecture: formatArchitecture(file),
                    file: attachFile,
                  });
                }),
              },
              '',
            );

            this.loading = true;
            applications
              .addAppFile(this.id || this.moderationId, data)
              .then(async (res: Response) => {
                if (res.status === 200 || res.status === 201) {
                  this.$message.info('Файл успешно добавлен :)', 2);
                  await this.getApplicationById();
                  if (!this.id) {
                    this.$router.push(`/images/${this.id}/edit`);
                  }
                  if (this.moderationId) {
                    this.$router.push(
                      `/moderation/${this.moderationId}/images`,
                    );
                  }

                  // hack to reset a-upload file lise
                  this.addFileForms = [];
                  setTimeout(() => this.addFileForms = [0]);
                }
                if (res.status === 500) {
                  this.$message.error('Ошибка добавления файла :(', 2);
                }
              }).finally(() => this.loading = false);
          }
        }
      });
    },

    addPartnerFile() {
      this.partnerForm.validateFieldsAndScroll((errors: any, values: any) => {
        if (isEmpty(errors)) {
          let formatData;
          let data;

          const {id: fileId} = values;

          if (this.hasEditMode) {
            formatData = {
              ...values,
              downloadUrl: values.link,
              _method: 'PUT',
            };
            data = getFormData(formatData, '');

            this.loading = true;
            applications
              .updateAppFile(fileId, data)
              .then(async (res: Response) => {
                if (res.status === 200 || res.status === 201) {
                  this.$message.info('Партнерский успешно обновлен :)', 2);
                  window.scrollTo(0, 0);
                  await this.getApplicationById();
                  this.hasEditMode = false;
                }

                if (res.status === 500) {
                  this.$message.error(
                    'Ошибка обновления партнерского файла :(',
                    2,
                  );
                }
              }).finally(() => this.loading = false);
          } else {
            formatData = {partner_links: [{...values}]};
            data = getFormData(formatData, '');

            applications
              .addAppFile(this.id || this.moderationId, data)
              .then(async (res: Response) => {
                if (res.status === 200 || res.status === 201) {
                  this.$message.info('Партнерский файл успешно добавлен :)', 2);
                  this.$router.push(
                    `/images/${this.id || this.moderationId}/edit`,
                  );
                  await this.getApplicationById();
                }
                if (res.status === 500) {
                  this.$message.error(
                    'Ошибка добавления партнерского файла :(',
                    2,
                  );
                }
              });
          }
        }
      });
    },

    /**
     * @description Удаление файла у приложения
     * @param {number} id - id файла
     * @return {Promise<any>}
     */
    async deleteApplicationFileById(id: number): Promise<any> {
      await applications.deleteAppFile(id).then(async (res: Response) => {
        if (res.status === 200) {
          this.$message.info('Файл успешно удален :)', 2);
          await this.getApplicationById();
        }
      });
    },

    /**
     * @description Получение приложения по id
     * @return {Promise<any>}
     */
    async getApplicationById(): Promise<any> {
      this.loading = true;
      this.selectedRowKeys = [];
      await applications
        .getAppById(this.id || this.moderationId)
        .then((res) => res.json())
        .then(({data}) => {
          const {
            files,
            operationSystem: {seo_name},
          } = data;
          this.operationSystemSeoName = seo_name;

          this.fileList = files.map((file: ApplicationFile) => {
            if (file.type === 'outside_link') {
              return {
                ...file,
                name: file.downloadUrl,
              };
            }
            return file;
          });
          this.loading = false;
          this.fileList.forEach((file: any, index) => {
            if (file.isLast === 1) {
              // @ts-ignore
              this.selectedRowKeys.push(file.id);
            }
          });
          this.partnerForm.resetFields();
          this.form.resetFields();
        });
    },

    handleDeleteFile(id: number) {
      this.$confirm({
        title: 'Вы действительно хотите удалить добавленный файл?',
        content:
          'Вы не сможете восстановить файл после удаления, только добавить его еще раз.',
        onOk: async () => {
          await this.deleteApplicationFileById(id);
        },
      });
    },

    beforeUpload() {
      return false;
    },

    onSelect(record: ApplicationFile) {
      this.$confirm({
        title:
          'Вы действительно хотите установить данную версию как последнюю ?',
        onOk: async () => {
          await this.setLastFile(record);
        },
      });
    },

    onSelectChange(selectedRowKeys: number[]) {
      // @ts-ignore
      this.selectedRowKeys = selectedRowKeys;
    },

    onEditFile(file: any) {
      const {downloadUrl} = file;

      // applicationFileType
      if (file.type === 'outside_link' || file.type === 'link' ) {
        this.activeTab = '2';
        this.partnerForm.setFieldsValue({...file, link: downloadUrl});
        this.partnerForm.setFieldsValue({availabilities: file.availabilities.map(availability => availability.locale)});
        this.form.resetFields();
      } else if (file.type === 'file' || file.type === 'link_saved') {
        this.activeTab = '1';
        this.currentEditFileId = file.id;

        for (const key in file) {
          const value = file[key];

          if (key === 'architecture') {
            this.form.setFieldsValue({
              [`${key}[0]`]: file.architecture.length
                ? [...file.architecture.split('/')]
                : '',
            });
          } else if (key === 'availabilities') {
            this.form.setFieldsValue({[`${key}[0]`]: value.map(availability => availability.locale)});
          } else {
            this.form.setFieldsValue({[`${key}[0]`]: value});
          }
        }

        this.partnerForm.resetFields();
      }
      this.hasEditMode = true;
    },
  },
});
