import { get, post, put, deleteMethod } from '../core';

/**
 * @description Список версий операционных систем
 * @return {Promise<any>}
 */
export const getOsVersions = (): Promise<any> =>
  get('/api/admin/operation-system-versions', {limit: 999});

/**
 * @description Получить версию операционной системы по id
 * @param {number} id
 * @return {Promise<any>}
 */
export const getVersionOsById = (id: number): Promise<any> =>
  get(`/api/admin/operation-system-versions/${id}`);

/**
 * @description Создании версии OS
 * @param {object} params
 * @return {Promise<any>}
 */
export const createOsVersion = (params: any): Promise<any> =>
  post('/api/admin/operation-system-versions', params);

/**
 * @description Обновление версии os
 * @param {number} id
 * @param {object} params
 * @return {Promise<any>}
 */
export const updateOsVersion = (id: number, params: any): Promise<any> =>
  put(`/api/admin/operation-system-versions/${id}`, params);

/**
 * @description Удаление версии OS
 * @param {number} id
 * @return {Promise<any>}
 */
export const deleteOsVersion = (id: number): Promise<any> =>
  deleteMethod(`/api/admin/operation-system-versions/${id}`);

export default {
  getOsVersions,
  getVersionOsById,
  createOsVersion,
  updateOsVersion,
  deleteOsVersion,
};
