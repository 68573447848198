var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a-modal', {
    attrs: {
      "title": "Ответить на комментарий",
      "visible": "",
      "confirm-loading": _vm.loading
    },
    on: {
      "ok": _vm.save,
      "cancel": _vm.close
    }
  }, [_c('p', [_vm._v(_vm._s(_vm.review.text))]), _c('a-form', {
    attrs: {
      "form": _vm.form
    },
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.save.apply(null, arguments);
      }
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "Ответ"
    }
  }, [_c('a-textarea', {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ['text', {
        rules: [{
          required: true,
          message: 'Введите ваш ответ'
        }]
      }],
      expression: "['text', { rules: [{ required: true, message: 'Введите ваш ответ' }] }]"
    }],
    attrs: {
      "rows": "4",
      "required": ""
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }