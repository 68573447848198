var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('PageTitle', {
    attrs: {
      "title": "Позиции рекламных блоков"
    }
  }, [_c('router-link', {
    attrs: {
      "to": "/advert/position/add"
    }
  }, [_c('a-button', {
    staticStyle: {
      "margin-right": "24px"
    },
    attrs: {
      "size": "large",
      "type": "primary"
    }
  }, [_vm._v("Добавить")])], 1)], 1), _c('a-layout-content', {
    staticClass: "page__content"
  }, [_c('a-row', [_c('h3', [_vm._v("Поиск")])]), _c('a-row', {
    attrs: {
      "gutter": 24
    }
  }, [_c('a-col', {
    attrs: {
      "span": "12"
    }
  }, [_c('a-input', {
    attrs: {
      "size": "large",
      "placeholder": "Введите описание позиции"
    },
    on: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.searchPositions.apply(null, arguments);
      }
    },
    model: {
      value: _vm.label,
      callback: function callback($$v) {
        _vm.label = $$v;
      },
      expression: "label"
    }
  })], 1), _c('a-col', {
    attrs: {
      "span": "12"
    }
  }, [_c('a-input', {
    attrs: {
      "size": "large",
      "placeholder": "Введите тип страницы"
    },
    on: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.searchPositions.apply(null, arguments);
      }
    },
    model: {
      value: _vm.type,
      callback: function callback($$v) {
        _vm.type = $$v;
      },
      expression: "type"
    }
  })], 1), _c('a-col', {
    staticStyle: {
      "margin-top": "25px"
    },
    attrs: {
      "span": "24"
    }
  }, [_c('a-button', {
    staticStyle: {
      "margin-right": "25px"
    },
    attrs: {
      "type": "primary",
      "size": "large",
      "icon": "search"
    },
    on: {
      "click": _vm.searchPositions
    }
  }, [_vm._v("Поиск")]), _c('a-button', {
    attrs: {
      "size": "large"
    },
    on: {
      "click": _vm.clearFilter
    }
  }, [_vm._v("Очистить")])], 1)], 1)], 1), _c('a-layout-content', {
    staticClass: "page__content"
  }, [_c('a-table', {
    attrs: {
      "columns": _vm.columns,
      "loading": _vm.hasLoadPositions,
      "dataSource": _vm.positions,
      "rowKey": "id"
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn(operation, record) {
        return [_c('router-link', {
          attrs: {
            "to": {
              path: "/advert/position/".concat(record.id, "/change")
            }
          }
        }, [_c('a-button', {
          staticClass: "gray-btn",
          staticStyle: {
            "margin-right": "10px"
          },
          attrs: {
            "type": "primary",
            "icon": "edit"
          }
        })], 1)];
      }
    }])
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }