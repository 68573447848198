var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('PageTitle', {
    attrs: {
      "title": "Пользователи"
    }
  }, [_c('router-link', {
    attrs: {
      "to": "/users/add"
    }
  }, [_c('a-button', {
    attrs: {
      "size": "large",
      "type": "primary",
      "icon": "plus"
    }
  }, [_vm._v("Добавить пользователя")])], 1)], 1), _c('a-layout-content', {
    staticClass: "page__content"
  }, [_c('a-row', [_c('h3', [_vm._v("Поиск")])]), _c('a-row', {
    attrs: {
      "gutter": 24
    }
  }, [_c('a-col', {
    attrs: {
      "span": "4"
    }
  }, [_c('a-input', {
    attrs: {
      "size": "large",
      "placeholder": "Email"
    },
    on: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.searchUsers.apply(null, arguments);
      }
    },
    model: {
      value: _vm.filter.email,
      callback: function callback($$v) {
        _vm.$set(_vm.filter, "email", $$v);
      },
      expression: "filter.email"
    }
  })], 1), _c('a-col', {
    attrs: {
      "span": "4"
    }
  }, [_c('a-input', {
    attrs: {
      "size": "large",
      "placeholder": "Имя"
    },
    on: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.searchUsers.apply(null, arguments);
      }
    },
    model: {
      value: _vm.filter.name,
      callback: function callback($$v) {
        _vm.$set(_vm.filter, "name", $$v);
      },
      expression: "filter.name"
    }
  })], 1), _c('a-col', {
    attrs: {
      "span": "4"
    }
  }, [_c('a-select', {
    attrs: {
      "size": "large",
      "defaultValue": "",
      "placeholder": "Выберите роль"
    },
    model: {
      value: _vm.filter.role,
      callback: function callback($$v) {
        _vm.$set(_vm.filter, "role", $$v);
      },
      expression: "filter.role"
    }
  }, [_c('a-select-option', {
    attrs: {
      "value": "developer"
    }
  }, [_vm._v("Разработчик")]), _c('a-select-option', {
    attrs: {
      "value": "user"
    }
  }, [_vm._v("Пользователь")]), _c('a-select-option', {
    attrs: {
      "value": "content_manager"
    }
  }, [_vm._v("Контент менеджер")]), _c('a-select-option', {
    attrs: {
      "value": "editor"
    }
  }, [_vm._v("Редактор")]), _c('a-select-option', {
    attrs: {
      "value": "admin"
    }
  }, [_vm._v("Администратор")]), _c('a-select-option', {
    attrs: {
      "value": "seo"
    }
  }, [_vm._v("SEO специалист")]), _c('a-select-option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("Не выбрано")])], 1)], 1), _c('a-col', {
    attrs: {
      "span": "5"
    }
  }, [_c('a-select', {
    attrs: {
      "size": "large",
      "placeholder": "Выберите статус"
    },
    model: {
      value: _vm.filter.active,
      callback: function callback($$v) {
        _vm.$set(_vm.filter, "active", $$v);
      },
      expression: "filter.active"
    }
  }, [_c('a-select-option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("Выберите статус")]), _c('a-select-option', {
    attrs: {
      "value": "0"
    }
  }, [_vm._v("Заблокирован")]), _c('a-select-option', {
    attrs: {
      "value": "1"
    }
  }, [_vm._v("Активен")]), _c('a-select-option', {
    attrs: {
      "value": "2"
    }
  }, [_vm._v("Удален")])], 1)], 1), _c('a-col', {
    attrs: {
      "span": "6"
    }
  }, [_c('a-checkbox', {
    model: {
      value: _vm.filter.officialRepresentative,
      callback: function callback($$v) {
        _vm.$set(_vm.filter, "officialRepresentative", $$v);
      },
      expression: "filter.officialRepresentative"
    }
  }, [_vm._v(" Официальный Представитель ")])], 1)], 1), _c('a-row', {
    attrs: {
      "gutter": 24
    }
  }, [_c('a-col', {
    attrs: {
      "span": "6"
    }
  }, [_c('a-range-picker', {
    attrs: {
      "placeholder": ['Начало', 'Конец'],
      "size": "large"
    },
    model: {
      value: _vm.dates,
      callback: function callback($$v) {
        _vm.dates = $$v;
      },
      expression: "dates"
    }
  })], 1), _c('a-col', {
    staticStyle: {
      "margin-top": "25px"
    },
    attrs: {
      "span": "24"
    }
  }, [_c('a-button', {
    staticStyle: {
      "margin-right": "25px"
    },
    attrs: {
      "size": "large",
      "type": "primary",
      "icon": "search"
    },
    on: {
      "click": _vm.searchUsers
    }
  }, [_vm._v("Поиск ")]), _c('a-button', {
    attrs: {
      "size": "large"
    },
    on: {
      "click": _vm.resetFilter
    }
  }, [_vm._v("Очистить")])], 1)], 1)], 1), _c('a-layout-content', {
    staticClass: "page__content"
  }, [_c('a-table', {
    attrs: {
      "columns": _vm.columns,
      "loading": _vm.hasLoadUsers,
      "dataSource": _vm.users.data,
      "pagination": false,
      "rowKey": "id"
    },
    on: {
      "change": _vm.onChange
    },
    scopedSlots: _vm._u([{
      key: "email",
      fn: function fn(user) {
        return _c('span', {}, [_vm._v(" " + _vm._s(user.email) + " "), user.deletedAt ? _c('a-tag', {
          attrs: {
            "color": "red"
          }
        }, [_vm._v("удален")]) : _vm._e()], 1);
      }
    }, {
      key: "name",
      fn: function fn(user) {
        return [_c('a', {
          attrs: {
            "href": user.url,
            "target": "_blank"
          }
        }, [_vm._v(_vm._s(user.name))])];
      }
    }, {
      key: "operation",
      fn: function fn(user) {
        return [_c('router-link', {
          attrs: {
            "to": {
              path: "/users/change-user/".concat(user.id)
            }
          }
        }, [_c('a-button', {
          staticClass: "gray-btn",
          attrs: {
            "type": "primary",
            "icon": "edit"
          }
        })], 1), _c('a-tooltip', [_c('template', {
          slot: "title"
        }, [_vm._v(" Login as user ")]), _c('a', {
          attrs: {
            "href": "".concat(_vm.APP_URL, "/api/admin/users/login-as?token=").concat(_vm.token, "&id=").concat(user.id),
            "target": "_blank"
          }
        }, [!user.deletedAt ? _c('a-button', {
          staticClass: "gray-btn",
          staticStyle: {
            "margin-left": "5px"
          },
          attrs: {
            "type": "default",
            "icon": "login"
          }
        }) : _vm._e()], 1)], 2), !user.deletedAt ? _c('a-button', {
          staticClass: "gray-btn",
          staticStyle: {
            "margin-left": "5px"
          },
          attrs: {
            "type": "danger",
            "icon": "delete"
          },
          on: {
            "click": function click($event) {
              return _vm.deleteUser(user);
            }
          }
        }) : _vm._e()];
      }
    }])
  }), _c('a-row', {
    staticStyle: {
      "text-align": "left"
    }
  }, [_vm.users.meta ? _c('a-pagination', {
    staticStyle: {
      "margin-top": "20px"
    },
    attrs: {
      "total": _vm.users.meta.total,
      "pageSize": _vm.users.meta.per_page
    },
    on: {
      "change": _vm.paginationChange
    },
    model: {
      value: _vm.users.meta.current_page,
      callback: function callback($$v) {
        _vm.$set(_vm.users.meta, "current_page", $$v);
      },
      expression: "users.meta.current_page"
    }
  }) : _vm._e()], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }