
// @ts-nocheck
import Vue from 'vue';
import { mapState } from 'vuex';
import { Developer } from '@/api/developer';
import { applications } from '@/api';
import { plural } from '@/helpers';

export default Vue.extend({
  props: ['appId'],
  computed: mapState(['user']),
  data() {
    return {
      hasLoadApp: false,
      moderationStatus: '',
      messagesCount: '',
      historyLength: 0,
    };
  },
  mounted() {
    this.getApplicationById();
  },
  methods: {
    /**
     * @description Получение статуса модерации у приложения
     * @return {Promise<any>}
     */
    async getApplicationById(): Promise<any> {
      this.hasLoadApp = true;
      await applications
        .getAppById(this.appId)
        .then((res: Response) => res.json())
        .then(({ data: { moderationStatus, moderationHistory } }) => {
          this.moderationStatus = moderationStatus;
          const historyLength = moderationHistory.length;
          this.historyLength = historyLength;
          const word = plural(historyLength, [
            'сообщение',
            'сообщения',
            'сообщений',
          ]);
          this.messagesCount = `${historyLength} ${word}`;
          this.hasLoadApp = false;
        });
    },

    async sendApplicationToModeration(): Promise<any> {
      await Developer.sendApplicationToModeration(this.appId, {})
        .then((res: Response) => res.json())
        .then(async (): Promise<any> => {
          this.$message.success('Приложение отправлено на модерацию :)');
          await this.getApplicationById();
        });
    },
  },
});
