
// @ts-nocheck
import Vue from 'vue';
import PageTitle from '@/components/Global/PageTitle.vue';
import { articles } from '@/api/articles';

export default Vue.extend({
  components: { PageTitle },
  data() {
    return {
      articlesCategories: [],
      hasLoadArticlesCategories: false,
      columns: [
        {
          title: 'Название',
          dataIndex: 'title',
          key: 'name',
        },
        {
          title: 'Родительская категория',
          dataIndex: 'parentCategory',
          key: 'parentCategory',
          scopedSlots: { customRender: 'parent-category' },
        },
        {
          title: 'Действия',
          dataIndex: 'parentCategory',
          key: 'parentCategory',
          scopedSlots: { customRender: 'actions' },
        },
      ],
    };
  },
  mounted() {
    this.getArticleCategories();
  },
  methods: {
    /**
     * @description Получение категорий статей
     * @return {Promise<any>}
     */
    async getArticleCategories(): Promise<any> {
      this.hasLoadArticlesCategories = true;
      await articles
        .getArticleCategory()
        .then((res: Response) => res.json())
        .then(({ data }) => {
          this.articlesCategories = data;
          this.hasLoadArticlesCategories = false;
        });
    },

    deleteCategory(id: number) {
      this.$confirm({
        title: `Вы действительно хотите удалить данную категорию ?`,
        content:
          'Вы не сможете восстановить ее после удаления, только добавить еще раз.',
        onOk: async () => {
          await articles.deleteArticleCategory(id).then(() => {
            this.$message.success('Категория успешна удалена :)', 2);
            this.getArticleCategories();
          });
        },
      });
    },
  },
});
