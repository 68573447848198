
// @ts-nocheck
import { Component, Vue } from 'vue-property-decorator';
import PageTitle from '@/components/Global/PageTitle.vue';
import { advert } from '@/api/advert';
import { Banner } from '@/types/advert';

@Component({ name: 'Advert', components: { PageTitle } })
export default class Advert extends Vue {
  advertBlocks = null;
  hasLoadAdvertBlocks: boolean = false;

  columns = [
    {
      title: 'Страница-Позиция',
      dataIndex: 'adPositions',
      key: 'adPositions',
      scopedSlots: { customRender: 'position' },
      width: '20%',
    },
    {
      title: 'Локаль',
      dataIndex: 'locale',
      key: 'locale',
    },
    {
      title: 'Десктоп',
      dataIndex: 'desktop',
      key: 'desktop',
      scopedSlots: { customRender: 'desktop' },
    },
    {
      title: 'Мобайл',
      dataIndex: 'mobile',
      key: 'mobile',
      scopedSlots: { customRender: 'mobile' },
    },
    {
      title: 'Действия',
      dataIndex: 'actions',
      key: 'actions',
      scopedSlots: { customRender: 'actions' },
    },
  ];

  mounted() {
    this.getAdvertBlocks();
  }

  handleDeleteBanner(id: number) {
    this.$confirm({
      title: 'Вы действительно хотите удалить рекламный баннер?',
      content:
        'Вы не сможете восстановить его после удаления, только добавить еще раз.',
      onOk: async () => {
        await advert.deleteAdvertBannerById(id);
        await this.getAdvertBlocks();
      },
    });
  }

  private async setVisibleBanner(banner: Banner): Promise<any> {
    await advert
      .updateAdvertBannerById(banner.id, {
        ...banner,
        isVisible: 1,
        adPositions: banner.adPositions.map((position) => position.id),
      })
      .then(() => {
        this.getAdvertBlocks();
      });
  }

  private async setUnVisibleBanner(banner: Banner): Promise<any> {
    await advert
      .updateAdvertBannerById(banner.id, {
        ...banner,
        isVisible: 0,
        adPositions: banner.adPositions.map((position) => position.id),
      })
      .then(() => {
        this.getAdvertBlocks();
      });
  }

  /**
   * @description
   * @return {Promise<any>}
   */
  private async getAdvertBlocks(): Promise<any> {
    this.hasLoadAdvertBlocks = true;
    await advert
      .getAdvertBanners()
      .then((res) => res.json())
      .then(({ data }) => {
        this.advertBlocks = data;
        this.hasLoadAdvertBlocks = false;
      });
  }
}
