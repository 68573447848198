<template>
  <a-affix :offsetTop="0">
    <div class="page__title">
      <a-row :gutter="24">
        <Breadcrumbs/>
      </a-row>
      <a-row :gutter="24">
        <a-col :span="12">
          <h3 @click="handleBack" class="back-arrow">
            <a-icon type="arrow-left"/>
            <span id="page-title">{{ title }}</span>
          </h3>
        </a-col>
        <a-col :span="12" style="text-align: right;">
          <slot/>
        </a-col>
      </a-row>
    </div>
  </a-affix>
</template>

<script>
import Breadcrumbs from './Breadcrumbs';

export default {
  name      : 'PageTitle',
  components: { Breadcrumbs },
  props     : {
    title: {},
  },
  methods   : {
    handleBack() {
      this.$router.go(-1);
    }
  }
};
</script>

<style scoped lang="scss">
.page__title {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  height: 92px;
  padding: 10px 40px;
}

.ant-row {
  margin-left: 0;
}

h3 {
  margin: 15px 0;
  font-size: 16px;
}

.back-arrow {
  cursor: pointer;
}
</style>
