var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.user.hasDeveloper ? _c('a-layout-content', {
    staticClass: "page__content",
    staticStyle: {
      "height": "88px"
    }
  }, [_c('a-spin', {
    attrs: {
      "spinning": _vm.hasLoadApp
    }
  }, [_c('a-row', {
    attrs: {
      "guttter": 24
    }
  }, [_c('a-col', {
    staticStyle: {
      "display": "flex",
      "align-items": "center"
    },
    attrs: {
      "span": "8"
    }
  }, [_c('span', [_vm.moderationStatus === 'черновик' ? _c('span', {
    staticClass: "moderation-status"
  }, [_c('span', {
    staticClass: "pause icon"
  }), _vm._v(" " + _vm._s(_vm.moderationStatus) + " ")]) : _vm._e(), _vm.moderationStatus === 'опубликовано на сайте' ? _c('span', {
    staticClass: "moderation-status"
  }, [_c('span', {
    staticClass: "play icon"
  }), _vm._v(" " + _vm._s(_vm.moderationStatus) + " ")]) : _vm._e(), _vm.moderationStatus === 'модерация не пройдена' ? _c('span', {
    staticClass: "moderation-status"
  }, [_c('span', {
    staticClass: "close icon"
  }), _vm._v(" " + _vm._s(_vm.moderationStatus) + " ")]) : _vm._e(), _vm.moderationStatus === 'ожидает модерации' ? _c('span', {
    staticClass: "moderation-status"
  }, [_c('span', {
    staticClass: "reload icon"
  }), _vm._v(" " + _vm._s(_vm.moderationStatus) + " ")]) : _vm._e()]), _vm.historyLength !== 0 && _vm.moderationStatus === 'модерация не пройдена' ? _c('router-link', {
    staticStyle: {
      "display": "inline-block",
      "margin-left": "10px",
      "margin-top": "10px"
    },
    attrs: {
      "to": "/developer-moderation/".concat(_vm.appId, "/send")
    }
  }, [_vm._v(" (" + _vm._s(_vm.messagesCount) + ") ")]) : _vm._e()], 1), _c('a-col', {
    staticStyle: {
      "text-align": "right"
    },
    attrs: {
      "span": "16"
    }
  }, [_vm.moderationStatus === 'черновик' || _vm.moderationStatus === 'модерация не пройдена' ? _c('a-button', {
    staticStyle: {
      "margin-left": "20px",
      "padding": "0 35px"
    },
    attrs: {
      "size": "large",
      "type": "primary"
    },
    on: {
      "click": _vm.sendApplicationToModeration
    }
  }, [_vm._v("Отправить на модерацию")]) : _vm._e()], 1)], 1)], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }