<template>
  <div>
    <PageTitle :title="title"/>

    <a-layout-content class="page__content">
      <a-spin :spinning="submitting">
        <a-form
            :form="form"
            layout="horizontal"
            @submit.prevent="handleSubmit"
        >
          <a-form-item
              label="Приложение"
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
          >
            <a-select
                show-search
                allow-clear
                placeholder="Название приложения"
                :default-active-first-option="false"
                :show-arrow="false"
                :filter-option="false"
                :not-found-content="appsSearchDataSearching ? undefined : 'Не найдено'"
                size="large"
                @search="handleAppNameSearch"
                v-decorator="['application_id', { rules: [{ required: true, message: 'Выберите приложение' }] }]"
            >
              <a-spin v-if="appsSearchDataSearching" slot="notFoundContent" size="small"/>

              <a-select-option
                  v-for="app in appsSearchData"
                  :key="app.id"
              >
                {{ app.name }} ({{ app.seoName }})
              </a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item
              label="Тип устройства"
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
          >
            <a-select
                placeholder="Выберите тип устройства"
                size="large"
                v-decorator="[
              'device_type',
              {
                rules: [
                  {
                    required: true,
                    message: 'Тип устройства - обязательное поле'
                  }
                ]
              }
            ]"
            >
              <a-select-option
                  v-for="type in deviceTypes"
                  :value="type.value"
                  :key="type.value"
              >{{ type.name }}
              </a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item
              label="Показывать для категории"
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
          >
            <a-select
                size="large"
                placeholder="категории"
                mode="multiple"
                :filter-option="filterOption"
                v-decorator="[
              'categories',
              {
                rules: []
              }
            ]"
            >
              <template v-for="category in categories">
                <a-select-option
                    :value="category.id"
                    :key="category.id"
                    :title="category.name"
                >
                  {{ category.parent ? category.parent.name + ' / ' : '' }}{{ category.name }} ({{ category.os.name }})
                </a-select-option>
                <template
                    v-if="category.children"
                    v-for="subCategory in category.children"
                >
                  <a-select-option
                      :value="subCategory.id"
                      :key="subCategory.id"
                      :title="subCategory.name"
                  >
                    {{ category.name }} / {{ subCategory.name }} ({{ category.os.name }})
                  </a-select-option>
                </template>
              </template>
            </a-select>
          </a-form-item>

          <a-form-item
            label="Показывать для приложения"
            :label-col="formItemLayout.labelCol"
            :wrapper-col="formItemLayout.wrapperCol"
          >
            <a-select
              show-search
              allow-clear
              placeholder="Название приложения"
              :default-active-first-option="false"
              :show-arrow="false"
              :filter-option="false"
              :not-found-content="relatedAppsSearchDataSearching ? undefined : 'Не найдено'"
              size="large"
              @search="handleRelatedAppNameSearch"
              v-decorator="['applications']"
              mode="multiple"
            >
              <a-spin v-if="relatedAppsSearchDataSearching" slot="notFoundContent" size="small"/>

              <a-select-option
                  v-for="app in relatedAppsSearchData"
                  :key="app.id"
              >
                {{ app.name }} ({{ app.seoName }})
              </a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item
              label="Действие"
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
          >
            <a-select
                placeholder="Выберите действие"
                size="large"
                v-decorator="[
              'action',
              {
                rules: [
                  {
                    required: true,
                    message: 'действие - обязательное поле'
                  }
                ]
              }
            ]"
            >
              <a-select-option
                  v-for="action in actions"
                  :value="action.value"
                  :key="action.value"
              >{{ action.name }}
              </a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item
              v-show="form.getFieldValue('action') === 'url'"
              label="Ссылка"
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
          >
            <a-input
                placeholder="Ссылка"
                v-decorator="['url', { rules: [{ required: false, message: 'Ссылка' }] }]"
            />
          </a-form-item>

          <a-form-item
              v-show="form.getFieldValue('action') === 'download_file'"
              label="Файл"
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
          >
            <a-upload
                name="file"
                :action="uploadAction"
                :headers="uploadHeaders"
                @change="uploadFileChange"
                :file-list="fileList"
                :multiple="false"
                :showUploadList="{showPreviewIcon: false, showRemoveIcon: false}"
            >
              <a-button>
                <a-icon type="upload"/>
                Click to upload
              </a-button>
            </a-upload>
          </a-form-item>

          <a-form-item
              label="Доступно для"
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
          >
            <a-checkbox-group
                :options="domainOptions"
                v-decorator="[
                  `domains`,
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Выберите хотя бы один домен'
                      }
                    ]
                  }
                ]"
            />
          </a-form-item>

          <a-form-item :wrapper-col="{ span: 14, offset: 4 }">
            <a-button type="primary" html-type="submit">
              {{ btnTitle }}
            </a-button>
          </a-form-item>
        </a-form>
      </a-spin>
    </a-layout-content>
  </div>
</template>

<script>
import PageTitle from "@/components/Global/PageTitle.vue";
import debounce from "lodash/debounce";
import {searchApps} from "@/api/Applications/app";
import {
  createSponsoredApplication,
  getSponsoredApplication,
  updateSponsoredApplication
} from "@/api/sponsoredApplication";
import {actions, deviceTypes} from './SponsoredApplication';
import {API_URL} from "@/global/constants";
import Cookie from "@/helpers/Cookie";
import {getCategories} from "@/api/category";
import {getDomains} from "@/api/domains";

export default {
  name: "ApplicationsSponsoredModify",

  components: {PageTitle},

  props: {
    id: {
      required: false,
    },
  },

  data() {
    return {
      form: this.$form.createForm(this),
      appsSearchController: null,
      appsSearchDataSearching: false,
      appsSearchData: [],
      formItemLayout: {
        labelCol: {span: 5},
        wrapperCol: {span: 14},
      },
      deviceTypes: deviceTypes,
      actions: actions,
      submitting: false,
      sponsoredApp: null,
      uploadAction: `${API_URL}/api/admin/sponsored-applications/upload`,
      uploadHeaders: {
        Authorization: `Bearer ${Cookie.get('admin_token')}`,
        Accept: 'application/json',
      },
      fileList: [],
      file: '',
      categories: [],
      domains: [],
      relatedAppsSearchController: null,
      relatedAppsSearchDataSearching: false,
      relatedAppsSearchData: [],
    };
  },

  async created() {
    const responseDomains = await getDomains();
    const responseDomainsJson = await responseDomains.json();
    this.domains = responseDomainsJson.data;

    if (this.id) {
      await this.fetchSponsoredApp();
      this.appsSearchData = [this.sponsoredApp.application];
      this.relatedAppsSearchData = [...this.sponsoredApp.applications];
      this.setFormFromSponsoredApp();
    }

    getCategories({all: 1}).then((response) => response.json()).then((json) => {
      this.categories = json.data;
    });

    this.handleAppNameSearch = debounce(this.handleAppNameSearch, 800);
  },

  computed: {
    title() {
      return this.id ? 'Редактировать спонсорское приложение' : 'Добавить спонсорское приложение';
    },

    btnTitle() {
      return this.id ? 'Сохранить' : 'Создать';
    },

    domainOptions() {
      return this.domains.map(domain => {
        return {
          label: domain.short_name,
          value: domain.id,
        };
      });
    },
  },

  methods: {
    async fetchSponsoredApp() {
      this.submitting = true;

      const response = await getSponsoredApplication(this.id);
      const json = await response.json();
      this.sponsoredApp = json.data;
      this.file = this.sponsoredApp.file;

      if (this.sponsoredApp.action === 'download_file' && this.sponsoredApp.file) {
        this.fileList = [
          {
            uid: '-1',
            name: this.sponsoredApp.file.substring(this.sponsoredApp.file.lastIndexOf('/') + 1),
            status: 'done',
            url: this.sponsoredApp.file,
          }
        ];
      }

      this.submitting = false;
    },

    setFormFromSponsoredApp() {
      this.form.setFieldsValue({
        application_id: this.sponsoredApp.application_id,
        device_type: this.sponsoredApp.device_type,
        action: this.sponsoredApp.action,
        url: this.sponsoredApp.url,
        categories: this.sponsoredApp.categories.map(category => category.id),
        domains: this.sponsoredApp.domains.map(domain => domain.id),
        applications: this.sponsoredApp.applications.map(application => application.id),
      });
    },

    async handleSubmit() {
      this.submitting = true;

      try {
        const values = await this.form.validateFields();
        values.file = this.file;
        if (this.id) {
          await updateSponsoredApplication(this.id, values);
          this.$message.success('Спонсорское приложение обновлено', 3);
        } else {
          await createSponsoredApplication(values);
          this.$message.success('Спонсорское приложение создано', 3);
          this.$router.push({name: 'ApplicationsSponsoredList'});
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.submitting = false;
      }
    },

    async handleAppNameSearch(value) {
      if (!value.trim().length) {
        return;
      }

      if (true === this.appsSearchDataSearching) {
        this.appsSearchController.abort();
      }

      this.appsSearchController = new AbortController();
      this.appsSearchDataSearching = true;
      this.appsSearchData = [];
      try {
        const res = await searchApps({
          name: value,
          limit: 50,
        }, {signal: this.appsSearchController.signal});

        const json = await res.json();
        this.appsSearchData = json.data;
      } catch (err) {
        if (err.name !== 'AbortError') { // обработать ошибку от вызова abort()
          throw err;
        }
      } finally {
        this.appsSearchDataSearching = false;
      }
    },

    uploadFileChange(info) {
      let fileList = [...info.fileList];

      // 1. Limit the number of uploaded files
      fileList = fileList.slice(-1);

      // 2. read from response and show file link
      fileList = fileList.map(file => {
        if (file.response) {
          // Component will show file.url as link
          file.url = file.response.url;
          this.file = file.url;
        }
        return file;
      });

      this.fileList = fileList;

      this.submitting = info.file.status === 'uploading';
    },

    beforeUpload(file, fileList) {
      return true;
    },

    filterOption(input, option) {
      return (
          option.componentOptions.propsData.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },

    async handleRelatedAppNameSearch(value) {
      if (!value.trim().length) {
        return;
      }

      if (true === this.relatedAppsSearchDataSearching) {
        this.relatedAppsSearchController.abort();
      }

      this.relatedAppsSearchController = new AbortController();
      this.relatedAppsSearchDataSearching = true;
      this.relatedAppsSearchData = [];
      try {
        const res = await searchApps({
          name: value,
          limit: 50,
        }, {signal: this.relatedAppsSearchController.signal});

        const json = await res.json();
        this.relatedAppsSearchData = json.data;
      } catch (err) {
        if (err.name !== 'AbortError') { // обработать ошибку от вызова abort()
          throw err;
        }
      } finally {
        this.relatedAppsSearchDataSearching = false;
      }
    },
  },
}
</script>

<style scoped>

</style>
