import { get, post } from '../core';
import { SearchApps } from '../../types/applications';

/**
 * @description Получение списка приложений
 * @param {number} page
 * @return {Promise<any>}
 */
export const getAllApplications = (page: number): Promise<any> =>
  get(`/api/admin/apps?page=${page}&limit=20`);

/**
 * @description Поиск по приложениям
 * @param {object} params
 * @return {Promise<any>}
 */
export const searchApplications = (params: SearchApps): Promise<any> =>
  get(`/api/admin/apps/search`, params);

export default {
  getAllApplications,
  searchApplications,
};
