
// @ts-nocheck
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Redirect } from '@/types/Redirect';
import { settings } from '@/api/settings';
import PageTitle from '@/components/Global/PageTitle.vue';
import lodashLast from 'lodash/last';

@Component({
  name: 'Add',
  components: { PageTitle },
})
export default class Add extends Vue {
  @Prop() public id!: number;
  buttonDisable: boolean = false;
  title: string = '';

  baseRedirect: Redirect = {
    id: 0,
    source: '',
    destination: '',
  };

  redirectItems: Redirect[] = [{ id: 0, source: '', destination: '' }];

  mounted() {
    if (this.id) {
      this.title = 'Редактирование редиректа';
      settings
        .getRedirect(this.id)
        .then((res) => res.json())
        .then(({ data }) => {
          this.baseRedirect = data;
        });
    } else {
      this.title = 'Добавление редиректа';
    }
  }

  /**
   * @destination Добавление редиректа в список
   * @return {void}
   */
  private addRedirect(): void {
    const last = lodashLast(this.redirectItems);
    this.redirectItems.push({ ...this.baseRedirect, id: last!.id + 1 });
  }

  /**
   * @destination Удаление редиректа из списка
   * @param {number} id - redirect id
   * @return {void}
   */
  private removeRedirect(id: number): void {
    this.redirectItems = this.redirectItems.filter((item) => item.id !== id);
  }

  private async saveRedirect(): Promise<any> {
    if (this.id) {
      await this.createRedirect(this.id, this.baseRedirect)
        .then((res: Response) => {
          const { status } = res;
          if (status === 200 || status === 201) {

            this.buttonDisable = false;
            this.$message.success('Редирект успешно сохранен', 2);
          }
          return res.json();
        })
        .then(({ code, data }) => {
          if (!code) { this.baseRedirect = data; }
        })
        .catch(() => {
          this.$message.error('Сохранить не удалось');
          this.buttonDisable = false;
        });
    } else {
      const list: Array<Promise<any>> = [];
      const redirects: Redirect[] = this.redirectItems;
      const message: string =
        redirects.length > 1
          ? 'Редиректы успешно сохранены'
          : 'Редирект успешно сохранен';

      redirects.forEach((redirect: Redirect) => {
        const request = this.createRedirect(null, redirect);
        list.push(request);
      });

      await Promise.all(list).then(() => {
        this.$message.success(message, 2);

      });
    }
  }

  /**
   * @param {number} id
   * @param {Redirect} redirect
   * @return {Promise<any>}
   */
  private createRedirect(id: number | null, redirect: Redirect): Promise<any> {
    return settings.saveRedirect(id, redirect);
  }
}
