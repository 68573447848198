export default class Cookie {
  /**
   *
   * @param {string} name
   * @return {String|null}
   */
  static get(name) {
    const matches = document.cookie.match(
      new RegExp(`(?:^|; )${name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1')}=([^;]*)`),
    );
    return matches ? decodeURIComponent(matches[1]) : '';
  }

  /**
   * @param {string} name
   * @param {any} value
   * @param {string} days
   * @param {string} path
   * @return {null}
   */
  static set(name, value, exp = undefined, path = undefined) {
    const dir = path || '/';
    let expires;

    if (exp) {
      const date = new Date();
      date.setTime(date.getTime() + exp * 24 * 60 * 60 * 1000);
      expires = `; expires=${date.toUTCString()}`;
    } else {
      expires = '';
    }
    document.cookie = `${name}=${value}${expires}; path=${dir}`;
  }

  /**
   *
   * @param {string} name
   * @return {null}
   */
  static delete(name) {
    this.set(name, '', -1);
  }
}

