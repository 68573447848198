import { get } from './core';

/**
 * @description Получение логов
 * @return {Promise<any>}
 */
export const getLogs = (page: number): Promise<any> => get(`/api/admin/model-log`);

/**
 * @description Поиск по логам
 * @param {object} params
 * @return {Promise<any>}
 */
export const searchLogs = (params: any): Promise<any> =>
  get('/api/admin/model-log/search', params);

export const Logging = {
  getLogs,
  searchLogs,
};

export default { Logging };
