var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('a-affix', {
    attrs: {
      "offsetTop": 0
    }
  }, [_c('a-menu', {
    staticStyle: {
      "padding": "20px 0"
    },
    attrs: {
      "mode": "horizontal",
      "size": "large"
    }
  }, [_c('a-menu-item', {
    key: "description",
    class: _vm.active === 'description' && 'ant-menu-item-active'
  }, [_c('router-link', {
    attrs: {
      "to": {
        path: _vm.descriptionLink
      }
    }
  }, [_vm._v("ОПИСАНИЕ")])], 1), _c('a-menu-item', {
    key: "files",
    class: _vm.active === 'files' && 'ant-menu-item-active',
    attrs: {
      "disabled": !_vm.moderationId && _vm.id === undefined
    }
  }, [_c('router-link', {
    attrs: {
      "to": {
        path: _vm.filesLink
      }
    }
  }, [_vm._v("ФАЙЛЫ")])], 1), _c('a-menu-item', {
    key: "logo",
    class: _vm.active === 'images' && 'ant-menu-item-active',
    attrs: {
      "disabled": !_vm.moderationId && _vm.id === undefined
    }
  }, [_c('router-link', {
    attrs: {
      "to": {
        path: _vm.imagesLink
      }
    }
  }, [_vm._v("ЛОГО И СКРИНШОТЫ")])], 1), !_vm.moderationId && (_vm.user.hasAdmin || _vm.user.hasEditor || _vm.user.hasSeo) ? _c('a-menu-item', {
    key: "seo",
    class: _vm.active === 'seo' && 'ant-menu-item-active',
    attrs: {
      "disabled": _vm.id === undefined
    }
  }, [_c('router-link', {
    attrs: {
      "to": {
        path: _vm.seoLink
      }
    }
  }, [_vm._v("SEO")])], 1) : _vm._e(), !_vm.moderationId && (_vm.user.hasAdmin || _vm.user.hasEditor || _vm.user.hasSeo) ? _c('a-menu-item', {
    key: "page_versions",
    class: _vm.active === 'page_versions' && 'ant-menu-item-active',
    attrs: {
      "disabled": _vm.id === undefined
    }
  }, [_c('router-link', {
    attrs: {
      "to": {
        name: 'AppPageVersions'
      }
    }
  }, [_vm._v("Страница versions")])], 1) : _vm._e(), !_vm.moderationId && (_vm.user.hasAdmin || _vm.user.hasEditor || _vm.user.hasSeo) ? _c('a-menu-item', {
    key: "tag_lists",
    class: {
      'ant-menu-item-active': _vm.active === 'tag_lists'
    },
    attrs: {
      "disabled": !_vm.moderationId && _vm.id === undefined
    }
  }, [_c('router-link', {
    attrs: {
      "to": _vm.tagListsRoute
    }
  }, [_vm._v("Категории")])], 1) : _vm._e()], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }