import OS from './Os/Os.vue';
import ChangeOs from './Os/ChangeOs.vue';
import ChangeVersionOs from './VersionOs/ChangeVersionOs.vue';
import ListApps from './List/List.vue';
import VersionOs from './VersionOs/VersionOs.vue';
import Languages from './Languages/languages.vue';
import Licenses from './Licenses/AppsLicenses.vue';
import Platforms from './Platforms/platforms.vue';
import SeoFrequency from './SeoFrequency/seoFrequency.vue';
import AddLicense from '@/components/Applications/Licenses/Add.vue';
import AddPlatform from '@/components/Applications/Platforms/Add.vue';
import TagListings from '@/components/Applications/TagListings/TagListings.vue';
import AddFiles from './Add/Files.vue';
import AddDescription from './Add/Description.vue';
import AddImages from './Add/Images.vue';
import AddSeo from './Add/Seo.vue';
import ApplicationsVersionsPage from './Add/ApplicationsVersionsPage';
import AddSite from './Add/Site.vue';
import AddLanguage from '@/components/Applications/Languages/Add.vue';
import UpdateLogs from './UpdateLogs/UpdateLogs.vue';
import ApplicationsSponsoredList from './Sponsored/ApplicationsSponsoredList';
import ApplicationsSponsoredModify from './Sponsored/ApplicationsSponsoredModify';

// Листинг приложений
export const listRoute = '/applications';

// Файлы
export const addFilesRoute = '/add-files';
export const editFilesRoute = '/add-files/:id/edit';

// Описание
export const addDescriptionRoute = '/add-description';
export const editDescriptionRoute = '/edit-description/:id/edit';

// Добавление изображений
export const addImagesRoute = '/add-images';
export const editImagesRoute = '/images/:id/edit';

// Добавление SEO
export const addSeoRoute = '/add-seo';
export const editSeoRoute = '/seo/:id/edit';

// Добавление мини сайт

export const addSiteRoute = `/sites/:id/edit`;

export const versionOsRoute = '/applications/version-os';
export const addVersionOsRoute = '/applications/version-os/add';
export const editVersionOsRoute = '/applications/version-os/:id/change';

export const seoFrequencyRoute = '/applications/seo-frequency';

export const licensesRoute = '/applications/licenses';
export const addLicenseRoute = '/applications/licenses/add';
export const editLicenseRoute = '/applications/licenses/:id';

export const languagesRoute = '/applications/languages';
export const addLanguageRoute = '/applications/languages/add';
export const editLanguageRoute = '/applications/languages/:id/change';

export const platformsRoute = '/applications/platforms';
export const addPlatformRoute = '/applications/platforms/add';
export const editPlatformRoute = '/applications/platforms/:id/change';

export const osRoute = '/applications/os';
export const osChangeRoute = '/applications/os/:id/change';
export const addOsRoute = '/applications/os/add';

export const applicationRoutes = [
  {
    path: listRoute,
    name: 'list',
    component: ListApps,
    breadcrumbName: 'Список приложений',
    meta: {
      title: 'Список приложений',
    },
  },
  {
    path: osRoute,
    name: 'os',
    component: OS,
    breadcrumbName: 'ОС',
    meta: {
      title: 'ОС',
    },
  },
  {
    path: versionOsRoute,
    name: 'versions os',
    component: VersionOs,
    breadcrumbName: 'Версии ОС',
    meta: {
      title: 'Версии ОС',
    },
  },
  {
    path: languagesRoute,
    name: 'languages',
    component: Languages,
    breadcrumbName: 'Языки',
    meta: {
      title: 'Языки',
    },
  },
  {
    path: addLanguageRoute,
    name: 'addLanguageRoute',
    component: AddLanguage,
    breadcrumbName: 'Добавить язык',
    meta: {
      title: 'Добавить язык',
    },
  },
  {
    path: editLanguageRoute,
    name: 'edit',
    component: AddLanguage,
    props: true,
    breadcrumbName: 'Редактировать язык',
    meta: {
      title: 'Редактировать язык',
    },
  },
  {
    path: licensesRoute,
    name: 'licenses',
    component: Licenses,
    breadcrumbName: 'Типы лицензий',
    meta: {
      title: 'Типы лицензий',
    },
  },
  {
    path: platformsRoute,
    name: 'platforms',
    component: Platforms,
    breadcrumbName: 'Платформы',
    meta: {
      title: 'Платформы',
    },
  },
  {
    path: editPlatformRoute,
    name: 'editPlatformRoute',
    component: AddPlatform,
    breadcrumbName: 'Редактирование платформы',
    props: true,
    meta: {
      title: 'Редактирование платформы',
    },
  },
  {
    path: addPlatformRoute,
    name: 'addPlaftformRoute',
    component: AddPlatform,
    breadcrumbName: 'Добавление платформы',
    props: true,
    meta: {
      title: 'Добавление платформы',
    },
  },
  {
    path: seoFrequencyRoute,
    name: 'seoFrequency',
    component: SeoFrequency,
    breadcrumbName: 'Загрузка SEO частотности',
    meta: {
      title: 'Загрузка SEO частотности',
    },
  },
  {
    path: addOsRoute,
    component: ChangeOs,
    name: 'AddOs',
    breadcrumbName: 'Добавление ОС',
    meta: {
      title: 'Добавление ОС',
    },
  },
  {
    path: osChangeRoute,
    component: ChangeOs,
    name: 'EditOs',
    breadcrumbName: 'Редактирование ОС',
    props: true,
    meta: {
      title: 'Редактирование ОС',
    },
  },
  {
    path: editVersionOsRoute,
    component: ChangeVersionOs,
    name: 'EditVersionOs',
    breadcrumbName: 'Редактирование Версии ОС',
    props: true,
    meta: {
      title: 'Редактирование Версии ОС',
    },
  },
  {
    path: addVersionOsRoute,
    component: ChangeVersionOs,
    name: 'AddVersionOs',
    breadcrumbName: 'Добавление Версии ОС',
    meta: {
      title: 'Добавление Версии ОС',
    },
  },
  {
    path: addDescriptionRoute,
    component: AddDescription,
    name: 'AddApp',
    breadcrumbName: 'Добавить приложение',
    meta: {
      title: 'Добавить приложение',
    },
  },
  {
    path: editDescriptionRoute,
    component: AddDescription,
    name: 'EditApp',
    breadcrumbName: 'Редактировать приложение',
    props: true,
    meta: {
      title: 'Редактировать приложение',
    },
  },
  {
    path: addFilesRoute,
    component: AddFiles,
    name: 'AddFiles',
    breadcrumbName: 'Добавить файлы',
    meta: {
      title: 'Добавить файлы',
    },
  },
  {
    path: editFilesRoute,
    component: AddFiles,
    name: 'EditFiles',
    breadcrumbName: 'Редактировать файлы',
    props: true,
    meta: {
      title: 'Редактировать файлы',
    },
  },
  {
    path: addImagesRoute,
    component: AddImages,
    name: 'addImages',
    breadcrumbName: 'Добавить лого и скриншоты',
    meta: {
      title: 'Добавить лого и скриншоты',
    },
  },
  {
    path: editImagesRoute,
    component: AddImages,
    name: 'editImages',
    breadcrumbName: 'Редактировать лого и скриншоты',
    props: true,
    meta: {
      title: 'Редактировать лого и скриншоты',
    },
  },
  {
    path: addSeoRoute,
    component: AddSeo,
    name: 'AddSeo',
    breadcrumbName: 'Добавить SEO',
    meta: {
      title: 'Добавить SEO',
    },
  },
  {
    path: editSeoRoute,
    component: AddSeo,
    name: 'EditSeo',
    breadcrumbName: 'Редактировать SEO',
    props: true,
    meta: {
      title: 'Редактировать SEO',
    },
  },
  {
    path: '/page_versions/:id/edit',
    component: ApplicationsVersionsPage,
    name: 'AppPageVersions',
    breadcrumbName: 'Редактировать страницу versions',
    props: true,
    meta: {
      title: 'Редактировать страницу versions',
    },
  },
  {
    path: addSiteRoute,
    component: AddSite,
    name: 'AddSite',
    breadcrumbName: 'Редактировать Site',
    props: true,
    meta: {
      title: 'Редактировать Site',
    },
  },
  {
    path: '/apps/:id/tag_lists',
    component: TagListings,
    name: 'app-tag-lists-edit',
    breadcrumbName: 'Редактировать Категории',
    props: true,
    meta: {
      title: 'Редактировать Категории',
    },
  },

  {
    path: addLicenseRoute,
    component: AddLicense,
    name: 'AddLicense',
    breadcrumbName: 'Добавить лицензию',
    meta: {
      title: 'Добавить лицензию',
    },
  },
  {
    path: editLicenseRoute,
    component: AddLicense,
    name: 'EditLicense',
    breadcrumbName: 'Редактировать лицензию',
    props: true,
    meta: {
      title: 'Редактировать лицензию',
    },
  },

  {
    path: '/applications/update-logs',
    component: UpdateLogs,
    name: 'UpdateLogs',
    breadcrumbName: 'Логи обновлений',
    props: true,
    meta: {
      title: 'Логи обновлений',
    },
  },

  {
    path: '/applications/sponsored',
    component: ApplicationsSponsoredList,
    name: 'ApplicationsSponsoredList',
    breadcrumbName: 'Спонсорские приложения',
    props: true,
    meta: {
      title: 'Спонсорские приложения',
    },
  },
  {
    path: '/applications/sponsored/add',
    component: ApplicationsSponsoredModify,
    name: 'AddApplicationsSponsored',
    breadcrumbName: 'Добавить спонсорское приложение',
    meta: {
      title: 'Добавить спонсорское приложение',
    },
  },
  {
    path: '/applications/sponsored/:id',
    component: ApplicationsSponsoredModify,
    name: 'EditApplicationsSponsored',
    breadcrumbName: 'Редактировать спонсорское приложение',
    props: true,
    meta: {
      title: 'Редактировать спонсорское приложение',
    },
  },

];

export default {applicationRoutes};
