import OfficialRepresentativeRequests from './OfficialRepresentativeRequests.vue';

import {Route} from '@/types/route';

export const OfficialRepresentativeRequestsRoute = '/official_representative/requests';

export const officialRepresentativeRoutes: Route[] = [
  {
    name: 'OfficialRepresentativeRequests',
    path: OfficialRepresentativeRequestsRoute,
    component: OfficialRepresentativeRequests,
    breadcrumbName: 'Официальный представитель запросы',
    meta: {
      title: 'Официальный представитель запросы',
    },
  },
];

export default {officialRepresentativeRoutes};
