import { Route } from '@/types/route';
import Edit from './Edit.vue';

export const editRoute = '/settings/fs-install';

export const FSInstallRoutes: Route[] = [
  {
    path: editRoute,
    name: 'editFS',
    component: Edit,
    breadcrumbName: 'Fs Install',
    meta: {
      title: 'Fs Install',
    },
  },
];

export default { FSInstallRoutes };
