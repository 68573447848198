import { get } from '../core';
import { SearchTags } from '../../types/applications';


/**
 * @description Получение тегов
 * @return {Promise<any>}
 */
export const getTags = (): Promise<any> => get('/api/admin/tags');

/**
 * @description Поиск по тегам
 * @param {object} params
 * @return {Promise<any>}
 */
export const searchTags = (params: SearchTags): Promise<any> =>
  get('/api/admin/tags/search', params);

export default {
  getTags,
  searchTags,
};
