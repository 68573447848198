import {get} from '@/api/core';

export const getApplicationUpdateLogs = (params: any = {}): Promise<any> => get(
  `/api/admin/application-update-logs`,
  params,
);

export default {
  getApplicationUpdateLogs,
};
