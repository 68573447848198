var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('AddPageTitle', {
    attrs: {
      "title": _vm.title,
      "id": _vm.id || _vm.moderationId
    }
  }), _vm.moderationId ? _c('ModerationTop', {
    attrs: {
      "appId": _vm.moderationId
    }
  }) : _vm._e(), _vm.id ? _c('DeveloperModeration', {
    attrs: {
      "appId": _vm.id
    }
  }) : _vm._e(), _c('a-spin', {
    attrs: {
      "spinning": _vm.loading
    }
  }, [_c('a-layout-content', {
    staticClass: "page__content"
  }, [_c('TopMenu', {
    attrs: {
      "id": _vm.id,
      "moderationId": _vm.moderationId,
      "active": "files"
    }
  }), _c('a-table', {
    attrs: {
      "columns": _vm.fileColumns,
      "dataSource": _vm.fileList,
      "pagination": false,
      "rowKey": "id",
      "rowSelection": {
        selectedRowKeys: _vm.selectedRowKeys,
        onChange: _vm.onSelectChange,
        type: 'radio',
        onSelect: _vm.onSelect
      }
    },
    scopedSlots: _vm._u([{
      key: "availabilities",
      fn: function fn(availabilities) {
        return [_vm._v(" " + _vm._s(availabilities.map(function (availability) {
          return _vm.LOCALE_TO_DOMAIN[availability.locale];
        }).join(', ')) + " ")];
      }
    }, {
      key: "size",
      fn: function fn(size) {
        return [size ? [_vm._v(_vm._s(size / 1000) + " мб")] : _vm._e()];
      }
    }, {
      key: "actions",
      fn: function fn(file) {
        return [_c('a-button', {
          directives: [{
            name: "scroll-to",
            rawName: "v-scroll-to",
            value: '#edit-form-file',
            expression: "'#edit-form-file'"
          }],
          staticClass: "gray-btn",
          staticStyle: {
            "margin-right": "10px"
          },
          attrs: {
            "type": "primary",
            "icon": "edit"
          },
          on: {
            "click": function click($event) {
              return _vm.onEditFile(file);
            }
          }
        }), _c('a-button', {
          staticClass: "gray-btn",
          attrs: {
            "type": "danger",
            "icon": "delete"
          },
          on: {
            "click": function click($event) {
              return _vm.handleDeleteFile(file.id);
            }
          }
        }), (_vm.user.hasAdmin || _vm.user.hasEditor) && file.virus_total_analyse ? _c('a-button', {
          staticClass: "gray-btn",
          staticStyle: {
            "margin-left": "10px"
          },
          attrs: {
            "type": "primary",
            "icon": "info"
          },
          on: {
            "click": function click($event) {
              _vm.fileInfo = file.virus_total_analyse;
            }
          }
        }) : _vm._e()];
      }
    }])
  }), _c('a-tabs', {
    staticStyle: {
      "margin-top": "50px"
    },
    attrs: {
      "defaultActiveKey": "1",
      "size": "large"
    },
    model: {
      value: _vm.activeTab,
      callback: function callback($$v) {
        _vm.activeTab = $$v;
      },
      expression: "activeTab"
    }
  }, [_c('a-tab-pane', {
    key: "1",
    attrs: {
      "forceRender": "",
      "tab": "Загрузить с компьютера"
    }
  }, [_c('a-form', {
    attrs: {
      "form": _vm.form,
      "id": "edit-form-file"
    }
  }, [_vm._l(_vm.addFileForms, function (count) {
    return [_c('a-row', {
      staticStyle: {
        "margin": "50px 0"
      },
      attrs: {
        "gutter": 24
      }
    }, [_c('a-col', {
      attrs: {
        "span": "6"
      }
    }, [_c('a-form-item', {
      staticStyle: {
        "display": "none"
      }
    }, [_c('a-input', {
      directives: [{
        name: "decorator",
        rawName: "v-decorator",
        value: ['id'],
        expression: "['id']"
      }]
    })], 1), _c('a-form-item', {
      attrs: {
        "label": "Загрузка файла "
      }
    }, [_c('a-upload', {
      directives: [{
        name: "decorator",
        rawName: "v-decorator",
        value: ["file[".concat(count, "]"), {
          rules: [{
            required: false,
            message: 'Загрузите файл'
          }]
        }],
        expression: "[\n                      `file[${count}]`,\n                      {\n                        rules: [\n                          {\n                            required: false,\n                            message: 'Загрузите файл'\n                          }\n                        ]\n                      }\n                    ]"
      }],
      attrs: {
        "size": "large",
        "name": "file",
        "multiple": false,
        "beforeUpload": _vm.beforeUpload
      }
    }, [_c('a-button', {
      attrs: {
        "size": "large"
      }
    }, [_c('a-icon', {
      attrs: {
        "type": "upload"
      }
    }), _vm._v(" Выберите файл ")], 1)], 1)], 1)], 1), _c('a-col', {
      attrs: {
        "span": "6"
      }
    }, [_c('a-form-item', {
      attrs: {
        "label": "Версия"
      }
    }, [_c('a-input', {
      directives: [{
        name: "decorator",
        rawName: "v-decorator",
        value: ["version[".concat(count, "]"), {
          rules: [{
            required: false,
            message: 'Укажите версию'
          }]
        }],
        expression: "[\n                      `version[${count}]`,\n                      {\n                        rules: [\n                          {\n                            required: false,\n                            message: 'Укажите версию'\n                          }\n                        ]\n                      }\n                    ]"
      }],
      attrs: {
        "size": "large",
        "placeholder": "Версия программы"
      }
    })], 1)], 1), _c('a-col', {
      attrs: {
        "span": "6"
      }
    }, [_vm.hasArchitecture ? _c('a-form-item', {
      attrs: {
        "label": "Разрядность программы "
      }
    }, [_c('a-checkbox-group', {
      directives: [{
        name: "decorator",
        rawName: "v-decorator",
        value: ["architecture[".concat(count, "]"), {
          rules: [{
            required: false,
            message: 'Укажите разрядность'
          }]
        }],
        expression: "[\n                      `architecture[${count}]`,\n                      {\n                        rules: [\n                          {\n                            required: false,\n                            message: 'Укажите разрядность'\n                          }\n                        ]\n                      }\n                    ]"
      }],
      attrs: {
        "options": _vm.architectureOptions
      }
    })], 1) : _vm._e()], 1), _c('a-col', {
      attrs: {
        "span": "6"
      }
    }, [_c('a-form-item', {
      attrs: {
        "label": "Сборка"
      }
    }, [_c('a-input', {
      directives: [{
        name: "decorator",
        rawName: "v-decorator",
        value: ["assembly[".concat(count, "]"), {
          rules: [{
            required: false
          }]
        }],
        expression: "[\n                      `assembly[${count}]`,\n                      {\n                        rules: [\n                          {\n                            required: false\n                          }\n                        ]\n                      }\n                    ]"
      }],
      attrs: {
        "size": "large",
        "placeholder": "Описание"
      }
    })], 1)], 1)], 1), _c('a-row', {
      attrs: {
        "gutter": 24
      }
    }, [_c('a-col', {
      attrs: {
        "span": "12"
      }
    }, [_c('a-form-item', {
      attrs: {
        "label": "Изменения в версии"
      }
    }, [_c('a-textarea', {
      directives: [{
        name: "decorator",
        rawName: "v-decorator",
        value: ["description[".concat(count, "]"), {
          rules: [{
            required: false
          }]
        }],
        expression: "[\n                      `description[${count}]`,\n                      {\n                        rules: [\n                          {\n                            required: false\n                          }\n                        ]\n                      }\n                    ]"
      }],
      staticStyle: {
        "height": "166px"
      }
    })], 1)], 1), _c('a-col', {
      attrs: {
        "span": "12"
      }
    }, [_c('a-form-item', {
      attrs: {
        "label": "Описание версии"
      }
    }, [_c('a-textarea', {
      directives: [{
        name: "decorator",
        rawName: "v-decorator",
        value: ["content[".concat(count, "]"), {
          rules: [{
            required: false
          }]
        }],
        expression: "[\n                      `content[${count}]`,\n                      {\n                        rules: [\n                          {\n                            required: false\n                          }\n                        ]\n                      }\n                    ]"
      }],
      staticStyle: {
        "height": "166px"
      }
    })], 1)], 1)], 1), _c('a-row', {
      attrs: {
        "gutter": 24
      }
    }, [_c('a-col', {
      attrs: {
        "span": "24"
      }
    }, [_c('a-form-item', {
      attrs: {
        "label": "Доступно для"
      }
    }, [_c('a-checkbox-group', {
      directives: [{
        name: "decorator",
        rawName: "v-decorator",
        value: ["availabilities[".concat(count, "]"), {
          rules: [{
            required: true,
            message: 'Выберите хотя бы одну локаль'
          }]
        }],
        expression: "[\n                    `availabilities[${count}]`,\n                    {\n                      rules: [\n                        {\n                          required: true,\n                          message: 'Выберите хотя бы одну локаль'\n                        }\n                      ]\n                    }\n                  ]"
      }],
      attrs: {
        "options": _vm.availabilityOptions
      }
    })], 1)], 1)], 1), count !== 0 ? _c('a-button', {
      staticStyle: {
        "margin-right": "10px"
      },
      attrs: {
        "type": "danger",
        "size": "large",
        "icon": "delete"
      },
      on: {
        "click": function click($event) {
          return _vm.onDeleteFile(count);
        }
      }
    }, [_vm._v("Удалить")]) : _vm._e()];
  }), _c('a-button', {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      "size": "large",
      "icon": "plus",
      "type": "primary",
      "disabled": _vm.hasEditMode
    },
    on: {
      "click": _vm.handleAddFile
    }
  }, [_vm._v("Добавить еще файл ")])], 2), _c('a-row', [_c('a-button', {
    attrs: {
      "type": "primary",
      "size": "large"
    },
    on: {
      "click": _vm.addFile
    }
  }, [_vm._v(" Сохранить ")]), _vm.hasEditMode ? _c('a-button', {
    staticStyle: {
      "margin-left": "15px"
    },
    attrs: {
      "size": "large",
      "type": "primary"
    },
    on: {
      "click": _vm.onCancelEdit
    }
  }, [_vm._v("Отменить ")]) : _vm._e()], 1)], 1), _vm.user.hasAdmin || _vm.user.hasEditor ? _c('a-tab-pane', {
    key: "2",
    attrs: {
      "forceRender": "",
      "tab": "Партнерские ссылки"
    }
  }, [_c('a-form', {
    attrs: {
      "form": _vm.partnerForm
    }
  }, [_c('a-row', {
    attrs: {
      "gutter": 24
    }
  }, [_c('a-col', {
    attrs: {
      "span": "8"
    }
  }, [_c('a-form-item', {
    staticStyle: {
      "display": "none"
    }
  }, [_c('a-input', {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ['id'],
      expression: "['id']"
    }]
  })], 1), _c('a-form-item', {
    attrs: {
      "label": "Партнерская ссылка"
    }
  }, [_c('a-input', {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ['link', {
        rules: [{
          required: true,
          message: 'Ссылка обязательное поле'
        }]
      }],
      expression: "[\n                    'link',\n                    {\n                      rules: [\n                        {\n                          required: true,\n                          message: 'Ссылка обязательное поле'\n                        }\n                      ]\n                    }\n                  ]"
    }],
    attrs: {
      "size": "large",
      "placeholder": "Ссылка"
    }
  })], 1)], 1), _c('a-col', {
    attrs: {
      "span": "4"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "Версия"
    }
  }, [_c('a-input', {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ['version', {
        rules: [{
          required: false
        }]
      }],
      expression: "[\n                    'version',\n                    {\n                      rules: [\n                        {\n                          required: false\n                        }\n                      ]\n                    }\n                  ]"
    }],
    attrs: {
      "size": "large",
      "placeholder": "Версия"
    }
  })], 1)], 1), _c('a-col', {
    attrs: {
      "span": "5"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "Цена"
    }
  }, [_c('a-input-number', {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ['price', {
        rules: [{
          required: false
        }]
      }],
      expression: "[\n                    'price',\n                    {\n                      rules: [\n                        {\n                          required: false\n                        }\n                      ]\n                    }\n                  ]"
    }],
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "size": "large",
      "placeholder": "Только цифры"
    }
  })], 1)], 1), _c('a-col', {
    attrs: {
      "span": "6"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "Описание"
    }
  }, [_c('a-input', {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ['description', {
        rules: [{
          required: false
        }]
      }],
      expression: "[\n                    'description',\n                    {\n                      rules: [\n                        {\n                          required: false\n                        }\n                      ]\n                    }\n                  ]"
    }],
    attrs: {
      "size": "large",
      "placeholder": "Описание программы"
    }
  })], 1)], 1)], 1), _c('a-row', {
    attrs: {
      "gutter": 24
    }
  }, [_c('a-col', {
    attrs: {
      "span": "24"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "Доступно для"
    }
  }, [_c('a-checkbox-group', {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["availabilities", {
        rules: [{
          required: true,
          message: 'Выберите хотя бы одну локаль'
        }]
      }],
      expression: "[\n                    `availabilities`,\n                    {\n                      rules: [\n                        {\n                          required: true,\n                          message: 'Выберите хотя бы одну локаль'\n                        }\n                      ]\n                    }\n                  ]"
    }],
    attrs: {
      "options": _vm.availabilityOptions
    }
  })], 1)], 1)], 1)], 1), _c('a-row', [!_vm.hasEditMode ? _c('a-button', {
    attrs: {
      "size": "large"
    },
    on: {
      "click": _vm.addPartnerFile
    }
  }, [_vm._v("Добавить ссылку")]) : _vm._e(), _vm.hasEditMode ? _c('a-button', {
    attrs: {
      "size": "large"
    },
    on: {
      "click": _vm.addPartnerFile
    }
  }, [_vm._v("Сохранить ссылку")]) : _vm._e(), _vm.hasEditMode ? _c('a-button', {
    staticStyle: {
      "margin-left": "15px"
    },
    attrs: {
      "size": "large",
      "type": "primary"
    },
    on: {
      "click": _vm.onCancelEdit
    }
  }, [_vm._v("Отменить ")]) : _vm._e()], 1)], 1) : _vm._e()], 1)], 1)], 1), _c('file-info-modal', {
    attrs: {
      "info": _vm.fileInfo
    },
    on: {
      "close": function close($event) {
        _vm.fileInfo = null;
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }