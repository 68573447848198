var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('PageTitle', {
    attrs: {
      "title": "Категории новостей"
    }
  }, [_c('router-link', {
    attrs: {
      "to": "/news/add-category"
    }
  }, [_c('a-button', {
    attrs: {
      "size": "large",
      "type": "primary",
      "icon": "plus"
    }
  }, [_vm._v("Добавить категорию")])], 1)], 1), _c('a-layout-content', {
    staticClass: "page__content"
  }, [_c('a-row', {
    attrs: {
      "gutter": 24
    }
  }, [_c('a-col', {
    attrs: {
      "span": "22"
    }
  }, [_c('a-input', {
    attrs: {
      "size": "large",
      "placeholder": "Название категории"
    },
    on: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.searchCategories.apply(null, arguments);
      }
    },
    model: {
      value: _vm.search.title,
      callback: function callback($$v) {
        _vm.$set(_vm.search, "title", $$v);
      },
      expression: "search.title"
    }
  })], 1), _c('a-col', {
    attrs: {
      "span": "2"
    }
  }, [_c('a-button', {
    attrs: {
      "type": "primary",
      "icon": "search",
      "size": "large"
    }
  }, [_vm._v("Поиск")])], 1)], 1)], 1), _c('a-layout-content', {
    staticClass: "page__content"
  }, [_c('a-table', {
    attrs: {
      "columns": _vm.columns,
      "pagination": false,
      "dataSource": _vm.newsCategories,
      "loading": _vm.hasLoadNewsCategories,
      "rowKey": "id"
    },
    scopedSlots: _vm._u([{
      key: "parent-category",
      fn: function fn(scope, record) {
        return [record.parentId ? _c('a-tag', {
          attrs: {
            "color": "blue"
          }
        }, [_vm._v(_vm._s(_vm.newsCategories.find(function (c) {
          return c.id === Number(record.parentId);
        }).title))]) : _vm._e()];
      }
    }, {
      key: "actions",
      fn: function fn(id, record) {
        return [_c('a', {
          attrs: {
            "href": record.url,
            "target": "_blank"
          }
        }, [_c('a-button', {
          staticClass: "gray-btn",
          staticStyle: {
            "margin-right": "10px"
          },
          attrs: {
            "type": "primary",
            "icon": "eye"
          }
        })], 1), _c('router-link', {
          attrs: {
            "to": {
              path: "/news/category/".concat(record.id, "/edit")
            }
          }
        }, [_c('a-button', {
          staticClass: "gray-btn",
          staticStyle: {
            "margin-right": "10px"
          },
          attrs: {
            "type": "primary",
            "icon": "edit"
          }
        })], 1), _c('a-button', {
          staticClass: "gray-btn",
          staticStyle: {
            "margin-right": "10px"
          },
          attrs: {
            "type": "primary",
            "icon": "delete"
          },
          on: {
            "click": function click($event) {
              return _vm.deleteCategory(record.id);
            }
          }
        })];
      }
    }, {
      key: "author",
      fn: function fn(author) {
        return [_vm._v(" " + _vm._s(author.name) + " ")];
      }
    }])
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }