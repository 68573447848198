import { get, post, put } from './core';

interface UpdateSeoFrequencyParams {
  id: number;
  frequency: number;
}

const getSeoFrequency = (page: number): Promise<any> =>
  get(`/api/admin/apps/seo-frequency?page=${page}`);

const searchSeoFrequency = (params: any): Promise<any> =>
  get('/api/admin/apps/seo-frequency/search', params);

const importSeoFrequency = (): Promise<any> =>
  post('/api/admin/apps/seo-frequency/import');

const exportSeoFrequency = (): Promise<any> =>
  get('/api/admin/apps/seo-frequency/export');

const updateSeoFrequency = (
  appId: number,
  params: UpdateSeoFrequencyParams,
): Promise<any> => put(`/api/admin/apps/seo-frequency/${appId}`, params);

export const SeoFrequency = {
  getSeoFrequency,
  searchSeoFrequency,
  importSeoFrequency,
  exportSeoFrequency,
  updateSeoFrequency,
};

export default { SeoFrequency };
