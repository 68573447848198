var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('AddPageTitle', {
    attrs: {
      "title": "Добавить versions страницу",
      "id": _vm.id
    }
  }), _c('a-layout-content', {
    staticClass: "page__content"
  }, [_c('TopMenu', {
    attrs: {
      "id": _vm.id,
      "active": "page_versions"
    }
  }), _c('a-spin', {
    attrs: {
      "spinning": _vm.loading
    }
  }, [_c('a-form', {
    attrs: {
      "form": _vm.form
    }
  }, [_c('a-card', {
    attrs: {
      "title": "Локализация"
    },
    scopedSlots: _vm._u([_vm.availableLocales.length ? {
      key: "extra",
      fn: function fn() {
        return [_c('a-dropdown', {
          scopedSlots: _vm._u([{
            key: "overlay",
            fn: function fn() {
              return [_c('a-menu', {
                on: {
                  "click": _vm.addTranslation
                }
              }, _vm._l(_vm.availableLocales, function (locale) {
                return _c('a-menu-item', {
                  key: locale
                }, [_vm._v(" " + _vm._s(_vm.getLocaleTitle(locale)) + " ")]);
              }), 1)];
            },
            proxy: true
          }], null, false, 2503522434)
        }, [_c('a-button', [_vm._v(" Добавить локаль "), _c('a-icon', {
          attrs: {
            "type": "down"
          }
        })], 1)], 1)];
      },
      proxy: true
    } : null], null, true)
  }, [!_vm.loading ? _c('a-tabs', {
    attrs: {
      "type": "card"
    },
    model: {
      value: _vm.activeTab,
      callback: function callback($$v) {
        _vm.activeTab = $$v;
      },
      expression: "activeTab"
    }
  }, [_vm._l(_vm.LOCALE_LIST_EXTENDED, function (_ref) {
    var locale = _ref.locale,
      title = _ref.title;
    return [_vm.page.translations.hasOwnProperty(locale) ? _c('a-tab-pane', {
      key: locale,
      attrs: {
        "tab": title
      }
    }, [_c('a-form-item', {
      attrs: {
        "label": "\u041E\u043F\u0438\u0441\u0430\u043D\u0438\u0435 (".concat(locale, ")")
      }
    }, [_c('ckeditor', {
      directives: [{
        name: "decorator",
        rawName: "v-decorator",
        value: ["translations.".concat(locale, ".content"), {
          initialValue: _vm.page.translations[locale] ? _vm.page.translations[locale].content : undefined
        }],
        expression: "[`translations.${locale}.content`, {initialValue: page.translations[locale] ? page.translations[locale].content : undefined}]"
      }],
      attrs: {
        "config": _vm.editorConfig()
      },
      on: {
        "input": function input($event) {
          var _vm$form$setFieldsVal;
          return _vm.form.setFieldsValue((_vm$form$setFieldsVal = {}, _vm$form$setFieldsVal["translations.".concat(locale, ".content")] = $event, _vm$form$setFieldsVal));
        }
      }
    })], 1), _c('a-form-item', {
      attrs: {
        "label": "H1 (".concat(locale, ")")
      }
    }, [_c('a-input', {
      directives: [{
        name: "decorator",
        rawName: "v-decorator",
        value: ["translations.".concat(locale, ".h1"), {
          initialValue: _vm.page.translations[locale] ? _vm.page.translations[locale].h1 : undefined
        }],
        expression: "[`translations.${locale}.h1`,{initialValue: page.translations[locale] ? page.translations[locale].h1 : undefined}]"
      }],
      attrs: {
        "size": "large",
        "placeholder": "Введите h1"
      }
    })], 1), _c('div', {
      staticClass: "spin-content"
    }, [_c('a-row', {
      attrs: {
        "gutter": "24"
      }
    }, [_c('a-col', [_c('a-form-item', {
      attrs: {
        "label": "SeoTitle (".concat(locale, ")")
      }
    }, [_c('a-input', {
      directives: [{
        name: "decorator",
        rawName: "v-decorator",
        value: ["translations.".concat(locale, ".seo_title"), {
          initialValue: _vm.page.translations[locale] ? _vm.page.translations[locale].seo_title : undefined
        }],
        expression: "[`translations.${locale}.seo_title`,{initialValue: page.translations[locale] ? page.translations[locale].seo_title : undefined}]"
      }],
      attrs: {
        "size": "large",
        "placeholder": "Введите title"
      }
    })], 1)], 1), _c('a-col', [_c('a-form-item', {
      attrs: {
        "label": "SeoDescription (".concat(locale, ")")
      }
    }, [_c('a-textarea', {
      directives: [{
        name: "decorator",
        rawName: "v-decorator",
        value: ["translations.".concat(locale, ".seo_description"), {
          initialValue: _vm.page.translations[locale] ? _vm.page.translations[locale].seo_description : undefined
        }],
        expression: "[`translations.${locale}.seo_description`,{initialValue: page.translations[locale] ? page.translations[locale].seo_description : undefined,}]"
      }],
      staticStyle: {
        "height": "145px"
      },
      attrs: {
        "auto-size": "",
        "size": "large",
        "placeholder": "Введите description"
      }
    })], 1)], 1), _c('a-col', [_c('a-form-item', {
      attrs: {
        "label": "SeoKeywords (".concat(locale, ")")
      }
    }, [_c('a-input', {
      directives: [{
        name: "decorator",
        rawName: "v-decorator",
        value: ["translations.".concat(locale, ".seo_keywords"), {
          initialValue: _vm.page.translations[locale] ? _vm.page.translations[locale].seo_keywords : undefined
        }],
        expression: "[`translations.${locale}.seo_keywords`,{initialValue: page.translations[locale]? page.translations[locale].seo_keywords : undefined,}]"
      }],
      attrs: {
        "size": "large",
        "placeholder": "Введите keywords"
      }
    })], 1)], 1)], 1)], 1)], 1) : _vm._e()];
  })], 2) : _vm._e()], 1)], 1), _c('a-row', {
    attrs: {
      "gutter": 24
    }
  }, [_c('a-col', {
    attrs: {
      "span": "12"
    }
  }, [_c('a-button', {
    attrs: {
      "size": "large",
      "type": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("Сохранить")])], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }