
// @ts-nocheck
import Vue from 'vue';
import PageTitle from '@/components/Global/PageTitle.vue';
import { Moderation } from '@/api/moderation';
import { applications } from '@/api';
import { mapState } from 'vuex';
import moment from 'moment';
import { Developer } from '@/api/developer';

export default Vue.extend({
  components: {
    PageTitle,
  },
  computed: mapState(['user']),
  props: ['appId'],
  data() {
    return {
      message: '',
      messageList: '',
      hasLoadMessageList: false,
      title: '',
    };
  },
  mounted() {
    this.getApplicationById();
    this.getModerationHistoryByAppId();
  },
  methods: {
    /**
     * @description Повторная отправка приложения на модерацию (админу)
     * @return {Promise<any>}
     */
    async sendToModeration(): Promise<any> {
      await Developer.sendApplicationToModeration(this.appId, {
        message: this.message,
      }).then(async (res: Response): Promise<any> => {
        if (res.status === 200) {
          this.$message.success('Статус модерации успешно обновлен :)');
          await this.getModerationHistoryByAppId();
        }
      });
    },

    /**
     * @description Получение приложения по id
     * @return {Promise<any>}
     */
    async getApplicationById(): Promise<any> {
      await applications
        .getAppById(this.appId)
        .then((res: Response): Promise<any> => res.json())
        .then(({ data: { name } }) => {
          this.title = `Модерация ${name}`;
        });
    },
    /**
     * @description Получение истории модерации для приложению
     * @return {Promise<any>}
     */
    async getModerationHistoryByAppId(): Promise<any> {
      this.hasLoadMessageList = true;
      await Moderation.getModerationHistoryByAppId(this.appId)
        .then((res: Response): Promise<any> => res.json())
        .then(({ data: messageList }) => {
          this.messageList = messageList.map((message: any) => ({
            ...message,
            createdAt: moment(message.createdAt).fromNow(),
          }));

          this.hasLoadMessageList = false;
        });
    },
    /**
     * @description Отправка статуса модерации
     * @return {Promise<any>}
     */
    async handleSubmit(): Promise<any> {
      await Moderation.updateModerationStatus(this.appId, {
        status: 'rejected',
        message: this.message,
      }).then(async (res: Response): Promise<any> => {
        if (res.status === 200) {
          this.$message.success('Статус модерации успешно обновлен :)');
          await this.getModerationHistoryByAppId();
        }
      });
    },
  },
});
