<template>
  <div>
    <PageTitle title="Официальный представитель запросы"/>
    <a-layout-content class="page__content">
      <a-table
        :columns="columns"
        :pagination="false"
        :dataSource="requests.data"
        :loading="loading"
        rowKey="id"
      >
        <template slot="verified_at" slot-scope="verified_at">
          {{ verified_at ? 'Да' : 'Нет' }}
        </template>

        <template slot="application" slot-scope="application">
          {{ application.name }}
        </template>

        <template slot="status" slot-scope="status">
          {{ statusMap[status] }}
        </template>

        <template slot="actions" slot-scope="row">
          <template v-if="row.verified_at && row.status === OFFICIAL_REPRESENTATIVE_REQUEST_STATUS_PENDING">
            <a-button
              type="primary"
              @click.prevent="accept(row)"
            >Одобрить
            </a-button>

            <a-button
              type="danger"
              style="margin-left: 10px"
              @click.prevent="decline(row)"
            >Отклонить
            </a-button>
          </template>
        </template>
      </a-table>

      <a-row style="text-align: right;">
        <a-pagination
          v-if="requests.meta"
          @change="paginationChange"
          v-model="requests.meta.current_page"
          :total="requests.meta.total"
          :pageSize="requests.meta.per_page"
          style="margin-top: 20px"
        />
      </a-row>
    </a-layout-content>
  </div>
</template>

<script>
import {
  acceptOfficialRepresentativeRequests, declineOfficialRepresentativeRequests,
  getOfficialRepresentativeRequests,
} from '@/api/officialRepresentativeRequests';
import {
  OFFICIAL_REPRESENTATIVE_REQUEST_STATUS_ACCEPTED, OFFICIAL_REPRESENTATIVE_REQUEST_STATUS_DECLINED,
  OFFICIAL_REPRESENTATIVE_REQUEST_STATUS_PENDING,
} from '@/global/constants';

export default {
  name: 'OfficialRepresentativeRequests',
  data() {
    return {
      OFFICIAL_REPRESENTATIVE_REQUEST_STATUS_PENDING,
      loading     : true,
      requests    : [],
      searchParams: {
        page: 1,
      },
      columns     : [
        {
          title    : 'ID',
          dataIndex: 'id',
          key      : 'id',
          sorter   : true,
        },
        {
          title         : 'Email',
          dataIndex     : 'email',
          key           : 'email',
          sorter        : true,
          sortDirections: ['asc', 'desc'],
        },
        {
          title         : 'Статус',
          dataIndex     : 'status',
          key           : 'status',
          sorter        : true,
          sortDirections: ['asc', 'desc'],
          scopedSlots   : { customRender: 'status' },
        },
        {
          title         : 'Подтвержден',
          dataIndex     : 'verified_at',
          key           : 'verified_at',
          sorter        : true,
          sortDirections: ['asc', 'desc'],
          scopedSlots   : { customRender: 'verified_at' },
        },
        {
          title      : 'Приложение',
          dataIndex  : 'application',
          key        : 'application',
          sorter     : false,
          scopedSlots: { customRender: 'application' },
        },
        {
          title      : 'Дата',
          dataIndex  : 'created_at',
          key        : 'created_at',
          sorter     : false,
        },
        {
          title      : 'Действия',
          dataIndex  : '',
          key        : 'actions',
          scopedSlots: { customRender: 'actions' },
        },
      ],
      statusMap   : {
        [OFFICIAL_REPRESENTATIVE_REQUEST_STATUS_PENDING] : 'В обработке',
        [OFFICIAL_REPRESENTATIVE_REQUEST_STATUS_ACCEPTED]: 'Одобрен',
        [OFFICIAL_REPRESENTATIVE_REQUEST_STATUS_DECLINED]: 'Отклонен',
      },
    };
  },
  created() {
    this.getRequests({ page: 1 });
  },
  methods: {
    async getRequests(params) {
      this.loading = true;

      const res = await getOfficialRepresentativeRequests({ ...params });

      const data = await res.json();

      this.requests = data;
      this.loading  = false;
    },

    paginationChange(page) {
      this.searchParams = { ...this.searchParams, page };
      this.getRequests({ ...this.searchParams, ...this.sortParams });
      window.scrollTo(0, 0);
    },

    accept(row) {
      this.$confirm({
        title: 'Вы действительно хотите одобрить заявку?',
        onOk : async () => {
          this.loading = true;

          const res  = await acceptOfficialRepresentativeRequests(row.id);
          const data = await res.json();

          const index = this.requests.data.findIndex((request) => request.id === row.id);
          this.requests.data.splice(index, 1, data.data);

          this.loading = false;
        },
      });
    },

    decline(row) {
      this.$confirm({
        title: 'Вы действительно хотите отклонить заявку?',
        onOk : async () => {
          this.loading = true;

          const res  = await declineOfficialRepresentativeRequests(row.id);
          const data = await res.json();

          const index = this.requests.data.findIndex((request) => request.id === row.id);
          this.requests.data.splice(index, 1, data.data);

          this.loading = false;
        },
      });
    },
  },
};
</script>

<style scoped>

</style>
