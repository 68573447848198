import { get, post, deleteMethod, put } from '@/api/core';

export const getOsList = (): Promise<any> => get('/api/admin/operation-system');

export const getOsById = (id: number) =>
  get(`/api/admin/operation-system/${id}`);

export const createOs = (params: any) =>
  post('/api/admin/operation-system', params);

export const updateOs = (id: number, params: any) =>
  put(`/api/admin/operation-system/${id}`, params);

const deleteOs = (id: number) =>
  deleteMethod(`/api/admin/operation-system/${id}`);

export default {
  getOsList,
  createOs,
  updateOs,
  deleteOs,
  getOsById,
};
