var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a-layout-content', {
    staticClass: "page__content"
  }, [_c('a-row', {
    attrs: {
      "guttter": 24
    }
  }, [_c('a-col', {
    attrs: {
      "span": "8"
    }
  }, [_vm.message.length !== 0 ? _c('a-alert', {
    attrs: {
      "showIcon": "",
      "message": _vm.message,
      "type": "info"
    }
  }) : _vm._e()], 1), _c('a-col', {
    staticStyle: {
      "text-align": "right"
    },
    attrs: {
      "span": "16"
    }
  }, [_vm.canBeRejected ? _c('router-link', {
    attrs: {
      "to": {
        path: "/moderation/".concat(_vm.appId, "/decline")
      }
    }
  }, [_c('a-button', {
    staticStyle: {
      "padding": "0 35px"
    },
    attrs: {
      "size": "large",
      "type": "danger"
    }
  }, [_vm._v("Отклонить")])], 1) : _vm._e(), _vm.canBePublished ? _c('a-button', {
    staticStyle: {
      "margin-left": "20px",
      "padding": "0 35px"
    },
    attrs: {
      "size": "large",
      "type": "primary"
    },
    on: {
      "click": _vm.handlePublishApp
    }
  }, [_vm._v("Опубликовать ")]) : _vm._e()], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }