
// @ts-nocheck
import PageTitle from '@/components/Global/PageTitle.vue';
import Vue from 'vue';
import {Moderation} from '@/api/moderation';
import {applications} from '@/api';
import {
  MODERATION_STATUS_ACCEPTED,
  MODERATION_STATUS_NOT_MODERATED,
  MODERATION_STATUS_REJECTED,
} from '@/global/constants';

export default Vue.extend({
  components: {
    PageTitle,
  },
  data() {
    return {
      filters: {
        moderate_status: MODERATION_STATUS_NOT_MODERATED,
      },
      statuses: [
        {
          name: 'опубликовано на сайте',
          value: MODERATION_STATUS_ACCEPTED,
        },
        {
          name: 'модерация не пройдена',
          value: MODERATION_STATUS_REJECTED,
        },
        {
          name: 'ожидает модерации',
          value: MODERATION_STATUS_NOT_MODERATED,
        },
        {
          name: 'Любой',
          value: null,
        },
      ],
      moderation: {
        data: [],
        meta: {
          current_page: 1,
          total: 0,
          per_page: 0,
        },
        links: {},
      },
      hasLoadModerationList: false,
      columns: [
        {
          title: 'Название программы',
          dataIndex: 'name',
          key: 'name',
          sorter: false,
          sortDirections: ['asc', 'desc'],
        },
        {
          title: 'Разработчик',
          dataIndex: 'developer',
          key: 'developer_id',
          scopedSlots: {customRender: 'developer'},
          sorter: true,
          sortDirections: ['asc', 'desc'],
        },
        {
          title: 'Дата добавления',
          dataIndex: 'createdAt',
          key: 'created_at',
          sorter: true,
          sortDirections: ['asc', 'desc'],
        },
        {
          title: 'Статус',
          dataIndex: 'moderate_status_label',
          key: 'moderate_status',
          sorter: true,
          sortDirections: ['asc', 'desc'],
        },
        {
          title: 'Дата модерации',
          dataIndex: 'moderated_at',
          key: 'moderated_at',
          sorter: true,
          sortDirections: ['asc', 'desc'],
        },
        {
          title: 'Модератор',
          dataIndex: 'moderator',
          key: 'moderator_id',
          scopedSlots: {customRender: 'moderator'},
          sorter: true,
          sortDirections: ['asc', 'desc'],
        },
        {
          title: 'Действия',
          dataIndex: 'actions',
          key: 'actions',
          scopedSlots: {customRender: 'actions'},
        },
      ],
    };
  },
  created() {
    this.getModerationAppsList();
  },
  methods: {
    /**
     * @description Обработчик на change таблички
     * @param {object} pagination
     * @param {object} filters
     * @param {object} sorter
     * @return {Promise<any>}
     */
    onChange(pagination: any, filters: any, sorter: any) {
      const {order, columnKey} = sorter;
      this.getModerationAppsList({orderBy: columnKey, orderDirection: order});
    },

    async getModerationAppsList(params: {} = {}): Promise<any> {
      const paramsAndFilters = Object.assign({filters: this.filters}, params);

      this.hasLoadModerationList = true;
      await Moderation.getModerationAppsList(paramsAndFilters)
        .then((res) => res.json())
        .then((data) => {
          this.moderation = data;
          this.hasLoadModerationList = false;
        });
    },

    /**
     * @description Удаление приложения
     * @param {number} id
     * @param {string} name
     * @return {Promise<any>}
     */
    deleteApplication(id: number, name: string): void {
      this.$confirm({
        title: `Вы действительно хотите удалить приложение ${name}?`,
        content:
          'Вы не сможете восстановить его после удаления, только добавить еще раз.',
        onOk: async () => {
          await applications.deleteAppById(id).then(() => {
            this.$message.success('Приложение успешно удаленно :)', 2);
            this.getModerationAppsList();
          });
        },
      });
    },

    /**
     * @description Обработчик на change компонента с пагинацией
     * @param {number} page
     * @return {void}
     */
    async paginationChange(page: number): Promise<any> {
      await this.getModerationAppsList({page});
      window.scrollTo(0, 0);
    },
  },
});
