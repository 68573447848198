
// @ts-nocheck
import Vue from 'vue';
import PageTitle from '@/components/Global/PageTitle.vue';
import { applications } from '@/api/Applications';

export default Vue.extend({
  components: {
    PageTitle,
  },

  data() {
    return {
      page: 1,
      landings: {
        data: [],
        meta: {},
        links: {},
      },
      hasLoadLandings: false,
      columns: [
        {
          title: 'Приложение',
          dataIndex: 'application',
          key: 'application',
          scopedSlots: { customRender: 'app' },
        },
        {
          title: 'Видимость',
          dataIndex: 'visible',
          key: 'visible',
          scopedSlots: { customRender: 'visible' },
        },
        {
          title: 'Действия',
          dataIndex: 'actions',
          key: 'actions',
          scopedSlots: { customRender: 'actions' },
        },
      ],
    };
  },

  //
  //         <Icon type="check-circle" style={{ fontSize: 20 }} theme="twoTone" twoToneColor="#52c41a" />
  // ) : (
  //         <Icon type="close-circle" style={{ fontSize: 20 }} theme="twoTone" twoToneColor="#f5222d" />

  methods: {
    /**
     * @description Обработчик на переключение видимости у лендинга
     * @param {boolean} checked
     * @param {number} landingId
     * @param {number} appId
     */
    handleChange(checked: boolean, landingId: number, appId: number) {
      applications
        .updateLanding(landingId, {
          visible: Number(checked),
          applicationId: appId,
        })
        .then(async (res: Response) => {
          if (res.status === 200) {
            await this.getLandingsList();
          }
        });
    },

    /**
     * @description Удаление лендинга
     * @param {number} id
     * @return {void}
     */
    handleDeleteLanding(id: number): void {
      this.$confirm({
        title: 'Вы действительно хотите удалить данный лендинг?',
        onOk: async () => {
          await applications.deleteLanding(id).then(async (res: Response) => {
            if (res.status === 200) {
              this.$message.success('Лендинг успешно удален', 2);
              await this.getLandingsList();
            }
          });
        },
      });
    },
    /**
     * @description Получение списка всех лендингов
     * @return {Promise<any>}
     */
    getLandingsList() {
      this.hasLoadLandings = true;
      applications
        .getLandingsList(this.page)
        .then((res: Response) => res.json())
        .then((data: any) => {
          this.landings = data;
          this.hasLoadLandings = false;
        });
    },
    /**
     * @description Обработчик на пагинацию
     * @param {number} page
     * @return {void}
     */
    paginationChange(page: number) {
      this.page = page;
    },
  },

  mounted() {
    this.getLandingsList();
  },
});
