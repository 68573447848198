
// @ts-nocheck
import Vue from 'vue';
import PageTitle from '@/components/Global/PageTitle.vue';
import { applications } from '@/api';
import { mapState } from 'vuex';

export default Vue.extend({
  props: ['id'],
  components: { PageTitle },
  computed: mapState(['operationSystems']),
  data() {
    return {
      title: '',
      action: '',
      osId: 0,
      baseVersionOs: {
        id: 0,
        name: '',
        label: '',
        enLabel: '',
        seoName: '',
        operationSystem: {
          id: 0,
          name: '',
          seo_name: '',
          label: '',
          en_label: '',
        },
        updatedAt: '',
        createdAt: '',
      },
    };
  },

  methods: {
    /**
     * @description
     * @return {Promise<any>}
     */
    async handleSubmit(): Promise<any> {
      const params = {
        name: this.baseVersionOs.name,
        seoName: this.baseVersionOs.seoName,
        label: this.baseVersionOs.label,
        enLabel: this.baseVersionOs.enLabel,
        operationSystemId: this.baseVersionOs.operationSystem.id,
      };

      const response = this.id ? await applications.updateOsVersion(this.id, params)
        : await applications.createOsVersion(params);

      if (response.ok) {
        const message = this.id ? 'Версия операционной системы успешно отредактирована' : 'Версия операционной системы успешно создана';
        this.$message.success(message, 2);
        this.$store.dispatch('GET_OPERATION_SYSTEMS_VERSIONS');
      } else {
        const json = await response.json();
        if (json.hasOwnProperty('errors')) {
          Object.values(json.errors).forEach((values: any) => {
            values.forEach((value: string) => {
              this.$message.error(value);
            });
          });
        }
      }
    },

    /**
     * @description Получение версии OS по id
     * @return {Promise<any>}
     */
    async getVersionOsById() {
      await applications
        .getVersionOsById(this.id)
        .then((res) => res.json())
        .then(({ data }) => {
          this.baseVersionOs = data;
        });
    },
  },

  mounted() {
    if (this.id) {
      this.action = 'Сохранить';
      this.title = 'Редактирование версии os';
      this.getVersionOsById();
    } else {
      this.action = 'Создать';
      this.title = 'Создание версии os';
    }
  },
});
