var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('PageTitle', {
    attrs: {
      "title": "Логи обновлений"
    }
  }), _c('a-layout-content', {
    staticClass: "page__content"
  }, [_c('h3', [_vm._v("Фильтр")]), _c('a-row', {
    attrs: {
      "gutter": 24
    }
  }, [_c('a-col', {
    attrs: {
      "span": "6"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "Статус"
    }
  }, [_c('a-select', {
    attrs: {
      "size": "large",
      "placeholder": "Статус"
    },
    model: {
      value: _vm.searchParams.status,
      callback: function callback($$v) {
        _vm.$set(_vm.searchParams, "status", $$v);
      },
      expression: "searchParams.status"
    }
  }, [_c('a-select-option', {
    attrs: {
      "value": undefined
    }
  }, [_vm._v("Любой")]), _vm._l(_vm.statuses, function (status) {
    return _c('a-select-option', {
      key: status.value,
      attrs: {
        "value": status.value
      }
    }, [_vm._v(_vm._s(status.name) + " ")]);
  })], 2)], 1)], 1)], 1), _c('a-row', [_c('a-col', {
    attrs: {
      "span": "12"
    }
  }, [_c('a-button', {
    attrs: {
      "size": "large"
    },
    on: {
      "click": _vm.resetFields
    }
  }, [_vm._v("Сбросить")])], 1), _c('a-col', {
    staticStyle: {
      "text-align": "right"
    },
    attrs: {
      "span": "12"
    }
  }, [_c('a-button', {
    staticStyle: {
      "margin-left": "20px"
    },
    attrs: {
      "size": "large",
      "type": "primary",
      "icon": "search"
    },
    on: {
      "click": _vm.handleSearch
    }
  }, [_vm._v("Фильтровать ")])], 1)], 1)], 1), _c('a-layout-content', {
    staticClass: "page__content"
  }, [_c('a-table', {
    attrs: {
      "columns": _vm.columns,
      "pagination": false,
      "dataSource": _vm.logs.data,
      "loading": _vm.loading,
      "rowKey": "id",
      "rowClassName": _vm.rowClassName
    },
    scopedSlots: _vm._u([{
      key: "name",
      fn: function fn(application) {
        return [_c('router-link', {
          attrs: {
            "to": {
              path: "/edit-description/".concat(application.id, "/edit")
            }
          }
        }, [_vm._v(" " + _vm._s(application.name) + " ")])];
      }
    }])
  }), _c('a-row', {
    staticStyle: {
      "text-align": "right"
    }
  }, [_vm.logs.meta ? _c('a-pagination', {
    staticStyle: {
      "margin-top": "20px"
    },
    attrs: {
      "total": _vm.logs.meta.total,
      "pageSize": _vm.logs.meta.per_page
    },
    on: {
      "change": _vm.paginationChange
    },
    model: {
      value: _vm.logs.meta.current_page,
      callback: function callback($$v) {
        _vm.$set(_vm.logs.meta, "current_page", $$v);
      },
      expression: "logs.meta.current_page"
    }
  }) : _vm._e()], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }