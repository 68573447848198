
// @ts-nocheck
import { Component, Vue } from 'vue-property-decorator';
import { FSInstall } from '@/types/FSInstall';
import { settings } from '@/api/';
import PageTitle from '@/components/Global/PageTitle.vue';

@Component({
    name: 'Edit',
    components: { PageTitle },
})

export default class Edit extends Vue {
    buttonDisable: boolean = false;

    fs: FSInstall = {
        button: 0,
        link: '',
    };

    mounted() {
        settings.getFSInstall()
            .then((res) => res.json())
            .then((data) => { this.fs = data; });
    }

    saveCode() {
            this.buttonDisable = true;
            settings.setFSInstall(this.fs).then((res) => {
                const { status } = res;
                if (status === 201) {
                    this.$message.success('Успешно сохранен');
                    this.buttonDisable = false;
                }
                return res.json();
            })
            .then(({ code }) => {
                if (!code) {
                    this.fs = code;
                }
            })
            .catch(() => {
                this.$message.error('Сохранить не удалось');
                this.buttonDisable = false;
            });
        }
    }
