import { deleteMethod, get, post, put } from '@/api/core';
import { License } from '@/types';

/**
 * @description Получение типов лицензий
 * @return {Promise<any>}
 */
export const getLicenseTypes = (): Promise<any> =>
  get('/api/admin/apps/licenses');

/**
 * @description Создание типа лицензии
 * @return {Promise<any>}
 */
const createLicenseType = (params: License): Promise<any> =>
  post('/api/admin/apps/licenses', params);

/**
 * @description Поиск по лицензиям
 * @param {object} params
 * @return {Promise<any>}
 */
const searchLicenseTypes = (params?: License): Promise<any> =>
  post(`/api/admin/apps/licenses/search`, params);

/**
 * @description Получить тип лицензии по id
 * @param {number} id
 * @return {Promise<any>}
 */
const getLicenseTypeById = (id: number): Promise<any> =>
  get(`/api/admin/apps/licenses/${id}`);

/**
 * @description Удаление типа лицензии
 * @param {number} id
 * @return {Promise<any>}
 */
const deleteLicenseType = (id: number): Promise<any> =>
  deleteMethod(`/api/admin/apps/licenses/${id}`);

/**
 * @description Обновление типа лицензии
 * @param {number} id
 * @param {object} params
 * @return {Promise<any>}
 */
const updateLicenseType = (id: number, params: License): Promise<any> =>
  put(`/api/admin/apps/licenses/${id}`, params);

export default {
  getLicenseTypes,
  createLicenseType,
  searchLicenseTypes,
  getLicenseTypeById,
  deleteLicenseType,
  updateLicenseType,
};
