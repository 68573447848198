<template>
  <PageTitle :title="title">
    <a
      v-if="url"
      :href="url"
      target="_blank"
    >
      <a-button
        type="primary"
        icon="eye"
      >Просмотреть
      </a-button>
    </a>
  </PageTitle>
</template>

<script>
import { applications } from '@/api';
import PageTitle from '@/components/Global/PageTitle';
import { MODERATION_STATUS_ACCEPTED } from '@/global/constants';

export default {
  name      : 'AddPageTitle',
  components: { PageTitle },
  props     : {
    title: {
      required: true,
      type    : String,
    },
    id   : {
      required: false,
      type    : [String, Number],
    },
  },
  data() {
    return {
      url: '',
    };
  },
  async created() {
    if (this.id) {
      const response = await applications.getAppById(this.id);
      const json     = await response.json();
      this.url       = json.data.moderate_status === MODERATION_STATUS_ACCEPTED ? json.data.url : '';
    }
  },
};
</script>

<style scoped>

</style>
