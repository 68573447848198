var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('PageTitle', {
    attrs: {
      "title": "Логирование"
    }
  }), _c('a-layout-content', {
    staticClass: "page__content"
  }, [_c('a-row', {
    attrs: {
      "gutter": 24
    }
  }, [_c('a-col', {
    attrs: {
      "span": "19"
    }
  }, [_c('a-input', {
    attrs: {
      "size": "large",
      "placeholder": "Email пользователя"
    },
    on: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.handleSubmit.apply(null, arguments);
      }
    },
    model: {
      value: _vm.filter.user,
      callback: function callback($$v) {
        _vm.$set(_vm.filter, "user", $$v);
      },
      expression: "filter.user"
    }
  })], 1), _c('a-col', {
    attrs: {
      "span": "5"
    }
  }, [_c('a-range-picker', {
    attrs: {
      "placeholder": ['Начало', 'Конец'],
      "size": "large"
    },
    model: {
      value: _vm.dates,
      callback: function callback($$v) {
        _vm.dates = $$v;
      },
      expression: "dates"
    }
  })], 1), _c('a-col', {
    staticStyle: {
      "margin-top": "25px"
    },
    attrs: {
      "span": "24"
    }
  }, [_c('a-button', {
    staticStyle: {
      "margin-right": "25px"
    },
    attrs: {
      "size": "large",
      "type": "primary",
      "icon": "search"
    },
    on: {
      "click": _vm.handleSubmit
    }
  }, [_vm._v("Поиск")]), _c('a-button', {
    attrs: {
      "size": "large"
    },
    on: {
      "click": _vm.resetFilter
    }
  }, [_vm._v("Сбросить")])], 1)], 1)], 1), _c('a-layout-content', {
    staticClass: "page__content"
  }, [_c('a-table', {
    attrs: {
      "columns": _vm.columns,
      "dataSource": _vm.logs.data,
      "pagination": false
    },
    on: {
      "change": _vm.onChange
    },
    scopedSlots: _vm._u([{
      key: "model",
      fn: function fn(model) {
        return [_c('p', [_vm._v(_vm._s(model.split(':')[1]))])];
      }
    }, {
      key: "html",
      fn: function fn(html) {
        return [_c('a-popover', {
          attrs: {
            "title": _vm.Изменения
          }
        }, [_c('template', {
          slot: "content"
        }, [_c('span', {
          staticClass: "code-style",
          domProps: {
            "innerHTML": _vm._s(html)
          }
        })]), html.length >= 50 ? _c('p', {
          staticClass: "code-style"
        }, [_vm._v(" " + _vm._s(html.substr(0, 30)) + " ... ")]) : _vm._e(), html.length <= 50 ? _c('span', {
          staticClass: "code-style",
          domProps: {
            "innerHTML": _vm._s(html)
          }
        }) : _vm._e()], 2)];
      }
    }, {
      key: "action",
      fn: function fn(action) {
        return [action === 'Delete' ? _c('p', [_c('a-tag', {
          attrs: {
            "color": "red"
          }
        }, [_vm._v("Удаление")])], 1) : _vm._e(), action === 'Create' ? _c('p', [_c('a-tag', {
          attrs: {
            "color": "green"
          }
        }, [_vm._v("Создание")])], 1) : _vm._e(), action === 'Update' ? _c('p', [_c('a-tag', {
          attrs: {
            "color": "blue"
          }
        }, [_vm._v("Обновление")])], 1) : _vm._e(), action === 'Restore' ? _c('p', [_c('a-tag', {
          attrs: {
            "color": "blue"
          }
        }, [_vm._v("Восстановление")])], 1) : _vm._e()];
      }
    }])
  }), _c('a-row', {
    staticStyle: {
      "text-align": "right"
    }
  }, [_c('a-pagination', {
    attrs: {
      "total": _vm.logs.meta.total,
      "pageSize": _vm.logs.meta.per_page
    },
    on: {
      "change": _vm.paginationChange
    },
    model: {
      value: _vm.logs.meta.current_page,
      callback: function callback($$v) {
        _vm.$set(_vm.logs.meta, "current_page", $$v);
      },
      expression: "logs.meta.current_page"
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }