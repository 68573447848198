import Apps from './Apps.vue';
import Categories from './Categories.vue';
import ModifyCategory from './ModifyCategory.vue';

import {Route} from '@/types/route';

export const CategorizationAppsRoute = '/categorization/apps';
export const CategorizationCategoriesRoute = '/categorization/categories';
export const CategorizationCategoryCreateRoute = '/categorization/categories/create';
export const CategorizationCategoryEditRoute = '/categorization/categories/:id';

export const categorization: Route[] = [
  {
    name: 'CategorizationApps',
    path: CategorizationAppsRoute,
    component: Apps,
    breadcrumbName: 'Программы',
    meta: {
      title: 'Программы',
    },
  },
  {
    name: 'CategorizationCategories',
    path: CategorizationCategoriesRoute,
    component: Categories,
    breadcrumbName: 'Категории',
    meta: {
      title: 'Категории',
    },
  },
  {
    name: 'CategoryCreate',
    path: CategorizationCategoryCreateRoute,
    component: ModifyCategory,
    breadcrumbName: 'Создать категорию',
    meta: {
      title: 'Создать категорию',
    },
  },
  {
    name: 'EditCreate',
    path: CategorizationCategoryEditRoute,
    component: ModifyCategory,
    breadcrumbName: 'Редактировать категорию',
    meta: {
      title: 'Редактировать категорию',
    },
    props: true,
  },
];

export default {categorization};
