var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('PageTitle', {
    attrs: {
      "title": "Реклама"
    }
  }, [_c('router-link', {
    attrs: {
      "to": "/advert/add"
    }
  }, [_c('a-button', {
    staticStyle: {
      "margin-right": "24px"
    },
    attrs: {
      "size": "large",
      "type": "primary"
    }
  }, [_vm._v("Добавить")])], 1)], 1), _c('a-layout-content', {
    staticClass: "page__content"
  }, [_c('a-table', {
    attrs: {
      "dataSource": _vm.advertBlocks,
      "columns": _vm.columns,
      "pagination": false,
      "bordered": false,
      "loading": _vm.hasLoadAdvertBlocks,
      "rowKey": "id"
    },
    scopedSlots: _vm._u([{
      key: "position",
      fn: function fn(position, record) {
        return _vm._l(position, function (positionItem, index) {
          return _c('a-tag', {
            key: index,
            staticStyle: {
              "margin": "5px",
              "font-size": "14px"
            },
            attrs: {
              "color": "blue"
            }
          }, [_vm._v(" " + _vm._s(positionItem.route) + " - " + _vm._s(positionItem.position) + " ")]);
        });
      }
    }, {
      key: "desktop",
      fn: function fn(desktop) {
        return [_c('p', {
          staticStyle: {
            "width": "300px"
          }
        }, [_vm._v(_vm._s(desktop))])];
      }
    }, {
      key: "mobile",
      fn: function fn(mobile) {
        return [_c('p', {
          staticStyle: {
            "width": "300px"
          }
        }, [_vm._v(" " + _vm._s(mobile))])];
      }
    }, {
      key: "actions",
      fn: function fn(operation, record) {
        return [record.isVisible === 0 ? _c('a-button', {
          staticClass: "gray-btn",
          staticStyle: {
            "margin-right": "10px",
            "border": "1px solid #007B14",
            "color": "#007B14",
            "background": "transparent"
          },
          attrs: {
            "icon": "play-circle"
          },
          on: {
            "click": function click($event) {
              return _vm.setVisibleBanner(record);
            }
          }
        }) : _vm._e(), record.isVisible === 1 ? _c('a-button', {
          staticClass: "gray-btn",
          staticStyle: {
            "margin-right": "10px",
            "border": "1px solid #BD0909",
            "color": "#BD0909",
            "background": "transparent"
          },
          attrs: {
            "icon": "pause-circle"
          },
          on: {
            "click": function click($event) {
              return _vm.setUnVisibleBanner(record);
            }
          }
        }) : _vm._e(), _c('router-link', {
          attrs: {
            "to": {
              path: "/advert/".concat(record.id, "/change")
            }
          }
        }, [_c('a-button', {
          staticClass: "gray-btn",
          staticStyle: {
            "margin-right": "10px"
          },
          attrs: {
            "type": "primary",
            "icon": "edit"
          }
        })], 1), _c('a-button', {
          staticClass: "gray-btn",
          attrs: {
            "type": "danger",
            "icon": "delete"
          },
          on: {
            "click": function click($event) {
              return _vm.handleDeleteBanner(record.id);
            }
          }
        })];
      }
    }])
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }