import { get, post, put, deleteMethod } from '@/api/core';

/**
 * @description Получить статичные страницы
 * @return {Promise<any>}
 */
export const getStaticPages = (page = 1): Promise<any> =>
  get(`/api/admin/page?page=${page}`);

/**
 * @description Создать статичную страницу
 * @param {object} params
 * @return {Promise<any>}
 */
export const createStaticPage = (params: any): Promise<any> =>
  post('/api/admin/page', params);

/**
 * @description Обновление статичной страницы
 * @param {number} id
 * @param {object} params
 * @return {Promise<any>}
 */
export const updateStaticPage = (id: number, params: any): Promise<any> =>
  put(`/api/admin/page/${id}`, params);

/**
 * @description Удаление статичной страницы
 * @param {number} id
 * @return {Promise<any>}
 */
export const deleteStaticPage = (id: number) =>
  deleteMethod(`/api/admin/page/${id}`);

/**
 * @description Получить статичную страницу по id
 * @param {number} id
 * @return {Promise<any>}
 */
export const getStaticPageById = (id: number): Promise<any> =>
  get(`/api/admin/page/${id}`);

export const staticPages = {
  getStaticPages,
  createStaticPage,
  updateStaticPage,
  deleteStaticPage,
  getStaticPageById,
};

export default { staticPages };
