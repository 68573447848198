var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page__header"
  }, [_c('a-row', {
    attrs: {
      "gutter": 24
    }
  }, [_c('a-col', {
    attrs: {
      "span": 12
    }
  }, [_c('a-icon', {
    style: {
      fontSize: '24px',
      margin: '5px 0 0 0'
    },
    attrs: {
      "type": "menu-fold"
    },
    on: {
      "click": _vm.setStatus
    }
  })], 1), _c('a-col', {
    staticStyle: {
      "text-align": "right",
      "margin-top": "5px"
    },
    attrs: {
      "span": 12
    }
  }, [_c('a-icon', {
    staticStyle: {
      "font-size": "20px"
    },
    attrs: {
      "type": "logout"
    }
  }), _c('span', {
    staticClass: "logout",
    on: {
      "click": _vm.logout
    }
  }, [_vm._v("Выйти")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }