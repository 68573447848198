var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('PageTitle', {
    attrs: {
      "title": "FS install"
    }
  }), _c('a-layout-content', {
    style: {
      margin: '24px 16px',
      padding: '24px',
      background: '#fff',
      minHeight: '280px'
    }
  }, [_c('a-form', [_c('a-form-item', {
    attrs: {
      "label": "Кнопку FS install на всех страницах"
    }
  }, [_c('a-select', {
    attrs: {
      "defaultValue": 0
    },
    model: {
      value: _vm.fs.button,
      callback: function callback($$v) {
        _vm.$set(_vm.fs, "button", $$v);
      },
      expression: "fs.button"
    }
  }, [_c('a-select-option', {
    attrs: {
      "value": 1
    }
  }, [_vm._v("Вкл.")]), _c('a-select-option', {
    attrs: {
      "value": 0
    }
  }, [_vm._v("Выкл.")])], 1)], 1), _c('a-form-item', {
    attrs: {
      "label": "Ссылка на файл FS install"
    }
  }, [_c('a-input', {
    attrs: {
      "placeholder": "Ссылка на файл FS install"
    },
    model: {
      value: _vm.fs.link,
      callback: function callback($$v) {
        _vm.$set(_vm.fs, "link", $$v);
      },
      expression: "fs.link"
    }
  })], 1), _c('a-button', {
    attrs: {
      "type": "success",
      "disabled": _vm.buttonDisable
    },
    on: {
      "click": _vm.saveCode
    }
  }, [_vm._v("Сохранить")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }