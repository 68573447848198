<template>
  <div>
    <PageTitle title="Комментарии новостей"/>
    <a-layout-content class="page__content">
      <a-form>
        <a-row>
          <h3>Поиск</h3>
        </a-row>
        <a-row :gutter="24">
          <a-col :span="6">
            <a-form-item>
              <a-input
                v-model="filter.textComment"
                placeholder="Ввведите текст комментария"
                size="large"
              />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item>
              <a-input
                v-model="filter.author"
                placeholder="Имя автора"
                size="large"
              />
            </a-form-item>
          </a-col>
          <a-col :span="5">
            <a-form-item>
              <a-select
                placeholder="Выберите статус"
                size="large"
                v-model="filter.status"
              >
                <a-select-option value="" key="0"
                >Выберите статус
                </a-select-option
                >
                <a-select-option value="0" key="1">Удаленно</a-select-option>
                <a-select-option value="1" key="2">Активно</a-select-option>
                <a-select-option value="2" key="3">Спам</a-select-option>
                <a-select-option value="3" key="4">Не спам</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="7">
            <a-form-item>
              <a-range-picker
                :placeholder="['Начало', 'Конец']"
                v-model="dates"
                size="large"
              ></a-range-picker>
            </a-form-item>
          </a-col>
          <a-col span="24" style="margin-top: 25px">
            <a-button
              icon="search"
              @click="handleSearch"
              size="large"
              type="primary"
              htmlType="submit"
              style="margin-right: 25px"
            >Поиск
            </a-button>

            <a-button size="large" @click="resetFilter">Сбросить</a-button>
          </a-col>
        </a-row>
      </a-form>
    </a-layout-content>

    <a-layout-content class="page__content">
      <a-table
        :loading="hasLoadComments"
        @change="onChange"
        :columns="columns"
        :pagination="false"
        :dataSource="comments.data ? comments.data : []"
        rowKey="id"
      >
        <template slot="operation" slot-scope="id, comment">
          <a :href="comment.url">
            <a-button
              type="primary"
              class="gray-btn"
              icon="eye"
              style="margin-right: 10px"
            ></a-button>
          </a>

          <a-button
            @click="editComment(id)"
            type="primary"
            class="gray-btn"
            icon="edit"
            style="margin-right: 10px"
          ></a-button>

          <a-button
            @click="handleSetCommentToSpam(id)"
            type="primary"
            v-if="comment.mark_as_spam === 0"
            class="gray-btn"
            icon="info-circle"
            style="margin-right: 10px"
          >
          </a-button>
          <a-tooltip v-if="comment.mark_as_spam === 1">
            <template slot="title">
              Комментарий помещен в спам
            </template>
            <a-button
              icon="info-circle"
              disabled
              style="background: #ffd666; margin-right: 10px; border: none"
            />
          </a-tooltip>

          <a-button
            @click="handleRemoveComment(id)"
            type="danger"
            class="gray-btn"
            icon="delete"
          ></a-button>
        </template>
        <template slot="rating" slot-scope="rating">
          <p style="color: #1BA305">{{ rating }}</p>
        </template>
        <template slot="user" slot-scope="user">
          <p>{{ user.name }}</p>
        </template>
        <template slot="text" slot-scope="text">
          <p v-if="text.length > 150">{{ text.slice(0, 150) }} ...</p>
          <p v-if="text.length < 150">{{ text }}</p>
        </template>
      </a-table>
      <a-row
        v-if="comments.meta"
        style="text-align: left;"
      >
        <a-pagination
          @change="paginationChange"
          v-model="comments.meta.current_page"
          :total="comments.meta.total"
          :pageSize="comments.meta.per_page"
          style="margin-top: 20px"
        />
      </a-row>
    </a-layout-content>
  </div>
</template>

<script>
import PageTitle from '@/components/Global/PageTitle.vue';
import { comments } from '@/api/comments';
import { changeComment } from '@/components/Comments/routes';
import moment from 'moment';
import { DATE_FORMAT } from '@/global/constants';
import { pickBy } from 'lodash';

export default {
  name      : 'CommentNews',
  components: { PageTitle },
  data() {
    return {
      filter         : {
        author     : '',
        textComment: '',
        dates      : [],
        status     : '',
        page       : 1,
      },
      dates          : [undefined, undefined],
      hasLoadComments: false,
      comments       : {},
      sort           : {
        [`order[created_at]`]: 'desc',
      },
      columns        : [
        {
          title         : 'Комментарий',
          dataIndex     : 'text',
          sorter        : true,
          sortDirections: ['desc', 'asc'],
          width         : 460,
          scopedSlots   : { customRender: 'text' },
        },
        {
          title         : 'Автор',
          dataIndex     : 'user',
          scopedSlots   : { customRender: 'user' },
          sorter        : true,
          sortDirections: ['desc', 'asc'],
        },
        {
          title         : 'Дата',
          dataIndex     : 'created_at',
          sorter        : true,
          sortDirections: ['desc', 'asc'],
        },
        {
          title         : 'Рейтинг',
          dataIndex     : 'rating',
          sorter        : true,
          sortDirections: ['desc', 'asc'],
          scopedSlots   : { customRender: 'rating' },
        },
        {
          title      : '',
          dataIndex  : 'id',
          scopedSlots: { customRender: 'operation' },
        },
      ],
    };
  },
  mounted() {
    this.getNewsComments();
  },
  methods: {
    resetFilter() {
      this.dates  = [undefined, undefined];
      this.filter = {
        text     : null,
        dates    : [],
        user     : {
          name: null,
        },
        createdAt: {
          start: null,
          end  : null,
        },
        page     : 1,
      };

      this.sort = {
        [`order[created_at]`]: 'desc',
      };
      this.getNewsComments();
    },

    /**
     * @description Получение комментариев новостей
     * @return {Promise<any>}
     */
    async getNewsComments() {
      this.hasLoadComments = true;
      await comments.searchComments({ ...this.filter, ...this.sort }).then((res) =>
        res.json().then((data) => {
          this.comments        = data;
          this.hasLoadComments = false;
        }),
      );
    },

    /**
     * @description Редирект на страницу с редактированием комментария
     * @return {void}
     */
    editComment(id) {
      this.$router.push(`${changeComment}/${id}`);
    },

    handleSearch() {
      this.searchComments();
    },

    async searchComments() {
      const [from, to] = this.dates;
      const params     = {
        text     : this.filter.textComment,
        user     : {
          name: this.filter.author,
        },
        createdAt: {
          start: from && moment(from).format(DATE_FORMAT),
          end  : to && moment(to).format(DATE_FORMAT),
        },
      };

      // TODO: Нужны человеческие статусы от Жени (когда нибудь), пока что так
      if (this.filter.status === '0') {
        params.deleted_at = 1;
      } else if (this.filter.status === '1') {
        params.deleted_at = 0;
      } else if (this.filter.status === '2') {
        params.markAsSpam = 1;
      } else if (this.filter.status === '3') {
        params.markAsSpam = 0;
      }

      this.filter          = { ...this.filter, ...params };
      this.hasLoadComments = true;

      await comments
        .searchComments(
          pickBy({ ...params, [`order[created_at]`]: 'desc', page: 1 }),
        )
        .then((res) => res.json())
        .then((data) => {
          this.hasLoadComments = false;
          this.comments        = data;
        });
    },

    /**
     * @description Сортировка комментариев
     * @param {string} field
     * @param {string} order
     * @return {Promise<any>}
     */
    async sortCommentsByField(field, order) {
      this.hasLoadComments = true;
      this.sort            = { [`order[${field}]`]: order };
      await comments
        .searchComments({
          ...this.sort,
          ...this.filter,
        })
        .then((res) => res.json())
        .then((data) => {
          this.hasLoadComments = false;
          this.comments        = data;
        });
    },

    /**
     * @description Отправка комментария в спам
     * @param {number} id
     * @return {Promise<any>}
     */
    handleSetCommentToSpam(id) {
      this.$confirm({
        title: 'Вы действительно хотите поместить данный комментарий в спам?',
        onOk : async () => {
          await comments.setCommentToSpam(id).then(() => {
            this.$message.success('Комментарий помещен в спам', 2);
            this.getNewsComments();
          });
        },
      });
    },

    onChange(pagination, filters, sorter) {
      const { order, field } = sorter;
      this.sortCommentsByField(field, order);
    },

    /**
     * @description Удаление комментария
     * @param {number} id
     */
    async removeComment(id) {
      await comments.deleteCommentById(id).then((res) => {
        if (res.status === 200) {
          this.getNewsComments();
          this.$message.success('Успешно удаленно', 2);
        }
      });
    },

    handleRemoveComment(id) {
      const self = this;
      this.$confirm({
        title  : 'Вы действительно хотите удалить данный комментарий?',
        content:
          'Вы не сможете восстановить данный комментарий после удаления, только добавить еще раз.',
        onOk() {
          self.removeComment(id);
        },
      });
    },

    /**
     * @param {number} page
     * @return {void}
     */
    paginationChange(page) {
      window.scrollTo(0, 0);
      this.filter = { ...this.filter, page };
      this.getNewsComments();
    },
  }
};
</script>
