
// @ts-nocheck
import Vue from 'vue';
import moment from 'moment';
import {users} from '@/api/users';
import {DATE_FORMAT} from '@/global/constants';
import PageTitle from '@/components/Global/PageTitle.vue';
// @ts-ignore
import bcrypt from 'bcryptjs';

export default Vue.extend({
  props: ['id'],
  components: {PageTitle},
  data() {
    return {
      loadUser: false,
      password: '',
      hashPassword: '',
      action: '',
      title: '',
      user: {
        id: 1,
        email: '',
        createdAt: '',
        updatedAt: '',
        name: '',
        avatar: '',
        emailVerified: 1,
        role: 'user',
        city: '',
        birthday: '',
        description: '',
        enDescription: '',
        deletedAt: '',
        surname: '',
        phone: '',
        roleOfActivity: '',
        position: '',
        official_representative: false,
      },
      formLayout: {
        labelCol: {span: 6},
        wrapperCol: {span: 14},
      },
    };
  },

  methods: {
    async setUserToBan() {
      await users
        .updateUser(this.id, {...this.user, active: 0})
        .then((res) => res.json())
        .then(() => {
          this.$message.success('Пользователь успешно добавлен в бан.');
        });
    },
    setRandomPassword() {
      this.password = Math.random()
        .toString(36)
        .slice(-8);
    },

    async deleteUser(): Promise<any> {
      this.$confirm({
        title: `Вы действительно хотите удалить пользователя ${this.user.name} ${this.user.surname} ?`,
        onOk: async () => {
          const res = await users.deleteUserById(this.id);
          const json = await res.json();
          this.$message.success('Пользователь успешно удален :)');
          this.user = json.data;
        },
      });
    },

    async restoreUser(): Promise<any> {
      this.$confirm({
        title: `Вы действительно хотите восстановить пользователя ${this.user.name} ${this.user.surname} ?`,
        onOk: async () => {
          const res = await users.restoreUserById(this.id);
          const json = await res.json();
          this.$message.success('Пользователь успешно восстановлен :)');
          this.user = json.data;
        },
      });
    },

    /**
     * @description Получение текущего пользователя
     * @return {Promise<any>}
     */
    async getUserById(): Promise<any> {
      this.loadUser = true;
      await users
        .getUserById(this.id)
        .then((res) => res.json())
        .then(({data}) => {
          if (data.birthday.length) {
            data.birthday = moment(data.birthday);
          }

          this.user = data;
          this.loadUser = false;
        });
    },

    /**
     * @description Обработка ошибок
     * @param {string} message
     * @return {function}
     */
    handleErrors(message: string) {
      return ({errors, code}: any) => {
        if (errors && code === 422) {
          Object.values(errors).forEach((values: any) => {
            values.forEach((value: string) => {
              this.$message.error(value);
            });
          });
        } else {
          this.$message.success(message, 2);
        }
      };
    },

    /**
     * @description Генерация хэша пароля
     * @return {string | null}
     */
    generateHashPassword(): string | null {
      if (!this.password.length) {
        return null;
      }

      const salt = bcrypt.genSaltSync(10);
      const hash = bcrypt.hashSync(this.password, salt);

      return hash;
    },

    saveChanges() {
      this.updateUserById();
    },

    async updateUserById(): Promise<any> {
      if (this.user.birthday) {
        this.user.birthday = moment(this.user.birthday).format(DATE_FORMAT);
      }

      if (this.id) {
        await users
          .updateUser(this.id, {
            ...this.user,
            password: this.generateHashPassword(),
          })
          .then((res) => res.json())
          .then(this.handleErrors('Пользователь успешно обновлен :)'));
      } else {
        await users
          .createUser({...this.user, password: this.generateHashPassword()})
          .then((res) => res.json())
          .then(this.handleErrors('Пользователь успешно создан :)'));
      }
    },
  },
  mounted() {
    if (this.id) {
      this.getUserById();
      this.action = 'Сохранить';
      this.title = 'Редактировать пользователя';
    } else {
      this.action = 'Создать';
      this.title = 'Создать пользователя';
    }
  },
});
