import {get, put} from './core';
import queryString from 'query-string';

/**
 * @description Список версий операционных систем
 * @return {Promise<any>}
 */
export const getTagListings = (params: any = {}): Promise<any> =>
  get(`/api/admin/tag-listings`, params);

export const getTagListing = (id: number): Promise<any> => get(`/api/admin/tag-listings/${id}`);
export const updateTagListing = (id: number, params: any): Promise<any> => put(`/api/admin/tag-listings/${id}`, params);
