<template>
  <a-row
    gutter="24"
    style="margin-top: 15px"
  >
    <h3 style="margin-top: 20px">Блок "Преимущества и недостатки"</h3>

    <a-row>
      <a-col span="10">
        Преимущества
        <a-textarea
          auto-size
          placeholder="Преимущества"
          :value="pros"
          @input="$emit('update:pros', $event.target.value)"
        />
      </a-col>
      <a-col span="10">
        Недостатки
        <a-textarea
          auto-size
          placeholder="Недостатки"
          :value="cons"
          @input="$emit('update:cons', $event.target.value)"
        />
      </a-col>
    </a-row>
  </a-row>
</template>

<script>
export default {
  name: 'AppProsCons',
  props: {
    pros: {
      required: true,
    },
    cons: {
      required: true,
    },
  },
};
</script>

<style scoped>
.ant-col + .ant-col {
  margin-left: 15px;
}
</style>
