var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.profile ? _c('a-layout-content', {
    staticClass: "page__content"
  }, [_c('a-form', {
    attrs: {
      "layout": "horizontal"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "Avatar",
      "label-col": _vm.formLayout.labelCol,
      "wrapper-col": _vm.formLayout.wrapperCol
    }
  }, [_c('a-upload', {
    staticClass: "avatar-uploader",
    attrs: {
      "name": "file",
      "list-type": "picture-card",
      "show-upload-list": false,
      "action": _vm.action,
      "headers": _vm.headers
    },
    on: {
      "change": _vm.handleChange
    }
  }, [_vm.profile.avatar_url ? _c('a-avatar', {
    attrs: {
      "src": _vm.profile.avatar_url,
      "shape": "square",
      "size": "large"
    }
  }) : _c('div', [_c('a-icon', {
    attrs: {
      "type": _vm.loadingAvatar ? 'loading' : 'plus'
    }
  }), _c('div', {
    staticClass: "ant-upload-text"
  }, [_vm._v(" Upload ")])], 1)], 1)], 1), _c('a-form-item', {
    class: {
      'has-error': _vm.errors.hasOwnProperty('email')
    },
    attrs: {
      "label": "Email",
      "label-col": _vm.formLayout.labelCol,
      "wrapper-col": _vm.formLayout.wrapperCol,
      "required": true
    }
  }, [_c('a-input', {
    attrs: {
      "size": "large"
    },
    model: {
      value: _vm.profile.email,
      callback: function callback($$v) {
        _vm.$set(_vm.profile, "email", $$v);
      },
      expression: "profile.email"
    }
  })], 1), _c('a-form-item', {
    class: {
      'has-error': _vm.errors.hasOwnProperty('name')
    },
    attrs: {
      "label": _vm.user.hasDeveloper ? 'Разработчик' : 'Имя',
      "size": "large",
      "label-col": _vm.formLayout.labelCol,
      "wrapper-col": _vm.formLayout.wrapperCol,
      "required": true
    }
  }, [_c('a-input', {
    attrs: {
      "size": "large"
    },
    model: {
      value: _vm.profile.name,
      callback: function callback($$v) {
        _vm.$set(_vm.profile, "name", $$v);
      },
      expression: "profile.name"
    }
  })], 1), _c('a-form-item', {
    class: {
      'has-error': _vm.errors.hasOwnProperty('surname')
    },
    attrs: {
      "label": "Фамилия",
      "size": "large",
      "label-col": _vm.formLayout.labelCol,
      "wrapper-col": _vm.formLayout.wrapperCol
    }
  }, [_c('a-input', {
    attrs: {
      "size": "large"
    },
    model: {
      value: _vm.profile.surname,
      callback: function callback($$v) {
        _vm.$set(_vm.profile, "surname", $$v);
      },
      expression: "profile.surname"
    }
  })], 1), _c('a-form-item', {
    class: {
      'has-error': _vm.errors.hasOwnProperty('birthday')
    },
    attrs: {
      "label": "Дата рождения",
      "label-col": _vm.formLayout.labelCol,
      "wrapper-col": _vm.formLayout.wrapperCol
    }
  }, [_c('a-date-picker', {
    attrs: {
      "size": "large",
      "placeholder": "Выберите дату"
    },
    model: {
      value: _vm.profile.birthday,
      callback: function callback($$v) {
        _vm.$set(_vm.profile, "birthday", $$v);
      },
      expression: "profile.birthday"
    }
  })], 1), _c('a-form-item', {
    class: {
      'has-error': _vm.errors.hasOwnProperty('official_representative')
    },
    attrs: {
      "label": "Официальный представитель",
      "size": "large",
      "label-col": _vm.formLayout.labelCol,
      "wrapper-col": _vm.formLayout.wrapperCol
    }
  }, [_c('a-checkbox', {
    attrs: {
      "size": "large"
    },
    model: {
      value: _vm.profile.official_representative,
      callback: function callback($$v) {
        _vm.$set(_vm.profile, "official_representative", $$v);
      },
      expression: "profile.official_representative"
    }
  })], 1), _c('a-form-item', {
    class: {
      'has-error': _vm.errors.hasOwnProperty('site')
    },
    attrs: {
      "label": "Сайт",
      "label-col": _vm.formLayout.labelCol,
      "wrapper-col": _vm.formLayout.wrapperCol
    }
  }, [_c('a-input', {
    attrs: {
      "size": "large"
    },
    model: {
      value: _vm.profile.site,
      callback: function callback($$v) {
        _vm.$set(_vm.profile, "site", $$v);
      },
      expression: "profile.site"
    }
  })], 1), _c('a-form-item', {
    class: {
      'has-error': _vm.errors.hasOwnProperty('city')
    },
    attrs: {
      "label": "Город",
      "label-col": _vm.formLayout.labelCol,
      "wrapper-col": _vm.formLayout.wrapperCol
    }
  }, [_c('a-input', {
    attrs: {
      "size": "large"
    },
    model: {
      value: _vm.profile.city,
      callback: function callback($$v) {
        _vm.$set(_vm.profile, "city", $$v);
      },
      expression: "profile.city"
    }
  })], 1), _c('a-form-item', {
    class: {
      'has-error': _vm.errors.hasOwnProperty('role_of_activity')
    },
    attrs: {
      "label": "Род деятельности",
      "label-col": _vm.formLayout.labelCol,
      "wrapper-col": _vm.formLayout.wrapperCol
    }
  }, [_c('a-input', {
    attrs: {
      "size": "large"
    },
    model: {
      value: _vm.profile.role_of_activity,
      callback: function callback($$v) {
        _vm.$set(_vm.profile, "role_of_activity", $$v);
      },
      expression: "profile.role_of_activity"
    }
  })], 1), _c('a-form-item', {
    class: {
      'has-error': _vm.errors.hasOwnProperty('position')
    },
    attrs: {
      "label": "Должность",
      "label-col": _vm.formLayout.labelCol,
      "wrapper-col": _vm.formLayout.wrapperCol
    }
  }, [_c('a-input', {
    attrs: {
      "size": "large"
    },
    model: {
      value: _vm.profile.position,
      callback: function callback($$v) {
        _vm.$set(_vm.profile, "position", $$v);
      },
      expression: "profile.position"
    }
  })], 1), _c('a-form-item', {
    class: {
      'has-error': _vm.errors.hasOwnProperty('description')
    },
    attrs: {
      "label": "Обо мне",
      "label-col": _vm.formLayout.labelCol,
      "wrapper-col": _vm.formLayout.wrapperCol
    }
  }, [_c('a-textarea', {
    staticStyle: {
      "height": "120px"
    },
    attrs: {
      "size": "large"
    },
    model: {
      value: _vm.profile.description,
      callback: function callback($$v) {
        _vm.$set(_vm.profile, "description", $$v);
      },
      expression: "profile.description"
    }
  })], 1)], 1), _c('a-row', {
    attrs: {
      "gutter": 24
    }
  }, [_c('a-col', {
    attrs: {
      "span": "12"
    }
  }, [_c('a-button', {
    attrs: {
      "type": "primary",
      "size": "large"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("Сохранить")])], 1)], 1)], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }