var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('PageTitle', {
    attrs: {
      "title": "Редактирование комментария"
    }
  }), _c('a-layout-content', {
    staticClass: "page__content"
  }, [_c('a-spin', {
    attrs: {
      "tip": "Loading...",
      "spinning": _vm.loading
    }
  }), !_vm.loading ? [_c('div', {
    staticClass: "change__comment__info"
  }, [_c('b', [_vm._v("Пользователь: " + _vm._s(_vm.comment.user && _vm.comment.user.name))])]), _c('div', {
    staticClass: "change__comment__info"
  }, [_c('b', [_vm._v("Дата создания: ")]), _vm._v(_vm._s(_vm.comment.created_at) + " ")]), _c('div', {
    staticClass: "change__comment__info"
  }, [_c('b', [_vm._v("Дата публикации: ")]), _vm._v(_vm._s(_vm.comment.published_at) + " ")]), _vm.commentId ? _c('div', {
    staticClass: "change__comment__info"
  }, [_c('b', [_vm._v("Оценка: ")]), _vm._v(_vm._s(_vm.comment.rating) + " ")]) : _vm._e(), _c('a-row', [_c('a-card', {
    attrs: {
      "title": "Параметры для локали"
    }
  }, [_c('a-tabs', {
    model: {
      value: _vm.activeTab,
      callback: function callback($$v) {
        _vm.activeTab = $$v;
      },
      expression: "activeTab"
    }
  }, _vm._l(_vm.LOCALE_LIST_EXTENDED, function (_ref) {
    var title = _ref.title,
      locale = _ref.locale;
    return _c('a-tab-pane', {
      key: locale,
      attrs: {
        "tab": title
      }
    }, [_c('a-form-item', {
      attrs: {
        "label": "\u0422\u0435\u043A\u0441\u0442 \u043A\u043E\u043C\u043C\u0435\u043D\u0442\u0430\u0440\u0438\u044F ".concat(locale)
      }
    }, [_c('a-textarea', {
      staticStyle: {
        "height": "120px"
      },
      model: {
        value: _vm.comment.translations[locale].text,
        callback: function callback($$v) {
          _vm.$set(_vm.comment.translations[locale], "text", $$v);
        },
        expression: "comment.translations[locale].text"
      }
    })], 1)], 1);
  }), 1)], 1)], 1), _c('a-button', {
    attrs: {
      "type": "primary",
      "size": "large"
    },
    on: {
      "click": _vm.handleSubmit
    }
  }, [_vm._v(" Сохранить ")]), _vm.comment.mark_as_spam ? _c('a-button', {
    staticClass: "orange-btn",
    attrs: {
      "size": "large",
      "type": "primary"
    },
    on: {
      "click": _vm.removeCommentFromSpam
    }
  }, [_vm._v(" Убрать из спама ")]) : _c('a-button', {
    staticClass: "orange-btn",
    attrs: {
      "size": "large"
    },
    on: {
      "click": _vm.seCommentToSpam
    }
  }, [_vm._v(" Отметить как спам ")])] : _vm._e()], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }