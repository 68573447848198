var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('PageTitle', {
    attrs: {
      "title": "Список приложений"
    }
  }, [_c('router-link', {
    attrs: {
      "to": "/add-description"
    }
  }, [_c('a-button', {
    attrs: {
      "size": "large",
      "icon": "plus",
      "type": "primary"
    }
  }, [_vm._v("Добавить приложение")])], 1)], 1), _c('a-layout-content', {
    staticClass: "page__content",
    staticStyle: {
      "display": "flex",
      "align-items": "center",
      "justify-content": "flex-start"
    }
  }, [_c('b', [_vm._v("Статусы:")]), _c('span', {
    staticClass: "status"
  }, [_c('span', {
    staticClass: "close icon"
  }), _vm._v("- модерация не пройденa")]), _c('span', {
    staticClass: "status"
  }, [_c('span', {
    staticClass: "play icon"
  }), _vm._v("- опубликовано на сайте")]), _c('span', {
    staticClass: "status"
  }, [_c('span', {
    staticClass: "reload icon"
  }), _vm._v("- ожидает модерации")]), _c('span', {
    staticClass: "status"
  }, [_c('span', {
    staticClass: "pause icon"
  }), _vm._v("- черновик")])]), _c('a-layout-content', {
    staticClass: "page__content"
  }, [_c('a-table', {
    attrs: {
      "columns": _vm.columns,
      "pagination": false,
      "dataSource": _vm.apps.data,
      "loading": _vm.hasLoadApps,
      "rowKey": "id"
    },
    on: {
      "change": _vm.onChange
    },
    scopedSlots: _vm._u([{
      key: "rate",
      fn: function fn(rate) {
        return [_vm._v(" " + _vm._s(rate.rating) + " ")];
      }
    }, {
      key: "status",
      fn: function fn(status) {
        return [status === 'черновик' ? _c('span', {
          staticClass: "table-status"
        }, [_c('span', {
          staticClass: "pause icon"
        })]) : _vm._e(), status === 'опубликовано на сайте' ? _c('span', {
          staticClass: "table-status"
        }, [_c('span', {
          staticClass: "play icon"
        })]) : _vm._e(), status === 'модерация не пройдена' ? _c('span', {
          staticClass: "table-status"
        }, [_c('span', {
          staticClass: "close icon"
        })]) : _vm._e(), status === 'ожидает модерации' ? _c('span', {
          staticClass: "table-status"
        }, [_c('span', {
          staticClass: "reload icon"
        })]) : _vm._e()];
      }
    }, {
      key: "actions",
      fn: function fn(id, record) {
        return [record.moderateStatus === 'опубликовано на сайте' ? _c('a', {
          attrs: {
            "href": record.showUrl,
            "target": "_blank"
          }
        }, [_c('a-button', {
          staticClass: "gray-btn",
          staticStyle: {
            "margin-right": "10px"
          },
          attrs: {
            "type": "primary",
            "icon": "eye"
          }
        })], 1) : _vm._e(), _c('router-link', {
          attrs: {
            "to": {
              path: "/edit-description/".concat(record.id, "/edit")
            }
          }
        }, [_c('a-button', {
          staticClass: "gray-btn",
          staticStyle: {
            "margin-right": "10px"
          },
          attrs: {
            "type": "primary",
            "icon": "edit"
          }
        })], 1), _c('a-button', {
          staticClass: "gray-btn",
          staticStyle: {
            "margin-right": "10px"
          },
          attrs: {
            "type": "primary",
            "icon": "delete"
          },
          on: {
            "click": function click($event) {
              return _vm.deleteApplication(record.id, record.name);
            }
          }
        })];
      }
    }])
  }), _c('a-row', {
    staticStyle: {
      "text-align": "right"
    }
  }, [_c('a-pagination', {
    staticStyle: {
      "margin-top": "20px"
    },
    attrs: {
      "total": _vm.apps.meta.total,
      "pageSize": _vm.apps.meta.per_page
    },
    on: {
      "change": _vm.paginationChange
    },
    model: {
      value: _vm.apps.meta.current_page,
      callback: function callback($$v) {
        _vm.$set(_vm.apps.meta, "current_page", $$v);
      },
      expression: "apps.meta.current_page"
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }