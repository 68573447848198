import { deleteMethod, get, post, put } from './core';

/**
 * @description Получение комментариев
 * @param {number} page
 * @return {Promise<any>}
 */
const getNewsComments = (page: number): Promise<any> => get(`/api/admin/news/comments?page=${page}`);

/**
 * @description Получение комментария  по id
 * @param {number} id
 * @return {Promise<any>}
 */
const getNewsCommentById = (id: number): Promise<any> => get(`/api/admin/news/comments/${id}`);

/**
 * @description Поиск по комментариям
 * @param {object} params
 * @return {Promise<any>}
 */
const searchComments = (params: any): Promise<any> => get(`/api/admin/news/comments/search/`, params);

/**
 * @description Удаление комментария по id
 * @param {number} id
 * @return {Promise<any>}
 */
const deleteCommentById = (id: number): Promise<any> => deleteMethod(`/api/admin/news/comments/${id}`);

/**
 * @description Редактирование комментари по id
 * @param {number} id
 * @param {object} params
 */
const editCommentById = (id: number, params: any): Promise<any> => put(`/api/admin/news/comments/${id}`, params);

/**
 * @description Отправка комментария в спам
 * @param {number} id
 * @return {Promise<any>}
 */
const setCommentToSpam = (id: number): Promise<any> => post(`/api/admin/news/comments/spam/${id}/1`);

/**
 * @description Удаление комментария из спама
 * @param {number} id
 * @return {Promise<any>}
 */
const removeCommentFromSpam = (id: number): Promise<any> => post(`/api/admin/news/comments/spam/${id}/0`);

export const comments = {
  getNewsComments,
  getNewsCommentById,
  searchComments,
  deleteCommentById,
  editCommentById,
  setCommentToSpam,
  removeCommentFromSpam,
};

export default { comments };
