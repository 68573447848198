var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a-table', {
    attrs: {
      "columns": _vm.columns,
      "dataSource": _vm.data,
      "pagination": false
    },
    scopedSlots: _vm._u([{
      key: "position",
      fn: function fn(value) {
        return [_vm._v(" " + _vm._s(_vm.setPosition(value)) + " ")];
      }
    }, {
      key: "operation",
      fn: function fn(operation, record) {
        return [_c('router-link', {
          attrs: {
            "to": {
              path: "/settings/external-code/".concat(record.id)
            }
          }
        }, [_c('a-button', {
          staticClass: "gray-btn",
          staticStyle: {
            "margin-right": "10px"
          },
          attrs: {
            "type": "primary",
            "icon": "edit"
          },
          on: {
            "click": function click($event) {
              return _vm.editCode(record.id);
            }
          }
        })], 1), _c('a-button', {
          staticClass: "gray-btn",
          attrs: {
            "type": "danger",
            "icon": "delete"
          },
          on: {
            "click": function click($event) {
              return _vm.removeCode(record.id);
            }
          }
        })];
      }
    }])
  });

}
var staticRenderFns = []

export { render, staticRenderFns }