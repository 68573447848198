const constantMock = window.fetch;

function getFullUrl(url) {
  return `${window.location.origin}${url}`;
}

window.fetch = function () {
  // Get the parameter in arguments
  // Intercept the parameter here
  return constantMock.apply(this, arguments).then((response) => {
    runResponseInterceptors(response);
    return response;
  });
};

const responseInterceptors = [];

const runResponseInterceptors = function (response) {
  for (let interceptor of responseInterceptors) {
    if (!interceptor.url || response.url === getFullUrl(interceptor.url)) {
      interceptor.callback(response);
    }
  }
};

export function addResponseInterceptor(url, callback) {
  responseInterceptors.push({ url, callback });
}
