<template>
  <div>
    <PageTitle title="Список приложений"/>
    <a-layout-content class="page__content">
      <h3>Фильтр</h3>
      <a-row :gutter="24">

        <a-col span="4">
          <a-form-item label="Выбрать OS">
            <a-select
              size="large"
              placeholder="Выбрать OS"
              v-model="searchParams.os"
            >
              <a-select-option
                v-for="os in operationSystems"
                :value="os.id"
                :key="os.id"
              >{{ os.name }}
              </a-select-option
              >
            </a-select>
          </a-form-item>
        </a-col>

        <a-col span="3">
          <a-form-item label="Min ID">
            <a-input
              size="large"
              placeholder="Min ID"
              v-model="searchParams.minId"
            >
            </a-input>
          </a-form-item>
        </a-col>

        <a-col span="3">
          <a-form-item label="Max ID">
            <a-input
              size="large"
              placeholder="Max ID"
              v-model="searchParams.maxId"
            >
            </a-input>
          </a-form-item>
        </a-col>

        <a-col span="4">
          <a-form-item label="Есть категория?">
            <a-select
              size="large"
              placeholder="Есть категория?"
              v-model="searchParams.hasCategory"
            >
              <a-select-option
                v-for="filter in categoryFilter"
                :value="filter.value"
                :key="filter.label"
              >{{ filter.label }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col span="10">
          <a-form-item label="Категория">
            <a-select
              size="large"
              placeholder="Категория"
              v-model="searchParams.category"
            >
              <a-select-option
                v-for="category in categories"
                :value="category.id"
                :key="category.id"
              >
                {{category.os.name}} - {{ category.parent ? category.parent.name + ' / ' : '' }}{{ category.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>

      <a-row>
        <a-col span="12">
          <a-button size="large" @click="resetFields">Сбросить</a-button>
        </a-col>
        <a-col span="12" style="text-align: right">
          <a-button
            size="large"
            type="primary"
            style="margin-left: 20px"
            icon="search"
            @click="handleSearch"
          >Фильтровать
          </a-button>
        </a-col>
      </a-row>
    </a-layout-content>

    <a-layout-content class="page__content">

      <a-table
        @change="onChange"
        :columns="columns"
        :pagination="false"
        :dataSource="apps.data"
        :loading="hasLoadApps"
        rowKey="id"
        :rowClassName="rowClassName"
      >
        <template slot="id" slot-scope="id, record">
          <a
            :key="id"
            target="_blank"
            :href="record.url"
          >
            {{ id }}
          </a>
        </template>

        <template slot="os" slot-scope="os">
          {{ os.name }}
        </template>

        <template slot="description" slot-scope="desc">
          <a-button type="primary" @click="showDescription=desc">
            показать
          </a-button>
        </template>

        <template slot="yandex" slot-scope="desc, record">
          <a
            :href="`https://yandex.by/search/?text=${record.name} ${removeTags(record.translations.ru.description).substring(0, 100)}`"
            target="_blank"
          >поиск
          </a>
        </template>

        <template slot="category" slot-scope="category, record">
          <a-select
            show-search
            size="large"
            placeholder="категория"
            v-model="record.t_category_id"
            :filter-option="categoryFilterOption"
          >
            <template
              v-for="cat in categories.filter(cat => cat.os_id === record.operation_system_id)"
            >
              <a-select-option
                :value="cat.id"
                :key="cat.id"
              >
                {{ cat.name }}
              </a-select-option>

              <a-select-option
                v-for="subCat in cat.children"
                :value="subCat.id"
                :key="subCat.id"
              >
                {{ cat.name}} / {{ subCat.name }}
              </a-select-option>
            </template>
          </a-select>
        </template>

        <template slot="actions" slot-scope="actions, record">
          <a-button type="primary" @click="save(record.id, record.t_category_id)">
            сохранить
          </a-button>
        </template>

      </a-table>

      <a-row style="text-align: right;">
        <a-pagination
          v-if="apps.meta"
          @change="paginationChange"
          v-model="apps.meta.current_page"
          :total="apps.meta.total"
          :pageSize="apps.meta.per_page"
          style="margin-top: 20px"
        />
      </a-row>
    </a-layout-content>

    <a-modal v-model="showDescription" title="Описание" @ok="showDescription=false">
      <div v-html="showDescription"></div>
    </a-modal>
  </div>
</template>

<script>
import Vue from 'vue';
import PageTitle from '@/components/Global/PageTitle.vue';
import { applications } from '@/api/Applications';
import { mapState } from 'vuex';
import { unescape } from 'lodash';
import { getCategories } from '@/api/category';
import { updateAppCategory } from '@/api/appCategory';

export default Vue.extend({
  components: { PageTitle },
  computed  : mapState(['operationSystems']),

  data() {
    return {
      showDescription: false,
      categoryFilter : [
        {
          label: 'Не выбрано',
          value: '',
        },
        {
          label: 'Да',
          value: 1,
        },
        {
          label: 'Нет',
          value: 0,
        },
      ],
      categories     : [],
      hasLoadApps    : false,
      apps           : [],
      sortParams     : {},
      searchParams   : {
        page       : 1,
        os         : undefined,
        minId      : undefined,
        maxId      : undefined,
        hasCategory: null,
        category   : undefined,
      },
      columns        : [
        {
          title      : 'ID',
          dataIndex  : 'id',
          key        : 'id',
          sorter     : true,
          scopedSlots: { customRender: 'id' },
        },
        {
          title         : 'Название программы',
          dataIndex     : 'name',
          key           : 'name',
          sorter        : true,
          sortDirections: ['asc', 'desc'],
        },
        {
          title         : 'OS',
          dataIndex     : 'operationSystem',
          key           : 'os',
          scopedSlots   : { customRender: 'os' },
          sorter        : true,
          sortDirections: ['asc', 'desc'],
        },
        {
          title      : 'Описание',
          dataIndex  : 'descriptionRu',
          key        : 'descriptionRu',
          sorter     : false,
          scopedSlots: { customRender: 'description' },
        },
        {
          title      : 'Yandex',
          dataIndex  : '',
          key        : 'yandex',
          scopedSlots: { customRender: 'yandex' },
        },
        {
          title      : 'Категория',
          dataIndex  : 'category',
          key        : 'category',
          scopedSlots: { customRender: 'category' },
          width      : 300,
        },
        {
          title      : 'Действия',
          dataIndex  : '',
          key        : 'actions',
          scopedSlots: { customRender: 'actions' },
        },
      ],
    };
  },

  async created() {
    this.getApplications({ page: 1 });

    const response = await getCategories({ all: 1 });

    const json = await response.json();

    this.categories = json.data;
  },

  methods: {
    removeTags(str) {
      return unescape(str.replace(/<\/?[^>]+(>|$)/g, '')).replace('&mdash;', '');
    },

    resetFields() {
      this.searchParams = {
        page       : 1,
        os         : undefined,
        minId      : undefined,
        maxId      : undefined,
        hasCategory: null,
        category   : undefined,
      };

      this.getApplications({ page: 1 });
    },

    async sortAppsByField(field, direction) {
      this.hasLoadApps = true;
      let params       = {};

      const order = direction ? String(direction).replace('end', '') : direction;

      if (field === 'operationSystem') {
        params          = {
          [`osOrder`]: order,
          ...this.searchParams,
        };
        this.sortParams = { [`osOrder`]: order };
      } else {
        params          = {
          [`${field}Order`]: order,
          ...this.searchParams,
        };
        this.sortParams = { [`${field}Order`]: order };
      }

      const res  = await applications.searchApplications({ ...params, withSoftportal: 1 });
      const data = await res.json();

      this.apps        = data;
      this.hasLoadApps = false;
    },

    /**
     * @description Обработчик на change таблички
     * @param {object} pagination
     * @param {object} filters
     * @param {object} sorter
     * @return {Promise<any>}
     */
    onChange(pagination, filters, sorter) {
      const { order, field } = sorter;
      this.sortAppsByField(field, order);
    },

    /**
     * @description Поиск по приложениям
     * @return {Promise<any>}
     */
    async handleSearch() {
      this.hasLoadApps = true;

      Object.keys(this.searchParams).forEach((key) => {
        const value = this.searchParams[key];
        if (typeof value === 'boolean') {
          this.searchParams[key] = Number(value);
        }
      });

      // @ts-ignore
      const res = await applications.searchApplications({
        ...this.searchParams,
        page          : 1,
        withSoftportal: 1,
      });

      const data = await res.json();

      this.apps        = data;
      this.hasLoadApps = false;
    },

    /**
     * @description Поиск приложений
     * @param {object} params
     * @return {Promise<any>}
     */
    async getApplications(params) {
      this.hasLoadApps = true;

      const res = await applications.searchApplications({ ...params, withSoftportal: 1 });

      const data = await res.json();

      this.apps        = data;
      this.hasLoadApps = false;
    },

    /**
     * @description Обработчик на change компонента с пагинацией
     * @param {number} page
     * @return {void}
     */
    paginationChange(page) {
      this.searchParams = { ...this.searchParams, page };
      this.getApplications({ ...this.searchParams, ...this.sortParams });
      window.scrollTo(0, 0);
    },

    rowClassName(record, index) {
      return !record.categories || !record.categories.length ? 'table-row-yellow' : '';
    },

    async save(id, catId) {
      const response = await updateAppCategory(id, { categories: [catId] });

      const json = await response.json();

      const application      = this.apps.data.find((app) => app.id === id);
      application.categories = json.data.categories;
    },

    categoryFilterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
  },
});
</script>

<style>
.table-row-yellow {
  background-color: lightyellow;
}
</style>
