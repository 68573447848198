var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('a-layout-content', {
    staticClass: "page__content"
  }, [_c('a-row', {
    attrs: {
      "gutter": 24
    }
  }, [_c('a-col', {
    attrs: {
      "span": "8"
    }
  }, [_c('a-form', [_c('a-form-item', {
    attrs: {
      "label": "Старый пароль",
      "size": "large",
      "validate-status": _vm.errors.oldPassword ? 'error' : '',
      "help": _vm.errors.oldPassword ? _vm.errors.oldPassword[0] : ''
    }
  }, [_c('a-row', [_c('a-input', {
    attrs: {
      "size": "large",
      "type": "password"
    },
    model: {
      value: _vm.oldPassword,
      callback: function callback($$v) {
        _vm.oldPassword = $$v;
      },
      expression: "oldPassword"
    }
  })], 1)], 1)], 1)], 1)], 1), _c('a-row', {
    attrs: {
      "gutter": 24
    }
  }, [_c('a-col', {
    attrs: {
      "span": "8"
    }
  }, [_c('a-form', [_c('a-form-item', {
    attrs: {
      "label": "Пароль",
      "size": "large",
      "validate-status": _vm.errors.password ? 'error' : '',
      "help": _vm.errors.password ? _vm.errors.password[0] : ''
    }
  }, [_c('a-row', [_c('a-input', {
    attrs: {
      "size": "large",
      "type": "password"
    },
    model: {
      value: _vm.password,
      callback: function callback($$v) {
        _vm.password = $$v;
      },
      expression: "password"
    }
  })], 1)], 1)], 1)], 1)], 1), _c('a-row', {
    attrs: {
      "gutter": 24
    }
  }, [_c('a-col', {
    attrs: {
      "span": "8"
    }
  }, [_c('a-form', [_c('a-form-item', {
    attrs: {
      "label": "Подтвердить Пароль",
      "size": "large",
      "validate-status": _vm.errors.password_confirmation ? 'error' : '',
      "help": _vm.errors.password_confirmation ? _vm.errors.password_confirmation[0] : ''
    }
  }, [_c('a-row', [_c('a-input', {
    attrs: {
      "size": "large",
      "type": "password"
    },
    model: {
      value: _vm.passwordConfirmation,
      callback: function callback($$v) {
        _vm.passwordConfirmation = $$v;
      },
      expression: "passwordConfirmation"
    }
  })], 1)], 1)], 1)], 1)], 1), _c('a-row', {
    attrs: {
      "gutter": 24
    }
  }, [_c('a-col', {
    attrs: {
      "span": "12"
    }
  }, [_c('a-button', {
    attrs: {
      "type": "primary",
      "size": "large"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("Сохранить пароль")])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }