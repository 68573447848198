var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('AddPageTitle', {
    attrs: {
      "title": _vm.title,
      "id": _vm.id || _vm.moderationId
    }
  }), _vm.moderationId ? _c('ModerationTop', {
    attrs: {
      "appId": _vm.moderationId
    }
  }) : _vm._e(), _vm.id && !_vm.hasLoadUser ? _c('DeveloperModeration', {
    attrs: {
      "appId": _vm.id
    }
  }) : _vm._e(), _c('a-layout-content', {
    staticClass: "page__content"
  }, [_c('TopMenu', {
    attrs: {
      "id": _vm.id,
      "moderationId": _vm.moderationId,
      "active": "description"
    }
  }), _c('a-spin', {
    attrs: {
      "spinning": _vm.hasLoadApplication
    }
  }, [_c('div', {
    staticClass: "spin-content"
  }, [_c('a-form', {
    staticStyle: {
      "margin-top": "30px"
    },
    attrs: {
      "form": _vm.form
    }
  }, [_c('a-row', {
    attrs: {
      "gutter": 24
    }
  }, [_vm.id ? _c('a-col', {
    attrs: {
      "span": "8"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "SEO Name (URL)"
    }
  }, [_c('a-input', {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ['seo_name', {
        initialValue: _vm.app.seo_name,
        rules: [{
          required: true,
          message: 'SEO Name (URL) - обязательное поле'
        }]
      }],
      expression: "[\n                    'seo_name',\n                    {\n                      initialValue: app.seo_name,\n                      rules: [\n                        {\n                          required: true,\n                          message: 'SEO Name (URL) - обязательное поле'\n                        }\n                      ]\n                    }\n                  ]"
    }],
    attrs: {
      "size": "large"
    }
  })], 1)], 1) : _vm._e(), _c('a-col', {
    attrs: {
      "span": "8"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "Платформа"
    }
  }, [_c('a-select', {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ['platformTypeId', {
        initialValue: _vm.app.platform && _vm.app.platform.id,
        rules: [{
          required: true,
          message: 'Платформа - обязательное поле'
        }]
      }],
      expression: "[\n                    'platformTypeId',\n                    {\n                      initialValue: app.platform && app.platform.id,\n                      rules: [\n                        {\n                          required: true,\n                          message: 'Платформа - обязательное поле'\n                        }\n                      ]\n                    }\n                  ]"
    }],
    attrs: {
      "placeholder": "Выберите платформу",
      "size": "large"
    },
    on: {
      "change": _vm.handlePlatformChange
    }
  }, _vm._l(_vm.platformTypes, function (platform) {
    return _c('a-select-option', {
      key: platform.id,
      attrs: {
        "value": platform.id
      }
    }, [_vm._v(_vm._s(platform.name) + " ")]);
  }), 1)], 1)], 1), _c('a-col', {
    attrs: {
      "span": "8"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "Операционная система"
    }
  }, [_c('a-select', {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ['operationSystemId', {
        initialValue: _vm.app.operationSystem && _vm.app.operationSystem.id,
        rules: [{
          required: true,
          message: 'Операционная система - обязательное поле'
        }]
      }],
      expression: "[\n                    'operationSystemId',\n                    {\n                      initialValue: app.operationSystem && app.operationSystem.id,\n                      rules: [\n                        {\n                          required: true,\n                          message: 'Операционная система - обязательное поле'\n                        }\n                      ]\n                    }\n                  ]"
    }],
    attrs: {
      "placeholder": "Выберите операционную систему",
      "size": "large"
    },
    on: {
      "change": _vm.handleOsChange
    }
  }, _vm._l(_vm.operationSystemsOptions, function (os) {
    return _c('a-select-option', {
      key: os.id,
      attrs: {
        "value": os.id
      }
    }, [_vm._v(" " + _vm._s(os.name) + " ")]);
  }), 1)], 1)], 1), _c('a-col', {
    attrs: {
      "span": "24"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "Версия операционной системы"
    }
  }, [_c('a-select', {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ['osVersions', {
        initialValue: _vm.app.osVersions && _vm.app.osVersions.map(function (version) {
          return String(version.id);
        }),
        rules: [{
          required: true,
          message: 'Версия операционной системы - обязательное поле'
        }]
      }],
      expression: "[\n                    'osVersions',\n                    {\n                      initialValue:\n                        app.osVersions &&\n                        app.osVersions.map(version => String(version.id)),\n                      rules: [\n                        {\n                          required: true,\n                          message:\n                            'Версия операционной системы - обязательное поле'\n                        }\n                      ]\n                    }\n                  ]"
    }],
    attrs: {
      "mode": "tags",
      "placeholder": "Выберите версию операционной системы",
      "size": "large"
    }
  }, _vm._l(_vm.operationSystemsVersionsByOs, function (version) {
    return _c('a-select-option', {
      key: version.id,
      attrs: {
        "value": String(version.id)
      }
    }, [_vm._v(_vm._s(version.name) + " ")]);
  }), 1)], 1)], 1)], 1), _c('a-row', {
    attrs: {
      "gutter": 24
    }
  }, [_vm.user.hasAdmin || _vm.user.hasEditor || _vm.user.hasSeo ? _c('a-col', {
    attrs: {
      "span": "8"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "Разработчик"
    }
  }, [_c('a-select', {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ['developerId', {
        initialValue: _vm.app.developer && _vm.app.developer.id,
        rules: [{
          required: true,
          message: 'Разработчик - обязательное поле'
        }]
      }],
      expression: "[\n                    'developerId',\n                    {\n                      initialValue: app.developer && app.developer.id,\n                      rules: [\n                        {\n                          required: true,\n                          message: 'Разработчик - обязательное поле'\n                        }\n                      ]\n                    }\n                  ]"
    }],
    attrs: {
      "size": "large",
      "showSearch": "",
      "placeholder": "Разработчик",
      "defaultActiveFirstOption": false,
      "showArrow": false,
      "filterOption": false,
      "notFoundContent": "Введите имя разработчика..."
    },
    on: {
      "search": _vm.searchDevelopers
    }
  }, _vm._l(_vm.developersList, function (developer) {
    return _c('a-select-option', {
      key: developer.id,
      attrs: {
        "value": developer.id
      }
    }, [_vm._v(_vm._s(developer.name) + " ")]);
  }), 1)], 1)], 1) : _vm._e(), _c('a-col', {
    attrs: {
      "span": "8"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "Лицензия"
    }
  }, [_c('a-select', {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ['licenseTypeId', {
        initialValue: _vm.app.license && _vm.app.license.id,
        rules: [{
          required: true,
          message: 'Лицензия - обязательное поле'
        }]
      }],
      expression: "[\n                    'licenseTypeId',\n                    {\n                      initialValue: app.license && app.license.id,\n                      rules: [\n                        {\n                          required: true,\n                          message: 'Лицензия - обязательное поле'\n                        }\n                      ]\n                    }\n                  ]"
    }],
    attrs: {
      "placeholder": "Выберите лицензию",
      "size": "large"
    }
  }, _vm._l(_vm.licenseTypes, function (license) {
    return _c('a-select-option', {
      key: license.id,
      attrs: {
        "value": license.id
      }
    }, [_vm._v(_vm._s(license.name) + " ")]);
  }), 1)], 1)], 1), _c('a-col', {
    attrs: {
      "span": "8"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "Сайт"
    }
  }, [_c('a-input', {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ['site', {
        initialValue: _vm.app.site,
        rules: [{
          required: false
        }]
      }],
      expression: "[\n                    'site',\n                    {\n                      initialValue: app.site,\n                      rules: [\n                        {\n                          required: false\n                        }\n                      ]\n                    }\n                  ]"
    }],
    attrs: {
      "placeholder": "Введите сайт",
      "size": "large"
    }
  })], 1)], 1)], 1), _c('a-row', {
    staticClass: "row-st",
    attrs: {
      "gutter": 24
    }
  }, [_vm.user.hasAdmin || _vm.user.hasEditor || _vm.user.hasSeo ? _c('a-col', {
    attrs: {
      "span": "8"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "Продажа ПО"
    }
  }, [_c('a-select', {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ['bzFutures', {
        initialValue: _vm.app.bzFutureApplication && _vm.app.bzFutureApplication.data && _vm.app.bzFutureApplication.data.map(function (app) {
          return String(app.id);
        }),
        rules: [{
          required: false
        }]
      }],
      expression: "[\n                    'bzFutures',\n                    {\n                      initialValue:\n                        app.bzFutureApplication &&\n                        app.bzFutureApplication.data &&\n                        app.bzFutureApplication.data.map(app => String(app.id)),\n                      rules: [\n                        {\n                          required: false\n                        }\n                      ]\n                    }\n                  ]"
    }],
    attrs: {
      "mode": "tags",
      "maxTagCount": "1",
      "size": "large",
      "showSearch": "",
      "placeholder": "Введите название программы",
      "defaultActiveFirstOption": false,
      "showArrow": false,
      "filterOption": false,
      "notFoundContent": "Введите название программы..."
    },
    on: {
      "search": _vm.searchBzfuture
    }
  }, _vm._l(_vm.bzfutureApps, function (bzfuture) {
    return _c('a-select-option', {
      key: bzfuture.id,
      attrs: {
        "value": String(bzfuture.id)
      }
    }, [_vm._v(_vm._s(bzfuture.text) + " ")]);
  }), 1)], 1)], 1) : _vm._e()], 1), _c('a-row', {
    staticClass: "row-st",
    attrs: {
      "gutter": 24
    }
  }, [_c('a-col', {
    attrs: {
      "span": "8"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "Категория",
      "name": "category_id"
    }
  }, [_c('a-select', {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ['category_id', {
        initialValue: _vm.app.category_id,
        rules: [{
          required: true
        }]
      }],
      expression: "[\n                      'category_id',\n                      {\n                        initialValue: app.category_id,\n                        rules: [\n                          {\n                            required: true\n                          }\n                        ]\n                      }\n                    ]"
    }],
    attrs: {
      "placeholder": "Выберите категорию",
      "size": "large",
      "show-search": "",
      "options": _vm.categoryOptions,
      "filter-option": _vm.categoryFilterOption
    }
  })], 1)], 1)], 1), _c('a-row', {
    staticClass: "row-st",
    attrs: {
      "gutter": 24
    }
  }, [_c('a-col', {
    attrs: {
      "lg": "24"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "Языки интерфейса"
    }
  }, [_c('a-checkbox-group', {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ['interfaceLangs', {
        initialValue: _vm.app.interfaceLangs && _vm.app.interfaceLangs.data && _vm.app.interfaceLangs.data.map(function (app) {
          return app.id;
        }),
        rules: [{
          required: true,
          message: 'Выберите хотя бы один язык'
        }]
      }],
      expression: "[\n                    'interfaceLangs',\n                    {\n                      initialValue:\n                        app.interfaceLangs &&\n                        app.interfaceLangs.data &&\n                        app.interfaceLangs.data.map(app => app.id),\n                      rules: [\n                        {\n                          required: true,\n                          message: 'Выберите хотя бы один язык'\n                        }\n                      ]\n                    }\n                  ]"
    }],
    attrs: {
      "options": _vm.options
    }
  })], 1)], 1)], 1), _c('a-row', {
    staticClass: "row-st",
    attrs: {
      "gutter": 24
    }
  }, [_c('a-card', {
    attrs: {
      "title": "Локализация"
    },
    scopedSlots: _vm._u([_vm.availableLocales.length ? {
      key: "extra",
      fn: function fn() {
        return [_c('a-dropdown', {
          scopedSlots: _vm._u([{
            key: "overlay",
            fn: function fn() {
              return [_c('a-menu', {
                on: {
                  "click": _vm.addTranslation
                }
              }, _vm._l(_vm.availableLocales, function (locale) {
                return _c('a-menu-item', {
                  key: locale
                }, [_vm._v(" " + _vm._s(_vm.getLocaleTitle(locale)) + " ")]);
              }), 1)];
            },
            proxy: true
          }], null, false, 2503522434)
        }, [_c('a-button', [_vm._v(" Добавить локаль "), _c('a-icon', {
          attrs: {
            "type": "down"
          }
        })], 1)], 1)];
      },
      proxy: true
    } : null], null, true)
  }, [_c('a-tabs', {
    attrs: {
      "type": "editable-card",
      "hide-add": ""
    },
    on: {
      "edit": _vm.onTabTranslationsEdit
    },
    model: {
      value: _vm.activeTab,
      callback: function callback($$v) {
        _vm.activeTab = $$v;
      },
      expression: "activeTab"
    }
  }, [_vm._l(_vm.LOCALE_LIST_EXTENDED, function (_ref) {
    var _vm$app$translations$, _vm$app$translations$2, _vm$app$translations$3, _vm$app$translations$4, _vm$app$benefits$loca, _vm$app$questions$loc, _vm$app$translations$5, _vm$app$translations$6;
    var locale = _ref.locale,
      title = _ref.title;
    return [_vm.app.translations.hasOwnProperty(locale) ? _c('a-tab-pane', {
      key: locale,
      attrs: {
        "tab": title,
        "closabel": true
      }
    }, [_c('a-row', {
      attrs: {
        "gutter": 24
      }
    }, [_c('a-col', {
      attrs: {
        "span": "12"
      }
    }, [_c('a-form-item', {
      attrs: {
        "label": "\u041D\u0430\u0437\u0432\u0430\u043D\u0438\u0435 (".concat(locale, ")")
      }
    }, [_c('a-input', {
      directives: [{
        name: "decorator",
        rawName: "v-decorator",
        value: ["translations.".concat(locale, ".name"), {
          initialValue: _vm.app.translations[locale] ? _vm.app.translations[locale].name : undefined
        }],
        expression: "[\n                                `translations.${locale}.name`,\n                                {\n                                  initialValue: app.translations[locale] ? app.translations[locale].name : undefined,\n                                }\n                              ]"
      }],
      attrs: {
        "size": "large",
        "placeholder": "Введите название (не более 150 символов)"
      }
    })], 1)], 1), _c('a-col', {
      attrs: {
        "span": "8"
      }
    }, [_c('a-form-item', {
      attrs: {
        "label": "Цена"
      }
    }, [_c('a-input', {
      directives: [{
        name: "decorator",
        rawName: "v-decorator",
        value: ["translations.".concat(locale, ".price"), {
          initialValue: _vm.app.translations[locale] ? _vm.app.translations[locale].price : undefined,
          rules: [{
            required: false
          }]
        }],
        expression: "[\n                                `translations.${locale}.price`,\n                                {\n                                  initialValue: app.translations[locale] ? app.translations[locale].price : undefined,\n                                  rules: [\n                                    {\n                                      required: false\n                                    }\n                                  ]\n                                }\n                              ]"
      }],
      attrs: {
        "placeholder": "Цена",
        "size": "large"
      }
    })], 1)], 1)], 1), _c('a-row', {
      attrs: {
        "gutter": 24
      }
    }, [_c('a-col', {
      attrs: {
        "span": "6"
      }
    }, [_c('a-form-item', {
      attrs: {
        "label": "Промокод"
      }
    }, [_c('a-input', {
      directives: [{
        name: "decorator",
        rawName: "v-decorator",
        value: ["translations.".concat(locale, ".promo_code"), {
          initialValue: _vm.app.translations[locale] ? _vm.app.translations[locale].promo_code : undefined,
          rules: [{
            required: false
          }]
        }],
        expression: "[\n                                `translations.${locale}.promo_code`,\n                                {\n                                  initialValue: app.translations[locale] ? app.translations[locale].promo_code : undefined,\n                                  rules: [\n                                    {\n                                      required: false\n                                    }\n                                  ]\n                                }\n                              ]"
      }],
      attrs: {
        "placeholder": "Промокод",
        "size": "large"
      }
    })], 1)], 1)], 1), _c('a-form-item', {
      class: {
        'has-error': _vm.errorsBag.shortDescriptionRu
      },
      attrs: {
        "label": "\u041A\u0440\u0430\u0442\u043A\u043E\u0435 \u043E\u043F\u0438\u0441\u0430\u043D\u0438\u0435 (".concat(locale, ")")
      }
    }, [_c('a-textarea', {
      directives: [{
        name: "decorator",
        rawName: "v-decorator",
        value: ["translations.".concat(locale, ".short_description"), {
          initialValue: _vm.app.translations[locale] ? _vm.app.translations[locale].short_description : undefined
        }],
        expression: "[`translations.${locale}.short_description`, {initialValue: app.translations[locale] ? app.translations[locale].short_description : undefined}]"
      }],
      attrs: {
        "auto-size": ""
      }
    }), _c('p', [_vm._v(" Количество слов: " + _vm._s(_vm.getWordCount(_vm.form.getFieldValue("translations.".concat(locale, ".short_description")))) + " Количество символов: " + _vm._s(_vm.form.getFieldValue("translations.".concat(locale, ".short_description")) ? String(_vm.form.getFieldValue("translations.".concat(locale, ".short_description"))).length : 0) + " ")]), _vm.errorsBag.shortDescriptionRu ? _c('div', {
      staticClass: "ant-form-explain"
    }, [_vm._v(" " + _vm._s(_vm.errorsBag.shortDescriptionRu) + " ")]) : _vm._e()], 1), _c('a-form-item', {
      class: {
        'has-error': _vm.errorsBag.descriptionRu
      },
      attrs: {
        "label": "\u041F\u043E\u043B\u043D\u043E\u0435 \u043E\u043F\u0438\u0441\u0430\u043D\u0438\u0435 (".concat(locale, ")")
      }
    }, [_c('ckeditor', {
      directives: [{
        name: "decorator",
        rawName: "v-decorator",
        value: ["translations.".concat(locale, ".description"), {
          initialValue: _vm.app.translations[locale] ? _vm.app.translations[locale].description : undefined
        }],
        expression: "[`translations.${locale}.description`, {initialValue: app.translations[locale] ? app.translations[locale].description : undefined}]"
      }],
      attrs: {
        "config": _vm.editorConfig()
      },
      on: {
        "input": function input($event) {
          var _vm$form$setFieldsVal;
          return _vm.form.setFieldsValue((_vm$form$setFieldsVal = {}, _vm$form$setFieldsVal["translations.".concat(locale, ".description")] = $event, _vm$form$setFieldsVal));
        }
      }
    }), _c('p', [_vm._v(" Количество слов: " + _vm._s(_vm.getWordCount(_vm.form.getFieldValue("translations.".concat(locale, ".description")))) + " Количество символов: " + _vm._s(_vm.form.getFieldValue("translations.".concat(locale, ".description")) ? String(_vm.form.getFieldValue("translations.".concat(locale, ".description"))).length : 0) + " ")]), _vm.errorsBag.descriptionRu ? _c('div', {
      staticClass: "ant-form-explain"
    }, [_vm._v(" " + _vm._s(_vm.errorsBag.descriptionRu) + " ")]) : _vm._e()], 1), _c('h3', {
      staticStyle: {
        "margin-top": "20px"
      }
    }, [_vm._v("Блок \"Преимущества и недостатки\"")]), _c('a-row', {
      attrs: {
        "gutter": "24"
      }
    }, [_c('a-col', {
      attrs: {
        "span": "10"
      }
    }, [_c('a-form-item', {
      attrs: {
        "label": "\u041F\u0440\u0435\u0438\u043C\u0443\u0449\u0435\u0441\u0442\u0432\u0430 (".concat(locale, ")")
      }
    }, [_c('a-textarea', {
      directives: [{
        name: "decorator",
        rawName: "v-decorator",
        value: ["translations.".concat(locale, ".pros"), {
          initialValue: _vm.app.translations[locale] ? _vm.app.translations[locale].pros : undefined
        }],
        expression: "[`translations.${locale}.pros`, {initialValue: app.translations[locale] ? app.translations[locale].pros : undefined}]"
      }],
      attrs: {
        "auto-size": "",
        "placeholder": "Преимущества"
      }
    })], 1)], 1), _c('a-col', {
      attrs: {
        "span": "10"
      }
    }, [_c('a-form-item', {
      attrs: {
        "label": "\u041D\u0435\u0434\u043E\u0441\u0442\u0430\u0442\u043A\u0438 (".concat(locale, ")")
      }
    }, [_c('a-textarea', {
      directives: [{
        name: "decorator",
        rawName: "v-decorator",
        value: ["translations.".concat(locale, ".cons"), {
          initialValue: _vm.app.translations[locale] ? _vm.app.translations[locale].cons : undefined
        }],
        expression: "[`translations.${locale}.cons`, {initialValue: app.translations[locale] ? app.translations[locale].cons : undefined}]"
      }],
      attrs: {
        "auto-size": "",
        "placeholder": "Недостатки"
      }
    })], 1)], 1)], 1), _c('a-row', {
      staticClass: "row-st",
      attrs: {
        "gutter": 24
      }
    }, [_vm.user.hasAdmin || _vm.user.hasEditor || _vm.user.hasSeo ? _c('a-col', {
      attrs: {
        "span": "24"
      }
    }, [_c('a-form-item', {
      attrs: {
        "label": "Настройки"
      }
    }, [_c('a-checkbox', {
      directives: [{
        name: "decorator",
        rawName: "v-decorator",
        value: ["translations.".concat(locale, ".enable_benefits_block"), {
          valuePropName: 'checked',
          initialValue: _vm.app.translations[locale] ? _vm.app.translations[locale].enable_benefits_block : undefined,
          rules: [{
            required: false
          }]
        }],
        expression: "[\n                              `translations.${locale}.enable_benefits_block`,\n                              {\n                                valuePropName: 'checked',\n                                initialValue: app.translations[locale] ? app.translations[locale].enable_benefits_block : undefined,\n                                rules: [\n                                  {\n                                    required: false\n                                  }\n                                ]\n                              }\n                            ]"
      }]
    }, [_vm._v(" Включить блок преимущества ")]), _c('a-checkbox', {
      directives: [{
        name: "decorator",
        rawName: "v-decorator",
        value: ["translations.".concat(locale, ".enable_qa_block"), {
          valuePropName: 'checked',
          initialValue: _vm.app.translations[locale] ? _vm.app.translations[locale].enable_qa_block : undefined,
          rules: [{
            required: false
          }]
        }],
        expression: "[\n                              `translations.${locale}.enable_qa_block`,\n                              {\n                                valuePropName: 'checked',\n                                initialValue: app.translations[locale] ? app.translations[locale].enable_qa_block : undefined,\n                                rules: [\n                                  {\n                                    required: false\n                                  }\n                                ]\n                              }\n                            ]"
      }]
    }, [_vm._v(" Включить блок \"Вопросы и ответы\" ")]), _c('a-checkbox', {
      directives: [{
        name: "decorator",
        rawName: "v-decorator",
        value: ["translations.".concat(locale, ".enable_pros_cons_block"), {
          valuePropName: 'checked',
          initialValue: (_vm$app$translations$ = (_vm$app$translations$2 = _vm.app.translations[locale]) === null || _vm$app$translations$2 === void 0 ? void 0 : _vm$app$translations$2.enable_pros_cons_block) !== null && _vm$app$translations$ !== void 0 ? _vm$app$translations$ : undefined,
          rules: [{
            required: false
          }]
        }],
        expression: "[\n                              `translations.${locale}.enable_pros_cons_block`,\n                              {\n                                valuePropName: 'checked',\n                                initialValue: app.translations[locale]?.enable_pros_cons_block ?? undefined,\n                                rules: [\n                                  {\n                                    required: false\n                                  }\n                                ]\n                              }\n                            ]"
      }]
    }, [_vm._v(" Включить блок \"Преимущества и недостатки\" ")]), _c('a-checkbox', {
      directives: [{
        name: "decorator",
        rawName: "v-decorator",
        value: ["translations.".concat(locale, ".disable_sponsored"), {
          valuePropName: 'checked',
          initialValue: (_vm$app$translations$3 = (_vm$app$translations$4 = _vm.app.translations[locale]) === null || _vm$app$translations$4 === void 0 ? void 0 : _vm$app$translations$4.disable_sponsored) !== null && _vm$app$translations$3 !== void 0 ? _vm$app$translations$3 : undefined
        }],
        expression: "[\n                              `translations.${locale}.disable_sponsored`,\n\n                              {\n                                valuePropName: 'checked',\n                                initialValue: app.translations[locale]?.disable_sponsored ?? undefined,\n                              }\n                            ]"
      }]
    }, [_vm._v(" Убрать спонсоров ")])], 1)], 1) : _vm._e()], 1), _vm.user.hasAdmin || _vm.user.hasEditor || _vm.user.hasSeo ? _c('app-benefits', {
      attrs: {
        "benefits": (_vm$app$benefits$loca = _vm.app.benefits[locale]) !== null && _vm$app$benefits$loca !== void 0 ? _vm$app$benefits$loca : []
      },
      on: {
        "add": function add($event) {
          return _vm.app.benefits[locale].push($event);
        }
      }
    }) : _vm._e(), _vm.user.hasAdmin || _vm.user.hasEditor || _vm.user.hasSeo ? _c('app-questions', {
      attrs: {
        "questions": (_vm$app$questions$loc = _vm.app.questions[locale]) !== null && _vm$app$questions$loc !== void 0 ? _vm$app$questions$loc : []
      }
    }) : _vm._e(), _c('a-form-item', _vm._b({
      attrs: {
        "label": "Опубликовано"
      }
    }, 'a-form-item', {
      labelCol: {
        sm: {
          span: 3
        }
      },
      wrapperCol: {
        sm: {
          span: 8
        }
      }
    }, false), [_c('a-date-picker', {
      directives: [{
        name: "decorator",
        rawName: "v-decorator",
        value: ["translations.".concat(locale, ".published_at"), {
          initialValue: (_vm$app$translations$5 = (_vm$app$translations$6 = _vm.app.translations[locale]) === null || _vm$app$translations$6 === void 0 ? void 0 : _vm$app$translations$6.published_at) !== null && _vm$app$translations$5 !== void 0 ? _vm$app$translations$5 : undefined
        }],
        expression: "[`translations.${locale}.published_at`, { initialValue: app.translations[locale]?.published_at??undefined }]"
      }],
      attrs: {
        "format": "YYYY-MM-DD HH:mm",
        "show-time": true,
        "allowClear": "",
        "placeholder": "Не опубликовано"
      }
    })], 1)], 1) : _vm._e()];
  })], 2)], 1)], 1), _c('a-row', {
    staticClass: "row-st",
    attrs: {
      "gutter": 24
    }
  }, [_vm.user.hasAdmin || _vm.user.hasEditor || _vm.user.hasSeo ? _c('a-col', {
    attrs: {
      "span": "24"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "Настройки"
    }
  }, [_c('a-checkbox', {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ['isPopular', {
        valuePropName: 'checked',
        initialValue: _vm.app.isPopular
      }],
      expression: "[\n                    'isPopular',\n\n                    {\n                      valuePropName: 'checked',\n                      initialValue: app.isPopular\n                    }\n                  ]"
    }]
  }, [_vm._v("Выводить в блоке “Выбор пользователя” ")]), _vm.user.hasAdmin ? _c('a-checkbox', {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ['isLanding', {
        initialValue: Boolean(_vm.app.landing !== null),
        valuePropName: 'checked'
      }],
      expression: "[\n                    'isLanding',\n                    {\n                      initialValue: Boolean(app.landing !== null),\n                      valuePropName: 'checked'\n                    }\n                  ]"
    }]
  }, [_vm._v("Включить Автолендинг ")]) : _vm._e(), _c('a-checkbox', {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ['isLastUploaded', {
        valuePropName: 'checked',
        initialValue: _vm.app.isLastUploaded,
        rules: [{
          required: false
        }]
      }],
      expression: "[\n                    'isLastUploaded',\n                    {\n                      valuePropName: 'checked',\n                      initialValue: app.isLastUploaded,\n                      rules: [\n                        {\n                          required: false\n                        }\n                      ]\n                    }\n                  ]"
    }]
  }, [_vm._v("Выводить в блоке \"Последние загруженные\" на главной ")]), _c('a-checkbox', {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ['isApi', {
        valuePropName: 'checked',
        initialValue: Boolean(_vm.app.isApi),
        rules: [{
          required: false
        }]
      }],
      expression: "[\n                    'isApi',\n                    {\n                      valuePropName: 'checked',\n                      initialValue: Boolean(app.isApi),\n                      rules: [\n                        {\n                          required: false\n                        }\n                      ]\n                    }\n                  ]"
    }]
  }, [_vm._v("Добавить в инсталлер как программу ")]), _c('a-checkbox', {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ['isApiOffer', {
        valuePropName: 'checked',
        initialValue: Boolean(_vm.app.isApiOffer),
        rules: [{
          required: false
        }]
      }],
      expression: "[\n                    'isApiOffer',\n                    {\n                      valuePropName: 'checked',\n                      initialValue: Boolean(app.isApiOffer),\n                      rules: [\n                        {\n                          required: false\n                        }\n                      ]\n                    }\n                  ]"
    }]
  }, [_vm._v("Добавить в инсталлер как оффер ")]), _c('a-checkbox', {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ['disable_download_modal', {
        valuePropName: 'checked',
        initialValue: Boolean(_vm.app.disable_download_modal),
        rules: [{
          required: false
        }]
      }],
      expression: "[\n                    'disable_download_modal',\n                    {\n                      valuePropName: 'checked',\n                      initialValue: Boolean(app.disable_download_modal),\n                      rules: [\n                        {\n                          required: false\n                        }\n                      ]\n                    }\n                  ]"
    }]
  }, [_vm._v("Отключить модальное окно скачивания ")]), _c('a-row', [_c('a-col', {
    attrs: {
      "span": "5"
    }
  }, [_c('a-checkbox', {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ['enable_autoupdate', {
        valuePropName: 'checked',
        initialValue: Boolean(_vm.app.enable_autoupdate),
        rules: [{
          required: false
        }]
      }],
      expression: "[\n                    'enable_autoupdate',\n                    {\n                      valuePropName: 'checked',\n                      initialValue: Boolean(app.enable_autoupdate),\n                      rules: [\n                        {\n                          required: false\n                        }\n                      ]\n                    }\n                  ]"
    }]
  }, [_vm._v(" Автоматическое обновление ")])], 1), _vm.app.operation_system_id === 6 ? [false === ['1xbet', 'melbet'].includes(_vm.app.seo_name) ? _c('a-col', {
    attrs: {
      "span": "8"
    }
  }, [_c('a-input', {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ['google_play_id', {
        initialValue: _vm.app.google_play_id,
        rules: [{
          required: false
        }]
      }],
      expression: "[\n                    'google_play_id',\n                    {\n                      initialValue: app.google_play_id,\n                      rules: [\n                        {\n                          required: false\n                        }\n                      ]\n                    }\n                  ]"
    }],
    attrs: {
      "size": "large",
      "placeholder": "Google Play ID"
    }
  }, [_c('a-tooltip', {
    attrs: {
      "slot": "suffix"
    },
    slot: "suffix"
  }, [_c('template', {
    slot: "title"
  }, [_vm._v(" ID из PlayStore https://play.google.com/store/apps/details?id=com.skype.raider. Не вся урла а только ID приложения"), _c('br'), _vm._v(" Пример: "), _c('b', [_vm._v("com.skype.raider")])]), _c('a-icon', {
    staticStyle: {
      "color": "rgba(0,0,0,.45)"
    },
    attrs: {
      "type": "info-circle"
    }
  })], 2)], 1)], 1) : _vm._e()] : _c('a-col', {
    attrs: {
      "span": "8"
    }
  }, [_c('a-input', {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ['update_url', {
        initialValue: _vm.app.update_url,
        rules: [{
          required: false
        }]
      }],
      expression: "[\n                          'update_url',\n                          {\n                            initialValue: app.update_url,\n                            rules: [\n                              {\n                                required: false\n                              }\n                            ]\n                          }\n                        ]"
    }],
    attrs: {
      "size": "large",
      "placeholder": "Введите url для автоматического обновления версии"
    }
  })], 1)], 2), _c('a-row', [_c('a-col', {
    attrs: {
      "span": "8"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "Позиция в категории"
    }
  }, [_c('a-input', {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ['category_position', {
        initialValue: _vm.app.category_position,
        rules: [{
          required: false
        }]
      }],
      expression: "[\n                    'category_position',\n                    {\n                      initialValue: app.category_position,\n                      rules: [\n                        {\n                          required: false\n                        }\n                      ]\n                    }\n                  ]"
    }],
    attrs: {
      "size": "large",
      "placeholder": "Позиция"
    }
  })], 1)], 1)], 1), ['1xbet-dlya-ios', 'melbet-na-ios'].includes(_vm.app.seo_name) ? _c('a-row', [_c('a-col', {
    attrs: {
      "span": "8"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "Download tag"
    }
  }, [_c('a-input', {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ['download_tag', {
        initialValue: _vm.app.download_tag
      }],
      expression: "[\n                          'download_tag',\n                          {\n                            initialValue: app.download_tag,\n                          }\n                        ]"
    }],
    attrs: {
      "size": "large",
      "placeholder": "Download tag"
    }
  })], 1)], 1)], 1) : _vm._e()], 1)], 1) : _vm._e()], 1), _vm.id && _vm.user.hasAdmin || _vm.user.hasEditor || _vm.user.hasSeo ? _c('a-row', {
    staticStyle: {
      "margin-top": "15px"
    },
    attrs: {
      "gutter": "24"
    }
  }, [_c('a-col', {
    attrs: {
      "span": "24"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "Доступно для"
    }
  }, [_c('a-checkbox-group', {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["availabilities", {
        initialValue: _vm.app.availabilities,
        rules: [{
          required: true,
          message: 'Выберите хотя бы один домен'
        }]
      }],
      expression: "[\n                      `availabilities`,\n                      {\n                        initialValue: app.availabilities,\n                        rules: [\n                          {\n                            required: true,\n                            message: 'Выберите хотя бы один домен'\n                          }\n                        ]\n                      }\n                    ]"
    }],
    attrs: {
      "options": _vm.availabilityOptions
    }
  })], 1)], 1)], 1) : _vm._e(), _vm.id && _vm.user.hasAdmin || _vm.user.hasEditor || _vm.user.hasSeo ? _c('a-row', {
    staticStyle: {
      "margin-top": "15px"
    },
    attrs: {
      "gutter": "24"
    }
  }, [_c('h3', {
    staticStyle: {
      "margin-top": "20px"
    }
  }, [_vm._v("Связи")]), _c('a-col', {
    attrs: {
      "span": "12"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "Связанные программы (введите название программы)"
    }
  }, [_c('a-select', {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ['groupNames', {
        initialValue: _vm.app.groupNames && _vm.app.groupNames.results && _vm.app.groupNames.results.map(function (name) {
          return String(name.id);
        }),
        rules: [{
          required: false
        }]
      }],
      expression: "[\n                    'groupNames',\n                    {\n                      initialValue:\n                        app.groupNames &&\n                        app.groupNames.results &&\n                        app.groupNames.results.map(name => String(name.id)),\n                      rules: [\n                        {\n                          required: false\n                        }\n                      ]\n                    }\n                  ]"
    }],
    attrs: {
      "mode": "multiple",
      "size": "large",
      "showSearch": "",
      "placeholder": "Введите название программы",
      "defaultActiveFirstOption": false,
      "showArrow": false,
      "filterOption": false,
      "notFoundContent": "Введите название программы...",
      "allowClear": true
    },
    on: {
      "search": _vm.searchRelatedApps
    }
  }, _vm._l(_vm.relatedAppsList, function (app) {
    return _c('a-select-option', {
      key: app.id,
      attrs: {
        "value": String(app.id)
      }
    }, [app.text ? [_vm._v(_vm._s(app.text))] : [_vm._v(_vm._s(app.name) + " : " + _vm._s(app.operationSystem.label))]], 2);
  }), 1)], 1)], 1), _c('a-col', {
    attrs: {
      "span": "12"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "Похожие программы  (введите название программы)"
    }
  }, [_c('a-select', {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ['relatedApplications', {
        initialValue: _vm.app.relatedApplications && _vm.app.relatedApplications.results && _vm.app.relatedApplications.results.map(function (name) {
          return String(name.id);
        }),
        rules: [{
          required: false
        }]
      }],
      expression: "[\n                    'relatedApplications',\n                    {\n                      initialValue:\n                        app.relatedApplications &&\n                        app.relatedApplications.results &&\n                        app.relatedApplications.results.map(name =>\n                          String(name.id)\n                        ),\n                      rules: [\n                        {\n                          required: false\n                        }\n                      ]\n                    }\n                  ]"
    }],
    attrs: {
      "mode": "multiple",
      "size": "large",
      "showSearch": "",
      "placeholder": "Введите название программы",
      "defaultActiveFirstOption": false,
      "showArrow": false,
      "filterOption": false,
      "notFoundContent": "Введите название программы...",
      "allowClear": true
    },
    on: {
      "search": _vm.searchSimilarApps
    }
  }, _vm._l(_vm.similarAppsList, function (app) {
    return _c('a-select-option', {
      key: app.id,
      attrs: {
        "value": String(app.id)
      }
    }, [app.text ? [_vm._v(_vm._s(app.text))] : [_vm._v(_vm._s(app.name) + " : " + _vm._s(app.operationSystem.label))]], 2);
  }), 1)], 1)], 1), _c('a-col', {
    attrs: {
      "span": "24"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "Связанные статьи (введите название статьи)"
    }
  }, [_c('a-select', {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ['articleRelations', {
        initialValue: _vm.app.articleRelations && _vm.app.articleRelations.results && _vm.app.articleRelations.results.map(function (article) {
          return String(article.id);
        }),
        rules: [{
          required: false
        }]
      }],
      expression: "[\n                    'articleRelations',\n                    {\n                      initialValue:\n                        app.articleRelations &&\n                        app.articleRelations.results &&\n                        app.articleRelations.results.map(article =>\n                          String(article.id)\n                        ),\n                      rules: [\n                        {\n                          required: false\n                        }\n                      ]\n                    }\n                  ]"
    }],
    attrs: {
      "allowClear": true,
      "size": "large",
      "mode": "multiple",
      "maxTagCount": "3",
      "showSearch": "",
      "placeholder": "Введите название статьи",
      "defaultActiveFirstOption": false,
      "showArrow": false,
      "filterOption": false,
      "notFoundContent": "Введите название статьи..."
    },
    on: {
      "search": _vm.searchArticles,
      "change": _vm.handleRelatedArticlesChange
    }
  }, _vm._l(_vm.articlesList, function (article) {
    return _c('a-select-option', {
      key: article.id,
      attrs: {
        "value": String(article.id)
      }
    }, [_vm._v(_vm._s(article.text) + " ")]);
  }), 1)], 1)], 1)], 1) : _vm._e(), _c('a-row', {
    attrs: {
      "gutter": 24
    }
  }, [_vm.user.hasAdmin || _vm.user.hasEditor || _vm.user.hasSeo ? _c('a-col', {
    attrs: {
      "span": "12"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "Теги"
    }
  }, [_c('a-select', {
    attrs: {
      "disabled": "",
      "size": "large",
      "showSearch": "",
      "placeholder": "Тег 1",
      "defaultActiveFirstOption": false,
      "showArrow": false,
      "filterOption": false,
      "notFoundContent": "Введите название тега..."
    },
    on: {
      "search": _vm.searchTags,
      "change": _vm.handleTagsChange
    }
  }, _vm._l(_vm.tagsList, function (tag) {
    return _c('a-select-option', {
      key: tag.id,
      attrs: {
        "value": String(tag.id)
      }
    }, [_vm._v(_vm._s(tag.name) + " ")]);
  }), 1)], 1)], 1) : _vm._e()], 1), _vm.user.hasAdmin || _vm.user.hasEditor || _vm.user.hasSeo ? _c('a-row', {
    staticStyle: {
      "margin": "15px 0"
    },
    attrs: {
      "gutter": 24
    }
  }, _vm._l(_vm.tags, function (tag) {
    return _c('a-tag', {
      key: tag.id,
      staticStyle: {
        "margin": "8px 8px 8px 0",
        "font-size": "16px"
      },
      attrs: {
        "size": "large",
        "color": "#108ee9"
      }
    }, [_vm._v(_vm._s(tag.name) + " ")]);
  }), 1) : _vm._e(), _c('a-row', {
    attrs: {
      "gutter": "24"
    }
  }, [_vm.user.hasAdmin || _vm.user.hasEditor || _vm.user.hasSeo ? _c('a-col', {
    attrs: {
      "span": "8"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "Частотность"
    }
  }, [_c('a-input-number', {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ['frequency', {
        initialValue: _vm.app.frequency,
        rules: [{
          required: false
        }]
      }],
      expression: "[\n                    'frequency',\n                    {\n                      initialValue: app.frequency,\n                      rules: [\n                        {\n                          required: false\n                        }\n                      ]\n                    }\n                  ]"
    }],
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "size": "large",
      "placeholder": "Введите частотность"
    }
  })], 1)], 1) : _vm._e(), _c('a-col', {
    attrs: {
      "span": "8"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "Включено"
    }
  }, [_c('a-switch', {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ['enabled', {
        valuePropName: 'checked',
        initialValue: _vm.app.enabled,
        rules: [{
          required: false
        }]
      }],
      expression: "[\n                    'enabled',\n                    {\n                      valuePropName: 'checked',\n                      initialValue: app.enabled,\n                      rules: [\n                        {\n                          required: false\n                        }\n                      ]\n                    }\n                  ]"
    }]
  })], 1)], 1)], 1), _c('a-row', {
    attrs: {
      "gutter": 24
    }
  }, [_c('a-col', {
    staticStyle: {
      "text-align": "right"
    },
    attrs: {
      "span": "24"
    }
  }, [_c('a-button', {
    attrs: {
      "size": "large",
      "type": "primary"
    },
    on: {
      "click": _vm.handleSaveDescription
    }
  }, [_vm._v(_vm._s(_vm.action))])], 1)], 1)], 1)], 1)])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }