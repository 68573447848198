var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('PageTitle', {
    attrs: {
      "title": _vm.title
    }
  }), _c('a-form', {
    staticStyle: {
      "margin-top": "30px"
    },
    attrs: {
      "form": _vm.form
    }
  }, [_c('a-layout-content', {
    staticClass: "page__content"
  }, [_c('a-row', {
    attrs: {
      "gutter": 24
    }
  }, [_c('a-col', {
    attrs: {
      "span": "12"
    }
  }, [_c('a-button', {
    attrs: {
      "size": "large",
      "type": "primary"
    },
    on: {
      "click": _vm.handleSubmit
    }
  }, [_vm._v(_vm._s(_vm.action))])], 1), _c('a-col', {
    staticStyle: {
      "text-align": "right",
      "margin-top": "10px"
    },
    attrs: {
      "span": "12"
    }
  }, [_c('a-form-item', [_c('a-switch', {
    attrs: {
      "size": "large"
    },
    model: {
      value: _vm.active,
      callback: function callback($$v) {
        _vm.active = $$v;
      },
      expression: "active"
    }
  }, [_vm._v("Опубликовано")])], 1)], 1)], 1)], 1), _c('a-layout-content', {
    staticClass: "page__content"
  }, [_c('a-spin', {
    attrs: {
      "spinning": false
    }
  }, [_c('div', {
    staticClass: "spin-content"
  }, [_c('a-row', {
    attrs: {
      "gutter": 24
    }
  }, [_c('a-col', {
    attrs: {
      "span": "12"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "Заголовок (ru)"
    }
  }, [_c('a-input', {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ['title', {
        initialValue: _vm.baseCategory.title,
        rules: [{
          required: true
        }]
      }],
      expression: "[\n                    'title',\n                    {\n                      initialValue: baseCategory.title,\n                      rules: [\n                        {\n                          required: true\n                        }\n                      ]\n                    }\n                  ]"
    }],
    attrs: {
      "size": "large",
      "placeholder": "Введите название (не более 150 символов)"
    }
  })], 1)], 1), _c('a-col', {
    attrs: {
      "span": "12"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "Заголовок (en)"
    }
  }, [_c('a-input', {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ['enTitle', {
        initialValue: _vm.baseCategory.enTitle,
        rules: [{
          required: false
        }]
      }],
      expression: "[\n                    'enTitle',\n                    {\n                      initialValue: baseCategory.enTitle,\n                      rules: [\n                        {\n                          required: false\n                        }\n                      ]\n                    }\n                  ]"
    }],
    attrs: {
      "size": "large",
      "placeholder": "Введите название (не более 150 символов)"
    }
  })], 1)], 1), _c('a-col', {
    attrs: {
      "span": "24"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "Слаг (Пример: введенная строка `slug` создаст ссылку `mysite.com/page/slug`) "
    }
  }, [_c('a-input', {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ['slug', {
        initialValue: _vm.baseCategory.slug,
        rules: [{
          required: false
        }]
      }],
      expression: "[\n                    'slug',\n                    {\n                      initialValue: baseCategory.slug,\n                      rules: [\n                        {\n                          required: false\n                        }\n                      ]\n                    }\n                  ]"
    }],
    attrs: {
      "size": "large",
      "placeholder": "Введите название (не более 150 символов)"
    }
  })], 1)], 1), _c('a-col', {
    attrs: {
      "span": "24"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "Родительская категория "
    }
  }, [_c('a-select', {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ['parentId', {
        initialValue: _vm.baseCategory.parentId,
        rules: [{
          required: false
        }]
      }],
      expression: "[\n                    'parentId',\n                    {\n                      initialValue: baseCategory.parentId,\n                      rules: [\n                        {\n                          required: false\n                        }\n                      ]\n                    }\n                  ]"
    }],
    attrs: {
      "size": "large",
      "placeholder": "Категория"
    }
  }, [_c('a-select-option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("Не выбрано")]), _vm._l(_vm.newsCategories, function (category) {
    return _c('a-select-option', {
      key: category.id,
      attrs: {
        "value": String(category.id)
      }
    }, [_vm._v(" " + _vm._s(category.title) + " ")]);
  })], 2)], 1)], 1)], 1), _c('a-row', {
    staticStyle: {
      "margin-top": "45px",
      "padding-top": "20px",
      "border-top": "1px solid #dedede"
    },
    attrs: {
      "gutter": "24"
    }
  }, [_c('a-col', {
    attrs: {
      "span": "12"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "SeoTitle (ru) "
    }
  }, [_c('a-input', {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ['seoTitle', {
        initialValue: _vm.baseCategory.seoTitle,
        rules: [{
          required: false
        }]
      }],
      expression: "[\n                    'seoTitle',\n                    {\n                      initialValue: baseCategory.seoTitle,\n                      rules: [\n                        {\n                          required: false\n                        }\n                      ]\n                    }\n                  ]"
    }],
    attrs: {
      "size": "large",
      "placeholder": "Введите title"
    }
  })], 1)], 1), _c('a-col', {
    attrs: {
      "span": "12"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "SeoTitle (en)"
    }
  }, [_c('a-input', {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ['enSeoTitle', {
        initialValue: _vm.baseCategory.enSeoTitle,
        rules: [{
          required: false
        }]
      }],
      expression: "[\n                    'enSeoTitle',\n                    {\n                      initialValue: baseCategory.enSeoTitle,\n                      rules: [\n                        {\n                          required: false\n                        }\n                      ]\n                    }\n                  ]"
    }],
    attrs: {
      "size": "large",
      "placeholder": "Введите title"
    }
  })], 1)], 1), _c('a-col', {
    attrs: {
      "span": "12"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "SeoDescription (ru) "
    }
  }, [_c('a-textarea', {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ['seoDescription', {
        initialValue: _vm.baseCategory.seoDescription,
        rules: [{
          required: false
        }]
      }],
      expression: "[\n                    'seoDescription',\n                    {\n                      initialValue: baseCategory.seoDescription,\n                      rules: [\n                        {\n                          required: false\n                        }\n                      ]\n                    }\n                  ]"
    }],
    staticStyle: {
      "height": "145px"
    },
    attrs: {
      "size": "large",
      "placeholder": "Введите description"
    }
  })], 1)], 1), _c('a-col', {
    attrs: {
      "span": "12"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "SeoDescription (en) "
    }
  }, [_c('a-textarea', {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ['enSeoDescription', {
        initialValue: _vm.baseCategory.enSeoDescription,
        rules: [{
          required: false
        }]
      }],
      expression: "[\n                    'enSeoDescription',\n                    {\n                      initialValue: baseCategory.enSeoDescription,\n                      rules: [\n                        {\n                          required: false\n                        }\n                      ]\n                    }\n                  ]"
    }],
    staticStyle: {
      "height": "145px"
    },
    attrs: {
      "size": "large",
      "placeholder": "Введите description"
    }
  })], 1)], 1)], 1), _c('a-row', {
    attrs: {
      "gutter": 24
    }
  }, [_c('a-col', {
    attrs: {
      "span": "12"
    }
  }, [_c('a-button', {
    attrs: {
      "size": "large",
      "type": "primary"
    },
    on: {
      "click": _vm.handleSubmit
    }
  }, [_vm._v("Сохранить")])], 1)], 1)], 1)])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }