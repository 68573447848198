import { deleteMethod, get, post, put } from '@/api/core';

interface PlatformType {
  name: string;
  seo_name: string;
  label: string;
  en_label: string;
}

/**
 * @description Список типов платформ систем
 * @return {Promise<any>}
 */
export const getPlatformTypes = (): Promise<any> =>
  get('/api/admin/apps/platform-types');

/**
 * @description Создать новый тип платформ
 * @param {object} params
 * @return {Promise<any>}
 */
export const createPlatformType = (params: PlatformType): Promise<any> =>
  post('/api/admin/apps/platform-types', params);

/**
 * @description Обновить тип платформы
 * @param {number} id
 * @param {object} params
 * @return {Promise<any>}
 */
export const updatePlatformType = (
  id: number,
  params: PlatformType,
): Promise<any> => put(`/api/admin/apps/platform-types/${id}`, params);

/**
 * @description Удалить тип платформы
 * @param {number} id
 * @return {Promise<any>}
 */
export const deletePlatformType = (id: number): Promise<any> =>
  deleteMethod(`/api/admin/apps/platform-types/${id}`);

/**
 * @description Получить тип платформы по id
 * @param {number} id
 * @return {Promise<any>}
 */
export const getPlatformTypeById = (id: number): Promise<any> =>
  get(`/api/admin/apps/platform-types/${id}`);

export default {
  getPlatformTypes,
  createPlatformType,
  updatePlatformType,
  deletePlatformType,
  getPlatformTypeById,
};
