import { Route } from '@/types/route';

import Advert from './Advert.vue';
import AddAdvert from './AddAdvert.vue';

import Position from './Position.vue';
import AddPosition from './AddPosition.vue';

export const advertRoute = '/advert';
export const addAdvertRoute = '/advert/add';
export const editAdvertRoute = '/advert/:id/change';

export const positionRoute = '/advert/position';
export const positionChangeRoute = '/advert/position/:id/change';
export const positionAddRoute = '/advert/position/add';

export const advert: Route[] = [
  {
    name: 'Advert',
    path: advertRoute,
    component: Advert,
    breadcrumbName: 'Реклама',
    meta: {
      title: 'Реклама',
    },
  },
  {
    name: 'AddAdvert',
    path: addAdvertRoute,
    component: AddAdvert,
    breadcrumbName: 'Добавить баннер',
    meta: {
      title: 'Добавить баннер',
    },
  },
  {
    name: 'EditAdvert',
    path: editAdvertRoute,
    component: AddAdvert,
    props: true,
    breadcrumbName: 'Редактировать баннер',
    meta: {
      title: 'Редактировать баннер',
    },
  },
  {
    name: 'Position',
    path: positionRoute,
    component: Position,
    breadcrumbName: 'Позиции рекламных блоков',
    meta: {
      title: 'Позиции рекламных блоков',
    },
  },
  {
    name: 'ChangePosition',
    path: positionChangeRoute,
    component: AddPosition,
    props: true,
    breadcrumbName: 'Редактировать позицию',
    meta: {
      title: 'Редактировать позицию',
    },
  },
  {
    name: 'AddPosition',
    path: positionAddRoute,
    component: AddPosition,
    breadcrumbName: 'Добавить позицию',
    meta: {
      title: 'Добавить позицию',
    },
  },
];

export default { advert };
