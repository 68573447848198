var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('PageTitle', {
    attrs: {
      "title": "Модерация"
    }
  }), _c('a-layout-content', {
    staticClass: "page__content"
  }, [_c('h3', [_vm._v("Фильтр")]), _c('a-row', {
    attrs: {
      "gutter": 24
    }
  }, [_c('a-col', {
    attrs: {
      "span": "8"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "Статус"
    }
  }, [_c('a-select', {
    attrs: {
      "size": "large",
      "placeholder": "Статус"
    },
    model: {
      value: _vm.filters.moderate_status,
      callback: function callback($$v) {
        _vm.$set(_vm.filters, "moderate_status", $$v);
      },
      expression: "filters.moderate_status"
    }
  }, _vm._l(_vm.statuses, function (status) {
    return _c('a-select-option', {
      key: status.value,
      attrs: {
        "value": status.value
      }
    }, [_vm._v(" " + _vm._s(status.name) + " ")]);
  }), 1)], 1)], 1)], 1), _c('a-row', {
    staticStyle: {
      "padding-top": "50px"
    }
  }, [_c('a-col', {
    attrs: {
      "span": "12"
    }
  }), _c('a-col', {
    staticStyle: {
      "text-align": "right"
    },
    attrs: {
      "span": "12"
    }
  }, [_c('a-button', {
    staticStyle: {
      "margin-left": "20px"
    },
    attrs: {
      "size": "large",
      "type": "primary",
      "icon": "search"
    },
    on: {
      "click": _vm.getModerationAppsList
    }
  }, [_vm._v(" Фильтровать ")])], 1)], 1)], 1), _c('a-layout-content', {
    staticClass: "page__content"
  }, [_c('a-table', {
    attrs: {
      "columns": _vm.columns,
      "dataSource": _vm.moderation.data,
      "pagination": false,
      "loading": _vm.hasLoadModerationList,
      "rowKey": "id"
    },
    on: {
      "change": _vm.onChange
    },
    scopedSlots: _vm._u([{
      key: "developer",
      fn: function fn(developer) {
        return [_vm._v(" " + _vm._s(developer ? developer.name : '') + " ")];
      }
    }, {
      key: "moderator",
      fn: function fn(moderator) {
        return [_vm._v(" " + _vm._s(moderator ? moderator.name : '') + " ")];
      }
    }, {
      key: "actions",
      fn: function fn(app, record) {
        return [_c('router-link', {
          attrs: {
            "to": {
              path: "/moderation/".concat(record.id, "/description")
            }
          }
        }, [_c('a-button', {
          staticClass: "gray-btn",
          staticStyle: {
            "margin-right": "10px"
          },
          attrs: {
            "type": "primary",
            "icon": "edit"
          }
        })], 1), _c('a-button', {
          staticClass: "gray-btn",
          attrs: {
            "type": "danger",
            "icon": "delete"
          },
          on: {
            "click": function click($event) {
              return _vm.deleteApplication(record.id, record.name);
            }
          }
        })];
      }
    }])
  }), _c('a-row', {
    staticStyle: {
      "text-align": "right"
    }
  }, [_c('a-pagination', {
    staticStyle: {
      "margin-top": "20px"
    },
    attrs: {
      "total": _vm.moderation.meta.total,
      "pageSize": _vm.moderation.meta.per_page
    },
    on: {
      "change": _vm.paginationChange
    },
    model: {
      value: _vm.moderation.meta.current_page,
      callback: function callback($$v) {
        _vm.$set(_vm.moderation.meta, "current_page", $$v);
      },
      expression: "moderation.meta.current_page"
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }