<template>
  <a-modal
    :visible="application"
    title="Редактировать публикацию"
    @ok="$emit('close')"
    @cancel="$emit('close')"
  >
    <a-form
      v-if="application"
      :form="form"
      @submit="handleSubmit"
    >
      <a-form-item
        v-for="(data, locale) in application.translations"
        v-bind="formItemLayout"
        :key="locale"
        :label="`Опубликовано ${locale}`"
      >
        <a-date-picker
          format="YYYY-MM-DD HH:mm"
          :show-time="true"
          allowClear
          placeholder="Выберите дату"
          v-decorator="[`translations.${locale}.published_at`, { initialValue: data.published_at }]"
        />
      </a-form-item>
    </a-form>

    <template slot="footer">
      <a-button
        key="ok"
        @click="$emit('close')"
      >
        Закрыть
      </a-button>
      <a-button
        type="primary"
        key="save"
        @click="onSave"
      >
        Сохранить
      </a-button>
    </template>
  </a-modal>
</template>

<script>
import { updateAppDescription } from '@/api/Applications/app';

export default {
  name : 'ApplicationPublishedAtModal',
  props: {
    application: {},
  },
  data() {
    return {
      visible: false,
      form: this.$form.createForm(this, { name: 'published_at' }),
      formItemLayout: {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 8 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 },
        },
      },
    };
  },
  methods: {
    async onSave() {
      this.form.validateFieldsAndScroll(async (err, values) => {
        if (!err) {
          const result = await updateAppDescription(this.application.id, values);
          const json = await result.json();
          this.$emit('save', json.data);
          this.$emit('close');
        }
      });
    },
  },
};
</script>

<style scoped>

</style>
