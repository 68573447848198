import { get, post, put, deleteMethod } from '../core';

interface LandingOptions {
  applicationId: number;
  layout?: string;
  visible?: number;
}

/**
 * @description Получить список лендингов
 * @return {Promise<any>}
 */
export const getLandingsList = (page: number): Promise<any> =>
  get(`/api/admin/landing?page=${page}`);

/**
 * @description Создать, добавить лендинг
 * @param {object} params
 * @return {Promise<any>}
 */
export const createLanding = (params: LandingOptions): Promise<any> =>
  post('/api/admin/landing', params);

/**
 * @description Обновить лендинг
 * @param {object} params
 * @param {number} landingId
 * @return {Promise<any>}
 */
export const updateLanding = (
  landingId: number,
  params: LandingOptions,
): Promise<any> => put(`/api/admin/landing/${landingId}`, params);

/**
 * @description Удаление лендинга
 * @param {number} landingId
 * @return {Promise<any>}∏
 */
export const deleteLanding = (landingId: number): Promise<any> =>
  deleteMethod(`/api/admin/landing/${landingId}`);

export default {
  getLandingsList,
  createLanding,
  updateLanding,
  deleteLanding,
};
