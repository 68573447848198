var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('PageTitle', {
    attrs: {
      "title": "Версии OS"
    }
  }, [_c('router-link', {
    attrs: {
      "to": {
        path: _vm.addVersionOsRoute
      }
    }
  }, [_c('a-button', {
    staticStyle: {
      "margin-right": "10px"
    },
    attrs: {
      "type": "primary",
      "size": "large"
    }
  }, [_vm._v("Добавить версию операционной системы")])], 1)], 1), _c('a-layout-content', {
    staticClass: "page__content"
  }, [_c('h3', [_vm._v("Поиск")]), _c('a-row', [_c('a-col', {
    attrs: {
      "span": "22"
    }
  }, [_c('a-input', {
    attrs: {
      "size": "large",
      "placeholder": "Введите название ОС"
    },
    model: {
      value: _vm.query,
      callback: function callback($$v) {
        _vm.query = $$v;
      },
      expression: "query"
    }
  })], 1)], 1)], 1), _c('a-layout-content', {
    staticClass: "page__content"
  }, [_c('a-table', {
    attrs: {
      "columns": _vm.columns,
      "dataSource": _vm.filteredList,
      "rowKey": "id"
    },
    scopedSlots: _vm._u([{
      key: "os",
      fn: function fn(os) {
        return [_c('p', {
          key: os.id
        }, [_vm._v(_vm._s(os.name))])];
      }
    }, {
      key: "actions",
      fn: function fn(operation, record) {
        return [_c('router-link', {
          attrs: {
            "to": {
              path: "/applications/version-os/".concat(record.id, "/change")
            }
          }
        }, [_c('a-button', {
          staticClass: "gray-btn",
          staticStyle: {
            "margin-right": "10px"
          },
          attrs: {
            "type": "primary",
            "icon": "edit"
          }
        })], 1), _c('a-button', {
          staticClass: "gray-btn",
          attrs: {
            "type": "danger",
            "icon": "delete"
          },
          on: {
            "click": function click($event) {
              return _vm.deleteOsVersion(record.id);
            }
          }
        })];
      }
    }])
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }