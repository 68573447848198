var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a-table', {
    attrs: {
      "columns": _vm.columns,
      "loading": _vm.loading,
      "dataSource": _vm.data,
      "bordered": false,
      "pagination": false
    },
    scopedSlots: _vm._u([{
      key: "operation",
      fn: function fn(operation, record) {
        return [_c('a-button', {
          staticClass: "gray-btn",
          staticStyle: {
            "margin-right": "10px"
          },
          attrs: {
            "type": "primary",
            "icon": "edit"
          },
          on: {
            "click": function click($event) {
              return _vm.editRedirect(record.id);
            }
          }
        }), _c('a-button', {
          staticClass: "gray-btn",
          attrs: {
            "type": "primary",
            "icon": "delete"
          },
          on: {
            "click": function click($event) {
              return _vm.removeRedirect(record.id);
            }
          }
        })];
      }
    }])
  });

}
var staticRenderFns = []

export { render, staticRenderFns }