import {post} from './core';

/**
 * @description Функция на получение JWT токена
 * @param {string} email
 * @param {string} password
 * @return {Promise<any>}
 */
export const auth = (email: string, password: string): Promise<any> => {
  return post('/api/admin/users/login', {email, password});
};

export const authByToken = (token: string): Promise<any> => {
  return post('/api/admin/users/login/token', {token});
};

export default {auth, authByToken};
