import { get, post, put, deleteMethod } from '@/api/core';
import { Language } from '@/types/applications';

/**
 * @description Получить список языков
 * @return {Promise<any>}
 */
export const getListLanguages = (): Promise<any> =>
  get('/api/admin/apps/languages');

/**
 * @description Создать язык интерфейса
 * @param {object} params
 * @return {Promise<any>}
 */
export const createLanguage = (params: Language): Promise<any> =>
  post(`/api/admin/apps/languages`, params);

/**
 * @description Поиск интерфейсов языков
 * @param {object} params
 * @return {Promise<any>}
 */
export const searchLanguages = (params: Language): Promise<any> =>
  get('/api/admin/apps/languages/search', params);

/**
 * @description Получить интерфейс языка по id
 * @param {number} id
 * @return {Promise<any>}
 */
export const getLanguageById = (id: number): Promise<any> =>
  get(`/api/admin/apps/languages/${id}`);

/**
 * @description Обновить интерфейс языка по id
 * @param {number} id
 * @param {object} params
 * @return {Promise<any>}
 */
export const updateLanguageById = (id: number, params: Language) =>
  put(`/api/admin/apps/languages/${id}`, params);

/**
 * @description Удалить язык интерфейса по id
 * @param {number} id
 * @return {Promise<any>}
 */
export const deleteLanguageById = (id: number): Promise<any> =>
  deleteMethod(`/api/admin/apps/languages/${id}`);

export default {
  getListLanguages,
  createLanguage,
  searchLanguages,
  getLanguageById,
  updateLanguageById,
  deleteLanguageById,
};
