var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a-row', {
    staticStyle: {
      "margin-top": "15px"
    },
    attrs: {
      "gutter": "24"
    }
  }, [_c('h3', {
    staticStyle: {
      "margin-top": "20px"
    }
  }, [_vm._v("Блок \"Преимущества и недостатки\"")]), _c('a-row', [_c('a-col', {
    attrs: {
      "span": "10"
    }
  }, [_vm._v(" Преимущества "), _c('a-textarea', {
    attrs: {
      "auto-size": "",
      "placeholder": "Преимущества",
      "value": _vm.pros
    },
    on: {
      "input": function input($event) {
        return _vm.$emit('update:pros', $event.target.value);
      }
    }
  })], 1), _c('a-col', {
    attrs: {
      "span": "10"
    }
  }, [_vm._v(" Недостатки "), _c('a-textarea', {
    attrs: {
      "auto-size": "",
      "placeholder": "Недостатки",
      "value": _vm.cons
    },
    on: {
      "input": function input($event) {
        return _vm.$emit('update:cons', $event.target.value);
      }
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }