var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('PageTitle', {
    attrs: {
      "title": "Список приложений"
    }
  }, [_c('router-link', {
    attrs: {
      "to": "/add-description"
    }
  }, [_c('a-button', {
    attrs: {
      "size": "large",
      "icon": "plus",
      "type": "primary"
    }
  }, [_vm._v(" Добавить приложение ")])], 1)], 1), _c('a-layout-content', {
    staticClass: "page__content"
  }, [_c('h3', [_vm._v("Поиск")]), _c('a-row', {
    attrs: {
      "gutter": 24
    }
  }, [_c('a-col', {
    attrs: {
      "span": "8"
    }
  }, [_c('a-input', {
    attrs: {
      "size": "large",
      "placeholder": "Название программы"
    },
    on: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.handleSearch.apply(null, arguments);
      }
    },
    model: {
      value: _vm.searchParams.name,
      callback: function callback($$v) {
        _vm.$set(_vm.searchParams, "name", $$v);
      },
      expression: "searchParams.name"
    }
  })], 1), _c('a-col', {
    attrs: {
      "span": "8"
    }
  }, [_c('a-input', {
    attrs: {
      "size": "large",
      "placeholder": "SEO name (URL)"
    },
    on: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.handleSearch.apply(null, arguments);
      }
    },
    model: {
      value: _vm.searchParams.seoName,
      callback: function callback($$v) {
        _vm.$set(_vm.searchParams, "seoName", $$v);
      },
      expression: "searchParams.seoName"
    }
  })], 1), _c('a-col', {
    attrs: {
      "span": "8"
    }
  }, [_c('a-select', {
    attrs: {
      "size": "large",
      "placeholder": "Выбрать OS"
    },
    model: {
      value: _vm.searchParams.os,
      callback: function callback($$v) {
        _vm.$set(_vm.searchParams, "os", $$v);
      },
      expression: "searchParams.os"
    }
  }, _vm._l(_vm.operationSystems, function (os) {
    return _c('a-select-option', {
      key: os.id,
      attrs: {
        "value": os.id
      }
    }, [_vm._v(" " + _vm._s(os.name) + " ")]);
  }), 1)], 1)], 1), _vm.hasShowMore ? _c('a-row', {
    staticStyle: {
      "padding-top": "20px"
    },
    attrs: {
      "gutter": 24
    }
  }, [_c('a-col', {
    attrs: {
      "span": "8"
    }
  }, [_c('a-select', {
    attrs: {
      "size": "large",
      "placeholder": "Платформа"
    },
    model: {
      value: _vm.searchParams.platform,
      callback: function callback($$v) {
        _vm.$set(_vm.searchParams, "platform", $$v);
      },
      expression: "searchParams.platform"
    }
  }, _vm._l(_vm.platformTypes, function (platform) {
    return _c('a-select-option', {
      key: platform.id,
      attrs: {
        "value": platform.id
      }
    }, [_vm._v(" " + _vm._s(platform.name) + " ")]);
  }), 1)], 1), _c('a-col', {
    attrs: {
      "span": "8"
    }
  }, [_c('a-select', {
    attrs: {
      "size": "large",
      "placeholder": "Лицензия"
    },
    model: {
      value: _vm.searchParams.license,
      callback: function callback($$v) {
        _vm.$set(_vm.searchParams, "license", $$v);
      },
      expression: "searchParams.license"
    }
  }, _vm._l(_vm.licenseTypes, function (license) {
    return _c('a-select-option', {
      key: license.id,
      attrs: {
        "value": license.id
      }
    }, [_vm._v(" " + _vm._s(license.name) + " ")]);
  }), 1)], 1), _c('a-col', {
    attrs: {
      "span": "8"
    }
  }, [_c('a-input', {
    attrs: {
      "size": "large",
      "placeholder": "Разработчик"
    },
    on: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.handleSearch.apply(null, arguments);
      }
    },
    model: {
      value: _vm.searchParams.developer,
      callback: function callback($$v) {
        _vm.$set(_vm.searchParams, "developer", $$v);
      },
      expression: "searchParams.developer"
    }
  })], 1)], 1) : _vm._e(), _vm.hasShowMore ? _c('a-row', {
    staticStyle: {
      "padding-top": "20px"
    },
    attrs: {
      "gutter": 24
    }
  }, [_c('a-col', {
    attrs: {
      "span": "8"
    }
  }, [_c('a-select', {
    attrs: {
      "size": "large",
      "showSearch": "",
      "placeholder": "Тег 1",
      "defaultActiveFirstOption": false,
      "showArrow": false,
      "filterOption": false,
      "notFoundContent": "Введите  первый тег..."
    },
    on: {
      "search": _vm.searchTags
    },
    model: {
      value: _vm.searchParams.tag1,
      callback: function callback($$v) {
        _vm.$set(_vm.searchParams, "tag1", $$v);
      },
      expression: "searchParams.tag1"
    }
  }, _vm._l(_vm.tagsList, function (tag) {
    return _c('a-select-option', {
      key: tag.id,
      attrs: {
        "value": tag.id
      }
    }, [_vm._v(" " + _vm._s(tag.name) + " ")]);
  }), 1)], 1), _c('a-col', {
    attrs: {
      "span": "8"
    }
  }, [_c('a-select', {
    attrs: {
      "size": "large",
      "showSearch": "",
      "placeholder": "Тег 1",
      "defaultActiveFirstOption": false,
      "showArrow": false,
      "filterOption": false,
      "notFoundContent": "Введите  второй тег..."
    },
    on: {
      "search": _vm.searchTags
    },
    model: {
      value: _vm.searchParams.tag2,
      callback: function callback($$v) {
        _vm.$set(_vm.searchParams, "tag2", $$v);
      },
      expression: "searchParams.tag2"
    }
  }, _vm._l(_vm.tagsList, function (tag) {
    return _c('a-select-option', {
      key: tag.id,
      attrs: {
        "value": tag.id
      }
    }, [_vm._v(" " + _vm._s(tag.name) + " ")]);
  }), 1)], 1), _c('a-col', {
    attrs: {
      "span": "8"
    }
  }, [_c('a-select', {
    attrs: {
      "size": "large",
      "showSearch": "",
      "placeholder": "Тег 3",
      "defaultActiveFirstOption": false,
      "showArrow": false,
      "filterOption": false,
      "notFoundContent": "Введите третий тег..."
    },
    on: {
      "search": _vm.searchTags
    },
    model: {
      value: _vm.searchParams.tag3,
      callback: function callback($$v) {
        _vm.$set(_vm.searchParams, "tag3", $$v);
      },
      expression: "searchParams.tag3"
    }
  }, _vm._l(_vm.tagsList, function (tag) {
    return _c('a-select-option', {
      key: tag.id,
      attrs: {
        "value": tag.id
      }
    }, [_vm._v(" " + _vm._s(tag.name) + " ")]);
  }), 1)], 1)], 1) : _vm._e(), _vm.hasShowMore ? _c('a-row', {
    staticStyle: {
      "padding-top": "20px"
    },
    attrs: {
      "gutter": 24
    }
  }, [_c('a-col', {
    attrs: {
      "span": "6"
    }
  }, [_c('a-switch', {
    model: {
      value: _vm.searchParams.isApiOffer,
      callback: function callback($$v) {
        _vm.$set(_vm.searchParams, "isApiOffer", $$v);
      },
      expression: "searchParams.isApiOffer"
    }
  }), _c('span', {
    staticStyle: {
      "padding": "15px 0 0 20px"
    }
  }, [_vm._v("API Offers")])], 1), _c('a-col', {
    attrs: {
      "span": "13"
    }
  }, [_c('a-switch', {
    model: {
      value: _vm.searchParams.isApi,
      callback: function callback($$v) {
        _vm.$set(_vm.searchParams, "isApi", $$v);
      },
      expression: "searchParams.isApi"
    }
  }), _c('span', {
    staticStyle: {
      "padding": "15px 0 0 20px"
    }
  }, [_vm._v("API Programs")])], 1), _c('a-col', {
    attrs: {
      "span": "3"
    }
  }, [_c('a-checkbox', {
    model: {
      value: _vm.searchParams.enabled,
      callback: function callback($$v) {
        _vm.$set(_vm.searchParams, "enabled", $$v);
      },
      expression: "searchParams.enabled"
    }
  }, [_vm._v("Опубликованы")])], 1), _c('a-col', {
    attrs: {
      "span": "2"
    }
  }, [_c('a-checkbox', {
    model: {
      value: _vm.searchParams.trashed,
      callback: function callback($$v) {
        _vm.$set(_vm.searchParams, "trashed", $$v);
      },
      expression: "searchParams.trashed"
    }
  }, [_vm._v("Удалены")])], 1)], 1) : _vm._e(), _c('a-row', {
    staticStyle: {
      "padding-top": "50px"
    }
  }, [_c('a-col', {
    attrs: {
      "span": "12"
    }
  }, [_c('a-button', {
    attrs: {
      "size": "large"
    },
    on: {
      "click": _vm.resetFields
    }
  }, [_vm._v("Сбросить")])], 1), _c('a-col', {
    staticStyle: {
      "text-align": "right"
    },
    attrs: {
      "span": "12"
    }
  }, [_c('a-button', {
    attrs: {
      "size": "large",
      "type": "default"
    },
    on: {
      "click": _vm.showMoreParams
    }
  }, [_vm._v(" " + _vm._s(_vm.showMoreStr) + " ")]), _c('a-button', {
    staticStyle: {
      "margin-left": "20px"
    },
    attrs: {
      "size": "large",
      "type": "primary",
      "icon": "search"
    },
    on: {
      "click": _vm.handleSearch
    }
  }, [_vm._v(" Поиск ")])], 1)], 1)], 1), _c('a-layout-content', {
    staticClass: "page__content"
  }, [_c('a-table', {
    attrs: {
      "columns": _vm.columns,
      "pagination": false,
      "dataSource": _vm.apps.data,
      "loading": _vm.hasLoadApps,
      "rowKey": "id"
    },
    on: {
      "change": _vm.onChange
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn(id, record) {
        return [_c('a-dropdown', [_c('a-menu', {
          attrs: {
            "slot": "overlay"
          },
          slot: "overlay"
        }, [_c('a-menu-item', {
          key: "1"
        }, [_c('router-link', {
          attrs: {
            "to": {
              path: "/edit-description/".concat(record.id, "/edit")
            }
          }
        }, [_c('a-button', {
          attrs: {
            "type": "link",
            "icon": "edit",
            "size": "small"
          }
        }, [_vm._v(" Редактировать ")])], 1)], 1), _c('a-menu-item', {
          key: "2"
        }, [_c('a', {
          attrs: {
            "href": record.url,
            "target": "_blank"
          }
        }, [_c('a-button', {
          attrs: {
            "type": "link",
            "icon": "eye",
            "size": "small"
          }
        }, [_vm._v(" Просмотр ")])], 1)]), _c('a-menu-item', {
          key: "3"
        }, [record.site ? _c('a', {
          attrs: {
            "href": record.site,
            "target": "_blank"
          }
        }, [_c('a-button', {
          attrs: {
            "type": "link",
            "icon": "link",
            "size": "small"
          }
        }, [_vm._v(" Открыть сайт приложения ")])], 1) : _vm._e()]), _c('a-menu-item', {
          key: "4"
        }, [_c('a-button', {
          attrs: {
            "type": "link",
            "icon": "delete",
            "size": "small"
          },
          on: {
            "click": function click($event) {
              return _vm.deleteApplication(record.id, record.name);
            }
          }
        }, [_vm._v(" Удалить приложение ")])], 1), _c('a-menu-item', {
          key: "5"
        }, [_c('a-button', {
          attrs: {
            "type": "link",
            "icon": "edit",
            "size": "small"
          },
          on: {
            "click": function click($event) {
              _vm.editApplicationPublishedAt = record;
            }
          }
        }, [_vm._v(" Настройки публикации ")])], 1)], 1), _c('a-button', [_vm._v(" Действия "), _c('a-icon', {
          attrs: {
            "type": "down"
          }
        })], 1)], 1)];
      }
    }, {
      key: "os",
      fn: function fn(os) {
        return [_vm._v(" " + _vm._s(os.name) + " ")];
      }
    }, {
      key: "version",
      fn: function fn(files) {
        return [_vm._v(" " + _vm._s(_vm.getLatestFileVersion(files)) + " ")];
      }
    }, {
      key: "developer",
      fn: function fn(developer) {
        return [_c('p', {
          key: developer.id,
          staticStyle: {
            "margin": "0"
          }
        }, [_vm._v(_vm._s(developer.name))])];
      }
    }, {
      key: "published",
      fn: function fn(id, record) {
        return _vm._l(record.translations, function (data, locale) {
          return _c('p', {
            key: locale
          }, [_vm._v(" " + _vm._s(locale) + " - "), data.published_at ? [_vm._v(_vm._s(_vm._f("dateFormat")(data.published_at)))] : [_vm._v("нет")]], 2);
        });
      }
    }])
  }), _c('a-row', {
    staticStyle: {
      "text-align": "right"
    }
  }, [_vm.apps.meta ? _c('a-pagination', {
    staticStyle: {
      "margin-top": "20px"
    },
    attrs: {
      "total": _vm.apps.meta.total,
      "pageSize": _vm.apps.meta.per_page
    },
    on: {
      "change": _vm.paginationChange
    },
    model: {
      value: _vm.apps.meta.current_page,
      callback: function callback($$v) {
        _vm.$set(_vm.apps.meta, "current_page", $$v);
      },
      expression: "apps.meta.current_page"
    }
  }) : _vm._e()], 1)], 1), _c('application-published-at-modal', {
    attrs: {
      "application": _vm.editApplicationPublishedAt
    },
    on: {
      "close": function close($event) {
        _vm.editApplicationPublishedAt = undefined;
      },
      "save": _vm.onPublishedAtSave
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }