import { get, put } from './core';

/**
 * @description Получение списка приложений
 * @return {Promise<any>}
 */
const getDeveloperApplications = (): Promise<any> =>
  get('/api/admin/developer-applications');

/**
 * @description Поиск по приложениям разработчика
 * @param {object} params
 * @return {Promise<any>}
 */
const searchDeveloperApplications = (params: any) =>
  get('/api/admin/developer-applications/search', params);

/**
 * @description Отправка приложения на модерацию
 * @param {number} applicationId
 * @param {object} params
 * @return {Promise<any>}
 */
const sendApplicationToModeration = (
  applicationId: number,
  params: any,
): Promise<any> =>
  put(`/api/admin/apps/send-to-moderation/${applicationId}`, params);

export const Developer = {
  getDeveloperApplications,
  searchDeveloperApplications,
  sendApplicationToModeration,
};

export default { Developer };
