
// @ts-nocheck
import { Component, Vue } from 'vue-property-decorator';
import { settings } from '@/api';
import PageTitle from '@/components/Global/PageTitle.vue';

@Component({
  name: 'Add',
  components: { PageTitle },
})
export default class Add extends Vue {
  title: string = 'Редактирование файлов';
  button: string = 'Сохранить';
  buttonDisable: boolean = false;

  // TODO: Нужно на бэке сделать camelCase
  robotsRu = {
    file_name: 'robots.txt',
    file_content: '',
  };
  robotsEn = {
    file_name: 'robots_en.txt',
    file_content: '',
  };
  adsRu = {
    file_name: 'ads.txt',
    file_content: '',
  };
  adsEn = {
    file_name: 'ads_en.txt',
    file_content: '',
  };

  mounted() {
    this.getFile('robots.txt', 'robotsRu');
    this.getFile('robots_en.txt', 'robotsEn');
    this.getFile('ads.txt', 'adsRu');
    this.getFile('ads_en.txt', 'adsEn');
  }

  /**
   * @param {object} fileName
   * @param {string} toVar
   * @return {void}
   */
  getFile(fileName: string, toVar: string) {
    settings
      .getFileForFileEditor(fileName)
      .then((res) => res.json())
      .then(({ data }) => {
        // @ts-ignore
        this[toVar].file_content = data;
      });
  }

  /**
   * @param {object} obj
   * @param {string} toVar
   * @return {void}
   */
  saveCode(obj: any, toVar: string) {
    this.buttonDisable = true;

    const params = {
      file_name: obj.file_name,
      file_content: obj.file_content,
    };

    settings
      .saveCodeForFileEditor(params)
      .then((res) => {
        const { status } = res;
        if (status === 200) {
          this.$message.success('Успешно сохранен');
          this.buttonDisable = false;
        }
        return res.json();
      })
      .then(({ code }) => {
        if (!code) {
          this.getFile(obj.file_name, toVar);
        }
      })
      .catch(() => {
        this.$message.error('Сохранить не удалось');
        this.buttonDisable = false;
      });
  }
}
