var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('PageTitle', {
    attrs: {
      "title": _vm.title
    }
  }), _c('a-layout-content', {
    staticClass: "page__content"
  }, [_c('a-form', [_c('a-form-item', {
    attrs: {
      "label": "Позиция баннера"
    }
  }, [_c('a-select', {
    attrs: {
      "size": "large",
      "mode": "multiple",
      "maxTagCount": 4,
      "placeholder": "Выберите позиции банеров",
      "filterOption": _vm.filterOption
    },
    model: {
      value: _vm.positionIds,
      callback: function callback($$v) {
        _vm.positionIds = $$v;
      },
      expression: "positionIds"
    }
  }, _vm._l(_vm.positions, function (position) {
    return _c('a-select-option', {
      key: position.id,
      attrs: {
        "value": position.id
      }
    }, [_vm._v(" " + _vm._s(position.route) + "-" + _vm._s(position.position) + " ")]);
  }), 1)], 1), _c('a-form-item', {
    attrs: {
      "label": "Десктоп",
      "requred": ""
    }
  }, [_c('a-textarea', {
    staticStyle: {
      "height": "114px"
    },
    model: {
      value: _vm.banner.desktop,
      callback: function callback($$v) {
        _vm.$set(_vm.banner, "desktop", $$v);
      },
      expression: "banner.desktop"
    }
  })], 1), _c('a-form-item', {
    attrs: {
      "label": "Мобайл",
      "requred": ""
    }
  }, [_c('a-textarea', {
    staticStyle: {
      "height": "114px"
    },
    model: {
      value: _vm.banner.mobile,
      callback: function callback($$v) {
        _vm.$set(_vm.banner, "mobile", $$v);
      },
      expression: "banner.mobile"
    }
  })], 1), _c('a-row', {
    attrs: {
      "gutter": 24
    }
  }, [_c('a-col', {
    attrs: {
      "span": "12"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "Локаль сайта"
    }
  }, [_c('a-select', {
    attrs: {
      "size": "large"
    },
    model: {
      value: _vm.banner.locale,
      callback: function callback($$v) {
        _vm.$set(_vm.banner, "locale", $$v);
      },
      expression: "banner.locale"
    }
  }, _vm._l(_vm.LOCALE_LIST, function (_ref) {
    var title = _ref.title,
      locale = _ref.locale;
    return _c('a-select-option', {
      key: locale,
      attrs: {
        "value": title
      }
    }, [_vm._v(" " + _vm._s(title) + " ")]);
  }), 1)], 1)], 1), _c('a-col', {
    attrs: {
      "span": "12"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "Отображение",
      "requred": ""
    }
  }, [_c('a-select', {
    attrs: {
      "size": "large",
      "defaultValue": "banner.isVisible"
    },
    model: {
      value: _vm.banner.isVisible,
      callback: function callback($$v) {
        _vm.$set(_vm.banner, "isVisible", $$v);
      },
      expression: "banner.isVisible"
    }
  }, [_c('a-select-option', {
    attrs: {
      "value": 1
    }
  }, [_vm._v("Вкл")]), _c('a-select-option', {
    attrs: {
      "value": 0
    }
  }, [_vm._v("Выкл")])], 1)], 1)], 1)], 1), _c('a-button', {
    attrs: {
      "type": "primary",
      "size": "large"
    },
    on: {
      "click": _vm.handleSubmit
    }
  }, [_vm._v("Сохранить")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }