import {get, post, put} from '@/api/core';

/**
 * @description Получить текущий профиль
 * @return {Promise<any>}
 */
export const getProfile = (): Promise<any> => get(`/api/admin/profile`);


/**
 * @description Метод для обновления профиля
 * @param {number} id
 * @param {object} params
 * @return {Promise<any>}
 */
export const updateProfile = (params: any): Promise<any> => put(`/api/admin/profile`, params);

export const updateProfilePassword = (params: any): Promise<any> => put(`/api/admin/profile/password`, params);
