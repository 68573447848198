<template>
  <div>
    <PageTitle title="Тег Листинги"/>

    <a-layout-content class="page__content">
      <h3>Фильтр</h3>
      <a-row :gutter="24">
        <a-col span="6">
          <a-select
            size="large"
            placeholder="Выбрать OS"
            v-model="searchParams.os_id"
          >
            <a-select-option
              v-for="os in operationSystems"
              :value="os.id"
              :key="os.id"
            >
              {{ os.seo_name }}
            </a-select-option>
          </a-select>
        </a-col>

        <a-col span="6">
          <a-input
            size="large"
            placeholder="URL"
            v-model="searchParams.url"
          />
        </a-col>

      </a-row>

      <a-row style="padding-top: 50px">
        <a-col span="12">
          <a-button size="large" @click="resetFields">Сбросить</a-button>
        </a-col>
        <a-col span="12" style="text-align: right">
          <a-button
            size="large"
            type="primary"
            style="margin-left: 20px"
            icon="search"
            @click="handleSearch"
          >Фильтровать
          </a-button>
        </a-col>
      </a-row>
    </a-layout-content>

    <a-layout-content class="page__content">
      <a-table
        :columns="columns"
        :pagination="false"
        :dataSource="listings.data"
        :loading="loading"
        rowKey="id"
      >
        <template slot="actions" slot-scope="id, record">
          <a-row type="flex">
            <a-col>
              <router-link :to="{ path: `/tag-listings/${record.id}` }">
                <a-button
                  type="primary"
                  class="gray-btn"
                  icon="edit"
                  style="margin-right: 10px"
                />
              </router-link>
            </a-col>
          </a-row>
        </template>
      </a-table>

      <a-row style="text-align: right;">
        <a-pagination
          v-if="listings.meta"
          @change="paginationChange"
          v-model="listings.meta.current_page"
          :total="listings.meta.total"
          :pageSize="listings.meta.per_page"
          style="margin-top: 20px"
        />
      </a-row>
    </a-layout-content>
  </div>
</template>

<script>
import {getTagListings} from "@/api/tagListing";
import {mapState} from "vuex";

export default {
  name: "TagListingList",

  data() {
    return {
      loading: true,
      listings: [],
      searchParams: {
        page: 1,
        os_id: undefined,
        url: undefined,
      },
      columns: [
        {
          title: 'ID',
          dataIndex: 'id',
          key: 'id',
          sorter: true,
        },
        {
          title: 'ОС',
          dataIndex: 'os.seo_name',
          key: 'os',
        },
        {
          title: 'Тег 1',
          dataIndex: 'tag_1_slug',
          key: 'tag_1_slug',
        },
        {
          title: 'Тег 2',
          dataIndex: 'tag_2_slug',
          key: 'tag_2_slug',
        },
        {
          title: 'Тег 3',
          dataIndex: 'tag_3_slug',
          key: 'tag_3_slug',
        },
        {
          title: 'Url',
          dataIndex: 'url',
          key: 'url',
        },
        {
          title: 'Действия',
          dataIndex: '',
          key: 'actions',
          scopedSlots: {customRender: 'actions'},
        },
      ],
    };
  },

  computed: {
    ...mapState(['operationSystems']),
  },

  created() {
    this.getListings({page: 1});
  },

  methods: {
    async getListings(params) {
      this.loading = true;

      const res = await getTagListings({...params});

      const data = await res.json();

      this.listings = data;
      this.loading = false;
    },

    paginationChange(page) {
      this.searchParams = {...this.searchParams, page};
      this.getListings({...this.searchParams, ...this.sortParams});
      window.scrollTo(0, 0);
    },

    resetFields() {
      this.searchParams = {
        page: 1,
        os_id: undefined,
      };

      this.getListings({page: 1});
    },

    handleSearch() {
      this.getListings(this.searchParams);
    },
  },
}
</script>

<style scoped>

</style>
