import {post, put, get, deleteMethod} from '../core';
import {CreateAppDescription} from '@/types/applications';

/**
 * @description Создать приложение (шаг 1 - основное описание)
 * @param {object} params
 * @return {Promise<any>}
 */
export const createAppDescription = (
  params: CreateAppDescription,
): Promise<any> => post('/api/admin/apps', params);

/**
 * @description Обновление приложения по id
 * @param {number} id
 * @param {object} params
 * @return {Promise<any>}
 */
export const updateAppDescription = (
  id: number,
  params: CreateAppDescription,
) => put(`/api/admin/apps/${id}`, params);

/**
 * @description Получить приложение по id
 * @param {number} id
 * @return {Promise<any>}
 */
export const getAppById = (id: number): Promise<any> =>
  get(`/api/admin/apps/${id}`);

/**
 * @description Удалить приложение по id
 * @param {number} id
 * @return {Promise<any>}
 */
export const deleteAppById = (id: number): Promise<any> =>
  deleteMethod(`/api/admin/apps/${id}`);

/**
 * @description Запрос для получения связанных новостей в виде листа (Связанные статьи)
 * @param {string} query
 * @return {Promise<any>}
 */
export const getApplicationArticlesByQuery = (query: string): Promise<any> =>
  get(`/admin/api/applications-article/{query}?term=${query}`);

/**
 * @description Запрос для получения Bzfuture приложений
 * @param query
 * @return {Promise<any>}
 */
export const getBzfutureApps = (query: string): Promise<any> =>
  get(`/admin/api/applications-bzfuture/${query}`);

/**
 * @description Получить файл приложения
 * @param {number} appId
 * @return {Promise<any>}
 */
export const getAppFiles = (appId: number): Promise<any> =>
  get(`/api/admin/apps/file/${appId}`);

/**
 * @description Обновить файл приложения
 * @param {number} appId
 * @param {object} params
 * @return {Promise<any>}
 */
export const updateAppFile = (appId: number, params: any): Promise<any> =>
  post(`/api/admin/apps/file/${appId}`, params, true);

/**
 * @description Обновить файл приложения
 * @param {number} appId
 * @param {object} params
 * @return {Promise<any>}
 */
export const updateAppFileByPut = (appId: number, params: any): Promise<any> =>
  put(`/api/admin/apps/file/${appId}`, params);

/**
 * @description Удалить файл приложения
 * @param {number} appId
 * @return {Promise<any>}
 */
export const deleteAppFile = (appId: number): Promise<any> =>
  deleteMethod(`/api/admin/apps/file/${appId}`);

/**
 * @description Добавить файл приложения
 * @param {number} appId
 * @param {object} params
 * @return {Promise<any>}
 */
export const addAppFile = (appId: number, params: any): Promise<any> =>
  post(`/api/admin/apps/file/${appId}`, params, true);

/**
 * @description Получить скриншоты приложения
 * @param {number} appId
 * @return {Promise<any>}
 */
export const getAppScreenshots = (appId: number) =>
  get(`/api/admin/apps/screenshots/${appId}`);

/**
 * @description Загрузка скриншота и логотипа по id
 * @param {number} appId
 * @param {object} params
 * @return {Promise<any>}
 */
export const uploadAppScreenshotsAndLogotype = (appId: number, params: any) =>
  post(`/api/admin/apps/screenshots/${appId}`, params, true);

/**
 * @description Удаление скриншота или логотипа у приложения
 * @param {number} imageId
 * @return {Promise<any>}
 */
export const deleteApplicationImage = (imageId: number) =>
  deleteMethod(`/api/admin/apps/screenshots/${imageId}`);

/**
 * @description Получить SEO информацию приложения
 * @param {number} appId
 * @return {Promise<any>}
 */
export const getApplicationSeo = (appId: number): Promise<any> =>
  get(`/api/admin/apps/seo/${appId}`);

/**
 * @description Обновить SEO информацию
 * @param {number} appId
 * @param {object} params∏
 */
export const updateApplicationSeo = (
  appId: number,
  params: any,
): Promise<any> => put(`/api/admin/apps/seo/${appId}`, params);


export const searchApps = (queryParams: any, params: {} = {}): Promise<any> => {
  return get(`/api/admin/applications/search`, queryParams, params);
};

export const getApplicationVersionsPage = (id: number): Promise<any> =>
  get(`/api/admin/applications/${id}/versions_page`);

export const updateApplicationVersionsPage = (
  appId: number,
  params: any,
): Promise<any> => put(`/api/admin/applications/${appId}/versions_page`, params);

export default {
  createAppDescription,
  updateAppDescription,
  getAppById,
  deleteAppById,
  getApplicationArticlesByQuery,
  getBzfutureApps,
  getAppFiles,
  updateAppFile,
  deleteAppFile,
  addAppFile,
  updateAppFileByPut,
  uploadAppScreenshotsAndLogotype,
  getAppScreenshots,
  deleteApplicationImage,
  getApplicationSeo,
  updateApplicationSeo,
  getApplicationVersionsPage,
  updateApplicationVersionsPage,
};
