var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a-breadcrumb', [_c('a-breadcrumb-item', {
    attrs: {
      "href": ""
    }
  }, [_c('router-link', {
    attrs: {
      "to": "/"
    }
  }, [_c('span', [_vm._v("Главная")])])], 1), _vm._l(_vm.breadcrumbs, function (breadcrumb) {
    return _c('a-breadcrumb-item', {
      key: breadcrumb.path
    }, [_c('router-link', {
      attrs: {
        "to": breadcrumb.path
      }
    }, [_c('span', [_vm._v(_vm._s(breadcrumb.breadcrumbName))])])], 1);
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }