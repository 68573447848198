<template>
  <div>
    <AddPageTitle
      title="Добавить versions страницу"
      :id="id"
    />

    <a-layout-content class="page__content">
      <TopMenu :id="id" active="page_versions"/>
      <a-spin :spinning="loading">
        <a-form :form="form">
          <a-card title="Локализация">
            <template
              v-if="availableLocales.length"
              v-slot:extra
            >
              <a-dropdown>
                <template v-slot:overlay>
                  <a-menu @click="addTranslation">
                    <a-menu-item
                      v-for="locale in availableLocales"
                      :key="locale"
                    >
                      {{ getLocaleTitle(locale) }}
                    </a-menu-item>
                  </a-menu>
                </template>
                <a-button> Добавить локаль
                  <a-icon type="down"/>
                </a-button>
              </a-dropdown>
            </template>

            <a-tabs
              v-if="!loading"
              v-model="activeTab"
              type="card"
            >
              <template v-for="{locale, title} in LOCALE_LIST_EXTENDED">
                <a-tab-pane
                  v-if="page.translations.hasOwnProperty(locale)"
                  :key="locale"
                  :tab="title"
                >
                  <a-form-item
                    :label="`Описание (${locale})`"
                  >
                    <ckeditor
                      v-decorator="[`translations.${locale}.content`, {initialValue: page.translations[locale] ? page.translations[locale].content : undefined}]"
                      @input="form.setFieldsValue({[`translations.${locale}.content`] : $event})"
                      :config="editorConfig()"
                    />
                  </a-form-item>

                  <a-form-item :label="`H1 (${locale})`">
                    <a-input
                      size="large"
                      placeholder="Введите h1"
                      v-decorator="[`translations.${locale}.h1`,{initialValue: page.translations[locale] ? page.translations[locale].h1 : undefined}]"
                    />
                  </a-form-item>

                  <div class="spin-content">
                    <a-row gutter="24">
                      <a-col>
                        <a-form-item :label="`SeoTitle (${locale})`">
                          <a-input
                            size="large"
                            placeholder="Введите title"
                            v-decorator="[`translations.${locale}.seo_title`,{initialValue: page.translations[locale] ? page.translations[locale].seo_title : undefined}]"
                          />
                        </a-form-item>
                      </a-col>

                      <a-col>
                        <a-form-item :label="`SeoDescription (${locale})`">
                          <a-textarea
                            auto-size
                            style="height: 145px;"
                            size="large"
                            placeholder="Введите description"
                            v-decorator="[`translations.${locale}.seo_description`,{initialValue: page.translations[locale] ? page.translations[locale].seo_description : undefined,}]"
                          />
                        </a-form-item>
                      </a-col>

                      <a-col>
                        <a-form-item :label="`SeoKeywords (${locale})`">
                          <a-input
                            size="large"
                            placeholder="Введите keywords"
                            v-decorator="[`translations.${locale}.seo_keywords`,{initialValue: page.translations[locale]? page.translations[locale].seo_keywords : undefined,}]"
                          />
                        </a-form-item>
                      </a-col>
                    </a-row>
                  </div>
                </a-tab-pane>
              </template>
            </a-tabs>
          </a-card>
        </a-form>

        <a-row :gutter="24">
          <a-col span="12">
            <a-button size="large" type="primary" @click="save">Сохранить</a-button>
          </a-col>
        </a-row>
      </a-spin>

    </a-layout-content>
  </div>
</template>

<script>
import AddPageTitle from "./AddPageTitle";
import PageTitle from "../../Global/PageTitle";
import TopMenu from "./TopMenu";
import {LOCALE_LIST_EXTENDED} from "@/global/constants";
import {getApplicationVersionsPage, updateApplicationVersionsPage} from "@/api/Applications/app";
import {getCkeditorConfig} from '../../../helpers/ckeditor';
import {isEmpty} from "lodash";

export default {
  name: "ApplicationsVersionsPage",

  components: {
    AddPageTitle,
    PageTitle,
    TopMenu,
  },

  props: {
    id: {required: true},
  },

  data() {
    return {
      form: this.$form.createForm(this),
      loading: false,
      page: {
        translations: {},
      },
      editorConfig: getCkeditorConfig,
      activeTab: '',
      LOCALE_LIST_EXTENDED,
    };
  },

  computed: {
    availableLocales() {
      const existLocales = Object.keys(this.page.translations ?? {});
      const allLocales = LOCALE_LIST_EXTENDED.map((item) => item.locale);

      return existLocales
        .filter(locale => !allLocales.includes(locale))
        .concat(allLocales.filter(locale => !existLocales.includes(locale)));
    },

  },

  async created() {
    const response = await getApplicationVersionsPage(this.id);
    const data = await response.json();
    this.page = data.data;

    if (!isEmpty(this.page.translations)) {
      this.activeTab = this.page.translations.hasOwnProperty('ru') ? 'ru' : Object.keys(this.page.translations)[0];
    }
  },

  methods: {
    getLocaleTitle(locale) {
      return LOCALE_LIST_EXTENDED.find((item) => item.locale === locale).title;
    },

    addTranslation(event) {
      this.$set(this.page.translations, event.key, {name: ''});
      this.activeTab = event.key;
    },

    async save() {
      this.form.validateFieldsAndScroll(async (errors, values) => {
        try {
          await updateApplicationVersionsPage(this.id, values);
          this.$message.success('Сохранено', 3);
        } catch (error) {
          this.$message.error('Ошибка', 3);
        }
      });
    },
  }
}
</script>

<style scoped>

</style>
