
// @ts-nocheck
import Vue from 'vue';
import PageTitle from '@/components/Global/PageTitle.vue';
import { Logging } from '@/api/logging';
import { Moment } from 'moment';
import moment from 'moment';
import { news } from '@/api/news';

export default Vue.extend({
  components: {
    PageTitle,
  },
  data() {
    return {
      pagination: {},
      dates: [null, null],
      sort: {
        createdAtOrder: 'desc',
      },
      filter: {
        user: '',
        page: 1,
      },
      logs: {
        data: [],
        meta: {},
        links: {},
      },
      columns: [
        {
          title: 'Пользователь',
          dataIndex: 'user',
          key: 'user',
        },
        {
          title: 'Тип',
          dataIndex: 'type',
          key: 'type',
          scopedSlots: { customRender: 'action' },
        },
        {
          title: 'Модель',
          dataIndex: 'model',
          key: 'model',
          scopedSlots: { customRender: 'model' },
        },
        {
          title: 'До',
          dataIndex: 'before',
          key: 'message',
          scopedSlots: { customRender: 'html' },
        },
        {
          title: ' После',
          dataIndex: 'after',
          key: 'message',
          scopedSlots: { customRender: 'html' },
        },
        {
          title: 'Время',
          dataIndex: 'createdAt',
          key: 'createdAt',
          sorter: true,
          sortDirections: ['asc', 'desc'],
        },
      ],
      hasLoadLogs: false,
    };
  },
  mounted() {
    this.getLogs();
  },
  methods: {
    /**
     * @description Обработчик на пагинацию
     * @param {number} page
     * @return {void}
     */
    paginationChange(page: number) {
      this.filter = { ...this.filter, page };
      window.scrollTo(0, 0);
      this.getLogs();
    },

    resetFilter() {
      this.filter = { user: '', page: 1 };
      this.dates = [null, null];
      this.getLogs();
    },

    async getLogs(): Promise<any> {
      this.hasLoadLogs = true;
      await Logging.searchLogs({ ...this.filter, ...this.sort })
        .then((res) => res.json())
        .then((data) => {
          const { data: logs } = data;
          const reformatLogsByUser = this.reformatLogsByUser(logs);
          this.logs = { ...data, data: reformatLogsByUser };
          this.hasLoadLogs = false;
        });
    },

    reformatLogsByUser(logs: any[]) {
      return logs.map((log: any) => {
        if (log.user === null) {
          return { ...log, user: 'Без пользователя' };
        } else {
          return { ...log, user: log.user.email || log.user.name };
        }
      });
    },

    onChange(pagination: any, filters: any, sorter: any) {
      const { order, field } = sorter;
      if (field === 'createdAt') {
        this.sort = { createdAtOrder: order };
        this.searchLogs();
      }
    },

    async handleSubmit() {
      this.hasLoadLogs = true;
      const { user } = this.filter;
      const [dateStart, dateEnd] = this.dates;
      const params = {
        user,
        // @ts-ignore FIXME
        createdAtBeforeOrder: dateStart && dateStart.format('YYYY-MM-DD'),
        // @ts-ignore FIXME
        createdAtAfterOrder: dateEnd && dateEnd.format('YYYY-MM-DD'),
      };
      this.sort = { createdAtOrder: 'desc' };
      this.filter = { ...params, page: 1 };
      await this.searchLogs();
    },

    async searchLogs() {
      await Logging.searchLogs({ ...this.filter, ...this.sort })
        .then((res) => res.json())
        .then((data) => {
          const { data: logs } = data;
          const reformatLogsByUser = this.reformatLogsByUser(logs);
          this.logs = { ...data, data: reformatLogsByUser };
          this.hasLoadLogs = false;
        });
    },
  },
});
