import { get, post, put, deleteMethod } from './core';

/**
 * @description Список новостей
 * @param {number} page
 * @return {Promise<any>}
 */
const getNewsList = (page: number): Promise<any> =>
  get(`/api/admin/news?page=${page}`);

/**
 * @description Создать новость
 * @param {object} params
 * @return {Promise<any>}
 */
const createNews = (params: any): Promise<any> =>
  post('/api/admin/news', params, true);

/**
 * @description Обновить новость
 * @param {number} newsId
 * @param {object} params
 * @return {Promise<any>}
 */
const updateNews = (newsId: number, params: any): Promise<any> =>
  post(`/api/admin/news/${newsId}`, params, true);

/**
 * @description Удалить новость
 * @param {number} newsId
 * @return {Promise<any>}
 */
const deleteNews = (newsId: number): Promise<any> =>
  deleteMethod(`/api/admin/news/${newsId}`);

/**
 * @description Получить новость по id
 * @param {number} newsId
 * @return {Promise<any>}
 */
const getNewsById = (newsId: number) => get(`/api/admin/news/${newsId}`);

/**
 * @description Поиск по новостям
 * @param {object} params
 * @return {Promise<any>}
 */
const searchNews = (params: any) => get('/api/admin/news/search', params);

// Категории новостей

/**
 * @description Получение категорий новостей
 * @return {Promise<any>}
 */
const getNewsCategory = (): Promise<any> => get('/api/admin/news-category');

/**
 * @description Поиск по категориями новостей
 * @param {object} params
 * @return {Promise<any>}
 */
const searchNewsCategory = (params: any): Promise<any> =>
  get(`/api/admin/news-category/search`, params);

/**
 * @description Получить категорию новости по id
 * @param {number} id
 * @return {Promise<any>}
 */
const getNewsCategoryNewsById = (id: number): Promise<any> =>
  get(`/api/admin/news-category/${id}`);

/**
 * @description Создать категорию новости
 * @param params
 * @return {Promise<any>}
 */
const createCategoryNews = (params: any): Promise<any> =>
  post(`/api/admin/news-category`, params);

/**
 * @description Обновить категорию новости по id
 * @param {number} id
 * @param {object} params
 * @return {Promise<any>}
 */
const updateCategoryNews = (id: number, params: any): Promise<any> =>
  put(`/api/admin/news-category/${id}`, params);

/**
 * @description Удалить категорию новости
 * @param {number} id
 * @return {Promise<any>}
 */
const deleteNewsCategory = (id: number) =>
  deleteMethod(`/api/admin/news-category/${id}`);

export const restoreNews = (id: number): Promise<any> => put(`/api/admin/news/${id}/restore`, {});

export const news = {
  getNewsList,
  createNews,
  updateNews,
  getNewsById,
  deleteNews,
  getNewsCategory,
  searchNews,
  searchNewsCategory,
  getNewsCategoryNewsById,
  createCategoryNews,
  updateCategoryNews,
  deleteNewsCategory,
};

export default { news };
