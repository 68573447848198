import * as licenses from './licenses';
import * as languages from './languages';
import * as platforms from './platforms';
import * as os from './os';
import * as osVersions from './osVersions';
import * as tags from './tags';
import * as appList from './list';
import * as app from './app';
import * as comments from './comments';
import * as landing from './landing';
import * as tagListing from './tagListing';

export const applications = {
  ...licenses.default,
  ...languages.default,
  ...platforms.default,
  ...os.default,
  ...osVersions.default,
  ...tags.default,
  ...appList.default,
  ...app.default,
  ...comments.default,
  ...landing.default,
  ...tagListing.default,
};

export default { applications };
