
// @ts-nocheck
import { Component, Vue } from 'vue-property-decorator';
import PageTitle from '@/components/Global/PageTitle.vue';
import { mapState } from 'vuex';
import { applications } from '@/api';

export interface Os {
  id: number;
  name: string;
  seo_name: string;
  label: string;
  en_label: string;
  enabled: number;
  platform_type: any;
  created_at: string;
  updated_at: string;
  is_mobile: number;
}

@Component({
  name: 'OS',
  components: { PageTitle },
  computed: mapState(['operationSystems']),
})
export default class OS extends Vue {

  columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      sorter: true,
    },
    {
      title: 'ОС',
      dataIndex: 'name',
      key: 'os',
      sorter: true,
    },
    {
      title: 'Seo Name',
      dataIndex: 'seo_name',
      key: 'versionOs',
      sorter: true,
    },
    {
      title: 'Название (ru)',
      dataIndex: 'label',
      key: 'label',
      sorter: true,
    },
    {
      title: 'Название (en)',
      dataIndex: 'en_label',
      key: 'name',
      sorter: true,
    },
    {
      title: 'Действия',
      dataIndex: 'actions',
      key: 'actions',
      sorter: true,
      scopedSlots: { customRender: 'actions' },
    },
  ];
  private operationSystems: any[];

  private deleteOs(id: number): void {
    this.$confirm({
      title: 'Вы действительно хотите операционную систему?',
      content:
        'Вы не сможете восстановить его после удаления, только добавить еще раз.',
      onOk: async () => {
        await applications.deleteOs(id).then(() => {
          this.$message.success('Операционная система успешно удалена :)', 2);
          this.$store.dispatch('GET_OPERATION_SYSTEMS');
        });
      },
    });
  }
}
