import {get, put, post, deleteMethod} from '@/api/core';

/**
 * @description Получить пользователей
 * @return {Promise<any>}
 */
const getAllUsers = (page: number): Promise<any> =>
  get(`/api/admin/user?page=${page}`);

/**
 * @description Поиск по пользователям
 * @param {object} params
 * @return {Promise<any>}
 */
export const searchUsers = (queryParams: any, params: {} = {}): Promise<any> => {
  return get(`/api/admin/user/search`, queryParams, params);
};

/**
 * @description Получение юзера по айди
 * @param {number} id
 * @return {Promise<any>}
 */
const getUserById = (id: number): Promise<any> => {
  return get(`/api/admin/user/${id}`);
};

/**
 * @description Метод для обновления пользователя
 * @param {number} id
 * @param {object} params
 * @return {Promise<any>}
 */
export const updateUser = (id: number, params: any): Promise<any> => {
  return put(`/api/admin/user/${id}`, params);
};

/**
 * @description Удаление пользователя
 * @param {number} id
 * @return {Promise<any>}
 */
const deleteUserById = (id: number): Promise<any> =>
  deleteMethod(`/api/admin/user/${id}`);

/**
 * @description Создание пользователя
 * @param {object} params
 * @return {Promise<any>}
 */
const createUser = (params: any): Promise<any> =>
  post('/api/admin/user', params);

/**
 * @description Получение информации о пользователе
 * @return {Promise<any>}
 */
const getUserInfo = (): Promise<any> => get('/api/admin/users/me');

/**
 * @description Восстановление пользователя
 * @param {number} id
 * @return {Promise<any>}
 */
const restoreUserById = (id: number): Promise<any> => put(`/api/admin/user/restore/${id}`, {});

export const users = {
  getAllUsers,
  searchUsers,
  getUserById,
  updateUser,
  deleteUserById,
  createUser,
  getUserInfo,
  restoreUserById,
};

export default {users};
