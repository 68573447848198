<template>
  <div>
    <PageTitle title="Статьи">
      <router-link to="/articles/add">
        <a-button size="large" type="primary" icon="plus">Добавить статью</a-button>
      </router-link>
    </PageTitle>
    <a-layout-content class="page__content">
      <a-row>
        <h3>Поиск</h3>
      </a-row>
      <a-row :gutter="24">
        <a-col span="6">
          <a-input
            size="large"
            v-model="search.title"
            placeholder="Название"
            v-on:keyup.enter="handleSubmit"
          ></a-input>
        </a-col>

        <a-col :span="5">
          <a-form-item>
            <a-select
              placeholder="Выберите статус"
              size="large"
              v-model="search.status"
            >
              <a-select-option value="" key="0">Выберите статус</a-select-option>
              <a-select-option value="0" key="1">В корзине</a-select-option>
              <a-select-option value="1" key="2">Активно</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="5">
          <a-select
            size="large"
            showSearch
            placeholder="Автор"
            :defaultActiveFirstOption="false"
            :showArrow="false"
            :filterOption="false"
            @search="searchDevelopers"
            notFoundContent="Введите имя разработчика..."
            v-model="search.authorId"
          >
            <a-select-option
              v-for="developer in developersList"
              :key="developer.id"
              :value="developer.id"
              >{{ developer.name }}</a-select-option
            >
          </a-select>
        </a-col>

        <a-col span="8">
          <a-range-picker
            :placeholder="['Начало', 'Конец']"
            size="large"
            v-model="dates"
          ></a-range-picker>
        </a-col>
        <a-col span="24" style="margin-top: 25px">
          <a-button
            size="large"
            type="primary"
            icon="search"
            style="margin-right: 25px"
            @click="handleSubmit"
            >Поиск</a-button>
          <a-button size="large" @click="resetFilter">Сбросить</a-button>
        </a-col>
      </a-row>
    </a-layout-content>

    <a-layout-content class="page__content">
      <a-table
        :columns="columns"
        :pagination="false"
        :dataSource="articles.data"
        :loading="hasLoadArticles"
        rowKey="id"
        @change="onChange"
      >
        <template slot="categories" slot-scope="categories, record">
          <a-tag
            v-if="categories.data"
            v-for="category in categories.data"
            :key="category.id"
            color="blue"
            >{{ category.title }}</a-tag
          >
        </template>

        <template slot="actions" slot-scope="id, record">
          <a :href="record.urlArticle" target="_blank">
            <a-button
              type="primary"
              class="gray-btn"
              icon="eye"
              style="margin-right: 10px"
            ></a-button>
          </a>

          <template v-if="record.deleted_at">
            <a-button
              type="dashed"
              class="gray-btn"
              icon="undo"
              style="margin-right: 10px"
              @click="restore(record.id)"
            />
          </template>

          <template v-else>
            <router-link :to="{ path: `/articles/${record.id}/edit` }">
              <a-button
                type="primary"
                class="gray-btn"
                icon="edit"
                style="margin-right: 10px"
              />
            </router-link>

            <a-button
              type="danger"
              class="gray-btn"
              icon="delete"
              style="margin-right: 10px"
              @click="deleteArticle(record.id)"
            />
          </template>

        </template>

        <template v-slot:author="author">
          {{ author.name }}
        </template>

        <template v-slot:name="translations">
          {{ translations?.ru?.title ?? translations[Object.keys(translations)[0]].title }}
        </template>
      </a-table>

      <a-row style="text-align: right;">
        <a-pagination
          v-if="articles.meta"
          @change="paginationChange"
          v-model="articles.meta.current_page"
          :total="articles.meta.total"
          :pageSize="articles.meta.per_page"
          style="margin-top: 20px"
        />
      </a-row>
    </a-layout-content>
  </div>
</template>

<script>
import PageTitle from '@/components/Global/PageTitle.vue';
import { debounce } from 'lodash';
import { users } from '@/api/users';
import {articles, restoreArticle} from '@/api/articles';

export default {
  name: 'ArticlesList',
  components: { PageTitle },
  data() {
    return {
      searchDevelopers: debounce(this.handleDevelopersSearch, 300),
      developersList: [],
      articles: {},
      dates: [],
      search: {
        title: undefined,
        authorId: undefined,
        status: '1',
        page: 1,
      },
      sort: {
        [`order[publishedAt]`]: 'desc',
      },
      hasLoadArticles: false,
      columns: [
        {
          title: 'Название',
          dataIndex: 'translations',
          key: 'title',
          width: 480,
          scopedSlots: { customRender: 'name' },
        },
        {
          title: 'Автор',
          dataIndex: 'author',
          key: 'author',
          scopedSlots: { customRender: 'author' },
          sorter: true,
          sortDirections: ['desc', 'asc'],
        },
        {
          title: 'Категория',
          dataIndex: 'categories',
          key: 'categories',
          scopedSlots: { customRender: 'categories' },
        },
        {
          title: 'Дата публикации',
          dataIndex: 'publishedAt',
          key: 'date',
          sorter: true,
          sortDirections: ['desc', 'asc'],
        },
        {
          title: 'Действия',
          dataIndex: '',
          key: 'actions',
          scopedSlots: { customRender: 'actions' },
        },
      ],
    };
  },
  mounted() {
    this.getArticlesList();
  },
  methods: {
    resetFilter() {
      this.dates = [];
      this.search = {
        title: undefined,
        authorId: undefined,
        status: '1',
        page: 1,
      };
      this.sort = {
        [`order[publishedAt]`]: 'desc',
      };
      this.getArticlesList();
    },
    onChange(pagination, filters, sorter) {
      const { order, field } = sorter;
      this.sortCommentsByField(field, order);
    },

    async sortCommentsByField(field, order) {
      this.hasLoadArticles = true;
      this.sort = { [`order[${field}]`]: order };
      await articles
        .searchArticles({
          ...this.sort,
          ...this.search,
        })
        .then((res) => res.json())
        .then((data) => {
          this.hasLoadArticles = false;
          this.articles = data;
        });
    },

    async handleSubmit() {
      this.hasLoadArticles = true;
      const { title, authorId } = this.search;
      const [dateStart, dateEnd] = this.dates;

      const params = {
        title,
        authorId,
        publishedAt: {
          start: dateStart && dateStart.format('YYYY-MM-DD'),
          end: dateEnd && dateEnd.format('YYYY-MM-DD'),
        },
      };

      // TODO: Нужны человеческие статусы от Жени (когда нибудь), пока что так
      if (this.search.status === '0') {
        params.deleted_at = 1;
      } else if (this.search.status === '1') {
        params.deleted_at = 0;
      } else if (this.search.status === '2') {
        params.markAsSpam = 1;
      } else if (this.search.status === '3') {
        params.markAsSpam = 0;
      }

      await articles
        .searchArticles({
          ...params,
          [`order[publishedAt]`]: 'desc',
          page: 1,
        })
        .then((res) => res.json())
        .then((data) => {
          this.articles = data;
          this.hasLoadArticles = false;
        });
    },

    async handleDevelopersSearch(value) {
      await users
        .searchUsers({
          name: value,
        })
        .then((res) => res.json())
        .then(({ data }) => {
          this.developersList = data;
        });
    },

    async getArticlesList() {
      this.hasLoadArticles = true;

      await articles
        .searchArticles({ ...this.search, ...this.sort })
        .then((res) => res.json())
        .then((data) => {
          this.articles = data;
          this.hasLoadArticles = false;
        });
    },

    deleteArticle(id) {
      this.$confirm({
        title: `Вы действительно хотите удалить данную статью?`,
        content: 'Вы сможете восстановить ее после удаления.',
        onOk: async () => {
          await articles.deleteArticle(id).then(() => {
            this.$message.success('Статья успешна удалена', 3);
            this.handleSubmit();
          });
        },
      });
    },

    paginationChange(page) {
      window.scrollTo(0, 0);
      this.search = { ...this.search, page };
      this.getArticlesList();
      window.scrollTo(0, 0);
    },

    restore(id) {
      this.$confirm({
        title: `Вы действительно хотите восстановить данную статью?`,
        onOk: async () => {
          await restoreArticle(id).then(() => {
            this.$message.success('Статья успешна восстановлена', 3);
            this.handleSubmit();
          });
        },
      });
    },
  },
};
</script>
