import Applications from './Applications/Applications.vue';
import DescriptionDeveloper from '@/components/Applications/Add/DescriptionDeveloper.vue';

export const applicationsRoute = '/applications-developer';
export const addApplicationDeveloper = 'add-description-developer';

export const developerRoutes = [
  {
    path: applicationsRoute,
    component: Applications,
    name: 'Applications',
    breadcrumbName: 'Приложения',
    meta: {
      title: 'Приложения',
    },
  },
  {
    path: addApplicationDeveloper,
    component: DescriptionDeveloper,
    breadcrumbName: 'Добавить приложение',
    name: 'Добавить приложение',
    meta: {
      title: 'Добавить приложение',
    },
  },
];

export default { developerRoutes };
