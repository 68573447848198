<template>
  <a-modal
    title="Ответить на комментарий"
    visible
    :confirm-loading="loading"
    @ok="save"
    @cancel="close"
  >
    <p>{{ review.text }}</p>
    <a-form :form="form" @submit.prevent="save">
      <a-form-item label="Ответ">
        <a-textarea
          rows="4"
          required
          v-decorator="['text', { rules: [{ required: true, message: 'Введите ваш ответ' }] }]"
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { createComment } from '@/api/Applications/comments';

export default {
  name : 'CommentsAnswerModal',
  props: {
    review: {
      required: true,
      type    : Number,
    },
  },
  data() {
    return {
      loading: false,
      form          : this.$form.createForm(this, { name: 'answer' }),
    };
  },
  methods: {
    async save() {
      try {
        this.loading = true;
        const values = await this.form.validateFields();
        await createComment(this.review.application_id, {...values, parent_id: this.review.id});
        this.$emit('created');
        this.close();
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped>

</style>
