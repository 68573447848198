
// @ts-nocheck
import {Component, Vue, Prop} from 'vue-property-decorator';
import AuthService from '@/helpers/AuthService';
import {APP_URL} from '@/global/constants';

const authService = new AuthService();

@Component({name: 'Header'})
export default class Header extends Vue {
  @Prop() setStatus: () => void;

  logout() {
    authService.logout();
    window.location.assign(`${APP_URL}/auth/logout-from-admin`);
  }
}
