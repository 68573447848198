<template>
  <div>
    <a-affix :offsetTop="0">
      <a-menu mode="horizontal" size="large" style="padding: 20px 0">
        <a-menu-item
          key="description"
          v-bind:class="active === 'description' && 'ant-menu-item-active'"
        >
          <router-link :to="{ path: descriptionLink }">ОПИСАНИЕ</router-link>
        </a-menu-item>
        <a-menu-item
          key="files"
          :disabled="!moderationId && id === undefined"
          v-bind:class="active === 'files' && 'ant-menu-item-active'"
        >
          <router-link :to="{ path: filesLink }">ФАЙЛЫ</router-link>
        </a-menu-item>
        <a-menu-item
          key="logo"
          :disabled="!moderationId && id === undefined"
          v-bind:class="active === 'images' && 'ant-menu-item-active'"
        >
          <router-link :to="{ path: imagesLink }">ЛОГО И СКРИНШОТЫ</router-link>
        </a-menu-item>
        <a-menu-item
          key="seo"
          v-if="!moderationId && (user.hasAdmin || user.hasEditor || user.hasSeo)"
          :disabled="id === undefined"
          :class="active === 'seo' && 'ant-menu-item-active'"
        >
          <router-link :to="{ path: seoLink }">SEO</router-link>
        </a-menu-item>

        <a-menu-item
          key="page_versions"
          v-if="!moderationId && (user.hasAdmin || user.hasEditor || user.hasSeo)"
          :disabled="id === undefined"
          :class="active === 'page_versions' && 'ant-menu-item-active'"
        >
          <router-link :to="{ name: 'AppPageVersions' }">Страница versions</router-link>
        </a-menu-item>

        <a-menu-item
          v-if="!moderationId && (user.hasAdmin || user.hasEditor || user.hasSeo)"
          key="tag_lists"
          :disabled="!moderationId && id === undefined"
          :class="{'ant-menu-item-active' : active === 'tag_lists'}"
        >
          <router-link :to="tagListsRoute">Категории</router-link>
        </a-menu-item>
      </a-menu>
    </a-affix>
  </div>
</template>

<script >
  import PageTitle from '@/components/Global/PageTitle.vue';
  import {addDescriptionRoute} from '@/components/Applications/routes';
  import {mapState} from 'vuex';

  export default {
    components: {PageTitle},
    props: ['id', 'active', 'moderationId'],
    data() {
      return {
        descriptionLink: '',
        filesLink: '',
        imagesLink: '',
        seoLink: '',
        hasAdmin: false,
        siteLink: '',
      };
    },
    computed: {
      ...mapState(['user']),
      tagListsRoute() {
        if (this.moderationId) {
          return {};
        }

        if (!this.id) {
          return '';
        }

        return {
          name: 'app-tag-lists-edit',
          params: {id: this.id},
        };
      },
    },
    mounted() {
      if (this.moderationId) {
        this.descriptionLink = `/moderation/${this.moderationId}/description`;
        this.filesLink = `/moderation/${this.moderationId}/files`;
        this.imagesLink = `/moderation/${this.moderationId}/images`;
        this.seoLink = `/moderation/${this.moderationId}/seo`;
      } else if (this.id) {
        this.descriptionLink = `/edit-description/${this.id}/edit`;
        this.filesLink = `/add-files/${this.id}/edit`;
        this.imagesLink = `/images/${this.id}/edit`;
        this.seoLink = `/seo/${this.id}/edit`;
        this.siteLink = `/sites/${this.id}/edit`;
      } else {
        this.descriptionLink = addDescriptionRoute;
      }
    },
  };
</script>

<style scoped lang="scss">
  .ant-menu-root {
    height: 80px !important;
  }

  .ant-menu-item {
    padding: 0 50px;
    font-size: 16px;
  }
</style>
