import Vue from 'vue';
import Router from 'vue-router';
import Login from '@/components/Global/Login.vue';
import Root from '@/components/Root/Root.vue';
import { news } from '@/components/News/routes';
import AuthService from '@/helpers/AuthService';
import { users } from '@/components/Users/routes';
import { advert } from '@/components/Advert/routes';
import { externalCodeRoutes } from '@/components/Settings/ExternalCode/routes';
import { FSInstallRoutes } from '@/components/Settings/FSInstall/routes';
import { redirectRoutes } from '@/components/Settings/Redirect/routes';
import { editFileRoutes } from '@/components/Settings/FileEditor/routes';
import { commentRoutes } from '@/components/Comments/routes';
import { applicationRoutes } from '@/components/Applications/routes';
import { staticPagesRoutes } from '@/components/Pages/routes';
import { moderationRoutes } from '@/components/Moderation/routes';
import { articles } from '@/components/Articles/routes';
import { loggingRoutes } from '@/components/Logging/routes';
import { developerRoutes } from '@/components/DeveloperAccount/routes';
import { landingRoutes } from './components/Landing/routes';
import { profile } from '@/components/Profile/routes';
import { categorization } from '@/components/Categorization/routes';
import { officialRepresentativeRoutes } from '@/components/OfficialRepresentative/routes';
import { authByToken } from '@/api/login';
import store from './store.js';
import { afterLoginCallback } from '@/global/callbacks/afterLoginCallback';
import tagListingRoutes from '@/components/TagListing/routes';
import LinkToSiteList from './components/LinkToSite/LinkToSiteList';

Vue.use(Router);

export const children = [
  ...externalCodeRoutes,
  ...FSInstallRoutes,
  ...redirectRoutes,
  ...editFileRoutes,
  ...commentRoutes,
  ...users,
  ...advert,
  ...applicationRoutes,
  ...staticPagesRoutes,
  ...news,
  ...moderationRoutes,
  ...articles,
  ...loggingRoutes,
  ...landingRoutes,
  // Developer FIXME
  ...developerRoutes,
  ...profile,
  ...categorization,
  ...officialRepresentativeRoutes,
  ...tagListingRoutes,
  {
    name: 'link-to-site',
    path: '/link-to-site',
    component: LinkToSiteList,
    breadcrumbName: 'Ссылка на сайт',
    meta: {title: 'Ссылка на сайт',},
  },
];

// @ts-ignore
const router = new Router({
  mode  : 'history',
  routes: [
    {
      path     : '/',
      component: Root,
      children,
      meta     : { requiresAuth: true },
    },
    {
      path     : '/login',
      name     : 'login',
      component: Login,
      meta     : { requiresAuth: false },
    },
  ],
});

const authService = new AuthService();

router.beforeEach(
  async (to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      if (to && to.meta && to.meta.title) {
        document.title = to.meta.title;
      }

      if (!authService.loggedIn() && to.path !== '/login') {
        if (to.query.token) {
          const response = await authByToken(String(to.query.token));

          if (response.status === 401) {
            return next(`/login?redirectTo=${to.path}`);
          }

          const json = await response.json();
          authService.setToken(json.access_token);

          afterLoginCallback(store);

          return next(to.path);
        }

        return next(`/login?redirectTo=${to.fullPath}`);
      } else {
        if (to.query.token) {
          delete to.query.token;
          return next(to);
        }
        next();
      }
    } else {
      next();
    }
  },
);

export default router;
