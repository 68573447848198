var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('PageTitle', {
    attrs: {
      "title": "Список лендингов"
    }
  }), _c('a-layout-content', {
    staticClass: "page__content"
  }, [_c('a-table', {
    attrs: {
      "columns": _vm.columns,
      "loading": _vm.hasLoadLandings,
      "dataSource": _vm.landings.data,
      "pagination": false
    },
    scopedSlots: _vm._u([{
      key: "visible",
      fn: function fn(visible) {
        return [visible === 0 ? _c('a-icon', {
          staticStyle: {
            "font-size": "18px"
          },
          attrs: {
            "type": "eye-invisible"
          }
        }) : _vm._e(), visible === 1 ? _c('a-icon', {
          staticStyle: {
            "font-size": "18px"
          },
          attrs: {
            "type": "eye"
          }
        }) : _vm._e()];
      }
    }, {
      key: "app",
      fn: function fn(app) {
        return [_c('p', [_vm._v(_vm._s(app.name))])];
      }
    }, {
      key: "actions",
      fn: function fn(_, landing) {
        return [_c('a-switch', {
          staticStyle: {
            "margin-left": "15px"
          },
          attrs: {
            "checked": Boolean(landing.visible)
          },
          on: {
            "change": function change(e) {
              return _vm.handleChange(e, landing.id, landing.application.id);
            }
          }
        }), _c('a-button', {
          staticStyle: {
            "margin-left": "15px"
          },
          attrs: {
            "type": "danger",
            "icon": "delete"
          },
          on: {
            "click": function click($event) {
              return _vm.handleDeleteLanding(landing.id);
            }
          }
        })];
      }
    }])
  }), _c('a-row', {
    staticStyle: {
      "text-align": "right"
    }
  }, [_c('a-pagination', {
    attrs: {
      "total": _vm.landings.meta.total,
      "pageSize": _vm.landings.meta.per_page
    },
    on: {
      "change": _vm.paginationChange
    },
    model: {
      value: _vm.landings.meta.current_page,
      callback: function callback($$v) {
        _vm.$set(_vm.landings.meta, "current_page", $$v);
      },
      expression: "landings.meta.current_page"
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }