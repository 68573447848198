import { Route } from '@/types/route';
import List from './List.vue';
import Add from './Add.vue';

export const listRoute = '/settings/redirect/';
export const addRoute = '/settings/redirect/add';
export const editRoute = '/settings/redirect/:id';

export const redirectRoutes: Route[] = [
  {
    path: listRoute,
    name: 'listRedirect',
    component: List,
    breadcrumbName: 'Редиректы',
    meta: {
      title: 'Редиректы',
    },
  },
  {
    path: addRoute,
    name: 'addRedirect',
    component: Add,
    breadcrumbName: 'Добавить редирект',
    meta: {
      title: 'Добавить редирект',
    },
  },
  {
    path: editRoute,
    name: 'editRedirect',
    component: Add,
    props: true,
    breadcrumbName: 'Редактировать редирект',
    meta: {
      title: 'Редактировать редирект',
    },
  },
];

export default { redirectRoutes };
