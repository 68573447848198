import { get, put, post, deleteMethod } from '@/api/core';
import { Position } from '@/types/advert';

/**
 * @description Получить список позиций рекламы
 * @return {Promise<any>}
 */
export const getAdvertPositions = (): Promise<any> =>
  get('/api/admin/ads/ad-position');

/**
 * @description Создание новой позиции
 * @param {object} params
 * @return {Promise<any>}
 */
export const createAdvertPosition = (params: Position): Promise<any> =>
  post('/api/admin/ads/ad-position', params);

/**
 * @description Получить позицию рекламы по {id}
 * @param {number} id
 * @return {Promise<any>}
 */
export const getAdvertPositionById = (id: number): Promise<any> =>
  get(`/api/admin/ads/ad-position/${id}`);

/**
 * @description /api/admin/ads/ad-position/{id}
 * @param {number} id
 * @param {object} params
 * @return {Promise<any>}
 */
export const updatePositionAdvertById = (
  id: number,
  params: Position,
): Promise<any> => put(`/api/admin/ads/ad-position/${id}`, params);

/**
 * @description Поиск рекламных позиций
 * @param {object} params
 * @return {Promise<any>}
 */
export const searchAdvertsPositions = (params: any): Promise<any> =>
  get('/api/admin/ads/ad-position/search', params);

export default {
  getAdvertPositions,
  createAdvertPosition,
  getAdvertPositionById,
  updatePositionAdvertById,
  searchAdvertsPositions,
};
