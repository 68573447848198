var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('PageTitle', {
    attrs: {
      "title": _vm.title
    }
  }), _c('a-layout-content', {
    staticClass: "page__content"
  }, [_c('a-form', {
    attrs: {
      "layout": "horizontal"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "Email",
      "required": true,
      "label-col": _vm.formLayout.labelCol,
      "wrapper-col": _vm.formLayout.wrapperCol
    }
  }, [_c('a-input', {
    attrs: {
      "size": "large"
    },
    model: {
      value: _vm.user.email,
      callback: function callback($$v) {
        _vm.$set(_vm.user, "email", $$v);
      },
      expression: "user.email"
    }
  })], 1), _c('a-form-item', {
    attrs: {
      "label": "Роль",
      "label-col": _vm.formLayout.labelCol,
      "wrapper-col": _vm.formLayout.wrapperCol
    }
  }, [_c('a-select', {
    attrs: {
      "size": "large",
      "defaultValue": "",
      "placeholder": "Выберите роль"
    },
    model: {
      value: _vm.user.role,
      callback: function callback($$v) {
        _vm.$set(_vm.user, "role", $$v);
      },
      expression: "user.role"
    }
  }, [_c('a-select-option', {
    attrs: {
      "value": "developer"
    }
  }, [_vm._v("Разработчик")]), _c('a-select-option', {
    attrs: {
      "value": "user"
    }
  }, [_vm._v("Пользователь")]), _c('a-select-option', {
    attrs: {
      "value": "content_manager"
    }
  }, [_vm._v("Контент менеджер")]), _c('a-select-option', {
    attrs: {
      "value": "editor"
    }
  }, [_vm._v("Редактор")]), _c('a-select-option', {
    attrs: {
      "value": "admin"
    }
  }, [_vm._v("Администратор")]), _c('a-select-option', {
    attrs: {
      "value": "seo"
    }
  }, [_vm._v("SEO специалист")]), _c('a-select-option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("Не выбрано")])], 1)], 1), _c('a-form-item', {
    attrs: {
      "label": "Имя",
      "size": "large",
      "required": true,
      "label-col": _vm.formLayout.labelCol,
      "wrapper-col": _vm.formLayout.wrapperCol
    }
  }, [_c('a-input', {
    attrs: {
      "size": "large"
    },
    model: {
      value: _vm.user.name,
      callback: function callback($$v) {
        _vm.$set(_vm.user, "name", $$v);
      },
      expression: "user.name"
    }
  })], 1), _c('a-form-item', {
    attrs: {
      "label": "Фамилия",
      "size": "large",
      "label-col": _vm.formLayout.labelCol,
      "wrapper-col": _vm.formLayout.wrapperCol
    }
  }, [_c('a-input', {
    attrs: {
      "size": "large"
    },
    model: {
      value: _vm.user.surname,
      callback: function callback($$v) {
        _vm.$set(_vm.user, "surname", $$v);
      },
      expression: "user.surname"
    }
  })], 1), _c('a-form-item', {
    attrs: {
      "label": "Пароль",
      "size": "large",
      "label-col": _vm.formLayout.labelCol,
      "wrapper-col": _vm.formLayout.wrapperCol
    }
  }, [_c('a-row', [_c('a-col', {
    attrs: {
      "span": 16
    }
  }, [_c('a-input', {
    attrs: {
      "size": "large"
    },
    model: {
      value: _vm.password,
      callback: function callback($$v) {
        _vm.password = $$v;
      },
      expression: "password"
    }
  })], 1), _c('a-col', {
    attrs: {
      "span": 8,
      "gutter": "4"
    }
  }, [_c('a-button', {
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      "type": "default",
      "size": "large"
    },
    on: {
      "click": _vm.setRandomPassword
    }
  }, [_vm._v("Сгенерировать ")])], 1)], 1)], 1), _c('a-form-item', {
    attrs: {
      "label": "Телефон",
      "label-col": _vm.formLayout.labelCol,
      "wrapper-col": _vm.formLayout.wrapperCol
    }
  }, [_c('a-input', {
    attrs: {
      "size": "large"
    },
    model: {
      value: _vm.user.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.user, "phone", $$v);
      },
      expression: "user.phone"
    }
  })], 1), _c('a-form-item', {
    attrs: {
      "label": "Дата рождения",
      "label-col": _vm.formLayout.labelCol,
      "wrapper-col": _vm.formLayout.wrapperCol
    }
  }, [_c('a-date-picker', {
    attrs: {
      "size": "large",
      "placeholder": "Выберите дату"
    },
    model: {
      value: _vm.user.birthday,
      callback: function callback($$v) {
        _vm.$set(_vm.user, "birthday", $$v);
      },
      expression: "user.birthday"
    }
  })], 1), _c('a-form-item', {
    attrs: {
      "label": "Официальный представитель",
      "size": "large",
      "label-col": _vm.formLayout.labelCol,
      "wrapper-col": _vm.formLayout.wrapperCol
    }
  }, [_c('a-checkbox', {
    attrs: {
      "size": "large"
    },
    model: {
      value: _vm.user.official_representative,
      callback: function callback($$v) {
        _vm.$set(_vm.user, "official_representative", $$v);
      },
      expression: "user.official_representative"
    }
  })], 1), _c('a-form-item', {
    attrs: {
      "label": "Город",
      "label-col": _vm.formLayout.labelCol,
      "wrapper-col": _vm.formLayout.wrapperCol
    }
  }, [_c('a-input', {
    attrs: {
      "size": "large"
    },
    model: {
      value: _vm.user.city,
      callback: function callback($$v) {
        _vm.$set(_vm.user, "city", $$v);
      },
      expression: "user.city"
    }
  })], 1), _c('a-form-item', {
    attrs: {
      "label": "Род деятельности",
      "label-col": _vm.formLayout.labelCol,
      "wrapper-col": _vm.formLayout.wrapperCol
    }
  }, [_c('a-input', {
    attrs: {
      "size": "large"
    },
    model: {
      value: _vm.user.roleOfActivity,
      callback: function callback($$v) {
        _vm.$set(_vm.user, "roleOfActivity", $$v);
      },
      expression: "user.roleOfActivity"
    }
  })], 1), _c('a-form-item', {
    attrs: {
      "label": "Должность",
      "label-col": _vm.formLayout.labelCol,
      "wrapper-col": _vm.formLayout.wrapperCol
    }
  }, [_c('a-input', {
    attrs: {
      "size": "large"
    },
    model: {
      value: _vm.user.position,
      callback: function callback($$v) {
        _vm.$set(_vm.user, "position", $$v);
      },
      expression: "user.position"
    }
  })], 1), _c('a-form-item', {
    attrs: {
      "label": "Сайт",
      "label-col": _vm.formLayout.labelCol,
      "wrapper-col": _vm.formLayout.wrapperCol
    }
  }, [_c('a-input', {
    attrs: {
      "size": "large"
    },
    model: {
      value: _vm.user.site,
      callback: function callback($$v) {
        _vm.$set(_vm.user, "site", $$v);
      },
      expression: "user.site"
    }
  })], 1), _c('a-form-item', {
    attrs: {
      "label": "О себе (ru)",
      "label-col": _vm.formLayout.labelCol,
      "wrapper-col": _vm.formLayout.wrapperCol
    }
  }, [_c('a-textarea', {
    staticStyle: {
      "height": "120px"
    },
    attrs: {
      "size": "large"
    },
    model: {
      value: _vm.user.description,
      callback: function callback($$v) {
        _vm.$set(_vm.user, "description", $$v);
      },
      expression: "user.description"
    }
  })], 1), _c('a-form-item', {
    attrs: {
      "label": "О себе (en)",
      "label-col": _vm.formLayout.labelCol,
      "wrapper-col": _vm.formLayout.wrapperCol
    }
  }, [_c('a-textarea', {
    staticStyle: {
      "height": "120px"
    },
    attrs: {
      "size": "large"
    },
    model: {
      value: _vm.user.enDescription,
      callback: function callback($$v) {
        _vm.$set(_vm.user, "enDescription", $$v);
      },
      expression: "user.enDescription"
    }
  })], 1)], 1), _c('a-row', {
    attrs: {
      "gutter": 24
    }
  }, [_c('a-col', {
    attrs: {
      "span": "12"
    }
  }, [_c('a-button', {
    attrs: {
      "type": "primary",
      "size": "large"
    },
    on: {
      "click": _vm.saveChanges
    }
  }, [_vm._v(_vm._s(_vm.action) + " ")])], 1), _vm.id ? _c('a-col', {
    staticStyle: {
      "text-align": "right"
    },
    attrs: {
      "span": "12"
    }
  }, [_vm.user.active !== 0 ? _c('a-button', {
    staticClass: "orange-btn",
    staticStyle: {
      "margin-right": "20px"
    },
    attrs: {
      "size": "large"
    },
    on: {
      "click": _vm.setUserToBan
    }
  }, [_vm._v("Заблокировать ")]) : _vm._e(), !_vm.user.deletedAt ? _c('a-button', {
    attrs: {
      "size": "large",
      "type": "danger"
    },
    on: {
      "click": _vm.deleteUser
    }
  }, [_vm._v("Удалить ")]) : _vm._e(), _vm.user.deletedAt ? _c('a-button', {
    attrs: {
      "size": "large",
      "type": "primary"
    },
    on: {
      "click": _vm.restoreUser
    }
  }, [_vm._v("Восстановить ")]) : _vm._e()], 1) : _vm._e()], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }