import {Store} from 'vuex';

export function afterLoginCallback(store: Store<any>): void {
  store.dispatch('GET_USER');
  store.dispatch('GET_OPERATION_SYSTEMS');
  store.dispatch('GET_OPERATION_SYSTEMS_VERSIONS');
  store.dispatch('GET_LICENSE_TYPES');
  store.dispatch('GET_PLATFORM_TYPES');
  store.dispatch('GET_LANGUAGES');
  store.dispatch('GET_MENU_LIST');
  store.dispatch('getCategories');
}
