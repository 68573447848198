var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('AddPageTitle', {
    attrs: {
      "title": "Добавить мини сайт",
      "id": _vm.id
    }
  }), _c('a-layout-content', {
    staticClass: "page__content"
  }, [_c('TopMenu', {
    attrs: {
      "id": _vm.id,
      "active": "site"
    }
  }), _c('a-spin', {
    attrs: {
      "spinning": false
    }
  }, [_c('a-form', {
    staticStyle: {
      "padding": "30px 10px"
    },
    attrs: {
      "form": _vm.form
    }
  }, [_c('a-row', {
    attrs: {
      "gutter": 24
    }
  }, [_c('a-col', {
    attrs: {
      "span": "12"
    }
  }, [_c('a-form-item', [_c('a-switch', {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ['enable', {
        initialValue: _vm.site && Boolean(_vm.site.enable)
      }],
      expression: "[\n                    'enable',\n                    {\n                      initialValue: site && Boolean(site.enable)\n                    }\n                  ]"
    }],
    model: {
      value: _vm.site.enable,
      callback: function callback($$v) {
        _vm.$set(_vm.site, "enable", $$v);
      },
      expression: "site.enable"
    }
  })], 1), _c('span', {
    staticStyle: {
      "margin-left": "10px"
    }
  }, [_vm._v("вкл/выкл мини-сайт")])], 1), _c('a-col', {
    attrs: {
      "span": "12"
    }
  }, [_c('a-form-item', [_c('a-input', {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ['name', {
        initialValue: _vm.site && _vm.site.name,
        rules: [{
          required: true,
          max: 150
        }]
      }],
      expression: "[\n                    'name',\n                    {\n                      initialValue: site && site.name,\n                      rules: [\n                        {\n                          required: true,\n                          max: 150,\n                        }\n                      ]\n                    }\n                  ]"
    }],
    attrs: {
      "size": "large",
      "placeholder": "Введите домен без www"
    }
  }), _c('i', {
    staticClass: "info"
  }, [_vm._v("Домен 2 или 3 уровня в формате ХХХ.ХХХ или ХХХ.ХХХ.ХХ без протоколов")])], 1)], 1), _c('a-col', {
    attrs: {
      "span": "24"
    }
  }, [_c('i', {
    staticClass: "info"
  }, [_vm._v(" Для настройки NS записей в панели регистратора домена нужно прописать для домена/поддомена A записи, в которых указать ip адрес: 77.244.208.170 ")])]), _c('a-col', {
    attrs: {
      "span": "24"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "Добавить в head"
    }
  }, [_c('a-textarea', {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ['seo_head', {
        initialValue: _vm.site && _vm.site.seo_head,
        rules: [{
          required: false,
          message: 'Поле обязательно'
        }]
      }],
      expression: "[\n                    'seo_head',\n                    {\n                      initialValue: site && site.seo_head,\n                      rules: [\n                        {\n                          required: false,\n                          message: 'Поле обязательно'\n                        }\n                      ]\n                    }\n                  ]"
    }],
    staticStyle: {
      "height": "145px"
    },
    attrs: {
      "size": "large",
      "placeholder": "Вся вводимая информация отображается перед закрывающимся тегом </head>"
    }
  })], 1)], 1), _c('a-col', {
    attrs: {
      "span": "24"
    }
  }, [_c('i', {
    staticClass: "info"
  }, [_vm._v("Поле подходит для добавления счетчиков статистики, подтверждения прав в Вебмастере и прочими мета-кодами сервисов.")])]), _c('a-col', {
    attrs: {
      "span": "24"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "Добавить в robots.txt"
    }
  }, [_c('a-textarea', {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ['robots', {
        initialValue: _vm.site && _vm.site.robots,
        rules: [{
          required: false,
          message: 'Поле обязательно'
        }]
      }],
      expression: "[\n                    'robots',\n                    {\n                      initialValue: site && site.robots,\n                      rules: [\n                        {\n                          required: false,\n                          message: 'Поле обязательно'\n                        }\n                      ]\n                    }\n                  ]"
    }],
    staticStyle: {
      "height": "145px"
    },
    attrs: {
      "size": "large",
      "placeholder": "Содержимое robots"
    }
  })], 1)], 1), _c('a-col', {
    attrs: {
      "span": "24"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "SeoTitle"
    }
  }, [_c('a-input', {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ['seo_title', {
        initialValue: _vm.site && _vm.site.seo_title,
        rules: [{
          required: false,
          message: 'Заполните пожалуйста seo title'
        }]
      }],
      expression: "[\n                    'seo_title',\n                    {\n                      initialValue: site && site.seo_title,\n                      rules: [\n                        {\n                          required: false,\n                          message: 'Заполните пожалуйста seo title'\n                        }\n                      ]\n                    }\n                  ]"
    }],
    attrs: {
      "size": "large",
      "placeholder": "Введите title"
    }
  })], 1)], 1), _c('a-col', {
    attrs: {
      "span": "24"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "SeoDescription"
    }
  }, [_c('a-textarea', {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ['seo_description', {
        initialValue: _vm.site && _vm.site.seo_description,
        rules: [{
          required: false,
          message: 'Заполните пожалуйста seo description'
        }]
      }],
      expression: "[\n                    'seo_description',\n                    {\n                      initialValue: site && site.seo_description,\n                      rules: [\n                        {\n                          required: false,\n                          message: 'Заполните пожалуйста seo description'\n                        }\n                      ]\n                    }\n                  ]"
    }],
    staticStyle: {
      "height": "145px"
    },
    attrs: {
      "size": "large",
      "placeholder": "Введите description"
    }
  })], 1)], 1), _c('a-col', {
    attrs: {
      "span": "24"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "Краткое описание"
    }
  }, [_c('ckeditor', {
    attrs: {
      "config": _vm.editorConfig
    },
    model: {
      value: _vm.short_description,
      callback: function callback($$v) {
        _vm.short_description = $$v;
      },
      expression: "short_description"
    }
  }), _c('p', [_vm._v(" Количество слов: " + _vm._s(_vm.getWordCount(_vm.short_description)) + " ")])], 1)], 1), _c('a-col', {
    attrs: {
      "span": "24"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "Полное описание"
    }
  }, [_c('ckeditor', {
    attrs: {
      "config": _vm.editorConfig
    },
    model: {
      value: _vm.description,
      callback: function callback($$v) {
        _vm.description = $$v;
      },
      expression: "description"
    }
  }), _c('p', [_vm._v(" Количество слов: " + _vm._s(_vm.getWordCount(_vm.description)) + " ")])], 1)], 1), _vm.hasCreate ? _c('a-col', {
    staticStyle: {
      "text-align": "right"
    },
    attrs: {
      "span": "24"
    }
  }, [_c('a-button', {
    attrs: {
      "size": "large",
      "type": "primary"
    },
    on: {
      "click": _vm.handleSaveSite
    }
  }, [_vm._v("Сохранить")])], 1) : _vm._e(), !_vm.hasCreate ? _c('a-col', {
    staticStyle: {
      "text-align": "right"
    },
    attrs: {
      "span": "24"
    }
  }, [_c('a-button', {
    attrs: {
      "size": "large",
      "type": "primary"
    },
    on: {
      "click": _vm.updateMiniSite
    }
  }, [_vm._v("Обновить")])], 1) : _vm._e()], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }