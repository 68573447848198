var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('AddPageTitle', {
    attrs: {
      "title": "Добавить SEO",
      "id": _vm.id
    }
  }), _c('a-layout-content', {
    staticClass: "page__content"
  }, [_c('TopMenu', {
    attrs: {
      "id": _vm.id,
      "active": "seo"
    }
  }), _c('a-spin', {
    attrs: {
      "spinning": _vm.hasLoadApplicationSeo
    }
  }, [_c('a-form', {
    attrs: {
      "form": _vm.form
    }
  }, [_vm.app ? _c('a-tabs', {
    attrs: {
      "type": "card"
    }
  }, _vm._l(_vm.locales, function (_ref) {
    var title = _ref.title,
      locale = _ref.locale;
    return _c('a-tab-pane', {
      key: locale,
      attrs: {
        "tab": title
      }
    }, [_c('div', {
      staticClass: "spin-content"
    }, [_c('a-row', {
      attrs: {
        "gutter": "24"
      }
    }, [_c('a-col', [_c('a-form-item', {
      attrs: {
        "label": "SeoTitle (".concat(locale, ")")
      }
    }, [_c('a-input', {
      directives: [{
        name: "decorator",
        rawName: "v-decorator",
        value: ["translations.".concat(locale, ".seo_title"), {
          initialValue: _vm.app.translations[locale] ? _vm.app.translations[locale].seo_title : undefined
        }],
        expression: "[`translations.${locale}.seo_title`,{initialValue: app.translations[locale] ? app.translations[locale].seo_title : undefined}]"
      }],
      attrs: {
        "size": "large",
        "placeholder": "Введите title"
      }
    })], 1)], 1), _c('a-col', [_c('a-form-item', {
      attrs: {
        "label": "SeoDescription (".concat(locale, ")")
      }
    }, [_c('a-textarea', {
      directives: [{
        name: "decorator",
        rawName: "v-decorator",
        value: ["translations.".concat(locale, ".seo_description"), {
          initialValue: _vm.app.translations[locale] ? _vm.app.translations[locale].seo_description : undefined
        }],
        expression: "[`translations.${locale}.seo_description`,{initialValue: app.translations[locale] ? app.translations[locale].seo_description : undefined,}]"
      }],
      staticStyle: {
        "height": "145px"
      },
      attrs: {
        "auto-size": "",
        "size": "large",
        "placeholder": "Введите description"
      }
    })], 1)], 1), _c('a-col', [_c('a-form-item', {
      attrs: {
        "label": "SeoKeywords (".concat(locale, ")")
      }
    }, [_c('a-input', {
      directives: [{
        name: "decorator",
        rawName: "v-decorator",
        value: ["translations.".concat(locale, ".seo_keywords"), {
          initialValue: _vm.app.translations[locale] ? _vm.app.translations[locale].seo_keywords : undefined
        }],
        expression: "[`translations.${locale}.seo_keywords`,{initialValue: app.translations[locale]? app.translations[locale].seo_keywords : undefined,}]"
      }],
      attrs: {
        "size": "large",
        "placeholder": "Введите keywords"
      }
    })], 1)], 1)], 1)], 1)]);
  }), 1) : _vm._e()], 1), _c('a-row', {
    attrs: {
      "gutter": 24
    }
  }, [_c('a-col', {
    attrs: {
      "span": "12"
    }
  }, [_c('a-button', {
    attrs: {
      "size": "large",
      "type": "primary"
    },
    on: {
      "click": _vm.handleSubmit
    }
  }, [_vm._v("Сохранить")])], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }