import {get, post, put, deleteMethod} from '@/api/core';

/**
 * @description Получить список комментариев
 * @param {number} params
 * @return {Promise<any>}
 */
const getApplicationComments = (params: any): Promise<any> =>
  get(`/api/admin/apps/comments`, params);

/**
 * @description Создать комментарий
 * @param {object} params
 * @return {Promise<any>}
 */
export const createComment = (id: number, params: any): Promise<any> =>
  post(`/api/admin/apps/${id}/comments`, params);

/**
 * @description Получить 1 комментарий
 * @param {number} id
 * @return {Promise<any>}
 */
const getCommentById = (id: number): Promise<any> =>
  get(`/api/admin/apps/comments/${id}`);

/**
 * @description Обновление комментария приложения
 * @param {number} id
 * @param {object} params
 * @return {Promise<any>}
 */
const updateCommentById = (id: number, params: any) =>
  put(`/api/admin/apps/comments/${id}`, params);

/**
 * @description Обновние статуса спама
 * @param {number} commentId
 * @param {object} params
 * @return {Promise<any>}
 */
const updateCommentSpam = (commentId: number, params: any) =>
  put(`/api/admin/apps/comments/mark-spam/${commentId}`, params);

/**
 * @description Удалить комментарий
 * @param {number} id
 * @return {Promise<any>}
 */
const deleteCommentById = (id: number) =>
  deleteMethod(`/api/admin/apps/comments/${id}`);

/**
 * @description Поиск по комментариям
 * @param {object} params
 * @return {Promise<any>}
 */
const searchComments = (params: any) =>
  get('/api/admin/apps/comments/search', params);

export const createFakeComment = (id: number, params: any): Promise<any> =>
  post(`/api/admin/apps/${id}/comments-fake`, params);

export default {
  getApplicationComments,
  createComment,
  getCommentById,
  updateCommentById,
  deleteCommentById,
  searchComments,
  updateCommentSpam,
};
