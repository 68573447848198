
// @ts-nocheck
import { auth } from '@/api/login';
import { Component, Vue, Prop } from 'vue-property-decorator';
import AuthService from '@/helpers/AuthService';
import {afterLoginCallback} from '@/global/callbacks/afterLoginCallback';

const authService = new AuthService();

@Component({ name: 'Login' })
export default class Login extends Vue {
  @Prop() setStatus: () => void;

  email: string =  '';
  password: string = '';

  getJwtToken(): void {
    if (this.email.length && this.password.length) {
      auth(this.email, this.password).then((res) => {
        if (res.status === 401) {
          this.$message.error('Неверный логин и пароль, попробуйте еще раз :(', 2);
        }
        return res.json();
      }).then(({ access_token }) => {
        authService.setToken(access_token);

        afterLoginCallback(this.$store);

        const route: string = this.$route.query.redirectTo ? String(this.$route.query.redirectTo) : '/';
        this.$router.push(route);
      });
    } else {
      this.$message.error('Заполните оба поля :)', 2);
    }
  }

  beforeCreate(): void {
    if (authService.loggedIn()) {
      this.$router.push('/');
    }
  }
}
