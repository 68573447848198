var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('PageTitle', {
    attrs: {
      "title": "Список приложений"
    }
  }), _c('a-layout-content', {
    staticClass: "page__content"
  }, [_c('h3', [_vm._v("Фильтр")]), _c('a-row', {
    attrs: {
      "gutter": 24
    }
  }, [_c('a-col', {
    attrs: {
      "span": "4"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "Выбрать OS"
    }
  }, [_c('a-select', {
    attrs: {
      "size": "large",
      "placeholder": "Выбрать OS"
    },
    model: {
      value: _vm.searchParams.os,
      callback: function callback($$v) {
        _vm.$set(_vm.searchParams, "os", $$v);
      },
      expression: "searchParams.os"
    }
  }, _vm._l(_vm.operationSystems, function (os) {
    return _c('a-select-option', {
      key: os.id,
      attrs: {
        "value": os.id
      }
    }, [_vm._v(_vm._s(os.name) + " ")]);
  }), 1)], 1)], 1), _c('a-col', {
    attrs: {
      "span": "3"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "Min ID"
    }
  }, [_c('a-input', {
    attrs: {
      "size": "large",
      "placeholder": "Min ID"
    },
    model: {
      value: _vm.searchParams.minId,
      callback: function callback($$v) {
        _vm.$set(_vm.searchParams, "minId", $$v);
      },
      expression: "searchParams.minId"
    }
  })], 1)], 1), _c('a-col', {
    attrs: {
      "span": "3"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "Max ID"
    }
  }, [_c('a-input', {
    attrs: {
      "size": "large",
      "placeholder": "Max ID"
    },
    model: {
      value: _vm.searchParams.maxId,
      callback: function callback($$v) {
        _vm.$set(_vm.searchParams, "maxId", $$v);
      },
      expression: "searchParams.maxId"
    }
  })], 1)], 1), _c('a-col', {
    attrs: {
      "span": "4"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "Есть категория?"
    }
  }, [_c('a-select', {
    attrs: {
      "size": "large",
      "placeholder": "Есть категория?"
    },
    model: {
      value: _vm.searchParams.hasCategory,
      callback: function callback($$v) {
        _vm.$set(_vm.searchParams, "hasCategory", $$v);
      },
      expression: "searchParams.hasCategory"
    }
  }, _vm._l(_vm.categoryFilter, function (filter) {
    return _c('a-select-option', {
      key: filter.label,
      attrs: {
        "value": filter.value
      }
    }, [_vm._v(_vm._s(filter.label) + " ")]);
  }), 1)], 1)], 1), _c('a-col', {
    attrs: {
      "span": "10"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "Категория"
    }
  }, [_c('a-select', {
    attrs: {
      "size": "large",
      "placeholder": "Категория"
    },
    model: {
      value: _vm.searchParams.category,
      callback: function callback($$v) {
        _vm.$set(_vm.searchParams, "category", $$v);
      },
      expression: "searchParams.category"
    }
  }, _vm._l(_vm.categories, function (category) {
    return _c('a-select-option', {
      key: category.id,
      attrs: {
        "value": category.id
      }
    }, [_vm._v(" " + _vm._s(category.os.name) + " - " + _vm._s(category.parent ? category.parent.name + ' / ' : '') + _vm._s(category.name) + " ")]);
  }), 1)], 1)], 1)], 1), _c('a-row', [_c('a-col', {
    attrs: {
      "span": "12"
    }
  }, [_c('a-button', {
    attrs: {
      "size": "large"
    },
    on: {
      "click": _vm.resetFields
    }
  }, [_vm._v("Сбросить")])], 1), _c('a-col', {
    staticStyle: {
      "text-align": "right"
    },
    attrs: {
      "span": "12"
    }
  }, [_c('a-button', {
    staticStyle: {
      "margin-left": "20px"
    },
    attrs: {
      "size": "large",
      "type": "primary",
      "icon": "search"
    },
    on: {
      "click": _vm.handleSearch
    }
  }, [_vm._v("Фильтровать ")])], 1)], 1)], 1), _c('a-layout-content', {
    staticClass: "page__content"
  }, [_c('a-table', {
    attrs: {
      "columns": _vm.columns,
      "pagination": false,
      "dataSource": _vm.apps.data,
      "loading": _vm.hasLoadApps,
      "rowKey": "id",
      "rowClassName": _vm.rowClassName
    },
    on: {
      "change": _vm.onChange
    },
    scopedSlots: _vm._u([{
      key: "id",
      fn: function fn(id, record) {
        return [_c('a', {
          key: id,
          attrs: {
            "target": "_blank",
            "href": record.url
          }
        }, [_vm._v(" " + _vm._s(id) + " ")])];
      }
    }, {
      key: "os",
      fn: function fn(os) {
        return [_vm._v(" " + _vm._s(os.name) + " ")];
      }
    }, {
      key: "description",
      fn: function fn(desc) {
        return [_c('a-button', {
          attrs: {
            "type": "primary"
          },
          on: {
            "click": function click($event) {
              _vm.showDescription = desc;
            }
          }
        }, [_vm._v(" показать ")])];
      }
    }, {
      key: "yandex",
      fn: function fn(desc, record) {
        return [_c('a', {
          attrs: {
            "href": "https://yandex.by/search/?text=".concat(record.name, " ").concat(_vm.removeTags(record.translations.ru.description).substring(0, 100)),
            "target": "_blank"
          }
        }, [_vm._v("поиск ")])];
      }
    }, {
      key: "category",
      fn: function fn(category, record) {
        return [_c('a-select', {
          attrs: {
            "show-search": "",
            "size": "large",
            "placeholder": "категория",
            "filter-option": _vm.categoryFilterOption
          },
          model: {
            value: record.t_category_id,
            callback: function callback($$v) {
              _vm.$set(record, "t_category_id", $$v);
            },
            expression: "record.t_category_id"
          }
        }, [_vm._l(_vm.categories.filter(function (cat) {
          return cat.os_id === record.operation_system_id;
        }), function (cat) {
          return [_c('a-select-option', {
            key: cat.id,
            attrs: {
              "value": cat.id
            }
          }, [_vm._v(" " + _vm._s(cat.name) + " ")]), _vm._l(cat.children, function (subCat) {
            return _c('a-select-option', {
              key: subCat.id,
              attrs: {
                "value": subCat.id
              }
            }, [_vm._v(" " + _vm._s(cat.name) + " / " + _vm._s(subCat.name) + " ")]);
          })];
        })], 2)];
      }
    }, {
      key: "actions",
      fn: function fn(actions, record) {
        return [_c('a-button', {
          attrs: {
            "type": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.save(record.id, record.t_category_id);
            }
          }
        }, [_vm._v(" сохранить ")])];
      }
    }])
  }), _c('a-row', {
    staticStyle: {
      "text-align": "right"
    }
  }, [_vm.apps.meta ? _c('a-pagination', {
    staticStyle: {
      "margin-top": "20px"
    },
    attrs: {
      "total": _vm.apps.meta.total,
      "pageSize": _vm.apps.meta.per_page
    },
    on: {
      "change": _vm.paginationChange
    },
    model: {
      value: _vm.apps.meta.current_page,
      callback: function callback($$v) {
        _vm.$set(_vm.apps.meta, "current_page", $$v);
      },
      expression: "apps.meta.current_page"
    }
  }) : _vm._e()], 1)], 1), _c('a-modal', {
    attrs: {
      "title": "Описание"
    },
    on: {
      "ok": function ok($event) {
        _vm.showDescription = false;
      }
    },
    model: {
      value: _vm.showDescription,
      callback: function callback($$v) {
        _vm.showDescription = $$v;
      },
      expression: "showDescription"
    }
  }, [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.showDescription)
    }
  })])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }