
// @ts-nocheck
import Vue from 'vue';
import PageTitle from '@/components/Global/PageTitle.vue';
import ModerationTop from '@/components/Moderation/ModerationTop.vue';
import TopMenu from './TopMenu.vue';
import {mapState} from 'vuex';
import {users} from '@/api/users';
import {debounce, isEmpty, findKey, merge} from 'lodash';
import {applications} from '@/api';
import {Language, Tag} from '@/types/applications';
import DeveloperModeration from '@/components/Moderation/DeveloperModeration.vue';
import AddPageTitle from '@/components/Applications/Add/AddPageTitle.vue';
import {getCkeditorConfig} from '../../../helpers/ckeditor.js';
import AppBenefits from '@/components/Applications/Add/AppBenefits.vue';
import AppQuestions from '@/components/Applications/Add/AppQuestions.vue';
import AppProsCons from '@/components/Applications/Add/AppProsCons.vue';
import {LOCALE_DOMAIN_LIST, LOCALE_LIST_EXTENDED} from '../../../global/constants.js';
import {searchApps} from "@/api/Applications/app";

export default Vue.extend({
  components: {
    AppProsCons,
    AppQuestions,
    AppBenefits,
    AddPageTitle,
    PageTitle,
    TopMenu,
    ModerationTop,
    DeveloperModeration,
  },

  computed: {
    ...mapState([
      'operationSystems',
      'platformTypes',
      'licenseTypes',
      'operationSystemsVersions',
      'languages',
      'user',
      'hasLoadUser',
      'categories',
    ]),

    availableLocales() {
      const existLocales = Object.keys(this.app.translations);
      const allLocales = LOCALE_LIST_EXTENDED.map((item) => item.locale);

      return existLocales
          .filter(locale => !allLocales.includes(locale))
          .concat(allLocales.filter(locale => !existLocales.includes(locale)));
    },

    existLocales() {
      const existLocales = Object.keys(this.app.translations);
      const allLocales = LOCALE_LIST_EXTENDED.map((item) => item.locale);

      return allLocales.filter(locale => existLocales.includes(locale));
    },

    categoryOptions() {
      if (!this.app.operation_system_id) {
        return [];
      }

      return this.categories.filter(category => Number(category.os_id) === Number(this.app.operation_system_id))
          .map(category => {
            if (category.children) {
              return  category.children.map(subCategory => {
                return {
                  label: category.name + ' / ' + subCategory.name,
                  value: subCategory.id,
                };
              });

            } else {
              return {
                label: category.name,
                value: category.id,
              }
            }
          })
          .flat();
    },

    operationSystemsOptions() {
      return this.operationSystems;
    },
  },

  props: ['id', 'moderationId'],

  created() {
    if (this.user.hasDeveloper && !Boolean(this.user.name)) {
      this.$message.error('Заполните имя разработчика');
      this.$router.push({name: 'Profile'});
    }
  },

  mounted() {
    if (this.moderationId) {
      this.title = 'Модерация приложения';
      this.action = 'Сохранить';
      this.getApplicationById();
    } else if (this.id) {
      this.title = 'Редактировать приложение';
      this.action = 'Сохранить';
      this.getApplicationById();
    } else {
      this.title = 'Создать приложение';
      this.action = 'Создать';
    }

    this.$store.subscribe((mutation) => {
      if (mutation.type === 'SET_OPERATION_SYSTEMS_VERSIONS') {
        this.operationSystemsVersionsByOs = this.$store.state.operationSystemsVersions.filter(
            (version: any) =>
                version.operationSystem.id === this.app.operationSystem.id,
        );
      }

      if (mutation.type === 'SET_LANGUAGES_LIST') {
        this.options = this.languages.map((language: Language) => ({
          label: language.name,
          value: language.id,
        }));
      }
    });

    this.options = this.languages.map((language: Language) => ({
      label: language.name,
      value: language.id,
    }));

    window.scrollTo(0, 0);
  },

  data() {
    return {
      tags: [],
      app: {
        id: 0,
        name: undefined,
        seoName: undefined,
        seo_name: undefined,
        platform: undefined,
        operationSystem: {id: 0},
        osVersions: [],
        site: undefined,
        developer: undefined,
        groupNames: undefined,
        relatedApplications: undefined,
        bzFutureApplication: undefined,
        articleRelations: undefined,
        license: undefined,
        interfaceLangs: undefined,
        tags: undefined,
        frequency: undefined,
        isLastUploaded: undefined,
        isApiOffer: undefined,
        isApi: undefined,
        isPopular: undefined,
        enabled: undefined,
        linkShow: undefined,
        disable_download_modal: undefined,
        update_url: undefined,
        benefits: {},
        questions: {},
        enable_autoupdate: undefined,
        google_play_id: undefined,
        translations: {},
        download_tag: null,
        availabilities: [],
        category_id: null,
        platform_type_id: null,
        operation_system_id: null,
      },
      title: '',
      action: '',
      developersList: [],
      bzfutureApps: [],
      similarAppsList: [],
      relatedAppsList: [],
      tagsList: [],
      articlesList: [],
      hasLoadApplication: false,
      editorConfig: getCkeditorConfig,
      form: this.$form.createForm(this),
      options: undefined,
      // @ts-ignore
      searchDevelopers: debounce(this.handleDevelopersSearch, 300),
      // @ts-ignore
      searchBzfuture: debounce(this.handleBzfutureSearchApps, 300),
      // @ts-ignore
      searchSimilarApps: debounce(this.handleSearchSimilarApps, 300),
      // @ts-ignore
      searchRelatedApps: debounce(this.handleSearchRelatedApps, 300),
      // @ts-ignore
      searchTags: debounce(this.handleTagsSearch, 300),
      // @ts-ignore
      searchArticles: debounce(this.handleSearchArticles, 300),
      operationSystemsVersionsByOs: [],
      hasAdmin: false,
      hasEditor: false,
      errorsBag: {},
      LOCALE_LIST_EXTENDED,
      activeTab: '',
      availabilityOptions: LOCALE_DOMAIN_LIST,
      categoryFilterOption: function (input, option) {
        return (
            option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
        );
      },
    };
  },

  methods: {
    getWordCount(text: string): number {
      if (!text) {
        return 0;
      }

      return text
          .replace(/<(?:.|\n)*?>/gm, '')
          .replace(/(\r\n|\n|\r)/gm, '')
          .replace('&nbsp;', '')
          .trim().length;
    },

    handlePlatformChange(platformId) {
      this.operationSystemsVersionsByOs = [];
      this.form.resetFields(['operationSystemId']);
      this.form.resetFields(['osVersions']);
      this.form.resetFields(['category_id']);
      this.app.osVersions = [];
      this.app.category_id = null;
      this.app.platform_type_id = platformId;
    },

    handleOsChange(osId: number): void {
      this.operationSystemsVersionsByOs = this.$store.state.operationSystemsVersions.filter(
          (version: any) => version.operationSystem.id === osId,
      );
      this.form.resetFields(['osVersions']);
      this.form.resetFields(['category_id']);
      this.app.osVersions = [];
      this.app.category_id = null;
      this.app.operation_system_id = osId;
    },

    /**
     * @description Получение приложения по id
     * @return {Promise<any>}
     */
    async getApplicationById(): Promise<any> {
      this.hasLoadApplication = true;
      await applications
          .getAppById(this.id || this.moderationId)
          .then((res) => res.json())
          .then(({data}) => {
            const {
              operationSystem: {id: osId},
            } = data;
            const osVersions = this.$store.state.operationSystemsVersions;

            if (osVersions.length) {
              this.operationSystemsVersionsByOs = osVersions.filter(
                  (version: any) => version.operationSystem.id === osId,
              );
            }

            this.hasLoadApplication = false;

            const {
              developer: {name},
              tags,
              groupNames,
              relatedApplications,
              articleRelations,
              bzFutureApplication,
            } = data;
            // this.app = merge(this.app, data);
            data.availabilities = data.availabilities.map(availability => availability.locale);
            this.app = data;

            this.handleDevelopersSearch(name);
            this.relatedAppsList = groupNames.results;
            this.similarAppsList = relatedApplications.results;
            this.articlesList = articleRelations.results;
            this.tags = tags;
            this.bzfutureApps = bzFutureApplication.data.map((item: any) => ({
              ...item,
              text: item.name,
            }));

            return data;
          });

      this.activeTab = this.existLocales.length ? this.existLocales[0] : 'ru';
    },

    /**
     * @description
     * @return {void}
     */
    handleTagsChange(tagId: number) {
      if (findKey(this.tags, (tag: Tag) => tag.id === tagId)) {
        this.$message.info('Данный тег уже есть :)');
      } else {
        // @ts-ignore
        this.tags = [
          ...this.tags,
          this.tagsList.find((tag: Tag) => tag.id === tagId),
        ];
      }
    },

    /**
     * @description Удаление тега из облака тегов
     * @return {void}
     */
    removeTag(id: number) {
      this.tags = this.tags.filter((tag: Tag) => tag.id !== id);
    },

    /**
     * @description Обработка ошибок
     * @param {string} message
     * @return {function}
     */
    handleErrors(defaultMessage: string) {
      return ({errors, code, data, message}: any) => {
        if (code === 500) {
          if (message) {
            this.$message.error(message);
          } else {
            this.$message.error('Произошла ошибка!');
          }
        } else if (errors && code === 422) {
          Object.values(errors).forEach((values: any) => {
            values.forEach((value: string) => {
              this.$message.error(value);
            });
          });
        } else {
          this.$message.success(defaultMessage, 2);
        }
        return data;
      };
    },

    /**
     * @description Сохранение описания
     * @return {Promise<any>}
     */
    async handleSaveDescription(): Promise<any> {
      this.form.validateFieldsAndScroll(
          async (errors: any, values: any): Promise<any> => {
            if (isEmpty(errors)) {
              if (!Object.keys(this.app.translations).length) {
                alert('Не добавлено ни одного перевода!');
                return;
              }

              const params = {
                ...values,
              };


              Object.keys(params).forEach((key: string) => {
                const value = params[key];

                if (key === 'availabilities') {
                  return;
                }

                if (value instanceof Array) {
                  params[key] = value.map((val: any) => Number(val));
                }

                if (typeof value === 'boolean') {
                  params[key] = Number(value);
                }
              });

              // Если роль - разработчик
              if (this.user.hasDeveloper && !this.id) {
                await applications
                    .createAppDescription({
                      ...params,
                      developerId: this.user.id,
                      benefits: this.app.benefits,
                      questions: this.app.questions,
                    })
                    .then((res) => res.json())
                    .then(
                        this.handleErrors(
                            'Описание успешно создано, добавьте файлы и скриншоты :)',
                        ),
                    )
                    .then(({id}) => {
                      window.scrollTo(0, 0);
                      if (id) {
                        this.$router.push(`/add-files/${id}/edit`);
                      }
                    });
                return;
              }

              // Если есть id приложения или moderation Id
              if (this.id || this.moderationId) {
                if (this.user.hasDeveloper) {
                  params.developerId = this.user.id;
                }

                await applications
                    .updateAppDescription(
                        this.id || this.moderationId,
                        {
                          ...params,
                          benefits: this.app.benefits,
                          questions: this.app.questions,
                        },
                    )
                    .then((res) => res.json())
                    .then(this.handleErrors('Описание успешно отредактировано :)'))
                    .then((data) => {
                      window.scrollTo(0, 0);
                      data.availabilities = data.availabilities.map(item => item.locale);
                      merge(this.app, data);

                      if (this.moderationId) {
                        this.$router.push(`/moderation/${this.moderationId}/files`);
                      }
                    });
              } else {
                try {
                  const response = await applications
                      .createAppDescription({
                        ...params,
                        benefits: this.app.benefits,
                        questions: this.app.questions,
                      });
                  const json = await response.json();
                  this.$message.success('Описание успешно создано, добавьте файлы и скриншоты :)', 2);
                  window.scrollTo(0, 0);
                  if (!this.id) {
                    this.$router.push(`/add-files/${json.data.id}/edit`);
                  }
                } catch (error) {
                  const json = await error.json();

                  if (json.errors && json.code === 422) {
                    Object.values(json.errors).forEach((values: any) => {
                      values.forEach((value: string) => {
                        this.$message.error(value);
                      });
                    });
                  }
                }
              }
            }
          },
      );
    },

    /**
     * @description
     * @return {Promise<any>}
     */
    async handleSearchArticles(value: string): Promise<any> {
      await applications
          .getApplicationArticlesByQuery(value)
          .then((res) => res.json())
          .then(({results}) => {
            this.articlesList = results;
          });
    },

    handleRelatedArticlesChange() {
      Object.assign(this, {
        articlesList: [],
      });
    },

    async handleSearchSimilarApps(value: string): Promise<any> {
      const res = await searchApps(
          {
            name: value,
            limit: 50,
          });

      const json = await res.json();

      this.similarAppsList = json.data;
    },

    async handleSearchRelatedApps(value: string): Promise<any> {
      const res = await searchApps(
          {
            name: value,
            limit: 50,
          });

      const json = await res.json();

      this.relatedAppsList = json.data;
    },

    /**
     * @description Поиск по разработчикам
     * @param {string} value
     * @return {Promise<any>}
     */
    async handleDevelopersSearch(value: string): Promise<any> {
      await users
          .searchUsers({
            role: 'developer',
            name: value,
            without_deleted: 1,
          })
          .then((res) => res.json())
          .then(({data}) => {
            this.developersList = data;
          });
    },
    /**
     * @description Поиск по тегам
     * @param {string} value
     * @return {Promise<any>}
     */
    async handleTagsSearch(value: string): Promise<any> {
      await applications
          .searchTags({tagList: value})
          .then((res) => res.json())
          .then(({data}) => {
            this.tagsList = data;
          });
    },

    /**
     * @description Поиск связанным приложениям BzfutureApps
     * @param {string} value
     * @return {Promise<any>}
     */
    async handleBzfutureSearchApps(value: string): Promise<any> {
      await applications
          .getBzfutureApps(value)
          .then((res) => res.json())
          .then(({results}) => {
            this.bzfutureApps = results;
          });
    },

    getLocaleTitle(locale) {
      return LOCALE_LIST_EXTENDED.find((item) => item.locale === locale).title;
    },

    addTranslation(event) {
      this.$set(this.app.translations, event.key, {name: ''});
      this.activeTab = event.key;
    },

    onTabTranslationsEdit(event) {
      this.$delete(this.app.translations, event);
      if (this.activeTab === event) {
        this.activeTab = this.existLocales.length ? this.existLocales[0] : 'ru';
      }
    },
  },
});
