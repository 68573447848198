var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('PageTitle', {
    attrs: {
      "title": "Комментарии статей"
    }
  }), _c('a-layout-content', {
    staticClass: "page__content"
  }, [_c('a-row', {
    attrs: {
      "gutter": 24
    }
  }, [_c('a-col', {
    attrs: {
      "span": 14
    }
  }, [_c('a-form', {
    attrs: {
      "form": _vm.form
    },
    on: {
      "submit": _vm.handleSubmit
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "Поиск комментариев"
    }
  }, [_c('a-input', {
    attrs: {
      "placeholder": "Поиск",
      "size": "large"
    }
  })], 1)], 1)], 1), _c('a-col', {
    attrs: {
      "span": 7
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "Временной промежуток"
    }
  }, [_c('a-range-picker', {
    attrs: {
      "size": "large"
    }
  })], 1)], 1), _c('a-col', {
    style: {
      padding: '36px 0 0 0'
    },
    attrs: {
      "span": 2
    }
  }, [_c('a-button', {
    attrs: {
      "icon": "search",
      "size": "large",
      "type": "primary",
      "htmlType": "submit"
    }
  }, [_vm._v("Поиск")])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }