var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a-table', {
    attrs: {
      "columns": _vm.columns,
      "dataSource": _vm.tagListings,
      "loading": _vm.loading || _vm.removing,
      "rowKey": "id"
    },
    scopedSlots: _vm._u([{
      key: "filterDropdown",
      fn: function fn(_ref) {
        var setSelectedKeys = _ref.setSelectedKeys,
          selectedKeys = _ref.selectedKeys,
          confirm = _ref.confirm,
          clearFilters = _ref.clearFilters,
          column = _ref.column;
        return _c('div', {
          staticStyle: {
            "padding": "8px"
          }
        }, [_c('a-input', {
          directives: [{
            name: "ant-ref",
            rawName: "v-ant-ref",
            value: function value(c) {
              return _vm.searchInput = c;
            },
            expression: "c => (searchInput = c)"
          }],
          staticStyle: {
            "width": "188px",
            "margin-bottom": "8px",
            "display": "block"
          },
          attrs: {
            "placeholder": "\u0410\u0434\u0440\u0435\u0441",
            "value": selectedKeys[0]
          },
          on: {
            "change": function change(e) {
              return setSelectedKeys(e.target.value ? [e.target.value] : []);
            },
            "pressEnter": function pressEnter() {
              return _vm.handleSearch(selectedKeys, confirm, column.dataIndex);
            }
          }
        }), _c('a-button', {
          staticStyle: {
            "width": "90px",
            "margin-right": "8px"
          },
          attrs: {
            "type": "primary",
            "icon": "search",
            "size": "small"
          },
          on: {
            "click": function click() {
              return _vm.handleSearch(selectedKeys, confirm, column.dataIndex);
            }
          }
        }, [_vm._v(" Искать ")]), _c('a-button', {
          staticStyle: {
            "width": "90px"
          },
          attrs: {
            "size": "small"
          },
          on: {
            "click": function click() {
              return _vm.handleReset(clearFilters);
            }
          }
        }, [_vm._v(" Сбросить ")])], 1);
      }
    }, {
      key: "filterIcon",
      fn: function fn(filtered) {
        return _c('a-icon', {
          style: {
            color: filtered ? '#108ee9' : undefined
          },
          attrs: {
            "type": "search"
          }
        });
      }
    }, {
      key: "customRender",
      fn: function fn(text, record, index, column) {
        return [_vm.searchText && _vm.searchedColumn === column.dataIndex ? _c('span', [_vm._l(text.toString().split(new RegExp("(?<=".concat(_vm.searchText, ")|(?=").concat(_vm.searchText, ")"), 'i')), function (fragment, i) {
          return [fragment.toLowerCase() === _vm.searchText.toLowerCase() ? _c('mark', {
            key: i,
            staticClass: "highlight"
          }, [_vm._v(_vm._s(fragment))]) : [_vm._v(_vm._s(fragment))]];
        })], 2) : [_vm._v(" " + _vm._s(text) + " ")]];
      }
    }, {
      key: "actions",
      fn: function fn(tagListing) {
        return [_c('a-tooltip', [_c('template', {
          slot: "title"
        }, [_vm._v(" Отвязать категорию ")]), _c('a-button', {
          staticClass: "gray-btn",
          attrs: {
            "type": "danger",
            "icon": "minus"
          },
          on: {
            "click": function click($event) {
              return _vm.remove(tagListing.id);
            }
          }
        })], 2)];
      }
    }])
  });

}
var staticRenderFns = []

export { render, staticRenderFns }