import Logging from './Logging.vue';

export const loggingRoute = '/model-log';

export const loggingRoutes = [
  {
    path: loggingRoute,
    component: Logging,
    name: 'Logging',
    breadcrumbName: 'Логирование',
    meta: {
      title: 'Логирование',
    },
  },
];

export default { loggingRoutes };
