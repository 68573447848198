var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('PageTitle', {
    attrs: {
      "title": "Редиректы"
    }
  }, [_c('a-button', {
    staticStyle: {
      "margin-right": "24px"
    },
    attrs: {
      "size": "large",
      "type": "default",
      "icon": "download"
    }
  }, [_vm._v("Экспортировать")]), _c('router-link', {
    attrs: {
      "to": "/settings/redirect/add"
    }
  }, [_c('a-button', {
    staticStyle: {
      "margin-bottom": "10px"
    },
    attrs: {
      "size": "large",
      "type": "primary",
      "icon": "plus"
    }
  }, [_vm._v("Добавить редирект")])], 1)], 1), _c('a-layout-content', {
    staticClass: "page__content"
  }, [_c('a-row', [_c('h3', [_vm._v("Поиск")])]), _c('a-row', {
    attrs: {
      "gutter": 24
    }
  }, [_c('a-col', {
    attrs: {
      "span": 12
    }
  }, [_c('a-input', {
    attrs: {
      "placeholder": "Откуда",
      "size": "large"
    },
    on: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.searchRedirect();
      }
    },
    model: {
      value: _vm.source,
      callback: function callback($$v) {
        _vm.source = $$v;
      },
      expression: "source"
    }
  })], 1), _c('a-col', {
    attrs: {
      "span": 12
    }
  }, [_c('a-input', {
    attrs: {
      "placeholder": "Куда",
      "size": "large"
    },
    on: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.searchRedirect();
      }
    },
    model: {
      value: _vm.destination,
      callback: function callback($$v) {
        _vm.destination = $$v;
      },
      expression: "destination"
    }
  })], 1), _c('a-col', {
    staticStyle: {
      "margin-top": "25px"
    },
    attrs: {
      "span": 24
    }
  }, [_c('a-button', {
    staticStyle: {
      "margin-right": "25px"
    },
    attrs: {
      "size": "large",
      "type": "primary",
      "icon": "search"
    },
    on: {
      "click": _vm.searchRedirect
    }
  }, [_vm._v("Поиск")]), _c('a-button', {
    attrs: {
      "size": "large"
    },
    on: {
      "click": _vm.clearFilter
    }
  }, [_vm._v("Очистить")])], 1)], 1)], 1), _c('a-layout-content', {
    staticClass: "page__content"
  }, [_c('Table', {
    attrs: {
      "data": _vm.redirects.data,
      "loading": _vm.loading
    },
    on: {
      "update-data": function updateData($event) {
        return _vm.getRedirects();
      }
    }
  }), _c('a-row', {
    staticStyle: {
      "text-align": "right"
    }
  }, [_c('a-pagination', {
    staticStyle: {
      "margin-top": "20px"
    },
    attrs: {
      "total": _vm.redirects.meta.total,
      "pageSize": _vm.redirects.meta.per_page
    },
    on: {
      "change": _vm.paginationChange
    },
    model: {
      value: _vm.redirects.meta.current_page,
      callback: function callback($$v) {
        _vm.$set(_vm.redirects.meta, "current_page", $$v);
      },
      expression: "redirects.meta.current_page"
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }