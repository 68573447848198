import Users from './Users.vue';
import ChangeUser from './Change.vue';

export const usersRoute      = '/users/all-users/';
export const changeUserRoute = '/users/change-user/:id';
export const addUserRoute    = '/users/add';

export const users = [
  {
    name          : 'Users',
    path          : usersRoute,
    component     : Users,
    breadcrumbName: 'Пользователи',
    meta          : { title: 'Пользователи', },
  },
  {
    name          : 'Change',
    path          : changeUserRoute,
    component     : ChangeUser,
    breadcrumbName: 'Редактирование пользователя',
    meta          : { title: 'Редактирование пользователя', },
    props         : true,
  },
  {
    name          : 'Add',
    path          : addUserRoute,
    component     : ChangeUser,
    breadcrumbName: 'Добавление пользователя',
    props         : true,
    meta          : { title: 'Добавление пользователя', },
  },
];

export default { users };
