<template>
  <div>
    <AddPageTitle
      :title="title"
      :id="id || moderationId"
    />
    <ModerationTop v-if="moderationId" :appId="moderationId"/>
    <DeveloperModeration v-if="id" :appId="id"/>
    <a-layout-content class="page__content">
      <TopMenu :id="id" :moderationId="moderationId" active="images"/>
      <a-form :form="form">
        <a-form-item label="Загрузка логотипа">
          <a-upload
            listType="picture-card"
            :fileList="logoList"
            :multiple="false"
            @preview="handlePreview"
            :before-upload="beforeUploadLogo"
            :remove="handleRemove"
            v-decorator="[
              'logotype',
              {
                rules: [
                  {
                    required: true,
                    message: 'Загрузите логотип приложения'
                  }
                ]
              }
            ]"
          >
            <div v-if="logoList.length < 1">
              <a-icon type="plus"/>
              <div class="ant-upload-text">Загрузить логотип</div>
            </div>
          </a-upload>
        </a-form-item>

        <a-row :gutter="24" class="row-st">
          <a-card title="Локализация">
            <template
              v-if="availableLocales.length"
              v-slot:extra
            >
              <a-dropdown>
                <template v-slot:overlay>
                  <a-menu @click="addTranslation">
                    <a-menu-item
                      v-for="locale in availableLocales"
                      :key="locale"
                    >
                      {{ getLocaleTitle(locale) }}
                    </a-menu-item>
                  </a-menu>
                </template>
                <a-button> Добавить локаль
                  <a-icon type="down"/>
                </a-button>
              </a-dropdown>
            </template>

            <a-tabs
              v-model="activeTab"
              type="editable-card"
              hide-add
              @edit="onTabTranslationsEdit"
            >
              <template v-for="{locale, title} in LOCALE_LIST_EXTENDED">
                <a-tab-pane
                  v-if="groupedScreenshotsList.hasOwnProperty(locale)"
                  :key="locale"
                  :tab="title"
                  :closabel="true"
                >
                  <a-form-item
                    label="Загрузка скриншотов"
                  >
                    <a-upload
                      listType="picture-card"
                      :fileList="groupedScreenshotsList[locale]"
                      @preview="handlePreview"
                      :remove="handleRemove"
                      :multiple="true"
                      :before-upload="beforeUploadScreenshot"
                      v-decorator="[
                        `screenshots.${locale}`,
                        {
                          rules: [
                            {
                              required: true,
                              message: 'Загрузите скриншоты приложения'
                            }
                          ]
                        }
                      ]"
                    >
                      <div v-if="groupedScreenshotsList[locale].length < 5">
                        <a-icon type="plus"/>
                        <div class="ant-upload-text">Загрузить скриншоты</div>
                      </div>
                    </a-upload>
                  </a-form-item>
                </a-tab-pane>
              </template>
            </a-tabs>
          </a-card>
        </a-row>
      </a-form>

      <a-button
        size="large"
        type="primary"
        @click="handleSubmit"
        style="margin-top: 20px;"
      >
        Сохранить
      </a-button>

    </a-layout-content>

    <a-modal
      :visible="previewVisible"
      :footer="null"
      @cancel="handleCancel"
    >
      <img alt="example" style="width: 100%" :src="previewImage"/>
    </a-modal>
  </div>
</template>

<script>
import ModerationTop from '@/components/Moderation/ModerationTop.vue';
import { applications } from '@/api';
import TopMenu from './TopMenu.vue';
import { getFormData } from '@/helpers';
import { mapState } from 'vuex';
import DeveloperModeration from '@/components/Moderation/DeveloperModeration.vue';
import AddPageTitle from '@/components/Applications/Add/AddPageTitle.vue';
import { LOCALE_LIST_EXTENDED } from '@/global/constants';
import {isEmpty} from 'lodash';

const formatImageFromFile = (file, locale = 'ru') => ({
  uid   : '-1',
  name  : file.name,
  status: 'done',
  url   : URL.createObjectURL(file),
  locale
});

const groupBy = function (xs, key) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export default {
  components: {
    AddPageTitle,
    TopMenu,
    ModerationTop,
    DeveloperModeration,
  },

  props: ['id', 'moderationId'],

  data() {
    return {
      title                 : 'Добавить логотип и скриншоты приложения',
      form                  : this.$form.createForm(this),
      previewVisible        : false,
      previewImage          : '',
      logoList              : [],
      screenshotsList       : [],
      activeTab             : 'ru',
      LOCALE_LIST_EXTENDED,
      groupedScreenshotsList: {},
    };
  },

  computed: {
    ...mapState(['user']),

    availableLocales() {
      const existLocales = this.screenshotsList.map(image => image.locale);
      const allLocales   = LOCALE_LIST_EXTENDED.map((item) => item.locale);

      return existLocales
        .filter(locale => !allLocales.includes(locale))
        .concat(allLocales.filter(locale => !existLocales.includes(locale)));
    },

    existLocales() {
      const existLocales = this.screenshotsList.map(image => image.locale);
      const allLocales   = LOCALE_LIST_EXTENDED.map((item) => item.locale);

      return allLocales.filter(locale => existLocales.includes(locale));
    },
  },

  async mounted() {
    if (this.moderationId) {
      this.title = 'Модерация приложения';
    }
    await this.getApplicationImages();
  },
  methods: {
    async getApplicationImages() {
      await applications
        .getAppScreenshots(this.id || this.moderationId)
        .then((res) => res.json())
        .then(({ data: images }) => {
          const formatImage = (image) => ({
            uid   : image.id,
            name  : String(image.id),
            status: 'done',
            url   : image.thumbnail,
            locale: image.locale,
          });

          this.logoList = images
            .filter((image) => image.isLogo === 1)
            .map(formatImage);

          this.screenshotsList = images
            .filter((image) => image.isLogo === 0)
            .map(formatImage);

          this.groupedScreenshotsList = groupBy(this.screenshotsList, 'locale');

          if (isEmpty(this.groupedScreenshotsList)) {
            this.groupedScreenshotsList.ru = [];
          }
        });
    },

    handleRemove(image) {
      return new Promise((resolve, reject) => {
        this.$confirm({
          title   : 'Вы действительно хотите удалить данное изображение?',
          content : 'Вы не сможете восстановить его после удаления, только добавить еще раз.',
          onOk    : async () => {
            if ('-1' === image.uid) {
              const logoIndex = this.logoList.indexOf(image);
              if (-1 !== logoIndex) {
                this.logoList.splice(logoIndex, 1);
              }

              const screenshotIndex = this.screenshotsList.indexOf(image);
              if (-1 !== screenshotIndex) {
                this.screenshotsList.splice(screenshotIndex, 1);
                this.groupedScreenshotsList = groupBy(this.screenshotsList, 'locale');
              }
              resolve(true);
            } else {
              await applications.deleteApplicationImage(image.uid).then(() => {
                this.$message.success('Изображение успешно удалено :)', 2);
                this.getApplicationImages();
                resolve(true);
              });
            }
          },
          onCancel: () => {
            reject(true);
          },
        });
      });
    },

    handleCancel() {
      this.previewVisible = false;
    },

    handlePreview(file) {
      this.previewImage   = file.url || file.thumbUrl;
      this.previewVisible = true;
    },

    beforeUploadLogo(file) {
      this.logoList = [formatImageFromFile(file)];
      return false;
    },

    beforeUploadScreenshot(file) {
      this.screenshotsList.push(formatImageFromFile(file, this.activeTab));
      this.groupedScreenshotsList = groupBy(this.screenshotsList, 'locale');
      return false;
    },

    handleSubmit() {
      const values = this.form.getFieldsValue();

      const params = {
        logo      : values.logotype && values.logotype.file,
        screenshots: {},
      };

      if (values.screenshots) {
        for (const locale in values.screenshots) {
          if (values.screenshots[locale]) {
            params.screenshots[locale] = values.screenshots[locale].fileList.filter((file) => 0 === file.percent).map((file) => file.originFileObj);
          }
        }
      }

      applications
        .uploadAppScreenshotsAndLogotype(
          this.id || this.moderationId,
          getFormData(params, ''),
        )
        .then((res) => res.json())
        .then(this.handleErrors('Изображения (-e) успешно добавлены :)'))
        .catch(async (response) => {
          if (response.status !== 422) {
            return;
          }

          const json = await response.json();
          Object.values(json.errors).forEach((errors) => {
            errors.forEach((error) => {
              this.$message.error(error);
            });
          });
        });
    },

    handleErrors(message) {
      return () => {
        this.$message.success(message, 2);

        if (!this.id) {
          this.$router.push(`/seo/${this.id}/edit`);
        }

        if (this.moderationId) {
          this.$router.push(`/moderation/${this.moderationId}/seo`);
        }
      };
    },

    getLocaleTitle(locale) {
      return LOCALE_LIST_EXTENDED.find((item) => item.locale === locale).title;
    },

    onTabTranslationsEdit(event) {
      this.$delete(this.groupedScreenshotsList, event);
      if (this.activeTab === event) {
        this.activeTab = this.existLocales.length ? this.existLocales[0] : 'ru';
      }
    },

    addTranslation(event) {
      this.$set(this.groupedScreenshotsList, event.key, []);
      this.activeTab = event.key;
    },
  },
};
</script>

<style scoped lang="scss">
.ant-menu-root {
  height: 80px !important;
}

.ant-menu-item {
  padding: 0 100px;
  font-size: 16px;
}

.ant-upload-select-picture-card i {
  margin-top: 15px;
  font-size: 24px;
  color: #1890ff;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>
