var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('AddPageTitle', {
    attrs: {
      "title": _vm.title,
      "id": _vm.id || _vm.moderationId
    }
  }), _vm.moderationId ? _c('ModerationTop', {
    attrs: {
      "appId": _vm.moderationId
    }
  }) : _vm._e(), _vm.id ? _c('DeveloperModeration', {
    attrs: {
      "appId": _vm.id
    }
  }) : _vm._e(), _c('a-layout-content', {
    staticClass: "page__content"
  }, [_c('TopMenu', {
    attrs: {
      "id": _vm.id,
      "moderationId": _vm.moderationId,
      "active": "tag_lists"
    }
  }), _c('a-tabs', {
    attrs: {
      "type": "card"
    }
  }, [_c('a-tab-pane', {
    key: "1",
    attrs: {
      "tab": "Категории"
    }
  }, [_c('TagListingsList', {
    attrs: {
      "id": _vm.id || _vm.moderationId,
      "loading": _vm.loadingAppTagListings,
      "tagListings": _vm.appTagListings
    },
    on: {
      "removed": _vm.onRemoved
    }
  })], 1), _c('a-tab-pane', {
    key: "2",
    attrs: {
      "tab": "Добавить категорию"
    }
  }, [_c('TagListingAdd', {
    attrs: {
      "id": _vm.id || _vm.moderationId,
      "loading": _vm.loadingAllTagListings,
      "tagListings": _vm.allTagListings,
      "exclude": _vm.appTagListings
    },
    on: {
      "added": _vm.onAdded
    }
  })], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }