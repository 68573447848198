var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('PageTitle', {
    attrs: {
      "title": _vm.title
    }
  }), _c('a-layout-content', {
    staticClass: "page__content"
  }, [_c('a-row', {
    attrs: {
      "gutter": 24
    }
  }, [_c('a-col', {
    attrs: {
      "span": "12"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "Название (ru)*"
    }
  }, [_c('a-input', {
    attrs: {
      "size": "large"
    },
    model: {
      value: _vm.baseLicense.label,
      callback: function callback($$v) {
        _vm.$set(_vm.baseLicense, "label", $$v);
      },
      expression: "baseLicense.label"
    }
  })], 1)], 1), _c('a-col', {
    attrs: {
      "span": "12"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "Название (en)"
    }
  }, [_c('a-input', {
    attrs: {
      "size": "large"
    },
    model: {
      value: _vm.baseLicense.enLabel,
      callback: function callback($$v) {
        _vm.$set(_vm.baseLicense, "enLabel", $$v);
      },
      expression: "baseLicense.enLabel"
    }
  })], 1)], 1), _c('a-col', {
    attrs: {
      "span": "12"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "Уникальное название"
    }
  }, [_c('a-input', {
    attrs: {
      "size": "large"
    },
    model: {
      value: _vm.baseLicense.name,
      callback: function callback($$v) {
        _vm.$set(_vm.baseLicense, "name", $$v);
      },
      expression: "baseLicense.name"
    }
  })], 1)], 1), _c('a-col', {
    attrs: {
      "span": "24"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "Описание (ru)"
    }
  }, [_c('a-textarea', {
    staticStyle: {
      "height": "120px"
    },
    attrs: {
      "size": "large"
    },
    model: {
      value: _vm.baseLicense.description,
      callback: function callback($$v) {
        _vm.$set(_vm.baseLicense, "description", $$v);
      },
      expression: "baseLicense.description"
    }
  })], 1)], 1), _c('a-col', {
    attrs: {
      "span": "24"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "Описание (en)"
    }
  }, [_c('a-textarea', {
    staticStyle: {
      "height": "120px"
    },
    attrs: {
      "size": "large"
    },
    model: {
      value: _vm.baseLicense.enDescription,
      callback: function callback($$v) {
        _vm.$set(_vm.baseLicense, "enDescription", $$v);
      },
      expression: "baseLicense.enDescription"
    }
  })], 1)], 1)], 1), _c('a-button', {
    attrs: {
      "size": "large",
      "type": "primary"
    },
    on: {
      "click": _vm.saveLicenseType
    }
  }, [_vm._v(_vm._s(_vm.action))])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }