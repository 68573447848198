var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('PageTitle', {
    attrs: {
      "title": _vm.title
    }
  }), _c('a-layout-content', {
    style: {
      margin: '24px 16px',
      padding: '24px',
      background: '#fff',
      minHeight: '280px'
    }
  }, [_c('a-form', [_c('a-row', {
    attrs: {
      "gutter": 24
    }
  }, [_c('a-col', {
    attrs: {
      "span": "8"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "Расположение"
    }
  }, [_c('a-select', {
    attrs: {
      "size": "large",
      "defaultValue": "0"
    },
    model: {
      value: _vm.code.position,
      callback: function callback($$v) {
        _vm.$set(_vm.code, "position", $$v);
      },
      expression: "code.position"
    }
  }, [_c('a-select-option', {
    attrs: {
      "value": 1
    }
  }, [_vm._v("Перед /body")]), _c('a-select-option', {
    attrs: {
      "value": 0
    }
  }, [_vm._v("В тэг head")])], 1)], 1)], 1), _c('a-col', {
    attrs: {
      "span": "8"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "Название"
    }
  }, [_c('a-input', {
    attrs: {
      "size": "large",
      "placeholder": "I'm the content"
    },
    model: {
      value: _vm.code.name,
      callback: function callback($$v) {
        _vm.$set(_vm.code, "name", $$v);
      },
      expression: "code.name"
    }
  })], 1)], 1), _c('a-col', {
    attrs: {
      "span": "8"
    }
  }, [_c('a-form-item', {
    attrs: {
      "label": "Язык"
    }
  }, [_c('a-select', {
    attrs: {
      "size": "large"
    },
    model: {
      value: _vm.code.locale,
      callback: function callback($$v) {
        _vm.$set(_vm.code, "locale", $$v);
      },
      expression: "code.locale"
    }
  }, _vm._l(_vm.LOCALE_LIST, function (_ref) {
    var title = _ref.title,
      locale = _ref.locale;
    return _c('a-select-option', {
      key: locale,
      attrs: {
        "value": locale
      }
    }, [_vm._v(" " + _vm._s(title) + " ")]);
  }), 1)], 1)], 1)], 1), _c('a-row', {
    attrs: {
      "gutter": 24
    }
  }, [_c('a-col', {
    attrs: {
      "span": 24
    }
  }, [_c('a-form-item', {
    attrs: {
      "size": "large",
      "label": "Код"
    }
  }, [_c('a-textarea', {
    attrs: {
      "rows": "20",
      "placeholder": "I'm the content"
    },
    model: {
      value: _vm.code.content,
      callback: function callback($$v) {
        _vm.$set(_vm.code, "content", $$v);
      },
      expression: "code.content"
    }
  })], 1)], 1)], 1), _c('a-button', {
    attrs: {
      "type": "primary",
      "size": "large",
      "disabled": _vm.buttonDisable
    },
    on: {
      "click": _vm.saveCode
    }
  }, [_vm._v(_vm._s(_vm.button))])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }