import { User } from '../types';
import {merge} from 'lodash';

/**
 * @description Формирование Form Data
 * @param {object} obj
 * @param {string} rootName
 * @param {Array} ignoreList
 * @link https://developer.mozilla.org/ru/docs/Web/API/FormData/Using_FormData_Objects
 */

export function getFormData(
  obj,
  rootName,
  ignoreList= [],
) {
  const formData = new FormData();

  /**
   * @description Функция для добавление свойства объекта в FormData
   * @param {object} data
   * @param {string} root
   * @return {void}
   */
  function appendFormData(data, root) {
    // Если не содержится в ignore list
    if (!ignore(root)) {
      root = root || '';
      if (data instanceof File) {
        formData.append(root, data);
      } else if (Array.isArray(data)) {
        for (let i = 0; i < data.length; i++) {
          appendFormData(data[i], root + '[' + i + ']');
        }
      } else if (typeof data === 'object' && data) {
        for (const key in data) {
          if (data.hasOwnProperty(key)) {
            if (root === '') {
              appendFormData(data[key], key);
            } else {
              appendFormData(data[key], `${root}[${key}]`);
            }
          }
        }
      } else {
        if (data !== null && typeof data !== 'undefined') {
          formData.append(root, data);
        }
      }
    }
  }

  const ignore = (root) => Array.isArray(ignoreList) && ignoreList.some((x) => x === root);

  appendFormData(obj, rootName);

  return formData;
}

// Склонение слова по числу: plural(10, ['оценка', 'оценки', 'оценок'])
export const plural = (n, textForms) => {
  n = Math.abs(n) % 100;
  const n1 = n % 10;
  if (n > 10 && n < 20) {
    return textForms[2];
  }
  if (n1 > 1 && n1 < 5) {
    return textForms[1];
  }
  if (n1 === 1) {
    return textForms[0];
  }
  return textForms[2];
};

export const hasAdmin = (user) =>
  user.role === 'admin' || user.role === 'super_admin';

export const hasEditor = (user) => user.role === 'editor';

export const hasDeveloper = (user) =>
  user.role === 'verified_developer' ||
  user.role === 'developer' ||
  user.role === 'verified_company' ||
  user.role === 'company';

export const hasSeo = (user) => user.role === 'seo';

export default { plural, hasAdmin, hasEditor, hasDeveloper, hasSeo };

// @ts-ignore
export function buildTranslationsDataForModel(translations) {
  const data = {};

  for (const locale of Object.keys(translations)) {
    for (const key in translations[locale]) {
      if (translations[locale][key]) {
        merge(data, { [locale]: { [key]: translations[locale][key] } });
      }
    }
  }

  return data;
}
