
// @ts-nocheck
// @ts-nocheck
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ExternalCode } from '@/types/externalCode';
import { settings } from '@/api';

@Component({ name: 'List' })
export default class List extends Vue {
  @Prop() public data!: ExternalCode[];
  @Prop() public loading: boolean;
  columns = [
    {
      title: 'Откуда',
      dataIndex: 'source',
      width: '40%',
    },
    {
      title: 'Куда',
      dataIndex: 'destination',
      width: '40%',
    },
    {
      title: 'Действия',
      dataIndex: 'operation',
      scopedSlots: { customRender: 'operation' },
    },
  ];

  editRedirect(id: number) {
    this.$router.push(`/settings/redirect/${id}`);
  }

  /**
   *
   * @param {number} id
   * @return {void}
   */
  removeRedirect(id: number): void {
    const self = this;
    this.$confirm({
      title: 'Вы действительно хотите удалить данный редирект?',
      content:
        'Вы не сможете восстановить данный редирект после удаления, только добавить еще раз.',
      onOk() {
        self.removeRedirectFetch(id);
      },
    });
  }

  removeRedirectFetch(id: number) {
    settings.deleteRedirect(id).then((res) => {
      if (res.ok) {
        this.$emit('update-data');
      }
    });
  }
}
