<template>
  <div>
    <a-layout-content class="page__content">
      <a-row :gutter="24">
        <a-col span="8">
          <a-form>
            <a-form-item
              label="Старый пароль"
              size="large"
              :validate-status="errors.oldPassword ? 'error' : ''"
              :help="errors.oldPassword ? errors.oldPassword[0] : ''"
            >
              <a-row>
                <a-input v-model="oldPassword" size="large" type="password"/>
              </a-row>
            </a-form-item>
          </a-form>
        </a-col>
      </a-row>

      <a-row :gutter="24">
        <a-col span="8">
          <a-form>
            <a-form-item
              label="Пароль"
              size="large"
              :validate-status="errors.password ? 'error' : ''"
              :help="errors.password ? errors.password[0] : ''"
            >
              <a-row>
                <a-input v-model="password" size="large" type="password"/>
              </a-row>
            </a-form-item>
          </a-form>
        </a-col>
      </a-row>

      <a-row :gutter="24">
        <a-col span="8">
          <a-form>
            <a-form-item
              label="Подтвердить Пароль"
              size="large"
              :validate-status="errors.password_confirmation ? 'error' : ''"
              :help="errors.password_confirmation?errors.password_confirmation[0]:''"
            >
              <a-row>
                <a-input v-model="passwordConfirmation" size="large" type="password"/>
              </a-row>
            </a-form-item>
          </a-form>
        </a-col>
      </a-row>

      <a-row :gutter="24">
        <a-col span="12">
          <a-button type="primary" size="large" @click="save">Сохранить пароль</a-button>
        </a-col>
      </a-row>
    </a-layout-content>
  </div>
</template>

<script>

import { updateProfilePassword } from '@/api/profile';

export default {
  name: 'ProfileChangePassword',
  data() {
    return {
      password            : '',
      passwordConfirmation: '',
      oldPassword         : '',

      errors: {},
    };
  },
  methods: {
    async save() {
      this.errors = {};

      if (this.password !== this.passwordConfirmation) {
        this.errors.password_confirmation = ['Пароль не совпадает'];
        return;
      }

      const response = await updateProfilePassword({
        oldPassword          : this.oldPassword,
        password             : this.password,
        password_confirmation: this.passwordConfirmation,
      });

      const json = await response.json();

      if (json.errors) {
        this.errors = json.errors;
        return;
      }

      this.password             = '';
      this.passwordConfirmation = '';
      this.oldPassword          = '';
    },
  },

};
</script>

<style scoped>

</style>
