import queryString from 'query-string';
import {deleteMethod, get, post, put} from './core';
import {FSInstall} from '@/types/FSInstall';
import {ExternalCode} from '@/types/externalCode';
import {Redirect, SearchRedirectsParams} from '@/types/Redirect';

/**
 * @description ... TODO
 * @return {Promise<any>}
 */
const getFSInstall = (): Promise<any> => get('/api/admin/settings/fs-install');

/**
 * @description ... TODO
 * @param {FSInstall} params
 * @return {Promise<any>}
 */
const setFSInstall = (params: FSInstall): Promise<any> => put('/api/admin/settings/fs-install', params);

/**
 * @description Функция для получения стороннего кода по id
 * @param {number} id
 * @return {Promise<any>}
 */
const getExternalCodeById = (id: number): Promise<any> => get(`/api/admin/settings/external-code/${id}`);

/**
 * @description Функция для получения листинга стороннего окда
 * @return {Promise<any>}
 */
const getExternalCode = (): Promise<any> => get('/api/admin/settings/external-code');

/**
 * @description Удаление стороннего кода
 * @param {number} id
 * @return {Promise<any>}
 */
const deleteExternalCode = (id: number): Promise<any> => {
  return deleteMethod(`/api/admin/settings/external-code/${id}`);
};

/**
 * @description Сохранение стороннего кода
 * @param {number} id
 * @param {ExternalCode} params
 * @return {Promise<any>}
 */
const setExternalCode = (id: number, params: ExternalCode): Promise<any> => {
  if (id) {
    return put(`/api/admin/settings/external-code/${id}`, params);
  }
  return post(`/api/admin/settings/external-code`, params);
};

/**
 * @description Получить файл для редактирования согласно названию
 * @param {string} fileName
 * @return {Promise<any>}
 */
const getFileForFileEditor = (fileName: string): Promise<any> => {
  return get(`/api/admin/settings/edit-file?file_name=${fileName}`);
};

/**
 * @description Сохранить изменения в файле
 * @param {object} params
 * @return {Promise<any>}
 */
const saveCodeForFileEditor = (params: any): Promise<any> => {
  return put('/api/admin/settings/edit-file', params);
};

/**
 * @description Получить редиректы согласно странице
 * @param {number} page
 * @return {Promise<any>}
 */
const getAllRedirects = (page: number): Promise<any> => {
  return get(`/api/admin/settings/redirect?page=${page}`);
};

/**
 * @description Функция для получения редиректа по id
 * @param {number }id
 * @return {Promise<any>}
 */
const getRedirect = (id: number): Promise<any> => {
  return get(`/api/admin/settings/redirect/${id}`);
};

/**
 * @description Функция для удаления редиректа
 * @param {number }id
 * @return {Promise<any>}
 */
const deleteRedirect = (id: number): Promise<any> => {
  return deleteMethod(`/api/admin/settings/redirect/${id}`);
};

/**
 * @param {SearchRedirectsParams} params
 * @return {Promise<any>}
 */
const searchRedirect = (params: SearchRedirectsParams): Promise<any> => {
  const query = queryString.stringify(params);
  return get(`/api/admin/settings/redirect/search?${query}`);
};

/**
 * @description Сохранение редиректа
 * @param {number} id
 * @param {Redirect} params
 * @return <Promise<any>
 */
const saveRedirect = (id: number | null, params: Redirect): Promise<any> => {
  if (id) {
    return put(`/api/admin/settings/redirect/${id}`, params);
  }

  return post(`/api/admin/settings/redirect`, params);
};

export const settings = {
  getFSInstall,
  setFSInstall,
  setExternalCode,
  getExternalCode,
  getExternalCodeById,
  deleteExternalCode,
  getFileForFileEditor,
  saveCodeForFileEditor,
  getRedirect,
  saveRedirect,
  getAllRedirects,
  searchRedirect,
  deleteRedirect,
};

export default settings;
