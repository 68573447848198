var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a-affix', {
    attrs: {
      "offsetTop": 0
    }
  }, [_c('div', {
    staticClass: "page__title"
  }, [_c('a-row', {
    attrs: {
      "gutter": 24
    }
  }, [_c('Breadcrumbs')], 1), _c('a-row', {
    attrs: {
      "gutter": 24
    }
  }, [_c('a-col', {
    attrs: {
      "span": 12
    }
  }, [_c('h3', {
    staticClass: "back-arrow",
    on: {
      "click": _vm.handleBack
    }
  }, [_c('a-icon', {
    attrs: {
      "type": "arrow-left"
    }
  }), _c('span', {
    attrs: {
      "id": "page-title"
    }
  }, [_vm._v(_vm._s(_vm.title))])], 1)]), _c('a-col', {
    staticStyle: {
      "text-align": "right"
    },
    attrs: {
      "span": 12
    }
  }, [_vm._t("default")], 2)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }