<template>
  <div>
    <PageTitle title="Логи обновлений"/>
    <a-layout-content class="page__content">
      <h3>Фильтр</h3>
      <a-row :gutter="24">

        <a-col span="6">
          <a-form-item label="Статус">
            <a-select
              size="large"
              placeholder="Статус"
              v-model="searchParams.status"
            >
              <a-select-option :value="undefined">Любой</a-select-option>

              <a-select-option
                v-for="status in statuses"
                :value="status.value"
                :key="status.value"
              >{{ status.name }}
              </a-select-option
              >
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col span="12">
          <a-button size="large" @click="resetFields">Сбросить</a-button>
        </a-col>
        <a-col span="12" style="text-align: right">
          <a-button
            size="large"
            type="primary"
            style="margin-left: 20px"
            icon="search"
            @click="handleSearch"
          >Фильтровать
          </a-button>
        </a-col>
      </a-row>
    </a-layout-content>

    <a-layout-content class="page__content">
      <a-table
        :columns="columns"
        :pagination="false"
        :dataSource="logs.data"
        :loading="loading"
        rowKey="id"
        :rowClassName="rowClassName"
      >
        <template slot="name" slot-scope="application">
          <router-link :to="{ path: `/edit-description/${application.id}/edit` }">
            {{ application.name }}
          </router-link>
        </template>
      </a-table>

      <a-row style="text-align: right;">
        <a-pagination
          v-if="logs.meta"
          @change="paginationChange"
          v-model="logs.meta.current_page"
          :total="logs.meta.total"
          :pageSize="logs.meta.per_page"
          style="margin-top: 20px"
        />
      </a-row>
    </a-layout-content>
  </div>
</template>

<script>
import { getApplicationUpdateLogs } from '@/api/applicationUpdateLogs';
import PageTitle from '@/components/Global/PageTitle';
import {
  APPLICATION_UPDATE_LOG_STATUS_ERROR,
  APPLICATION_UPDATE_LOG_STATUS_NO_UPDATES,
  APPLICATION_UPDATE_LOG_STATUS_UPDATED,
} from '@/global/constants';

export default {
  name      : 'UpdateLogs',
  components: { PageTitle },
  data() {
    return {
      columns     : [
        {
          title    : 'Название программы',
          dataIndex: 'application',
          key      : 'application',
          sorter   : false,
          scopedSlots: {customRender: 'name'},
        },
        {
          title    : 'Статус',
          dataIndex: 'status',
          key      : 'status',
          sorter   : false,
        },
        {
          title    : 'Сообщение',
          dataIndex: 'message',
          key      : 'message',
          sorter   : false,
        },
        {
          title    : 'Источник',
          dataIndex: 'application.update_source',
          key      : 'application.update_source',
          sorter   : false,
        },
        {
          title    : 'Дата',
          dataIndex: 'created_at',
          key      : 'created_at',
          sorter   : false,
        },
      ],
      loading     : true,
      logs        : {
        data: [],
        meta: null,
      },
      searchParams: {
        page  : 1,
        status: undefined,
      },
      statuses    : [
        {
          name : 'Обновлено',
          value: APPLICATION_UPDATE_LOG_STATUS_UPDATED,
        },
        {
          name : 'Нет обновлений',
          value: APPLICATION_UPDATE_LOG_STATUS_NO_UPDATES,
        },
        {
          name : 'Ошибка',
          value: APPLICATION_UPDATE_LOG_STATUS_ERROR,
        },
      ],
    };
  },
  created() {
    this.getLogs();
  },
  methods: {
    async getLogs(params) {
      this.loading = true;
      const res    = await getApplicationUpdateLogs(params);
      const data   = await res.json();
      this.logs    = data;
      this.loading = false;
    },

    paginationChange(page) {
      this.searchParams = { ...this.searchParams, page };
      this.getLogs({ ...this.searchParams });
      window.scrollTo(0, 0);
    },

    rowClassName(record, index) {
      switch (record.status) {
        case APPLICATION_UPDATE_LOG_STATUS_NO_UPDATES:
          return 'table-row-yellow';
        case APPLICATION_UPDATE_LOG_STATUS_ERROR:
          return 'table-row-red';
        case APPLICATION_UPDATE_LOG_STATUS_UPDATED:
          return 'table-row-green';
      }
    },

    handleSearch() {
      this.getLogs({ ...this.searchParams, page: 1 });
    },

    resetFields() {
      this.searchParams = {
        page  : 1,
        status: undefined,
      };

      this.getLogs({ ...this.searchParams });
    },
  },
};
</script>

<style>

</style>
