
// @ts-nocheck
import Vue from 'vue';
import {plural} from '@/helpers';
import {Moderation} from '@/api/moderation';
import {applications} from '@/api';
import {mapState} from 'vuex';
import {MODERATION_STATUS_ACCEPTED, MODERATION_STATUS_REJECTED} from '@/global/constants';

export default Vue.extend({
  name: 'ModeraionTop',
  props: ['appId'],
  computed: {
    ...mapState(['user']),
    canBePublished(): boolean {
      return this.app.moderate_status !== MODERATION_STATUS_ACCEPTED;
    },
    canBeRejected(): boolean {
      return this.app.moderate_status !== MODERATION_STATUS_REJECTED;
    },
  },
  data() {
    return {
      message: '',
      app: {
        moderate_status: '',
      },
    };
  },
  async created() {
    this.getModerationHistoryByAppId();
    this.getApp();
  },
  methods: {
    async getApp() {
      const response = await applications.getAppById(this.appId);
      const json = await response.json();
      this.app = json.data;
    },

    /**
     * @description Публикация приложения
     * @return {Promise<any>}
     */
    async handlePublishApp(): Promise<any> {
      await Moderation.updateModerationStatus(this.appId, {
        status: MODERATION_STATUS_ACCEPTED,
      }).then((res) => {
        if (res.status === 200) {
          this.$message.success('Приложение успешно опубликовано :)', 2);
          setTimeout(async () => {
            await applications
              .getAppById(this.appId)
              .then((response) => response.json())
              .then(({data: {linkShow}}) => {
                window.open(linkShow);
              });
          }, 2);
        }
      });
    },
    /**
     * @description Получение истории модерации для приложению
     * @return {Promise<any>}
     */
    async getModerationHistoryByAppId(): Promise<any> {
      await Moderation.getModerationHistoryByAppId(this.appId)
        .then((res) => res.json())
        .then(({data}) => {
          const messageCount = data.length;
          if (messageCount !== 0) {
            const ruWord = plural(messageCount, [
              'сообщение',
              'сообщения',
              'сообщений',
            ]);
            this.message = `Повторная модерация (${messageCount} ${ruWord})`;
          }
        });
    },
  },
});
