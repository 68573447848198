
// @ts-nocheck
import Vue from 'vue';
import {children} from '@/router';
import {Route} from '@/types/route';

export default Vue.extend({
  name: 'Breadcrumbs',
  data() {
    return {
      breadcrumbs: [],
    };
  },
  mounted() {
    const pathname = window.location.pathname + '/';
    children.forEach((child: Route) => {
      const path = child.path + '/';
      if (pathname.includes(path)) {
        // @ts-ignore
        this.breadcrumbs.push(child);
      }
    });
  },
});
