<template>
  <div>
    <PageTitle :title="title"/>
    <a-form :form="form">
      <a-layout-content class="page__content">
        <a-row :gutter="24">
          <a-col span="12">
            <a-button size="large" type="primary" @click="handleSubmit">
              {{ action }}
            </a-button>
          </a-col>
          <a-col span="12" style="text-align: right; margin-top: 10px">
            <a-form-item label="Опубликовать">
              <a-switch
                size="large"
                :checked="form.getFieldValue('published_at')"
                @change="onPublishChanged"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </a-layout-content>

      <a-layout-content class="page__content">
        <a-row :gutter="24" class="row-st">
          <a-col span="24">
            <a-form-item
              label="Слаг (Пример: введенная строка 'slug', создаст ссылку 'freesoft.ru/blog/slug')"
            >
              <a-input
                size="large"
                placeholder="Slug"
                v-decorator="[
                    'slug',
                    {
                      initialValue: baseNews.slug,
                      rules: [
                        {
                          required: true,
                          message: 'Slug обязательное поле'
                        }
                      ]
                    }
                  ]"
              />
            </a-form-item>
          </a-col>

          <a-col span="12">
            <a-form-item label="Добавьте категории">
              <a-select
                size="large"
                placeholder="Категория"
                mode="multiple"
                maxTagCount="2"
                v-decorator="[
                  'categories',
                  {
                    initialValue: baseNews.categories,
                    rules: [
                      {
                        required: true,
                        message: 'Категории обязательное поле'
                      }
                    ]
                  }
                ]"
              >
                <a-select-option
                  v-for="category in articleCategories"
                  :key="category.id"
                  :value="category.id"
                >
                  {{ category.title }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter="24" class="row-st">
          <a-col span="12">
            <a-form-item label="Загрузка превью">
              <a-upload
                listType="picture-card"
                :fileList="imageList"
                @preview="handlePreview"
                :remove="handlePreviewRemove"
                :before-upload="beforeUploadPreview"
                v-decorator="[
                  'image',
                  {
                    initialValue: imageList,
                    rules: [
                      {
                        required: true,
                        message: 'Загрузите превью'
                      }
                    ]
                  }
                ]"
              >
                <div v-if="imageList && imageList.length < 1" style="padding-top: 15px">
                  <a-icon type="plus"/>
                  <div class="ant-upload-text">Загрузить изображение</div>
                </div>
              </a-upload>
              <a-modal
                :visible="previewVisible"
                :footer="null"
                @cancel="handleCancel"
              >
                <img alt="example" style="width: 100%" :src="previewImage"/>
              </a-modal>
            </a-form-item>
          </a-col>

          <a-col span="24">
            <a-form-item
              label="Дата публикации"
            >
              <a-date-picker
                format="YYYY-MM-DD HH:mm"
                valueFormat="YYYY-MM-DD HH:mm"
                :show-time="true"
                allowClear
                placeholder="Не опубликовано"
                v-decorator="[`published_at`, { initialValue: baseNews.published_at??undefined }]"
              />
            </a-form-item>
          </a-col>

          <a-col span="24">
            <a-form-item label="Настройки">
              <a-checkbox
                v-decorator="[
                      'disable_ads',
                      {
                        valuePropName: 'checked',
                        initialValue: baseNews.disable_ads
                      }
                    ]"
              >
                Отключить рекламу
              </a-checkbox>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter="24" class="row-st">
          <a-card title="Локализация">
            <template
              v-if="availableLocales.length"
              v-slot:extra
            >
              <a-dropdown>
                <template v-slot:overlay>
                  <a-menu @click="addTranslation">
                    <a-menu-item
                      v-for="locale in availableLocales"
                      :key="locale"
                    >
                      {{ getLocaleTitle(locale) }}
                    </a-menu-item>
                  </a-menu>
                </template>
                <a-button> Добавить локаль
                  <a-icon type="down"/>
                </a-button>
              </a-dropdown>
            </template>

            <a-tabs
              v-model="activeTab"
              type="editable-card"
              hide-add
              @edit="onTabTranslationsEdit"
            >
              <template v-for="{locale, title} in LOCALE_LIST_EXTENDED">
                <a-tab-pane
                  v-if="baseNews.translations.hasOwnProperty(locale)"
                  :key="locale"
                  :tab="title"
                  :closabel="true"
                >
                  <a-form-item :label="`Заголовок (${locale})`">
                    <a-input
                      size="large"
                      placeholder="Заголовок"
                      v-decorator="[
                        `translations.${locale}.title`,
                        {
                          initialValue: baseNews.translations[locale] ? baseNews.translations[locale].title : undefined,
                          rules: [
                            {
                              required: true,
                              message: 'Заголовок обязательное поле'
                            }
                          ]
                        }
                      ]"
                    />
                  </a-form-item>

                  <a-col span="24">
                    <a-form-item :label="`Анонс (${locale})`">
                      <a-textarea
                        style="height: 200px"
                        placeholder="Анонс"
                        v-decorator="[
                          `translations.${locale}.anons`,
                          {
                            initialValue: baseNews.translations[locale] ? baseNews.translations[locale].anons : undefined,
                            rules: [
                              {
                                required: true,
                                message: 'Анонс обязательное поле'
                              }
                            ]
                          }
                        ]"
                      />
                    </a-form-item>
                  </a-col>

                  <a-col span="24">
                    <a-form-item
                      :label="`Текст (${locale})`"
                      class="ant-form-item-required"
                    >
                      <ckeditor
                        v-decorator="[`translations.${locale}.text`, {initialValue: baseNews.translations[locale] ? baseNews.translations[locale].text : undefined}]"
                        :config="editorConfig()"
                        @input="form.setFieldsValue({[`translations.${locale}.text`] : $event})"
                      />
                    </a-form-item>
                  </a-col>

                  <a-col span="24">
                    <a-form-item :label="`SeoTitle (${locale})`">
                      <a-input
                        size="large"
                        placeholder="Введите title"
                        v-decorator="[
                          `translations.${locale}.seo_title`,
                          {
                            initialValue: baseNews.translations[locale] ? baseNews.translations[locale].seo_title : undefined,
                            rules: [
                              {
                                required: false
                              }
                            ]
                          }
                        ]"
                      />
                    </a-form-item>
                  </a-col>

                  <a-col span="24">
                    <a-form-item :label="`SeoDescription (${locale})`">
                      <a-textarea
                        style="height: 145px;"
                        size="large"
                        placeholder="Введите description"
                        v-decorator="[
                          `translations.${locale}.seo_description`,
                          {
                            initialValue: baseNews.translations[locale] ? baseNews.translations[locale].seo_description : undefined,
                            rules: [
                              {
                                required: false
                              }
                            ]
                          }
                        ]"
                      />
                    </a-form-item>
                  </a-col>

                  <a-col span="24">
                    <a-form-item :label="`SeoKeywords (${locale})`">
                      <a-input
                        size="large"
                        placeholder="Введите keywords"
                        v-decorator="[
                          `translations.${locale}.seo_keywords`,
                          {
                            initialValue: baseNews.translations[locale] ? baseNews.translations[locale].seo_keywords : undefined,
                            rules: [
                              {
                                required: false
                              }
                            ]
                          }
                        ]"
                      />
                    </a-form-item>
                  </a-col>
                </a-tab-pane>
              </template>
            </a-tabs>
          </a-card>
        </a-row>

        <a-row :gutter="24" class="row-st">
          <a-col span="12">
            <a-button size="large" type="primary" @click="handleSubmit">
              {{ action }}
            </a-button>
          </a-col>
        </a-row>
      </a-layout-content>
    </a-form>
  </div>
</template>

<script>
import PageTitle from '../Global/PageTitle';
import {getFormData} from '@/helpers';
import moment from 'moment';
import {articles} from '../../api/articles';
import {isEmpty} from 'lodash';
import {getCkeditorConfig} from '../../helpers/ckeditor';
import {LOCALE_LIST_EXTENDED} from '../../global/constants';

const formatImageFromFile = (file) => ({
  uid: '-1',
  name: file.name,
  status: 'done',
  url: URL.createObjectURL(file),
});

export default {
  name: 'ArticlesChange',
  components: {
    PageTitle,
  },
  props: ['id'],
  data() {
    return {
      form: this.$form.createForm(this),
      editorConfig: getCkeditorConfig,
      imageList: [],
      previewImage: '',
      previewVisible: false,
      articleCategories: [],
      baseNews: {
        id: 0,
        slug: '',
        disable_ads: false,
        translations: {},
        categories: [],
        published_at: null,
      },

      activeTab: '',
      LOCALE_LIST_EXTENDED,
    };
  },
  computed: {
    availableLocales() {
      const existLocales = Object.keys(this.baseNews.translations);
      const allLocales = LOCALE_LIST_EXTENDED.map((item) => item.locale);

      return existLocales
        .filter(locale => !allLocales.includes(locale))
        .concat(allLocales.filter(locale => !existLocales.includes(locale)));
    },

    action() {
      return this.id ? 'Сохранить' : 'Создать';
    },

    title() {
      return this.id ? 'Редактировать статью' : 'Создать статью';
    },
  },
  async created() {
    await this.getArticleCategories();

    if (this.id) {
      await this.getNewsById();
    }
  },
  methods: {
    async getArticleCategories() {
      await articles
        .getArticleCategory()
        .then((res) => res.json())
        .then(({data}) => {
          this.articleCategories = data;
        });
    },

    handleErrors(message) {
      return ({errors, code}) => {
        if (errors && code === 422) {
          Object.values(errors).forEach((values) => {
            values.forEach((value) => {
              this.$message.error(value);
            });
          });
        } else {
          this.$message.success(message, 2);
        }
      };
    },

    async handleSubmit() {
      this.form.validateFieldsAndScroll(async (errors, values) => {
        if (isEmpty(errors)) {
          const params = {...values};

          if (values.image && values.image.file) {
            params.preview = values.image.file;
          }

          delete params.image;

          params.published_at = params.published_at ?? '';

          if (this.id) {
            params['_method'] = 'PUT';
            await articles
              .updateArticle(this.id, getFormData(params, ''))
              .then((res) => res.json())
              .then(this.handleErrors('Статья успешно обновлена :)'));
          } else {
            try {
              const response = await articles.createArticle(getFormData(params, ''));
              const json = await response.json();
              this.handleErrors('Статья успешно создана :)');
              this.$router.push({name: 'EditArticle', params: {id: json.data.id}});
            } catch (error) {
              if (error.status === 422) {
                const json = await error.json();
                this.$message.error(json.message, 5);
              } else {
                console.log(error)
              }
            }
          }
        }
      });
    },

    handlePreviewRemove() {
      this.imageList = [];
    },

    handleCancel() {
      this.previewVisible = false;
    },

    handleChange({fileList}) {
      this.imageList = fileList;
    },

    handlePreview(file) {
      this.previewImage = file.url || file.thumbUrl;
      this.previewVisible = true;
    },

    async getNewsById() {
      await articles
        .getArticleById(this.id)
        .then((res) => res.json())
        .then(({data}) => {
          const {
            id,
            slug,
            translations,
            published_at,
            categories: {data: categoriesList},
            previewUrl,
            disable_ads,
          } = data;

          this.baseNews.id = id;
          this.baseNews.slug = slug;
          this.baseNews.translations = translations;
          this.baseNews.published_at = published_at;
          this.baseNews.disable_ads = disable_ads;
          this.baseNews.categories = categoriesList.map(category => category.id);

          this.imageList = [
            {
              uid: '1111',
              name: '22',
              status: 'done',
              url: previewUrl,
            },
          ];

          const locales = Object.keys(this.baseNews.translations);
          this.activeTab = locales.length ? locales[0] : 'ru';
        });
    },

    getLocaleTitle(locale) {
      return LOCALE_LIST_EXTENDED.find((item) => item.locale === locale).title;
    },

    addTranslation(event) {
      this.$set(this.baseNews.translations, event.key, {name: ''});
      this.activeTab = event.key;
    },

    onTabTranslationsEdit(event) {
      this.$delete(this.baseNews.translations, event);
      this.form.setFieldsValue({[`translations.${event}`]: undefined});
      if (this.activeTab === event) {
        this.activeTab = this.existLocales.length ? this.existLocales[0] : '';
      }
    },

    beforeUploadPreview(file) {
      this.imageList.push(formatImageFromFile(file));
      return false;
    },

    onPublishChanged(checked) {
      this.form.setFieldsValue({published_at: checked ? moment().format('YYYY-MM-DD HH:mm') : null});
    },
  },
};
</script>

<style scoped lang="scss">

.page__content {
  position: relative;
}
</style>
