
// @ts-nocheck
import Vue from 'vue';
import PageTitle from '@/components/Global/PageTitle.vue';
import TopMenu from './TopMenu.vue';
import {isEmpty} from 'lodash';
import {MiniSite} from '@/api/Applications/miniSite';
import AddPageTitle from '@/components/Applications/Add/AddPageTitle.vue';
import {getCkeditorConfig} from '@/helpers/ckeditor';

const initialSite = {
  enable: false,
};

export default Vue.extend({
  components: {
    AddPageTitle,
    PageTitle,
    TopMenu,
  },
  props: ['id'],
  mounted() {
    this.getMiniSite();
  },
  data() {
    return {
      hasCreate: true,
      site: null,
      editorConfig: getCkeditorConfig(),
      description: '',
      short_description: '',
      form: this.$form.createForm(this),
    };
  },
  methods: {
    getMiniSite() {
      MiniSite.getMiniSiteByApplicationId(this.id)
        .then((res) => {
          if (res.status === 200) {
            this.hasCreate = false;
            return res.json();
          } else {
            this.hasCreate = true;
            // @ts-ignore
            this.site = initialSite;
          }
        })
        .then(({data}) => {
          this.site = data;
          const {description, short_description, enable} = data;
          this.description = description;
          this.short_description = short_description;
          this.form.setFieldsValue({
            enable: Boolean(enable),
          });
        });
    },

    updateMiniSite() {
      this.form.validateFieldsAndScroll((errors: any, values: any) => {
        if (isEmpty(errors)) {
          const data = {
            ...values,
            description: this.description,
            short_description: this.short_description,
            enable: Number(values.enable),
          };

          MiniSite.updateMiniSiteForApplication(data, this.id)
            .then((res: any) => res.json())
            .then((json: any) => {
              this.$message.success('Мини сайт успешно обновлен :)');
            });
        }
      });
    },

    /**
     * @description Создание (сохранение) мини сайта
     * @param {object} e
     * @return {void}
     */
    handleSaveSite(e: any) {
      this.form.validateFieldsAndScroll((errors: any, values: any) => {
        if (isEmpty(errors)) {
          const data = {
            ...values,
            description: this.description,
            short_description: this.short_description,
            enable: Number(values.enable),
          };

          MiniSite.createMiniSiteForApplication(data, this.id)
            .then((res: any) => res.json())
            .then((json: any) => {
              this.$message.success('Мини сайт успешно создан :)');
            });
        }
      });
    },
    /**
     * @description Обработка ошибок
     * @param {string} message
     * @return {function}
     */
    handleErrors(message: string) {
      return ({errors, code, data}: any) => {
        if (errors && code === 422) {
          Object.values(errors).forEach((values: any) => {
            values.forEach((value: string) => {
              this.$message.error(value);
            });
          });
        } else {
          // const { linkShow } = data;
          this.$message.success(message, 2);
          // setTimeout(() => {
          //   window.open(linkShow);
          // }, 2000);
        }
        return data;
      };
    },
    getWordCount(text: string): number {
      return text
        .replace(/<(?:.|\n)*?>/gm, '')
        .replace(/(\r\n|\n|\r)/gm, '')
        .replace('&nbsp;', '')
        .trim().length;
    },
  },
});
