import { Route } from '@/types/route';
import List from './List.vue';
import Add from './Add.vue';

export const listRoute = '/settings/external-code/';
export const addRoute = '/settings/external-code/add';
export const editRoute = '/settings/external-code/:id';

export const externalCodeRoutes: Route[] = [
  {
    path: listRoute,
    name: 'listCode',
    component: List,
    breadcrumbName: 'Сторонний код',
    meta: {
      title: 'Сторонний код',
    },
  },
  {
    path: addRoute,
    name: 'addCode',
    component: Add,
    breadcrumbName: 'Добавить код',
    meta: {
      title: 'Добавить код',
    },
  },
  {
    path: editRoute,
    name: 'editCode',
    component: Add,
    props: true,
    breadcrumbName: 'Редактировать код',
    meta: {
      title: 'Редактировать код',
    },
  },
];

export default { externalCodeRoutes };
