import ArticleList from './ArticlesList.vue';
import ChangeArticle from './ArticlesChange.vue';
import Categories from './Categories.vue';
import EditCategory from './EditCategory.vue';

export const articleRoute = '/articles';
export const articleChangeRoute = '/articles/:id/edit';
export const articleAddRoute = '/articles/add/';
export const articleCategoryEditRoute = '/articles/category/:id/edit';
export const articleCategoryRoute = '/articles/category';
export const articleCategoryAddRoute = '/articles/category/add';

export const articles = [
  {
    name: 'Edit article category',
    path: articleCategoryEditRoute,
    component: EditCategory,
    props: true,
    breadcrumbName: 'Редактировать категорию',
    meta: {
      title: 'Редактировать категорию',
    },
  },
  {
    name: 'Add article category',
    path: articleCategoryAddRoute,
    component: EditCategory,
    breadcrumbName: 'Создать категорию',
    meta: {
      title: 'Создать категорию',
    },
  },
  {
    name: 'Article Category',
    path: articleCategoryRoute,
    component: Categories,
    breadcrumbName: 'Категории статей',
    meta: {
      title: 'Категории статей',
    },
  },
  {
    name: 'Article',
    path: articleRoute,
    component: ArticleList,
    breadcrumbName: 'Статьи',
    meta: {
      title: 'Статьи',
    },
  },
  {
    name: 'EditArticle',
    path: articleChangeRoute,
    component: ChangeArticle,
    breadcrumbName: 'Редактировать статью',
    props: true,
    meta: {
      title: 'Редактировать статью',
    },
  },
  {
    name: 'AddArticle',
    path: articleAddRoute,
    component: ChangeArticle,
    breadcrumbName: 'Добавить статью',
    meta: {
      title: 'Добавить статью',
    },
  },
];

export default { articles };
