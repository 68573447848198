var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('PageTitle', {
    attrs: {
      "title": "Официальный представитель запросы"
    }
  }), _c('a-layout-content', {
    staticClass: "page__content"
  }, [_c('a-table', {
    attrs: {
      "columns": _vm.columns,
      "pagination": false,
      "dataSource": _vm.requests.data,
      "loading": _vm.loading,
      "rowKey": "id"
    },
    scopedSlots: _vm._u([{
      key: "verified_at",
      fn: function fn(verified_at) {
        return [_vm._v(" " + _vm._s(verified_at ? 'Да' : 'Нет') + " ")];
      }
    }, {
      key: "application",
      fn: function fn(application) {
        return [_vm._v(" " + _vm._s(application.name) + " ")];
      }
    }, {
      key: "status",
      fn: function fn(status) {
        return [_vm._v(" " + _vm._s(_vm.statusMap[status]) + " ")];
      }
    }, {
      key: "actions",
      fn: function fn(row) {
        return [row.verified_at && row.status === _vm.OFFICIAL_REPRESENTATIVE_REQUEST_STATUS_PENDING ? [_c('a-button', {
          attrs: {
            "type": "primary"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.accept(row);
            }
          }
        }, [_vm._v("Одобрить ")]), _c('a-button', {
          staticStyle: {
            "margin-left": "10px"
          },
          attrs: {
            "type": "danger"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.decline(row);
            }
          }
        }, [_vm._v("Отклонить ")])] : _vm._e()];
      }
    }])
  }), _c('a-row', {
    staticStyle: {
      "text-align": "right"
    }
  }, [_vm.requests.meta ? _c('a-pagination', {
    staticStyle: {
      "margin-top": "20px"
    },
    attrs: {
      "total": _vm.requests.meta.total,
      "pageSize": _vm.requests.meta.per_page
    },
    on: {
      "change": _vm.paginationChange
    },
    model: {
      value: _vm.requests.meta.current_page,
      callback: function callback($$v) {
        _vm.$set(_vm.requests.meta, "current_page", $$v);
      },
      expression: "requests.meta.current_page"
    }
  }) : _vm._e()], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }